import React, { useEffect, useState } from "react";
import ArrowLeft from "../assets/images/arrow-left-icon.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AllCountryJsonData from "../constants/locales/CountryTranslation.json";
import { AllLanguageFromJson } from "../constants/json/languages";
import axios from "axios";
import { ApiUrl } from "../config/config";
import SelectAvatar from "./SelectAvatar";
import avatarman from "../assets/images/avatar-man.png";
import avatarwoman from "../assets/images/avatar-woman.png";
import avatar1 from "../assets/images/icons/7.png";
import avatar2 from "../assets/images/icons/8.png";
import avatar3 from "../assets/images/icons/9.png";
import avatar4 from "../assets/images/icons/10.png";
import avatar5 from "../assets/images/icons/11.png";
import avatar6 from "../assets/images/icons/12.png";
import avatar7 from "../assets/images/icons/13.png";
import avatar8 from "../assets/images/icons/14.png";
import avatar9 from "../assets/images/icons/15.png";
import avatar10 from "../assets/images/icons/16.png";
import Chat from "../pages/home/Chat";

const AddGroupForm = ({ setGroupPopUpFalse, CallgetAllGroups, CallgetAllUsers }) => {
  const { t, i18n } = useTranslation();
  let currentLanguageSetting = localStorage.getItem("prefered_language")
    ? localStorage.getItem("prefered_language")
    : "en";
  const [currentLanguage, setCurrentLanguage] = useState(
    currentLanguageSetting
  );
  const [newSelectLang, setNewSelectLang] = useState(currentLanguage ? currentLanguage : "en");
  const [HideAvtar, setHideAvtar] = useState(true);
  const [username, setUsername] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [TopicName, setTopicName] = useState("");
  const [ChatRoom, setChatRoom] = useState("");
  const [Language, setLanguage] = useState(currentLanguage ? currentLanguage : "en");
  const [Subject1, setSubject1] = useState("");
  const [Subject2, setSubject2] = useState("");
  const [Subject3, setSubject3] = useState("");
  const [AvatarIndex, setAvatarIndex] = useState();
  const [GroupData, setGroupData] = useState();
  const [error, setError] = useState(false);
  const [emptyUser, setEmptyUser] = useState();
  const [emptyGroup, setEmptyGroup] = useState();
  const [AllUsers, setAllUsers] = useState();
  const [disableBtn, setDisableBtn] = useState(true);
  const [newAddedGroup, setNewAddedGroup] = useState();
  const [flagToOpenChat, setFlagToOpenChat] = useState(false);

  const localUserData = JSON.parse(localStorage.getItem("user"));

  const avatar = [
    avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10
  ];

  const onSubmitGroup = (e) => {
    e.preventDefault();
    setDisableBtn(false)
    let ApiData = {
      group_name: ChatRoom,
      topic_name: TopicName,
      language: Language,
      subject_1: Subject1,
      subject_2: Subject2,
      subject_3: Subject3,
    };
    const isGroupNameExists = GroupData?.some(
      (item) => item.group_name.toLowerCase() === ChatRoom.toLowerCase()
    );
    if (isGroupNameExists) {
      setEmptyGroup("Group Already Exist")
    } else if (ChatRoom && ChatRoom.length > 0) {
      setEmptyGroup()
      axios
        .post(`${ApiUrl}addNewGroup`, ApiData)
        .then(async (result) => {
          await addParticipantToGroup(ChatRoom);
          // await CallgetAllUsers() this is commented because chat modal is open by this page
          // await CallgetAllGroups() this is commented because chat modal is open by this page
          await fetchSelectedGroup()
          // setGroupPopUpFalse();   this is commented because chat modal is open by this page
        }).then(() => {
          setFlagToOpenChat(true)
          setDisableBtn(true)
          // setTimeout(() => {
          // window.location.reload();

          // CallgetAllGroups()
          // CallgetAllUsers()
          // setGroupPopUpFalse();
          // }, 1000);
        })
        .catch((err) => { });
    } else {
      setEmptyGroup("Enter Group Name")
    }
  };

  const exitGroup = () => {

    let ApiData = {
      userId: localUserData?.userId
    };
    axios
      .post(`${ApiUrl}/deleteChatUser`, ApiData)
      .then((res) => {
        // getAllGroups();
        localStorage.removeItem('user');
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
      });
    // remove person from group by socket id

  }

  const addParticipantToGroup = async (ChatRoom) => {
    let ApiData = {
      number: 1,
      participant: localUserData?.userId && localUserData?.userId,
    };
    await axios
      .post(`${ApiUrl}updateGroupParticipants/${ChatRoom}`, ApiData)
      .then((result) => {

        // CallgetAllGroups()
        // setGroupPopUpFalse();

      })
      .catch((err) => {
        console.log("updateGroupParticipants", err);
      });
  };

  useEffect(() => {
    if (localUserData) {
      // exitGroup()
      // setHideAvtar(false);
    }
    getAllGroups();
    getAllUsers()
  }, []);

  const getAllUsers = () => {
    axios
      .get(`${ApiUrl}/getAllUsers`)
      .then((userData) => {
        setAllUsers(userData?.data?.list);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddUserWithSocketID = () => {

    let ApiData = {
      name: username,
      avatar: selectedAvatar,
    };
    // const isNameExists = AllUsers?.some(
    //   (item) => item.name.toLowerCase() === username.toLowerCase()
    // );
    // if (isNameExists) {
    //   setEmptyUser("The user name is already in use")
    // } else

    if (username && username.length > 0 && selectedAvatar?.length > 0) {
      setEmptyUser()
      axios
        .post(`${ApiUrl}addNewUser`, ApiData)
        .then((res) => {

          let newData = {
            name: res?.data?.list?.name,
            userId: res?.data?.list?.userId,
            avatar: res?.data?.list?.avatar,
          }
          localStorage.setItem("user", JSON.stringify(newData));
          setHideAvtar(false);
        })
        .catch((err) => { });
    } else {
      setEmptyUser(`${t("The user name is already in use")}`)
    }
  };

  const getAllGroups = () => {
    axios
      .get(`${ApiUrl}/getAllGroups`)
      .then((groupData) => {
        setGroupData(groupData?.data?.list);
      })
      .catch((err) => {
        console.log("groupData", err);
      });
  };

  const fetchSelectedGroup = (groupinput) => {
    axios
      .get(`${ApiUrl}/getAllGroups`)
      .then((groupData) => {
        let group = groupData?.data?.list?.find((group) => {
          return group.group_name === ChatRoom;
        });
        setNewAddedGroup(group);

      })
      .catch((err) => {
        console.log("groupData", err);
      });
  };

  // const checkUserUniqueness = (groupinput) => {
  //   const isNameExists = AllUsers?.some(
  //     (item) => item.name.toLowerCase() === username.toLowerCase()
  //   );
  //   if (isNameExists) {
  //     setError(true)
  //   } else {
  //     setError(false);
  //   }
  // };

  return (
    <>
      {flagToOpenChat == false ?

        <div>
          {HideAvtar ? (
            <div className="middle text-centercontrol  readstory_middle">
              {/* <div className="B1"></div> */}
              <div className="B2 cards search_readstory">
                <div className="writestory-body-container">
                  <div
                    role="document"
                    className="modal-dialog modal-dialog-centered readastory-modal-dialog write-a-story-modal border-style-8  writestory_New"
                  >
                    <div className="modal-content">
                      <a
                        onClick={() => {
                          setGroupPopUpFalse();
                        }}
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close-button-style"
                      >
                        <span></span>
                      </a>
                      <div className="chat-user-list-bg">
                        <div className="chat-content-view">
                          <div className="chat-scroll">
                            <div className="chat-user-list">
                              {avatar?.map((item, index) => {
                                return (
                                  <Link
                                    to="#"
                                    className={`${index === AvatarIndex ? "active" : null
                                      } cardparent-list`}
                                    key={index}
                                    onClick={() => {
                                      setSelectedAvatar(item);
                                      setAvatarIndex(index);
                                    }}
                                  >
                                    <img src={item} alt="" />
                                  </Link>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Cap">
                        <div className="joinChatContainer mb-3">
                          <div className="col-md-4 mx-auto">
                            <div className="form-group input-custom-field inputbg">
                              <input
                                className="form-control"
                                type="text"
                                placeholder={t("username")}
                                onChange={(event) => {
                                  setUsername(event.target.value);
                                }}
                              />
                            </div>
                            {emptyUser && (
                              <div>
                                <span className="text-danger">
                                  {emptyUser}
                                </span>
                              </div>
                            )}
                            <button
                              onClick={() => {
                                AddUserWithSocketID();
                              }}
                              type="submit"
                              className="btn-apply write-btn-bg cmn-submit-button"
                            >
                              <span></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="middle text-centercontrol  readstory_middle">
              {/* <div className="B1"></div> */}
              <div className="B2 cards search_readstory">
                <div className="writestory-body-container">
                  <div
                    role="document"
                    className="modal-dialog modal-dialog-centered readastory-modal-dialog write-a-story-modal border-style-8  writestory_New"
                  >
                    <div className="modal-content">
                      <Link
                        to=""
                        className="close-button-style-write"
                        onClick={() => {
                          setGroupPopUpFalse();
                        }}
                      >
                        <span></span>
                      </Link>
                      <form className="ng-untouched ng-pristine ng-invalid">
                        <div className="writestory_popup">
                          <div className="modal-body">
                            <div className="row custom-field-row write-align">
                              <div className="col-md-12 custom-field-col">
                                <div className="row custom-field-row rab-flex-direction">
                                  <div className="col-md-12 custom-field-heading cmn-title-head text-center">
                                    <h2>{t("SUBMIT")}</h2>
                                  </div>
                                </div>
                              </div>
                              {/* topic  */}
                              {/* <div className="col-md-4 custom-field-col">
                            <div className="form-group input-custom-field">
                              <input
                                type="text"
                                placeholder={t("Topic")}
                                className="form-control ng-untouched ng-pristine ng-invalid "
                                onChange={(e) => {
                                  setTopicName(e.target.value);
                                }}
                              />
                            </div>
                            {errors.TopicName && (
                              <span className="text-danger">
                                {t("TopicName is required")}
                              </span>
                            )}
                          </div> */}

                              {/* publish  */}
                              <div className="col-md-6 custom-field-col">
                                <div className="form-group input-custom-field">
                                  <input
                                    type="text"
                                    placeholder={t("Topic")}
                                    formcontrolname="published_by"
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    onChange={(e) => {
                                      setChatRoom(e.target.value);
                                    }}
                                  />
                                </div>
                                {emptyGroup && (
                                  <div>
                                    <span className="text-danger">
                                      {emptyGroup}
                                    </span>
                                  </div>
                                )}
                              </div>
                              {/* lang  */}
                              <div className="col-md-6 custom-field-col">
                                <div className="form-group select-custom-field">
                                  <select
                                    formcontrolname="language_id"
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    value={newSelectLang}
                                    onChange={(e) => {
                                      localStorage.setItem(
                                        "language_selected",
                                        e.target.value
                                      );
                                      setLanguage(e.target.value);
                                      setNewSelectLang(e.target.value)
                                    }}
                                  >
                                    {!currentLanguage ? (
                                      <option value="">Language</option>
                                    ) : null}

                                    {AllLanguageFromJson &&
                                      AllLanguageFromJson.map((x, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={x.BCP47}
                                          // selected={
                                          //   x.BCP47 == currentLanguage
                                          // }
                                          >
                                            {x.Native}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>

                              {/* sub 1  */}
                              <div className="col-md-6 custom-field-col">
                                <div className="form-group input-custom-field">
                                  <input
                                    type="text"
                                    placeholder={t("Subject 1")}
                                    formcontrolname="subject_id"
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    onChange={(e) => {
                                      setSubject1(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              {/* sub2  */}
                              <div className="col-md-6 custom-field-col">
                                <div className="form-group input-custom-field">
                                  <input
                                    type="text"
                                    placeholder={t("Subject 2")}
                                    formcontrolname="subject_second"
                                    className="form-control ng-untouched ng-pristine ng-valid"
                                    onChange={(e) => {
                                      setSubject2(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              {/* sub - 3  */}
                              {/* <div className="col-md-4 custom-field-col">
                            <div className="form-group input-custom-field">
                              <input
                                type="text"
                                placeholder={t("Subject 3")}
                                formcontrolname="subject_third"
                                className="form-control ng-untouched ng-pristine ng-valid"
                                onChange={(e) => {
                                  setSubject3(e.target.value);
                                }}
                              />
                            </div>
                          </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="btn-cmn-group writestory_mobile_top new_close_write">
                          {disableBtn ? <button
                            type="submit"
                            className="btn-apply write-btn-bg cmn-submit-button"
                            onClick={(e) => {
                              onSubmitGroup(e);
                            }}
                          >
                            <span></span>
                          </button> : <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="continue-btn-group">
                    <a
                      routerlink="/home"
                      className="btn-continue arrow-bounce-right"
                      href="/home"
                    >
                      <img src={ArrowLeft} />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="B3"></div> */}
            </div>
          )}
        </div> :


        <div className="middle text-centercontrol  readstory_middle">
          {/* <div className="B1"></div> */}
          <div className="B2 cards search_readstory">
            <div className="writestory-body-container">
              <div
                role="document"
                className="modal-dialog modal-dialog-centered readastory-modal-dialog write-a-story-modal border-style-8  writestory_New"
              >
                <div className="modal-content">
                  <Chat
                    username={username}
                    selectedAvatar={selectedAvatar}
                    room={ChatRoom}
                    SelectedGroupData={newAddedGroup}
                    groupisfull={'Join'}
                    emptyNumber={null}
                    validUser={true}
                    addedUserCheck={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AddGroupForm;
