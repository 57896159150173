import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import ChatRoom from "../../chatapp/ChatRoom";
import avtar from "../../assets/images/avatar-man.png";
import downarrow from "../../assets/images/drop-down-arrow.png";
import Zoom from "react-reveal/Zoom";
import axios from "axios";
import { ApiUrl } from "../../config/config";
import { useTranslation } from "react-i18next";
import SubmitButton from "../../assets/images/submit-button-s-icon.svg";
import Modal from "react-modal";
import toastr from "toastr";
import { Link } from "react-router-dom";
import "toastr/build/toastr.min.css";
import "./toast.css";
import Button from 'react-bootstrap/Button';
import Microphone from "../../assets/images/mick1.png";
import StopIcon from "../../assets/images/stop-icon.svg";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import exit_btn from "../../assets/images/exit_btn.png"
import io from 'socket.io-client';


function Chat({
  username,
  room,
  clientsInGroup,
  validUser,
  selectedAvatar,
  SelectedGroupData,
  emptyNumber,
  groupisfull,
  isExistingrup,
  addedUserCheck
}) {
  const { t, i18n } = useTranslation();
  const [IsThankOpen, setIsThankOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [showChatRoom, setShowChatRoom] = useState(true);
  const [showsingleMgsPopUp, setShowsingleMgsPopUp] = useState(false);
  const [singleMessageData, setSingleMessageData] = useState();
  const [ReferrenceId, setReferrenceId] = useState();
  const [dataBaseMsgList, setDataBaseMsgList] = useState();
  const [filterMsgList, setFilterMsgList] = useState();
  const [newfilterMsgList, setNewFilterMsgList] = useState();
  const [AllUsers, setAllUsers] = useState();
  const [GroupData, setGroupData] = useState(SelectedGroupData);
  const [warningModalData, setWarningModalData] = useState();
  const localUserData = JSON.parse(localStorage.getItem("user"));
  const language_selected = localStorage.getItem("language_selected") ? localStorage.getItem("language_selected") : "en-US";
  const [show, setShow] = useState(false);
  const [allowSpeach, setAllowSpeach] = useState();
  const [newtextArea, setNewTextarea] = useState("");
  const [textArea, setTextarea] = useState("");
  const [infoPopup, setInfoPopup] = useState(false);
  const [OtherPersonInfoPopup, setOtherPersonInfoPopup] = useState(false);
  const [OtherPersonInfoDetail, setOtherPersonInfoDetail] = useState();
  const [OtheraddedPersonInfoDetail, setOtheraddedPersonInfoDetail] = useState(false);
  const [deletePersonFlag, setDeletePersonFlag] = useState(false);
  const [userExistFlag, setUserExistFlag] = useState();
  // socket 
  const [socket, setSocket] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (addedUserCheck) {
      setUserExistFlag(addedUserCheck)
      console.log("+++++++++++++++++++++++++", addedUserCheck);
    }
  }, [addedUserCheck])


  // socket code  start
  useEffect(() => {
    const newSocket = io.connect('https://api2.heresays.com'); // Replace with your server URL
    setSocket(newSocket);
    updateGroupSimulation()

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket?.on('userRemoved', (userId, groupname) => {
      let userDetail = { userId: userId, groupname: groupname }
      if (userId == JSON.parse(localStorage.getItem("user")).name) {
        setInfoPopup(true);
      } else {
        setOtherPersonInfoDetail(userDetail)
        setOtherPersonInfoPopup(true)
      }
    });

    // new added user 
    socket?.on('userAdded', (userId, groupname) => {
      let userDetail = { userId: userId, groupname: groupname }
      if (JSON.parse(localStorage.getItem("user"))?.userId && userId == JSON.parse(localStorage.getItem("user")).name) {
        console.log("++++++++++++++     itself added");
      } else {
        console.log("++++++++++++++     new person added");
        setOtheraddedPersonInfoDetail(true)
        setOtherPersonInfoDetail(userDetail)
        setOtherPersonInfoPopup(true)
      }
    });

  }, [socket]);

  // socket code  end


  const updateGroupSimulation = () => {
    let ApiData = {
      id: SelectedGroupData?.groupid
    }
    axios
      .post(`${ApiUrl}updateGroupSimulation`, ApiData)
      .then((result) => {
      })
      .catch((err) => { });
  }

  const sendMessage = async () => {
    if (textArea !== "") {
      const messageData = {
        room: room,
        author: username,
        // socketID: socket?.id,
        avatar: selectedAvatar,
        message: textArea.replace(/'/g, `/"`),
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };
      setMessageList((list) => [...list, messageData]);
      addMessage();
      setTextarea("");
    }
  };

  const closeChatRoom = () => {
    setShowChatRoom(false);
  };

  const addMessage = () => {
    let ApiData = {
      groupName: room,
      avatar: selectedAvatar,
      userId: localUserData?.userId && localUserData?.userId,
      textMessage: textArea.replace(/'/g, `/"`),
      time:
        new Date(Date.now()).getHours() +
        ":" +
        new Date(Date.now()).getMinutes(),
    };
    axios
      .post(`${ApiUrl}addNewMessage`, ApiData)
      .then((result) => {
        getAllMessages();
        getAllUsers();
        getAllGroups();
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (emptyNumber?.length > 0 && !validUser && JSON.parse(localStorage.getItem("user"))?.userId) addParticipantToGroup(emptyNumber[0])
  }, [emptyNumber, JSON.parse(localStorage.getItem("user"))?.userId])


  const addParticipantToGroup = (num) => {
    let ApiData = {
      number: num,
      participant: JSON.parse(localStorage.getItem("user"))?.userId && JSON.parse(localStorage.getItem("user"))?.userId,
    };
    axios
      .post(`${ApiUrl}updateGroupParticipants/${room}`, ApiData)
      .then(async (result) => {
        // await getAllUsers();
        await getAllGroups();
        // await getAllMessages()
        setShowChatRoom(false);
        if (socket) {
          socket?.emit('addNewUserToGroup', JSON.parse(localStorage.getItem("user"))?.name, room);

        }
      })
      .catch((err) => {
        console.log("updateGroupParticipants", err);
      });
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))?.userId) {
      setUserExistFlag(checkUserIdExists(GroupData, JSON.parse(localStorage.getItem("user")).userId))
    }
  }, [GroupData, JSON.parse(localStorage.getItem("user"))?.userId])


  useEffect(() => {
    getAllGroups();
    getAllMessages();
    getAllUsers();
  }, []);

  useEffect(() => {
    console.log(
      "SpeechRecognition.browserSupportsSpeechRecognition()",
      setAllowSpeach(SpeechRecognition.browserSupportsSpeechRecognition())
    );
  }, []);

  useEffect(async () => {
    let filterGroupData = await dataBaseMsgList?.map((msgDetail) => {

      for (let i = 1; i <= 5; i++) {
        msgDetail[`userDetail`] = AllUsers?.find(
          (user) => user.userId === msgDetail[`userId`]
        );
      }
      return msgDetail;
    });
    const newDATA = filterGroupData?.filter((data) => {
      for (let i = 1; i <= 5; i++) {
        if (data[`userDetail`]?.userId === GroupData[`participants${i}`]) {
          return true; // Return true if the condition is met for any iteration
        }
      }
      return false; // Return false if the condition is not met for any iteration
    });


    if (filterGroupData && newDATA) setFilterMsgList(newDATA);

  }, [dataBaseMsgList, AllUsers, GroupData]);


  const getAllUsers = () => {
    axios
      .get(`${ApiUrl}/getAllUsers`)
      .then((userData) => {
        setAllUsers(userData?.data?.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllGroups = () => {
    axios
      .get(`${ApiUrl}/getAllGroups`)
      .then((groupData) => {
        let group = groupData?.data?.list?.find((group) => {
          return group.group_name === room;
        });
        setGroupData(group);

      })
      .catch((err) => {
        console.log("groupData", err);
      });
  };



  const getAllMessages = async () => {
    // await getAllGroups()
    axios
      .get(`${ApiUrl}getAllMessage/${room}`)
      .then(async (result) => {
        setDataBaseMsgList(result?.data?.list);
        console.log("all new message data", result?.data?.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  // new user remove code 

  const returnFalseifuserRemove = () => {

    const isUserIDPresent = GroupData?.length > 0 &&
      (() => {
        for (const obj of GroupData) {
          const matchingKey = Object.keys(obj).find(key => key.startsWith('participants') && obj[key]?.userId === 571);
          if (matchingKey) {
            return true; // Match found, exit the loop and return true
          } else {
            // No match found for this object, perform any actions needed
            // For example, log the userId value

          }
        }
        return 234; // If no match is found after iterating through all objects
      })();
  }



  function closeThankModal() {
    setIsThankOpen(false);
  }
  useEffect(() => {
    localStorage.setItem(
      "messageList",
      messageList.map((message) => {
        return JSON.stringify(message);
      })
    );
  }, [messageList]);

  // useEffect(() => {
  // socket.on("receive_message", (data) => {

  //   setMessageList((list) => [...list, data]);
  // });

  // const clientsInRoom = socket.io.sockets.adapter.rooms[room];
  // const numClients = clientsInRoom ? clientsInRoom.length : 0;

  // }, [socket]);

  const sendReq = (chatGroupData) => {

    addParticipantToGroup(chatGroupData);
    setShowChatRoom(false);
  };

  const singleMessagePopup = (messageData) => {
    setShowsingleMgsPopUp(true);
    setSingleMessageData(messageData);
  };

  const deleteuserConfirm = (data) => {
    setWarningModalData(data)
    handleShow(data)
  }

  const deleteuserConfirmnew = () => {
    handleShow()
  }


  const checkUserIdExists = (data, userId) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] === userId) {
          return true;
        }
      }
    }
    return false;
  };

  // const checkUserIdExists = (data, userIdToFind) => {
  //   // Iterate over each property in the object
  //   for (const key in data) {
  //     // Check if the property is an object (nested level)
  //     if (typeof data[key] === 'object' && data[key] !== null) {
  //       // Recursively check the nested object
  //       if (checkUserIdExists(data[key], userIdToFind)) {
  //         return true; console.log("trueeeeeeeeeeeeeeee");
  //       }
  //     } else if (key === 'userId' && data[key] === userIdToFind) {
  //       // Check if the current property is 'userId' and matches the target value
  //       return true; console.log("falseeeeeeeee");
  //     }
  //   }
  //   // If no match is found
  //   return false; console.log("falseeeeeeeee not");
  // };

  // const YesToDelete = ()=>{

  // }

  const findPartNum = (SelectedGroupData, personData) => {

    let partNum = 0;
    for (let i = 1; i <= 5; i++) {
      if (SelectedGroupData && personData && SelectedGroupData[`participants${i}`] == personData?.userId) {
        partNum = i;
      }
    }
    return partNum;
  }

  const deletePerson = async (personData) => {

    const partData = await findPartNum(GroupData, personData)
    // var partNum = 0;
    // for (let i = 1; i <= 5; i++) {
    //   if (
    //     SelectedGroupData[`participants${i}`]?.userId === personData?.userId
    //   ) {
    //     partNum = i;
    //   }
    // }

    // const isUserIDPresent = Object.values(SelectedGroupData).some(value => value === String(personData?.userId));
    let ApiData = {
      groupName: personData?.groupName,
      userId: personData?.userId,
      partNumber: partData,
    };
    axios
      .post(`${ApiUrl}/deleteUserInGroup`, ApiData)
      .then((res) => {
        getAllMessages();
        getAllGroups()
        toastr.info(
          `${personData?.userId} ${t("is")} ${t("removed")} ${t("from")}   ${personData?.groupName}`
        );
        setWarningModalData()
        handleClose()
        if (socket) {
          socket?.emit('removeUserFromGroup', personData?.userDetail?.name, personData?.groupName);
        }

        // if (personData?.userId === JSON.parse(localStorage.getItem("user"))?.userId) {

        // }
      })
      .catch((err) => {
        console.log("deleteuser err", err);
        toastr.info(t("User is not present in the group"));
      });
    // remove person from group by socket id
  };



  useEffect(() => {
    const interval = setInterval(() => {
      // Your function logic here
      getAllGroups()
      getAllMessages();
      getAllUsers();
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const postMsgAsStory = () => {
    if (singleMessageData) {
      let ApiData = {
        topic_name: room,
        topic_details: singleMessageData?.textMessage.replace(/'/g, `/"`),
        publisher_name: username,
        book_images: "",
        language: "en",
        country: "",
        city: "",
        place: "",
        subject_1: "",
        subject_2: "",
        subject_3: "",
        date: Date.now(),
        title: "",
      };
      axios
        .post(`${ApiUrl}addListStory`, ApiData)
        .then((res) => {
          axios
            .put(
              `${ApiUrl}updateListStory/${res.data.list && res.data.list.insertId
              }`,
              {
                reference_id: `HERESAYS000${res.data.list && res.data.list.insertId
                  }`,
              }
            )
            .then((result) => {
              setReferrenceId(res.data.list.insertId);
              setShowsingleMgsPopUp(false);
              setIsThankOpen(true);
            })
            .catch((errr) => {
              console.log(errr);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // mick 

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();


  const closeInfoPopup = () => {
    setInfoPopup(false)
  }

  const closeOtherPersonInfoPopup = () => {
    setOtherPersonInfoPopup(false)
    setOtheraddedPersonInfoDetail(false)
  }

  const startListening = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: language_selected ? language_selected : "en-US",
    });
  };
  const stopListening = () => {
    SpeechRecognition.abortListening();
    resetTranscript();
    if (newtextArea) setTextarea(textArea + newtextArea + " ");
    setNewTextarea();
  };
  useEffect(() => {
    setNewTextarea(transcript);
  }, [transcript]);

  const handleTextArea = (e) => {
    setTextarea(e.target.value);
  };

  const exitGroup = () => {

    let ApiData = {
      userId: localUserData?.userId
    };
    axios
      .post(`${ApiUrl}/deleteChatUser`, ApiData)
      .then((res) => {
        getAllGroups();
        toastr.info(
          `${localUserData?.userId} ${t("is")} ${t("deleted")}`
        );
        if (socket) {
          socket?.emit('removeUserFromGroup', localUserData?.name, GroupData?.group_name);

        }
        localStorage.removeItem('user');
        handleClose()
        window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        toastr.info(t("User is not present in the group"));
      });
    // remove person from group by socket id

  }

  const MickSupportedLanguage = [
    "en",
    "en-US",
    "zh-CN",
    "es-ES",
    "ar-SA",
    "ru-RU",
    "bn-BD",
    "pt-PT",
    "id-ID",
    "fr-FR",
    "af",
    "eu",
    "ca",
    "ar-SA",
    "cs",
    "nl-NL",
    "fi",
    "gl",
    "de-DE",
    "el-GR",
    "he",
    "hu",
    "is",
    "it-IT",
    "ja",
    "ko",
    "la",
    "no-NO",
    "pl",
    "ro-RO",
    "sr-SP",
    "sk",
    "sv-SE",
    "tr",
    "zu",
  ];

  return showChatRoom && !validUser ? (
    <>
      {/* <ChatRoom
        sendReq={sendReq}
        clientsInGroup={clientsInGroup}
        messageList={messageList}
        SelectedGroupData={SelectedGroupData}
        filterMsgList={filterMsgList && filterMsgList}
        room={room}
        closeChatRoom={closeChatRoom}
      /> */}
      <div className="chat-window">
        <div style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          margin: "auto",
          // height: "50%",
        }} className="spinner-border" role="status">/
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  ) : (
    <div className="chat-window">
      <div className="chat-header">
        <p>{t("live chat")}
          {/* <img alt="" src={exit_btn} className="close-button-style" onClick={() => { deleteuserConfirmnew() }} /> */}
          {userExistFlag == true && <a
            onClick={() => { deleteuserConfirmnew() }}
            data-dismiss="modal"
            aria-label="Close"
            className="close-button-style"
          >
            <span></span>
          </a>}
        </p>

      </div>
      <div className="chat-body bg-transparent">
        <ScrollToBottom className="message-container">
          {!filterMsgList ? (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            filterMsgList?.map((messageContent, index) => {
              return (
                <>
                  <div className="d-flex chat-listusers" key={index}>
                    <div className="avtar-icon">
                      <img src={messageContent?.userDetail?.avatar} alt="" />
                      <p id="author">{messageContent?.userDetail?.name}</p>
                    </div>

                    <div className="message" id={"you"}>
                      {[1, 2, 3, 4, 5].map((num) => {
                        if (
                          GroupData && userExistFlag == true &&
                          GroupData[`participants${num}`] ===
                          messageContent?.userId && GroupData[`participants${num}`] !==
                          localUserData?.userId
                        ) {
                          return (
                            <Link
                              key={num}
                              to="#"
                              data-dismiss="modal"
                              aria-label="Close"
                              className="close-button-style"
                              onClick={() => {
                                // deletePerson(messageContent);
                                deleteuserConfirm(messageContent)
                              }}
                            >
                              <span></span>
                            </Link>
                          );
                        }
                      })}
                      {/* <Link
                        to="#"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close-button-style"
                        onClick={() => {
                          deleteuserConfirm()
                          handleShow()

                        }}
                      >
                        <span></span>
                      </Link> */}
                      <div>
                        <div className="message-content">
                          <p>{messageContent.textMessage}</p>
                        </div>
                        <div className="message-meta">
                          <p id="time">{messageContent.msgtime}</p>
                        </div>
                        {username === messageContent?.userDetail?.name ||
                          1 === 1 ? (
                          <a
                            href="#"
                            className="downarrow01"
                            onClick={() => {
                              singleMessagePopup(messageContent);
                            }}
                          >
                            <img src={downarrow} alt="download" />
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          )}
        </ScrollToBottom>
      </div>
      {userExistFlag == true ? <div className="chat-footer">
        {
          MickSupportedLanguage.includes(language_selected) && allowSpeach ? (
            // <div className="help-icon">
            <button className="mick_icon"
              type="button"
              // className="type-camera-btn st-cmn-btn"
              onTouchStart={startListening}
              onMouseDown={startListening}
              onTouchEnd={stopListening}
              onMouseUp={stopListening}
            >
              <span>
                <i>
                  {" "}
                  {listening ? (
                    <img
                      src={StopIcon}

                      alt="img"
                      onContextMenu="return false;"
                      onCopy="return false"
                      className="mick_image"
                    />
                  ) : (
                    <img
                      src={Microphone}

                      alt="img"
                      onContextMenu="return false;"
                      onCopy="return false"
                      className="mick_image"
                    />
                  )}
                </i>
              </span>
            </button>
            // </div>
          ) : null}

        <input
          type="text"
          value={textArea + transcript}
          onChange={handleTextArea}
          // value={currentMessage}
          placeholder="Hey..."
          // onChange={(event) => {
          //   setCurrentMessage(event.target.value);
          // }}
          onKeyPress={(event) => {
            event.key === "Enter" && sendMessage();
          }}
        />

        <button onClick={sendMessage}>&#9658;</button>

      </div> : <div className="chat-footer" > <marquee style={{ margin: "auto" }} scrollamount="10">{t("The group is now filled, however if someone leaves, you can still join and speak")}.</marquee></div>}
      <Zoom when={showsingleMgsPopUp} center>
        <div
          id="DisclaimerModall"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ContactModal"
          aria-hidden="true"
          data-backdrop="false"
          className={
            showsingleMgsPopUp
              ? "modal overflow-hidden fade zoom-in zoom-in-left show"
              : "modal fade zoom-in zoom-in-left"
          }
          style={
            showsingleMgsPopUp ? { display: "block" } : { display: "none" }
          }
        >
          <div className="modal-dialog modal-dialog-centered sociallogin-modal-dialog chat_modal">
            <div className="modal-content">
              <a
                onClick={() => {
                  setShowsingleMgsPopUp(false);
                }}
                data-dismiss="modal"
                aria-label="Close"
                className="close-button-style"
              >
                <span></span>
              </a>
              <div className="modal-dialog modal-dialog-centered contact-modal-dialog w-100">
                <div className="modal-body border-style-88">
                  <div className="contact-body-ind p-4 pt-2">
                    <div className="contact-checkbox-groups">
                      <div className="text-center">
                        <h2 className="text-uppercase title-fonts">{singleMessageData?.userDetail?.name}</h2>
                        <img
                          src={singleMessageData?.userDetail?.avatar}
                          width={70}
                          className="my-auto"
                          alt=""
                        />
                      </div>
                      <div className="my-3 single-msg-content">
                        {/* <h4>{singleMessageData?.userDetail?.name}</h4> */}
                        <div className="message-content2">
                          {filterMsgList?.map((item, index) => {
                            return (
                              <>
                                {item?.userDetail?.name == singleMessageData?.userDetail?.name ? <p key={index}>{item?.textMessage} </p> : null}
                              </>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    {/* <button
                      onClick={() => {
                        postMsgAsStory();
                      }}
                      type="submit"
                      className="btn-apply write-btn-bg cmn-submit-button"
                    >
                      <span></span>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Zoom>


      {/* single message shown popup  */}
      <Modal
        id="chat-post-success"
        className="modal-popup-01 typestory_test_thank_modal chat-post-success"
        isOpen={IsThankOpen}
        onRequestClose={closeThankModal}
        contentLabel="Example Modal"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered sociallogin-modal-dialog thankstype-modal-dialog typestory_test_thank_inner_modal"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <p className="text-uppercase">
                {t("Your post has been properly submitted")}
              </p>
              <p>{t("and is published under")}</p>
              <h3>ID:HERESAYS000{ReferrenceId} </h3>
              <p>{t("Please copy this for your reference")}</p>
              <a
                onClick={() => {
                  setIsThankOpen(false);
                  setShowsingleMgsPopUp(false);
                }}
              >
                <img
                  src={SubmitButton}
                  onClick={() => {
                    setIsThankOpen(false);
                    setShowsingleMgsPopUp(false);
                  }}
                  alt="post"
                />
              </a>
            </div>
          </div>
        </div>
      </Modal>


      {/* user delete modal */}
      <Modal
        id="chat-post-success"
        className="modal-popup-01 typestory_test_thank_modal chat-post-success"
        isOpen={show}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered sociallogin-modal-dialog thankstype-modal-dialog typestory_test_thank_inner_modal"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <p className="text-uppercase">
                {t("Warning")}
              </p>
              {warningModalData ? <p>{t(`Do You Really Want To Remove User`)} <span style={{ color: "black" }}>{warningModalData?.userDetail?.name} </span> From <span style={{ color: "black" }}>{warningModalData?.groupName} </span> Group</p> :
                <p>{t(`Do You Really Want To Leave The Group`)}?</p>
              }

              <a
                onClick={handleClose}
                data-dismiss="modal"
                aria-label="Close"
                className="close-button-style"
              >
                <span></span>
              </a>

              <button
                onClick={() => {
                  warningModalData ? deletePerson(warningModalData) : exitGroup()
                }}
                type="submit"
                className="btn-apply write-btn-bg cmn-submit-button"
              >
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </Modal>


      {/* info popup that you are removed from group  */}
      <Modal
        id="chat-post-success"
        className="modal-popup-01 typestory_test_thank_modal chat-post-success"
        isOpen={infoPopup}
        onRequestClose={closeInfoPopup}
        contentLabel="Example Modal"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered sociallogin-modal-dialog thankstype-modal-dialog typestory_test_thank_inner_modal"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <p>{t("You have been excluded from this group")}</p>
              <a
                onClick={() => {
                  setInfoPopup(false);
                }}
              >
                <img
                  src={SubmitButton}
                  onClick={() => {
                    setInfoPopup(false); window.location.reload()
                  }}
                  alt="post"
                />
              </a>
            </div>
          </div>
        </div>
      </Modal>



      <Modal
        id="chat-post-success"
        className="modal-popup-01 typestory_test_thank_modal chat-post-success"
        isOpen={OtherPersonInfoPopup}
        onRequestClose={closeOtherPersonInfoPopup}
        contentLabel="Example Modal"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered sociallogin-modal-dialog thankstype-modal-dialog typestory_test_thank_inner_modal"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              {
                OtheraddedPersonInfoDetail == true ?
                  <p>{`${OtherPersonInfoDetail?.userId} ${t("is added to")}  ${OtherPersonInfoDetail?.groupname}`}</p> :
                  <p>{`${OtherPersonInfoDetail?.userId} ${t("is removed from")} ${OtherPersonInfoDetail?.groupname}`}</p>
              }

              <a
                onClick={() => {
                  setOtherPersonInfoPopup(false);
                  setOtheraddedPersonInfoDetail(false)
                }}
              >
                <img
                  src={SubmitButton}
                  onClick={() => {
                    setOtherPersonInfoPopup(false);
                    setOtheraddedPersonInfoDetail(false)
                  }}
                  alt="post"
                />
              </a>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default Chat;
