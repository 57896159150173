import {
  SIMPLE_ACTION,
  SET_FRUIT_ONE,
  SET_FRUIT_TWO,
  SET_GROUP_DATA,
} from "../actiontypes/ActionType";

export const simpleAction = (data) => ({
  type: SIMPLE_ACTION,
  payload: data,
});

export const setFruitOne = (data) => ({
  type: SET_FRUIT_ONE,
  payload: data,
});

export const setFruitTwo = (data) => ({
  type: SET_FRUIT_TWO,
  payload: data,
});

export const setgroupDataRedux = (data) => ({
  type: SET_GROUP_DATA,
  payload: data,
});
