import {
  SIMPLE_ACTION,
  SET_FRUIT_ONE,
  SET_FRUIT_TWO,
  SET_GROUP_DATA,
} from "../actiontypes/ActionType";

const initialState = {
  result: "",
  fruitOne: "",
  fruitTwo: "",
  groupData: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
const Simplereducer = (state = initialState, action) => {
  switch (action.type) {
    case SIMPLE_ACTION:
      return {
        ...state,
        result: action.payload,
      };
    case SET_FRUIT_ONE:
      return {
        ...state,
        fruitOne: action.payload,
      };
    case SET_FRUIT_TWO:
      return {
        ...state,
        fruitTwo: action.payload,
      };
    case SET_GROUP_DATA:
      return {
        ...state,
        groupData: action.payload,
      }
    default:
      return state;
  }
};

export default Simplereducer;
