import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import avatarman from "../assets/images/avatar-man.png";
import avatarwoman from "../assets/images/avatar-woman.png";
import Chat from "../pages/home/Chat";
import axios from "axios";
import { ApiUrl } from "../config/config";
import avatar1 from "../assets/images/icons/7.png";
import avatar2 from "../assets/images/icons/8.png";
import avatar3 from "../assets/images/icons/9.png";
import avatar4 from "../assets/images/icons/10.png";
import avatar5 from "../assets/images/icons/11.png";
import avatar6 from "../assets/images/icons/12.png";
import avatar7 from "../assets/images/icons/13.png";
import avatar8 from "../assets/images/icons/14.png";
import avatar9 from "../assets/images/icons/15.png";
import avatar10 from "../assets/images/icons/16.png";
import { useTranslation } from "react-i18next";
import closeIcon from "../assets/images/Close_Icon_Circle.png";
import lodash from "lodash";

const SelectAvatar = ({ room, SelectedGroupData, groupisfull, emptyNumber }) => {
  console.log("SelectedGroupData", SelectedGroupData);
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [selectedAvatarError, setSelectedAvatarError] = useState(false);
  const [AvatarIndex, setAvatarIndex] = useState();
  const [showChat, setShowChat] = useState(false);
  const [AllUsers, setAllUsers] = useState();
  const [usedAvatar, setUsedAvatar] = useState();
  const [unUsedAvatar, setUnUsedAvatar] = useState();
  const [addedAvatarArr, setAddedAvatarArr] = useState([]);

  const [clientsInGroup, setClientsInGroup] = useState([]);
  const localUserData = JSON.parse(localStorage.getItem("user"));
  const avatar = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10];
  const [error, setError] = useState(false);
  const AddAvatar = () => {
    const isNameExists = AllUsers?.some(
      (item) => item.name.toLowerCase() === username.toLowerCase()
    );

    if (!selectedAvatar) {
      setSelectedAvatarError(true)
    } else { setSelectedAvatarError(false) }

    // if (isNameExists) {
    //   setError(true)
    // } else {
    //   setError(false);
    if (username && selectedAvatar && room) {
      setSelectedAvatarError(false)
      // setShowChat(true);
      AddUserWithSocketID();
    }
    // }

  };

  useEffect(() => {
    if (localUserData) {
      setUsername(localUserData?.name);
      setSelectedAvatar(localUserData?.avatar);
      setShowChat(true);
    }
    getAllUsers();
  }, []);

  const getAllUsers = () => {
    axios
      .get(`${ApiUrl}/getAllUsers`)
      .then((userData) => {
        setAllUsers(userData?.data?.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddUserWithSocketID = () => {

    let ApiData = {
      name: username,
      group_id: SelectedGroupData?.groupid,
      avatar: selectedAvatar,
    };
    // const isNameExists = AllUsers?.some(
    //   (item) => item.name.toLowerCase() === username.toLowerCase()
    // );
    axios
      .post(`${ApiUrl}addNewUser`, ApiData)
      .then((res) => {
        // console.log("+++++++______9999res_____", res);
        let newData = {
          name: res?.data?.list?.name,
          userId: res?.data?.list?.userId,
          avatar: res?.data?.list?.avatar,
        }
        localStorage.setItem("user", JSON.stringify(newData));
        setShowChat(true);
        setError(false);
      })
      .catch((err) => { setError(true); });
  };
  useEffect(() => {
    // filter unused
    for (let i = 1; i <= 5; i++) {
      if (SelectedGroupData[`participants${i}`]?.avatar)
        setAddedAvatarArr((prev) => [
          ...prev,
          SelectedGroupData[`participants${i}`]?.avatar,
        ]);
    }
  }, []);



  useEffect(() => {
    let remainingAvatars = avatar?.filter(
      (value) => !addedAvatarArr.includes(value)
    );
    setUnUsedAvatar(remainingAvatars);
  }, [addedAvatarArr]);

  return !showChat ? (
    <div className="modal-body text-center">
      <div className="chat-user-list-bg">
        <div className="chat-content-view">
          <div className="chat-scroll">
            <div className="chat-user-list">
              {unUsedAvatar?.map((item, index) => {
                return (
                  <Link
                    to="#"
                    className={`${index === AvatarIndex ? "active" : null
                      } cardparent-list`}
                    key={index}
                    onClick={() => {
                      setSelectedAvatar(item);
                      setAvatarIndex(index);
                    }}
                  >
                    {/* <img className="close_icon_icon" src={closeIcon} alt="" /> */}
                    <img src={item} alt="" />
                  </Link>
                );
              })}
              {addedAvatarArr?.map((item, index) => {
                return (
                  <Link to="#" className={`cardparent-list`} key={index}>
                    <img className="close_icon_icon" src={closeIcon} alt="" />
                    <img src={item} alt="" />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="Cap">
        <div className="joinChatContainer mb-3">
          <div className="col-md-4 mx-auto">
            {selectedAvatarError && (
              <div>
                <span className="text-danger">
                  {t("Please choose an avatar")}
                </span>
              </div>
            )}
            <div className="form-group input-custom-field inputbg">
              <input
                className="form-control"
                type="text"
                placeholder={t("username")}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </div>
            {error && (
              <div>
                <span className="text-danger">
                  {t("The user name is already in use")}
                </span>
              </div>
            )}

            <button
              onClick={() => {
                AddAvatar();
              }}
              type="submit"
              className="btn-apply write-btn-bg cmn-submit-button"
            >
              <span></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Chat
      username={username}
      selectedAvatar={selectedAvatar}
      room={room}
      SelectedGroupData={SelectedGroupData}
      groupisfull={groupisfull}
      emptyNumber={emptyNumber}
    />
  );
};

export default SelectAvatar;
