import React, { useEffect, useState, useContext } from "react";
import "../pages/readstory/readstory.css";
import ArrowLeft from "../assets/images/arrow-left-icon.png";
import { useForm } from "react-hook-form";
// import Header from "../components/header";
import { AllLanguageFromJson } from "../constants/json/languages";
import {
  withNamespaces,
  NamespacesConsumer,
  Trans,
  useTranslation,
} from "react-i18next";

const GroupFilter = (props) => {
  const { t, i18n } = useTranslation();

  const [newQueryString, setNewQueryString] = useState("");
  const [input, setInput] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState("");
  const [dateType, setDateType] = useState("text");
  const [newSelectLang, setNewSelectLang] = useState("en");
  const [formData, setFormData] = useState({
    publishedby: "",
    TopicName: "",
    Country: "",
    City: "",
    Place: "",
    PublisherName: "",
    Subject1: "",
    Subject2: "",
    Subject3: "",
    ReferenceId: "",
    Date: "",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  let currentLanguageSetting = localStorage.getItem("prefered_language")
    ? localStorage.getItem("prefered_language")
    : "en";
  const [currentLanguage, setCurrentLanguage] = useState(
    currentLanguageSetting
  );

  const onFormDataInput = (e, name) => {
    let queryString = "";
    if (name == "groupName") {
      setFormData((prevState) => ({
        ...prevState,
        group_name: e.target.innerText,
      }));
      queryString = queryString + `[group_name]=${e.target.innerText}`;
      setNewQueryString(queryString);
    } else if (name == "TopicName") {
      setFormData((prevState) => ({
        ...prevState,
        topic_name: e.target.innerText,
      }));
      queryString = queryString + `[topic_name]=${e.target.innerText}`;
      setNewQueryString(queryString);
    } else if (name == "Language") {
      setFormData((prevState) => ({
        ...prevState,
        Language: e.target.innerText,
      }));
      queryString = queryString + `[language]=${e.target.innerText}`;
      setNewQueryString(queryString);
    } else if (name == "Subject1") {
      setFormData((prevState) => ({
        ...prevState,
        Subject1: e.target.innerText,
      }));
      queryString = queryString + `[subject_1]=${e.target.innerText}`;
      setNewQueryString(queryString);
    } else if (name == "Subject2") {
      setFormData((prevState) => ({
        ...prevState,
        Subject2: e.target.innerText,
      }));
      queryString = queryString + `[subject_2]=${e.target.innerText}`;
      setNewQueryString(queryString);
    } else if (name == "Subject3") {
      setFormData((prevState) => ({
        ...prevState,
        Subject3: e.target.innerText,
      }));
      queryString = queryString + `[subject_3]=${e.target.innerText}`;
      setNewQueryString(queryString);
    }

    setFilteredSuggestions([]);
    setInput(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions("");
  };
  const SuggestionsListComponent = ({ suggestion, name }) => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className;
          // Flag the active suggestion with a className
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
          return (
            <li
              className={className}
              key={suggestion}
              onClick={(e) => onFormDataInput(e, name)}
            >
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <em>No suggestions, you're on your own!</em>
      </div>
    );
  };

  const onSubmit = (data) => {
    props.searchStory(newQueryString);
    props.closemodal();
  };
  return (
    <div className=" readstory_background read_story_mobile">
      {/* <Header /> */}
      <div className="middle readstory_middle">
        <div className="B1"></div>
        <div className="B2 cards search_readstory">
          <div>
            <div className="readstory-body-container readStoryNew">
              <div
                role="document"
                className="modal-dialog modal-dialog-centered readastory-modal-dialog border-style-8"
              >
                <div className="modal-content">
                  <a
                    onClick={() => {
                      props.closemodal();
                    }}
                    href="#"
                    // routerlink="/storylist2"
                    className="close-button-style-read"
                  >
                    <span></span>
                  </a>
                  <form
                    noValidate=""
                    onSubmit={handleSubmit(onSubmit)}
                    className="ng-pristine ng-valid ng-touched"
                  >
                    <div className="btn-cmn-group writestory_mobile_top d-block d-md-none d-lg-none">
                      <button
                        type="submit"
                        className="btn-apply read-btn-bg cmn-submit-button"
                      >
                        <span></span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row custom-field-row row-align">
                        <div className="col-md-12 custom-field-col">
                          <div className="row custom-field-row rab-flex-direction">
                            {/* <div className="col-md-6 custom-field-col">

                            </div> */}
                            <div className="col-md-12 custom-field-heading cmn-title-head text-center">
                              <h2> {t("SUBMIT")}</h2>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-4 custom-field-col">


                        </div> */}

                        <div className="col-md-4 custom-field-col">
                          <div className="form-group input-custom-field">
                            <input
                              type="text"
                              formcontrolname="TopicName"
                              placeholder={t("Topic")}
                              className="form-control ng-pristine ng-valid ng-touched"
                              value={input.TopicName}
                              onClick={(e) => onFormDataInput(e, "TopicName")}
                              onChange={(e) => {
                                setNewQueryString(
                                  `[topic_name]=${e.target.value}`
                                );
                              }}
                            // onChange={(e) =>
                            //   onChange(e, allTopic, "TopicName")
                            // }
                            />
                            {/* {!input.TopicName && !formData.TopicName &&
                              <p
                                className="placeHolder text-white"
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "8px",
                                  bottom: "",
                                }}
                              >
                                {t('Topic')}
                              </p>
                            } */}

                            {showSuggestions == "TopicName" &&
                              input.TopicName && (
                                <SuggestionsListComponent name={"TopicName"} />
                              )}
                          </div>
                        </div>

                        <div className="col-md-4 custom-field-col">
                          <div className="form-group input-custom-field">
                            <input
                              type="text"
                              placeholder={t("chat room")}
                              formcontrolname="group_name"
                              //placeholder={}
                              className="form-control ng-pristine ng-valid ng-touched"
                              // value={
                              //   formData.PublisherName
                              //     ? formData.PublisherName
                              //     : input && input.publishedBy
                              // }
                              value={input.chatRoom}
                              onClick={(e) => onFormDataInput(e, "chatRoom")}
                              onChange={(e) => {
                                setNewQueryString(
                                  `[group_name]=${e.target.value}`
                                );
                              }}
                            // onChange={(e) =>
                            //   onChange(
                            //     e,
                            //     allPublishedBy,
                            //     "publishedBy"
                            //   )
                            // }
                            />
                            {/* {!input.publishedBy && !formData.PublisherName &&
                              <p
                                className="placeHolder text-white"
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "8px",
                                  bottom: "",
                                }}
                              >
                                {t('Published BY')}
                              </p>
                            } */}
                            {showSuggestions == "chatRoom" &&
                              input.publishedBy && (
                                <SuggestionsListComponent name={"chatRoom"} />
                              )}
                          </div>
                        </div>

                        <div className="col-md-4 custom-field-col">
                          <div className="form-group select-custom-field">
                            <select
                              formcontrolname="Language"
                              {...register("Language")}
                              className="form-control ng-pristine ng-valid ng-touched"
                              // value={newSelectLang}
                              // onChange={(e) => {
                              //   setNewSelectLang(e.target.value);
                              // }}
                              value={input.language}
                              onClick={(e) => onFormDataInput(e, "language")}
                              onChange={(e) => {
                                setNewQueryString(
                                  `[language]=${e.target.value}`
                                );
                                setNewSelectLang(e.target.value);
                              }}
                            >
                              {!currentLanguage ? (
                                <option value="">Language</option>
                              ) : null}

                              {/* <option value="">{langText?langText:"Language"}</option> */}
                              {AllLanguageFromJson &&
                                AllLanguageFromJson.map((x, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={x.BCP47}
                                    // selected={
                                    //   x.BCP47 == currentLanguage
                                    // }
                                    >
                                      {x.Native}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6 custom-field-col">
                          <div className="form-group input-custom-field">
                            <input
                              type="text"
                              formcontrolname="Subject1"
                              placeholder={t("Subject 1")}
                              className="form-control ng-untouched ng-pristine ng-valid"
                              // value={
                              //   formData.Subject1
                              //     ? formData.Subject1
                              //     : input.Subject1
                              // }
                              // onClick={(e) => onFormDataInput(e, "Subject1")}
                              // onChange={(e) =>
                              //   onChange(e, allSubject1, "Subject1")
                              // }

                              value={input.Subject1}
                              onClick={(e) => onFormDataInput(e, "Subject1")}
                              onChange={(e) => {
                                setNewQueryString(
                                  `[subject_1]=${e.target.value}`
                                );
                              }}
                            />
                            {/* {!input.Subject1 && !formData.Subject1 &&
                              <p
                                className="placeHolder text-white"
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "8px",
                                  bottom: "",
                                }}
                              >
                                {t('Subject 1')}
                              </p>
                            } */}
                            {showSuggestions == "Subject1" && input && (
                              <SuggestionsListComponent name={"Subject1"} />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 custom-field-col">
                          <div className="form-group input-custom-field">
                            <input
                              type="text"
                              formcontrolname="Subject2"
                              placeholder={t("Subject 2")}
                              className="form-control ng-untouched ng-pristine ng-valid"
                              // value={
                              //   formData.Subject2 ? formData.Subject2 : ""
                              // }
                              // onClick={(e) => onFormDataInput(e, "Subject2")}
                              // onChange={(e) =>
                              //   onChange(e, allSubject2, "Subject2")
                              // }

                              value={input.Subject2}
                              onClick={(e) => onFormDataInput(e, "Subject2")}
                              onChange={(e) => {
                                setNewQueryString(
                                  `[subject_2]=${e.target.value}`
                                );
                              }}
                            />
                            {/* {!input.Subject2 && !formData.Subject2 &&
                              <p
                                className="placeHolder text-white"
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "8px",
                                  bottom: "",
                                }}
                              >
                                {t('Subject 2')}
                              </p>
                            } */}
                            {showSuggestions == "Subject2" && input && (
                              <SuggestionsListComponent name={"Subject2"} />
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-4 custom-field-col">
                          <div className="form-group input-custom-field">
                            <input
                              type="text"
                              formcontrolname="Subject3"
                              placeholder={t("Subject 3")}
                              className="form-control ng-untouched ng-pristine ng-valid"
                              // value={
                              //   formData.Subject3 ? formData.Subject3 : ""
                              // }
                              // onClick={(e) => onFormDataInput(e, "Subject3")}
                              // onChange={(e) =>
                              //   onChange(e, allSubject3, "Subject3")
                              // }

                              value={input.Subject3}
                              onClick={(e) => onFormDataInput(e, "Subject3")}
                              onChange={(e) => {
                                setNewQueryString(
                                  `[subject_3]=${e.target.value}`
                                );
                              }}
                            />
                            {showSuggestions == "Subject3" && input && (
                              <SuggestionsListComponent name={"Subject3"} />
                            )}
                          </div>
                        </div> */}

                        <div className="col-md-12 d-none d-md-block d-lg-block custom-field-col">
                          <div className="btn-cmn-group">
                            <button
                              type="submit"
                              className="btn-apply read-btn-bg cmn-submit-button"
                            >
                              <span></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="continue-btn-group ">
                <a
                  routerlink="/home"
                  className="btn-continue arrow-bounce-right"
                  href="/home"
                >
                  <img src={ArrowLeft} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="B3"></div>
      </div>
      {/* <Banner /> */}
    </div>
  );
};

export default GroupFilter;
