import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowLeft from "../../assets/images/arrow-left-icon.png";
import Zoom from "react-reveal/Zoom";
import InfiniteScroll from "react-infinite-scroll-component";
import "./storyList.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../config/config";
import Banner from "../components/banner";
import { Loader } from "../components/loader";
import Header from "../components/header";
import emailImg from "../../assets/images/icons/denounce.png";
import searchImg from "../../assets/images/icons/search.png";
import shareImg from "../../assets/images/icons/share.png";
import bookImg from "../../assets/images/bookImg.png";
import SubmitButton from "../../assets/images/submit-button-s-icon.svg";
import "../../pages/readstory/readstory.css";
import { useForm } from "react-hook-form";
import footerimg from "../../assets/images/footer-bottom-bar.png";
import _debounce from "lodash/debounce";
// import { useWindowScroll } from "react-use";
import ReadStory from "./readstory";
import spinnerSvg from "../../assets/images/spinner.svg";
import { useQuery } from "react-query";
import {
  withNamespaces,
  NamespacesConsumer,
  Trans,
  useTranslation,
} from "react-i18next";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import validator from "validator";
import { StoryListContext } from "../../context/storyListContext";
import ReactHtmlParser from "react-html-parser";


const StoryList2 = (props) => {
  const { t, i18n } = useTranslation();
  // const { y: pageYOffset } = useWindowScroll();
  // const {x, y} = useWindowScroll();
  const [ContactModal, setContactModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [emailLink, setEmailLink] = useState();
  const [TopicNameForLink, setTopicNameForLink] = useState();
  const [emailAddress, setMailAddress] = useState("");
  const [wholeItem, setWholeItem] = useState([]);
  const [page, setPage] = useState(0);
  const [storyById, setStoryById] = useState([]);
  const [checkedValue, setCheckedValue] = useState(1);
  const [topicData, setTopicData] = useState();
  const [urlData, setUrlData] = useState(window.location.href);
  const [toggleAudio, setToggleAudio] = useState(false);
  const [ThanksTypeModalContact, setThanksTypeModalContact] = useState(false);
  const [ReferrenceId, setReferrenceId] = useState(false);
  const [feedbackData, setFeedbackData] = useState({});
  const [storyList, setStoryList] = useState([]);
  const [storyIndex, setStoryIndex] = useState(1);
  const [spinner, setSpinner] = useState(true);
  const [closeLoader, setCloseLoader] = useState(true);
  const [paramsID, setParamsID] = useState();
  const [scrollPlace, setScrollPlace] = useState();
  const [emailError, setEmailError] = useState("");
  const [modalShowTerm, setModalShowTerm] = useState(false);
  const [userAgreementData, setUserAgreementData] = useState(null);
  const localWholeData = JSON.parse(localStorage.getItem("wholeStoryData"));

  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const location = useLocation();

  let currentLanguageSetting = localStorage.getItem("prefered_language")
    ? localStorage.getItem("prefered_language")
    : "en";
  const [currentLanguage, setCurrentLanguage] = useState(
    currentLanguageSetting
  );

  const searchStory = (newQueryString) => {
    let url = `${ApiUrl}getListStory`;
    url = url + `?${newQueryString}`;
    setSpinner(true);
    axios.get(url).then((res) => {
      let attribute = res?.data?.data?.slice(storyIndex)[0]?.attributes;
      let attributes = res?.data?.data?.slice(-2)[0]?.attributes;
      setSpinner(false);
      setStoryById(attribute);
      let storiesRes = res?.data?.data?.reverse().filter((item) => {
        return item.attributes.isDisable === 0;
      });
      setStoryList(storiesRes);
      // localStorage.setItem("wholeStoryData", JSON.stringify(storiesRes));
      setWholeItem(storiesRes?.slice(0, 5));
      setPage(0);
    });
  };

  useEffect(() => {
    let localStoryData = location?.state;

    if (localStoryData) {
      setStoryList(localStoryData);
      setWholeItem(localStoryData);
    } else {
      let url = `${ApiUrl}getListStory`;
      setSpinner(true);
      axios.get(url).then((res) => {
        let attribute = res?.data?.data?.slice(storyIndex)[0]?.attributes;
        setSpinner(false);
        setStoryById(attribute);
        let storiesRes = res?.data?.data?.reverse().filter((item) => {
          return item.attributes.isDisable === 0;
        });
        setStoryList(storiesRes);
        // localStorage.setItem("wholeStoryData", JSON.stringify(storiesRes));
        setWholeItem(storiesRes?.slice(page, 5));
      });
    }
    // console.log("lastListValue", lastListValue);
  }, []);

  useEffect(() => {
    // a fake async api call like which sends
    // 5 more records in 1.5 secs
    if (page != 0) {
      setTimeout(() => {
        setWholeItem(wholeItem.concat(storyList.slice(page * 5, page * 5 + 5)));
      }, 1500);
    }
  }, [page]);


  useEffect(() => {
    getTermsData()
    if (JSON.parse(localStorage.getItem("terms")) != false) {
      setModalShowTerm(true);
    }
  }, []);

  const getTermsData = () => {
    axios.post(`${ApiUrl}getTermsAndCondition`, { language: localStorage.getItem("prefered_language") }).then((result) => {
      // let requiredTermsData = Object.fromEntries(
      //   Object.entries(
      //     JSON.parse(
      //       decodeURIComponent(
      //         result?.data?.data[0]?.attributes?.description
      //       )
      //     )
      //   )?.filter(([key]) => key.includes(currentLanguage))
      // )[currentLanguage];
      setUserAgreementData(result?.data?.data?.description);
    });
  };


  const fetchMoreData = () => {
    // console.log("page change");
    if (wholeItem.length >= storyList.length) {
      setCloseLoader(false);
    }
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setPage(page + 1);
  };

  // useEffect(() => {
  //     let id = 295;
  //     let url = `${ApiUrl}getListStoryById/${id}`;
  //     axios.get(url).then((res) => {
  //         setStoryById(res?.data?.list[0]);
  //     });

  // }, [])

  // const handleScroll = (e)=>{
  //     setStoryIndex(storyIndex + 1)
  //     console.log("scroll" , e.currentTarget.scrollTop);
  // }

  let handleScroll = useCallback(
    _debounce((e) => {
      // console.log("event scroll", e.currentTarget.scrollTop);
      setStoryIndex(storyIndex + 1);
      // console.log("scroll", storyIndex);
    }, 150),
    [storyIndex]
  );

  // const getStoryList = () => {
  //   let url = `${ApiUrl}getListStory`;
  //   url = url + `?${location.state}`;
  //   axios.get(url).then((res) => {
  //     setStoryList(
  //       res.data.data.filter((x) => x.attributes.published_at != null)
  //     );
  //   });
  // };
  const navigate = useNavigate();

  const handleBook = (item) => {
    const element = document.getElementById("ScrolingElement");
    setScrollPlace(element.scrollTop);
    localStorage.setItem("scrollPosition", element.scrollTop);
    navigate(`/readstorybook/${item?.id}`);
  };
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("ScrolingElement");
      element.scrollTop = parseInt(localStorage.getItem("scrollPosition"));
    }, 1000);
    // setInterval(() => {
    //     const element = document.getElementById("ScrolingElement")
    //     element.scrollTop = parseInt(localStorage.getItem("scrollPosition"))
    // }, 1000);
    // window.scrollTo(0, scrollPlace);
  }, []);

  const handleReport = (id) => {
    setContactModal(true);
    setParamsID(id);
  };

  const selectOnlyThis = (id) => {
    setCheckedValue(id);
  };

  const handleAudioChange = (listening) => {
    setToggleAudio(!toggleAudio);
    // listening ? stop() : listen();
  };

  const onSubmit = (data) => {
    let bodyData = {
      data: {
        // feedback_type: feedBackStatus(),
        feedback_type: "Denounce",
        feedback_subject: data.feedbackSub,
        // feedback_message: data.feedbackMessage,
        // feedback_message: paramsID + " " + urlData + topicData,
        feedback_message: paramsID + " " + topicData,
        feedback_reference: "",
      },
    };
    setThanksTypeModalContact(true);
    setFeedbackData(bodyData);
    onFeedbackSubmit(bodyData.data);
  };

  const onFeedbackSubmit = (data) => {
    axios
      .post(`${ApiUrl}addFeedback`, data)
      .then((res) => {
        axios
          .put(
            `${ApiUrl}updateFeedback/${res.data.list && res.data.list.insertId
            }`,
            {
              feedback_reference: `HERESAYSFEEDBACK00${res.data.list && res.data.list.insertId
                }`,
            }
          )
          .then((result) => {
            setReferrenceId(res.data.list.insertId);
            setThanksTypeModalContact(true);
            setCheckedValue(0);
            reset();
          })
          .catch((errr) => {
            console.log(errr);
          });
      })
      .catch((error) => {
        console.log(error);
        reset();
      });
  };

  const feedBackStatus = () => {
    let feedBackStatus = "";
    switch (checkedValue) {
      case 1:
        feedBackStatus = "Complaint";
        break;
      case 2:
        feedBackStatus = "Suggestion";
        break;
      case 3:
        feedBackStatus = "Remark";
        break;
      case 4:
        feedBackStatus = "Other";
        break;
      default:
        feedBackStatus = "Complaint";
        break;
    }
    return feedBackStatus;
  };

  const infinityStory = (item, index) => {
    let allPara = item?.attributes?.topic_details;
    return (
      <div className="individual_stories" key={index}>
        <h4>{item?.attributes?.topic_name}</h4>
        <div className="deteil_htmlparse">{allPara}</div>
        <br />
        {/* <p className="post_reviewed">{t('This post has not been reviewed')}</p> */}
        <div className="likeshare_icon">
          <div className="help-icon shareIconss emailDiv ">
            <div className="likeCount1">
              <div className="shareStroyIcon">
                <div
                  className=""
                // disabled={isDisabled ? "true" : "false"}
                >
                  <a
                    className="welcome-item animate__animated duration-animation-choice-home animate__rotateInDownLeft home-img-div"
                    onClick={() => {
                      openEmailModal(item);
                      setMailAddress("");
                    }}
                    target="_blank"
                  >
                    <img className="icon_size" src={shareImg} />
                  </a>
                  <span className="sLShare">{t("SHARE")}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="read_bookIcon">
            <span>{t("READ")}</span>
            <Link
              to={`/readstorybook/${item?.id}`}
              state={storyList}
            >
              <img
                className="icon_size"
                src={bookImg}
                onClick={() => {
                  handleBook(item);
                }}
                alt="img"
              />
            </Link>
          </div>
          <div className="help-icon ReportIconss emailDiv ">
            <div className="likeCount1">
              <div className="emailDivMain">
                <div
                  className="emailDiv"
                  onClick={() => {
                    handleReport(item?.attributes?.id);
                  }}
                // disabled={isDisabled ? "true" : "false"}
                >
                  {" "}
                  <a
                    routerlink="/story/read-story"
                    className="welcome-item animate__animated duration-animation-choice-home animate__rotateInDownLeft home-img-div"
                  >
                    <img className="icon_size" src={emailImg} />
                  </a>
                  <span className="sLReport">{t("DENOUNCE")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="devider_line" />
      </div>
    );
  };

  const validateEmail = (e) => {
    setMailAddress(e.target.value);
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError();
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const openEmailModal = (item) => {
    setEmailModal(true);
    setMailAddress("");
    setEmailLink(`https://heresays.com/readstorybook/${item?.id}`);
    setTopicNameForLink(item?.attributes?.topic_name);
  };

  const EmailSent = () => {
    const NewEmailLink =
      "Topic Name :" + TopicNameForLink + " <br/> <br/>" + emailLink;
    // sendEmail(emailAddress, "heresays@inens.com", "welcome to heresays", emailLink)
    let url = `${ApiUrl}sendMail`;
    let data = {
      to_mail: emailError ? null : emailAddress,
      subject: "heresays - I thought it may interest you",
      msg: NewEmailLink,
    };
    if (!emailError) {
      axios
        .post(url, data)
        .then((res) => {
          toastr.success(t("successfull"), t("Mail sent successfully"));
          setMailAddress("");
        })
        .catch((error) => {
          toastr.error(t("unSuccessfull"), error);
          setMailAddress("");
        });
    } else {
      toastr.error(t("unSuccessfull"), t("Please Try Again"));
      setMailAddress("");
    }
    setEmailModal(false);
  };

  if (!props.imagesPreloaded && !storyById) return <Loader />;

  return (
    <StoryListContext.Provider value={storyList}>
      {
        <div className={`container2 storylist_container mobile_height`}>
          <Header />
          <div className="middle mobile_middle">
            {/* <div className="B1"></div> */}
            <div className="B2 cards list_cards">
              <div className="home-body-container-list storylist1a">
                <h3
                  style={{
                    textAlign: "center",
                    color: "#cfa741",
                    fontFamily: "sanitarium_bbregular",
                    marginBottom: "0%",
                  }}
                >
                  {t("CHOOSE")}
                </h3>
                <div
                  role="document"
                  className="modal-dialog-list modal-dialog-centered-list readastory-modal-dialog-list border-style-8-list modal-align-list"
                >
                  <div className="modal-content-list">
                    {/* close buttion */}
                    <Link
                      to="/home"
                      // routerlink="/home"
                      className="close-button-style-read closeBtn closeBtns1"
                    >
                      <span></span>
                    </Link>

                    {/* search img */}
                    <div className="help-icon searchIcon emailDiv position-absolute top-0 start-0">
                      <div className="likeCount1">
                        <div className="searchDivMain">
                          <div
                            className="searchDiv"
                          // disabled={isDisabled ? "true" : "false"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                setSearchModal(true);
                              }}
                              className="welcome-item animate__animated duration-animation-choice-home animate__rotateInDownLeft home-img-div"
                            >
                              <img
                                className="icon iconbook searchIcon"
                                src={searchImg}
                                alt="img"
                              />
                              <span className="sLSearch publish11">{t("SEARCH")}</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="help-icon content_Boxes1"
                      id="ScrolingElement"
                    >
                      <div className="likeCount1 like_Content2">
                        <div className="like-icon-borderss">
                          <div>
                            {
                              // (spinner) ?
                              //     <div>
                              //         <LazyLoad height={762}>
                              //             <img className="lazy_loader position-absolute top-50 start-50 translate-middle" src={LoaderImageNew} alt="" width="84px" />
                              //         </LazyLoad>
                              //     </div>
                              //     :
                              <div className="headertop121">
                                <InfiniteScroll
                                  dataLength={wholeItem?.length}
                                  next={() => {
                                    fetchMoreData();
                                  }}
                                  hasMore={closeLoader}
                                  loader={<img src={spinnerSvg} alt="img" />}
                                  scrollableTarget="ScrolingElement"
                                >
                                  {wholeItem.map((i, index) => {
                                    return infinityStory(i, index);
                                  })}
                                </InfiniteScroll>
                              </div>
                            }

                            <img
                              className="footer_bar"
                              src={footerimg}
                              alt="img"
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="continue-btn-group-list continue-btn-group-list-story-list button-position-list">
                <a
                  routerlink="/home"
                  className="btn-continue-list arrow-bounce-right-list"
                  href="/home"
                >
                  <Link to="/home">
                    <img src={ArrowLeft} alt="img" />
                  </Link>
                </a>
              </div>
            </div>
          </div>
          <app-footer-panel>
            <div className="bottom-wrapper-initial-mobile">
              <div className="">
                <Banner />
              </div>
            </div>
          </app-footer-panel>
        </div>
      }

      <Zoom when={ContactModal} center>
        <div
          id="ContactModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ContactModal"
          aria-hidden="true"
          data-backdrop="false"
          className={
            ContactModal
              ? "modal fade zoom-in zoom-in-left show"
              : "modal fade zoom-in zoom-in-left"
          }
          style={ContactModal ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-dialog-centered contact-modal-dialog">
            <form className="modal-content" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body border-style-8">
                <div className="contact-body-in">
                  <div className="contact-checkbox-group">
                    <div className="contact-checkbox-item ng-star-inserted">
                      {/* <label>
                        <input
                          type="checkbox"
                          defaultChecked={checkedValue === 1 ? true : false}
                          // checked={checkedValue === 1}
                          // checked
                          value="Complaint"
                          name="check"
                          onChange={() => selectOnlyThis(1)}
                        />
                        <span className="check">{t("Complaint")} </span>
                      </label> */}
                    </div>
                    {/* <div className="contact-checkbox-item ng-star-inserted">
                        <label>
                          <input
                            type="checkbox"
                            value="Request"
                            checked={checkedValue == 2 ? true : false}
                            name="check"
                            onClick={() => selectOnlyThis(2)}
                          />
                          <span className="check">
                            {t('Explanation')}Suggestion{" "}
                          </span>
                        </label>
                      </div>
                      <div className="contact-checkbox-item ng-star-inserted">
                        <label>
                          <input
                            type="checkbox"
                            value="Suggestion"
                            checked={checkedValue == 3 ? true : false}
                            name="check"
                            onClick={() => selectOnlyThis(3)}
                          />
                          <span className="check">
                            {t('Explanation')}Remark{" "}
                          </span>
                        </label>
                      </div>
                      <div className="contact-checkbox-item ng-star-inserted">
                        <label>
                          <input
                            type="checkbox"
                            value="Question"
                            checked={checkedValue == 4 ? true : false}
                            name="check"
                            onClick={() => selectOnlyThis(4)}
                          />
                          <span className="check">
                            {t('Explanation')}Other{" "}
                          </span>
                        </label>
                      </div> */}
                  </div>
                  <div className="input-custom-field contact-topic-field">
                    <input
                      type="text"
                      className="form-control ng-untouched ng-pristine ng-valid"
                      placeholder={t("Enter Your Feedback")}
                      formcontrolname="feedbackSub"
                      {...register("feedbackSub")}
                    />
                  </div>
                  <div className="contact-textarea-bx">
                    <textarea
                      value={topicData}
                      placeholder={t("Enter Your Feedback")}
                      formcontrolname="feedbackMessage"
                      {...register("feedbackMessage", {
                        onChange: (e) => {
                          setTopicData(e.target.value);
                        },
                      })}
                      className="ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                  <div className="err-msg1">
                    <span> </span>
                  </div>
                </div>
              </div>
              <div className="type-btngroup add-adv-btngroup">
                <button
                  type="submit"
                  className="ads-submit-btn cmn-submit-button"
                  onClick={() => {
                    setContactModal(false);
                  }}
                >
                  <span></span>
                </button>

                {/* <button
                                    type="button"
                                    className="mt-btn camera-icon-ads ng-star-inserted"
                                    onClick={() => handleAudioChange(listening)}
                                >
                                    <i>
                                        {" "}
                                        {listening ? <img src={StopIcon} /> : <img src={Microphone} />}
                                    </i>
                                </button> */}

                <a
                  data-dismiss="modal"
                  onClick={() => {
                    setContactModal(false);
                  }}
                  className="ads-close-btn close-button-style"
                >
                  <span></span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </Zoom>

      <Zoom when={searchModal} center>
        <div
          id="DisclaimerModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ContactModal"
          aria-hidden="true"
          data-backdrop="false"
          className={
            searchModal
              ? "modal overflow-hidden fade zoom-in zoom-in-left show"
              : "modal fade zoom-in zoom-in-left"
          }
          style={searchModal ? { display: "block" } : { display: "none" }}
        >
          {/* <div className="modal-dialog modal-dialog-centered contact-modal-dialog">
                        <div className="modal-body border-style-8">
                            <div className="contact-body-in">
                                <div className="contact-checkbox-group"> */}
          <ReadStory
            searchStory={searchStory}
            closemodal={() => setSearchModal(!searchModal)}
          />
          {/* </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </Zoom>

      <Zoom when={emailModal} center>
        <div
          id="ContactModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ContactModal"
          aria-hidden="true"
          data-backdrop="false"
          className={
            emailModal
              ? "modal fade zoom-in zoom-in-left show"
              : "modal fade zoom-in zoom-in-left"
          }
          style={emailModal ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-dialog-centered contact-modal-dialog modal_open_share">
            <div className="modalbody">
              <div className="modal-body border-style-8">
                <div className="contact-body-in">
                  <div className="contact-checkbox-group">
                    <form className="share_submit">
                      <input
                        type="email"
                        value={emailAddress}
                        placeholder={t("Enter Email Address")}
                        onChange={(e) => validateEmail(e)}
                      ></input>
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {emailError}
                      </span>
                      {/* <button type="submit"> Share </button> */}
                    </form>
                  </div>
                </div>
              </div>
              <div className="type-btngroup add-adv-btngroup">
                <button
                  type="submit"
                  className="ads-submit-btn cmn-submit-button"
                  onClick={() => {
                    EmailSent();
                  }}
                >
                  <span></span>
                </button>
                <a
                  data-dismiss="modal"
                  onClick={() => {
                    setEmailModal(false);
                  }}
                  className="ads-close-btn close-button-style"
                >
                  <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Zoom>

      {/* terms data  */}
      {modalShowTerm && (
        <div
          id="TermsModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="LanguageModal"
          aria-hidden="true"
          data-backdrop="static"
          // className="modal fade zoom-in-center show"
          className={
            modalShowTerm
              ? "modal fade zoom-in zoom-in-right show"
              : "modal fade zoom-in zoom-in-right"
          }
        >
          <div className="modal-dialog modal-dialog-centered terms-modal-dialog terms-modal">
            <div className="modal-content modal-content-term">
              <div className="modal-body">
                <div className="terms-wrapper">
                  <div className="terms-wrap terms-wrap-term">
                    <div className="disclaimer-title-head cmn-title-head text-center">
                      <h2>
                        <span>{t("Terms of use")}</span>
                      </h2>
                    </div>
                    <div className="terms-info terms-info-term">

                      {" "}
                      {!userAgreementData ?
                        <div className="manual-loader-window">
                          <div style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            margin: "auto",
                            // height: "50%",
                          }} className="spinner-border" role="status">/
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div> :
                        ReactHtmlParser(
                          userAgreementData
                        )}
                    </div>
                  </div>
                  <div className="terms-footer acc_ref">
                    <a
                      data-dismiss="modal"
                      onClick={() => {
                        // setModalShowTerm(false);
                      }}
                      className="btn-dontagree refuse"
                      style={{ textDecoration: "none" }}
                    >
                      <span className="btn-d-text">{t("REFUSE")}</span>
                    </a>
                    <a
                      onClick={() => {
                        setModalShowTerm(false); localStorage.setItem("terms", false);
                      }}
                      data-dismiss="modal"
                      className="btn-agree accept"
                      style={{ textDecoration: "none" }}
                    >
                      <span>{t("ACCEPT")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        id="ThanksTypeModalContact"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ThanksTypeModalContact"
        aria-hidden="true"
        className={ThanksTypeModalContact ? "modal fade show" : "modal fade"}
        style={
          ThanksTypeModalContact ? { display: "block" } : { display: "none" }
        }
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered sociallogin-modal-dialog thankstype-modal-dialog"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <p className="text-uppercase">
                {t("THANK YOU FOR REACHING OUT TO US... YOUR MAIL HAS BEEN SUCCESSFULLY SENT AND WILL BE READ AND ASSESSED AS SOON AS POSSIBLE. PLEASE BE ASSURED THAT ANY SUGGESTIONS AND USER INTERACTIONS, LIKE YOURS, ARE TAKEN EXTREMELY SERIOUS... AND THAT, WITH REGARD TO THE MESSAGES THAT ARE RECEIVED, THE APPROPRIATE MEASURES WILL BE TAKEN AT THE EARLIEST CONVENIENCE.")}
              </p>
              {/* <p className="text-uppercase">
                                Your query has been properly submitted
                            </p>
                            <p>and is published under</p>
                            <h3>ID:HERESAYSFEEDBACK00{ReferrenceId} </h3>
                            <p>Please copy this for your reference</p> */}
              <a
                onClick={() => {
                  setThanksTypeModalContact(false);
                  // setTopicData(window.location.href);
                  reset();
                  navigate("/storylist2");
                }}
              >
                <img src={SubmitButton} alt="img" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </StoryListContext.Provider>
  );
};

export default StoryList2;
