export const AllLanguageFromJson = [
  {
    "Language": "Oromo",
    "Native": "AfaanOromoo",
    "BCP47": "om"
  },
  {
    "Language": "Afrikaans",
    "Native": "Afrikaans",
    "BCP47": "af"
  },
  {
    "Language": "Aymara",
    "Native": "Aymaraaru",
    "BCP47": "ay"
  },
  {
    "Language": "Azerbaijani",
    "Native": "Azərbaycandili",
    "BCP47": "az"
  },
  {
    "Language": "Indonesian",
    "Native": "BahasaIndo",
    "BCP47": "id-ID"
  },
  {
    "Language": "Bambara",
    "Native": "Bambara",
    "BCP47": "bm"
  },
  {
    "Language": "Javanese",
    "Native": "BasaJawa",
    "BCP47": "jv"
  },
  {
    "Language": "Sundanese",
    "Native": "basaSunda",
    "BCP47": "su"
  },
  {
    "Language": "Bosnian",
    "Native": "Bosanski",
    "BCP47": "bs"
  },
  {
    "Language": "Catalan",
    "Native": "català",
    "BCP47": "ca"
  },
  {
    "Language": "Cebuano",
    "Native": "Cebuano",
    "BCP47": "ceb"
  },
  {
    "Language": "Czech",
    "Native": "čeština",
    "BCP47": "cs"
  },
  {
    "Language": "Corsican",
    "Native": "corsu",
    "BCP47": "co"
  },
  {
    "Language": "Welsh",
    "Native": "Cymraeg",
    "BCP47": "cy"
  },
  {
    "Language": "Danish",
    "Native": "Dansk",
    "BCP47": "da"
  },
  {
    "Language": "German",
    "Native": "Deutsch",
    "BCP47": "de-DE"
  },
  {
    "Language": "Estonian",
    "Native": "eestikeel",
    "BCP47": "e"
  },
  {
    "Language": "English",
    "Native": "English",
    "BCP47": "en"
  },
  {
    "Language": "Spanish",
    "Native": "Español",
    "BCP47": "es-ES"
  },
  {
    "Language": "Esperanto",
    "Native": "esperanto",
    "BCP47": "eo"
  },
  {
    "Language": "Basque",
    "Native": "Euskara",
    "BCP47": "eu"
  },
  {
    "Language": "Ewe",
    "Native": "Eʋegbe",
    "BCP47": "ee"
  },
  {
    "Language": "Filipino(Tagalog)",
    "Native": "Filipino",
    "BCP47": "fil"
  },
  {
    "Language": "Tagalog(Filipino)",
    "Native": "Filipino(Tagalog)",
    "BCP47": "tl"
  },
  {
    "Language": "Malagasy",
    "Native": "fitenymalagasy",
    "BCP47": "mg"
  },
  {
    "Language": "French",
    "Native": "Français",
    "BCP47": "fr-FR"
  },
  {
    "Language": "Frisian",
    "Native": "Frysk",
    "BCP47": "fy"
  },
  {
    "Language": "Irish",
    "Native": "Gaeilge",
    "BCP47": "ga"
  },
  {
    "Language": "ScotsGaelic",
    "Native": "Gàidhlignah-Alba",
    "BCP47": "gd"
  },
  {
    "Language": "Galician",
    "Native": "Galego",
    "BCP47": "gl"
  },
  {
    "Language": "Guarani",
    "Native": "Guaran",
    "BCP47": "gn"
  },
  {
    "Language": "Hausa",
    "Native": "Hausa",
    "BCP47": "ha"
  },
  {
    "Language": "Hmong",
    "Native": "Hmoob",
    "BCP47": "hmn"
  },
  {
    "Language": "Croatian",
    "Native": "Hrvatski",
    "BCP47": "hr"
  },
  {
    "Language": "Igbo",
    "Native": "Igbo",
    "BCP47": "ig"
  },
  {
    "Language": "Ilocano",
    "Native": "Ilocano",
    "BCP47": "ilo"
  },
  {
    "Language": "Xhosa",
    "Native": "isiXhosa",
    "BCP47": "xh"
  },
  {
    "Language": "Zulu",
    "Native": "isiZulu",
    "BCP47": "zu"
  },
  {
    "Language": "Icelandic",
    "Native": "íslenskur",
    "BCP47": "is"
  },
  {
    "Language": "Italian",
    "Native": "Italiano",
    "BCP47": "it-I"
  },
  {
    "Language": "Kinyarwanda",
    "Native": "Kinyarwanda",
    "BCP47": "rw"
  },
  {
    "Language": "Swahili",
    "Native": "kiswahili",
    "BCP47": "sw"
  },
  {
    "Language": "HaitianCreole",
    "Native": "Kreyòlayisyen",
    "BCP47": "h"
  },
  {
    "Language": "Krio",
    "Native": "Krio",
    "BCP47": "kri"
  },
  {
    "Language": "Kurdish",
    "Native": "Kurdî",
    "BCP47": "ku"
  },
  {
    "Language": "Latin",
    "Native": "Latinus",
    "BCP47": "la"
  },
  {
    "Language": "Latvian",
    "Native": "Latviski",
    "BCP47": "lv"
  },
  {
    "Language": "Luxembourgish",
    "Native": "Lëtzebuergesch",
    "BCP47": "lb"
  },
  {
    "Language": "Lithuanian",
    "Native": "Lietuvių",
    "BCP47": "l"
  },
  {
    "Language": "Lingala",
    "Native": "Lingála",
    "BCP47": "ln"
  },
  {
    "Language": "Hungarian",
    "Native": "Magyar",
    "BCP47": "hu"
  },
  {
    "Language": "Maltese",
    "Native": "Malti",
    "BCP47": "m"
  },
  {
    "Language": "Maori",
    "Native": "Māori",
    "BCP47": "mi"
  },
  {
    "Language": "Malay",
    "Native": "Melayu",
    "BCP47": "ms"
  },
  {
    "Language": "Mizo",
    "Native": "Mizo",
    "BCP47": "lus"
  },
  {
    "Language": "Dutch",
    "Native": "Nederlands",
    "BCP47": "nl-NL"
  },
  {
    "Language": "Norwegian",
    "Native": "Norsk",
    "BCP47": "no-NO"
  },
  {
    "Language": "Uzbek",
    "Native": "o'zbek",
    "BCP47": "uz"
  },
  {
    "Language": "Hawaiian",
    "Native": "ŌleloHawaiʻi",
    "BCP47": "haw"
  },
  {
    "Language": "Luganda",
    "Native": "Oluganda",
    "BCP47": "lg"
  },
  {
    "Language": "Polish",
    "Native": "Polski",
    "BCP47": "pl"
  },
  {
    "Language": "Portuguese",
    "Native": "Portugalski",
    "BCP47": "pt-P"
  },
  {
    "Language": "Romanian",
    "Native": "Românesc",
    "BCP47": "ro-RO"
  },
  {
    "Language": "Quechua",
    "Native": "Runasimi",
    "BCP47": "qu"
  },
  {
    "Language": "Samoan",
    "Native": "Samoa",
    "BCP47": "sm"
  },
  {
    "Language": "Sepedi",
    "Native": "Sepedi",
    "BCP47": "nso"
  },
  {
    "Language": "Sesotho",
    "Native": "seSotho",
    "BCP47": "s"
  },
  {
    "Language": "Shona",
    "Native": "Shona",
    "BCP47": "sn"
  },
  {
    "Language": "Albanian",
    "Native": "Shqiptare",
    "BCP47": "sq"
  },
  {
    "Language": "Slovenian",
    "Native": "Slovenščina",
    "BCP47": "sl"
  },
  {
    "Language": "Slovak",
    "Native": "Slovenský",
    "BCP47": "sk"
  },
  {
    "Language": "Somali",
    "Native": "Soomaali",
    "BCP47": "so"
  },
  {
    "Language": "Finnish",
    "Native": "Suomenkieli",
    "BCP47": "fi"
  },
  {
    "Language": "Swedish",
    "Native": "Svenska",
    "BCP47": "sv-SE"
  },
  {
    "Language": "Vietnamese",
    "Native": "TiếngViệt",
    "BCP47": "vi"
  },
  {
    "Language": "Tsonga",
    "Native": "Tsonga",
    "BCP47": "ts"
  },
  {
    "Language": "Turkish",
    "Native": "Türkçe",
    "BCP47": "tr"
  },
  {
    "Language": "Turkmen",
    "Native": "Türkmenler",
    "BCP47": "tk"
  },
  {
    "Language": "Twi(Akan)",
    "Native": "Twi",
    "BCP47": "ak"
  },
  {
    "Language": "Yoruba",
    "Native": "Yoruba",
    "BCP47": "yo"
  },
  {
    "Language": "Belarusian",
    "Native": "беларускі",
    "BCP47": "be"
  },
  {
    "Language": "Bulgarian",
    "Native": "български",
    "BCP47": "bg"
  },
  {
    "Language": "Kyrgyz",
    "Native": "Кыргызча",
    "BCP47": "ky"
  },
  {
    "Language": "Kazakh",
    "Native": "қазақ",
    "BCP47": "kk"
  },
  {
    "Language": "Macedonian",
    "Native": "македонски",
    "BCP47": "mk"
  },
  {
    "Language": "Mongolian",
    "Native": "Монгол",
    "BCP47": "mn"
  },
  {
    "Language": "Russian",
    "Native": "Русский",
    "BCP47": "ru-RU"
  },
  {
    "Language": "Serbian",
    "Native": "Српски",
    "BCP47": "sr-SP"
  },
  {
    "Language": "Tatar",
    "Native": "Татар",
    "BCP47": "t"
  },
  {
    "Language": "Tajik",
    "Native": "тоҷикӣ",
    "BCP47": "tg"
  },
  {
    "Language": "Ukrainian",
    "Native": "українська",
    "BCP47": "uk"
  },
  {
    "Language": "Konkani",
    "Native": "कोंकणी",
    "BCP47": "gom"
  },
  {
    "Language": "Chichewa",
    "Native": "चिचेवा",
    "BCP47": "ny"
  },
  {
    "Language": "Dogri",
    "Native": "डोगरी",
    "BCP47": "doi"
  },
  {
    "Language": "Nepali",
    "Native": "नेपाली",
    "BCP47": "ne"
  },
  {
    "Language": "Marathi",
    "Native": "मराठी",
    "BCP47": "mr"
  },
  {
    "Language": "Maithili",
    "Native": "मैथिली",
    "BCP47": "mai"
  },
  {
    "Language": "Sanskri",
    "Native": "संस्कृत",
    "BCP47": "sa"
  },
  {
    "Language": "Hindi",
    "Native": "हिंदी",
    "BCP47": "hi"
  },
  {
    "Language": "Urdu",
    "Native": "اردو,Perso-Arabic",
    "BCP47": "ur"
  },
  {
    "Language": "Pashto",
    "Native": "پښتو,Perso-Arabic",
    "BCP47": "ps"
  },
  {
    "Language": "Persian",
    "Native": "فارسی,Perso-Arabic",
    "BCP47": "fa"
  },
  {
    "Language": "Arabic",
    "Native": "عربي,Arabic",
    "BCP47": "ar-SA"
  },
  {
    "Language": "Uyghur",
    "Native": "ئۇيغۇر,Arabic",
    "BCP47": "ug"
  },
  {
    "Language": "Yiddish",
    "Native": "יידיש,Hebrew",
    "BCP47": "yi"
  },
  {
    "Language": "Hebrew",
    "Native": "עִברִית,Hebrew",
    "BCP47": "he"
  },
  {
    "Language": "Armenian",
    "Native": "հայերենtArmenian",
    "BCP47": "hy"
  },
  {
    "Language": "Assamese",
    "Native": "অসমীয়া,Bengali",
    "BCP47": "as"
  },
  {
    "Language": "Bengali",
    "Native": "বাংলা,Bengali",
    "BCP47": "bn-BD"
  },
  {
    "Language": "Burmese",
    "Native": "မြန်မာ,Burmese",
    "BCP47": "my"
  },
  {
    "Language": "Amharic",
    "Native": "አማርኛ,Ethiopic",
    "BCP47": "am"
  },
  {
    "Language": "Tigrinya",
    "Native": "ትግሪኛ,Ge'ez",
    "BCP47": "ti"
  },
  {
    "Language": "Georgian",
    "Native": "ქართული,Georgian",
    "BCP47": "ka"
  },
  {
    "Language": "Greek",
    "Native": "Έλληνες,Greek",
    "BCP47": "el-GR"
  },
  {
    "Language": "Punjabi",
    "Native": "ਪੰਜਾਬੀ,Gurmukhi",
    "BCP47": "pa"
  },
  {
    "Language": "MandarinChinese",
    "Native": "国语,Han",
    "BCP47": "zh-CN"
  },
  {
    "Language": "Korean",
    "Native": "한국인,Hangul",
    "BCP47": "ko"
  },
  {
    "Language": "Japanese",
    "Native": "日本,Hiragana",
    "BCP47": "ja"
  },
  {
    "Language": "Bhojpuri",
    "Native": "भोजपुरी,Kaithiscrip",
    "BCP47": "bho"
  },
  {
    "Language": "Kannada",
    "Native": "ಕನ್ನಡ,Kannada",
    "BCP47": "kn"
  },
  {
    "Language": "Khmer",
    "Native": "ខ្មែរ,Khmer",
    "BCP47": "km"
  },
  {
    "Language": "Kurdish(Sorani)",
    "Native": "کوردی,Kurdish",
    "BCP47": "ckb"
  },
  {
    "Language": "Lao",
    "Native": "ພາສາລາວLao",
    "BCP47": "lo"
  },
  {
    "Language": "Malayalam",
    "Native": "മലയാളം",
    "BCP47": "ml"
  },
  {
    "Language": "Odia(Oriya)",
    "Native": "ଓଡିଆଭାଷା,Odia",
    "BCP47": "or"
  },
  {
    "Language": "Sindhi",
    "Native": "سنڌي,Sindhi",
    "BCP47": "sd"
  },
  {
    "Language": "Sinhala(Sinhalese)",
    "Native": "සිංහලtSinhala",
    "BCP47": "si"
  },
  {
    "Language": "Gujarati",
    "Native": "ગુજરાતીSyllabic",
    "BCP47": "gu"
  },
  {
    "Language": "Tamil",
    "Native": "தமிழ்t,Tamil",
    "BCP47": "ta"
  },
  {
    "Language": "Telugu",
    "Native": "తెలుగు,Telugu",
    "BCP47": "te"
  },
  {
    "Language": "Dhivehi",
    "Native": "ދިވެހި,Thaana",
    "BCP47": "dv"
  },
  {
    "Language": "Thai",
    "Native": "แบบไทย,Thai",
    "BCP47": "th"
  },
]