import React, { useState, useEffect } from "react";
import queryString from "query-string";
import io from "socket.io-client";

let socket;

const ChatNew = ({ location }) => {
      const [name, setName] = useState("");
      const [room, setRoom] = useState("");
      const [messages, setMessages] = useState([]);
      const [message, setMessage] = useState("");

      const ENDPOINT = "https://api2.heresays.com";

      useEffect(() => {
            // const { name, room } = queryString.parse(location.search);
            const queryParams = new URLSearchParams(window.location.search);

            // Extract the 'name' and 'room' parameters
            const name = queryParams.get('name');
            const room = queryParams.get('room');
            socket = io(ENDPOINT);
            setRoom(room);
            setName(name);

            socket.emit("join", { name, room }, (error) => {
                  if (error) {
                        alert(error);
                  }
            });
      }, [window.location.search]);
      useEffect(() => {
            socket.on("message", (message) => {
                  setMessages((messages) => [...messages, message]);
            });
            // socket.on("roomData", ({ users }) => {
            //   setUsers(users);
            // });
      }, []);

      const handleSubmit = (e) => {
            e.preventDefault();
            if (message) {
                  socket.emit("sendMessage", { message });
                  setMessage("");
            } else alert("empty input");
      };

      return (
            <div style={{ backgroundColor: "white" }}>
                  {messages.map((val, i) => {
                        return (
                              <div key={i}>
                                    {val.text}
                                    <br />
                                    <b>{val.user}</b>
                              </div>
                        );
                  })}
                  <form action="" onSubmit={handleSubmit}>
                        <input
                              type="text"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                        />
                        <input type="submit" />
                  </form>
            </div>
      );
};

export default ChatNew;
