import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./routing/layout";
import { useImagePreloader } from "./pages/components/imagePreloader/imagepreloader";
import { CardData } from "./constants/cardconstants";
import backgroundImage from "./assets/images/background_images/gold-web.webp";
import Language from "./assets/images/language-bg.png";
import i18n from "i18next";
import { Loader } from "./pages/components/loader";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "./config/config";
import "bootstrap/dist/css/bootstrap.min.css";
import configureStore from "./redux/store";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import { initialTranslation } from "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";

const store = configureStore();
const language = localStorage.getItem("prefered_language");
// if (!language) {
//   localStorage.setItem("prefered_language", "en");
// }

const queryClient = new QueryClient({});

function App() {
  const [isRunning, setRunning] = useState(true);
  const { t, i18n } = useTranslation();

  const { imagesPreloaded } = useImagePreloader([
    ...CardData.map((x) => x.image),
    backgroundImage,
    Language,
  ]);
  useEffect(() => {
    if (language) i18n.changeLanguage(language);
    // initialTranslation();
    // setTimeout(() => {
    setRunning(false);
    // }, 4000);
  }, []);

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      setTimeout(function () {
        window.scrollTo(0, 1);
      }, 0);
    });
  }, []);

  document.addEventListener("DOMContentLoaded", function () {
    setTimeout(function () {
      window.scrollTo(0, 1);
    }, 0);
  });





  return (
    <Provider store={store}>
      <Router>
        <Layout imagesPreloaded={imagesPreloaded} />
      </Router>
    </Provider>
  );
}

export default App;
