import React, { useEffect, useState } from "react";
import Chat from "../pages/home/Chat";
import SelectAvatar from "./SelectAvatar";
import axios from "axios";
import { ApiUrl } from "../config/config";
import SpinnerSvg from "../assets/images/spinner.svg"
import toastr from "toastr";
import { useTranslation } from "react-i18next";
import "toastr/build/toastr.min.css";
import "./GroupModal.css";
import "./toast.css";
import AlertDismissible from "./SweetAlert";
import Modal from "react-modal";
import eye_svg from "../assets/svg/eye.svg"
import { AllLanguageFromJson } from "../constants/json/languages";

const GroupModal = ({ groupdata, closeNewsModal, openGroupModal, usersData }) => {
  const [showAvatar, setShowAvatar] = useState(false);
  const [room, setRoom] = useState();
  const [GroupData, setGroupData] = useState();
  const [GroupDataNew, setGroupDataNew] = useState();
  const [emptyParticipantGroup, setEmptyParticipantGroup] = useState();
  const [AllUsers, setAllUsers] = useState();
  const [validUser, setValidUser] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showGroupLoader, setShowGroupLoader] = useState(false);
  const [groupisfull, setGroupisfull] = useState();
  const [SelectedGroupData, setSelectedGroupData] = useState();
  const [stopRerendring, setstopRerendring] = useState(false);
  const localUserData = JSON.parse(localStorage.getItem("user"));
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [warningModalData, setWarningModalData] = useState({
    item: "",
    localid: ""
  });
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [emptyNumber, setEmptyNumber] = useState([]);
  var isExistingrup;
  // let emptyNumber = []


  const handleClose = () => setShow(false);
  const handleShow = (item1, data1) => {
    setShow(true); setWarningModalData({
      item: item1,
      localid: data1
    })
  }

  useEffect(() => {
    // setGroupData(groupdata)
    setTimeout(() => {
      setShowGroupLoader(true)
    }, 3000);
  }, [groupdata])



  useEffect(() => {
    if (!loadedOnce) {
      getAllGroups();
      getAllUsers();
      setLoadedOnce(true);
    }
  }, [loadedOnce]);

  useEffect(() => {
    if (AllUsers?.length > 0 && GroupDataNew?.length > 0 && GroupData?.length > 0) {
      // Find the groupid where all participants are empty, undefined, or null
      const emptyParticipantGroupIds = GroupDataNew?.filter(group => areAllParticipantsEmpty(group)).map(group => group.groupid);
      let result = GroupDataNew.filter(item => !emptyParticipantGroupIds.includes(item));
      setGroupDataNew(result)
    }

  }, [GroupData, AllUsers]);

  useEffect(() => {
    if (AllUsers?.length > 0 && GroupDataNew?.length > 0 && GroupData?.length > 0 && emptyParticipantGroup) {
      // setTimeout(() => {
      //   deleteGroupIfNoUser()
      // }, 10000);
    }
  }, [GroupDataNew]);

  useEffect(() => {
    if (GroupData && AllUsers) {
      let filterGroupData = GroupData?.map((group) => {
        for (let i = 1; i <= 5; i++) {
          group[`participants${i}`] = AllUsers?.find(
            (user) => user.userId === group[`participants${i}`]
          );
        }
        return group;
      });
      if (filterGroupData) {
        setGroupDataNew(filterGroupData);
        const emptyParticipantGroupIds = filterGroupData?.filter(group => areAllParticipantsEmpty(group)).map(group => group.groupid);
        emptyParticipantGroupIds && emptyParticipantGroupIds?.length > 0 && setEmptyParticipantGroup(emptyParticipantGroupIds)
      }
    }
  }, [GroupData, AllUsers]);



  useEffect(() => {
    if (groupdata && usersData) {
      let filterGroupData = groupdata?.map((group) => {
        for (let i = 1; i <= 5; i++) {
          group[`participants${i}`] = usersData?.find(
            (user) => user.userId === group[`participants${i}`]
          );
        }
        return group;
      });
      if (filterGroupData) {
        setGroupDataNew(filterGroupData);
      }
    }
    if (!groupdata || !usersData) {
      // getAllUsers();
      // getAllGroups();
    }
  }, [groupdata, usersData]);

  const getAllGroups = () => {
    axios
      .get(`${ApiUrl}/getAllGroups`)
      .then((groupData) => {
        setGroupData(groupData?.data?.list);
      })
      .catch((err) => {
        console.log("groupData", err);
      });
  };

  function areAllParticipantsEmpty(group) {
    return ["participants1", "participants2", "participants3", "participants4", "participants5"].every(participant => {
      return !group[participant] || group[participant] === "undefined" || group[participant] === null;
    });
  }




  const deleteGroupIfNoUser = () => {
    // debugger;
    emptyParticipantGroup?.length > 0 && emptyParticipantGroup?.map((groupid) => {
      let ApiData = {
        groupid: groupid,
      };
      axios
        .post(`${ApiUrl}/deleteGroupParticipants`, ApiData)
        .then(async (res) => {
          await getAllUsers();
          await getAllGroups();
        })
        .catch((err) => {
          console.log(err);
        });
    })

  }

  const checkUserIdExists = (data, userIdToFind) => {
    // Iterate over each property in the object
    for (const key in data) {
      // Check if the property is an object (nested level)
      if (typeof data[key] === 'object' && data[key] !== null) {
        // Recursively check the nested object
        if (checkUserIdExists(data[key], userIdToFind)) {
          return true; console.log("trueeeeeeeeeeeeeeee");
        }
      } else if (key === 'userId' && data[key] === userIdToFind) {
        // Check if the current property is 'userId' and matches the target value
        return true; console.log("falseeeeeeeee");
      }
    }
    // If no match is found
    return false; console.log("falseeeeeeeee not");
  };

  const getAllUsers = () => {
    axios
      .get(`${ApiUrl}/getAllUsers`)
      .then((userData) => {
        setAllUsers(userData?.data?.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const joinRoom = async (item) => {
    try {
      const data = await ExtractVacantSpace(item)
      const FullOrEmpty = document.getElementById(`FullOrEmpty${item?.groupid}`).innerHTML
      setGroupisfull(FullOrEmpty)
      var isExistingrup = checkUserIdExists(item, localUserData?.userId)
      if (FullOrEmpty == "Full" && isExistingrup != true) {
        // setModalVisible(true)
        setValidUser(true);
      }

      // showAlert("group is full")
      closeNewsModal();
      setSelectedGroupData(item);
      setRoom(item?.group_name);
      setShowAvatar(true);


      for (let i = 1; i <= 5; i++) {
        if (
          item[`participants${i}`]?.userId === localUserData?.userId &&
          localUserData?.userId !== undefined &&
          item[`participants${i}`] !== undefined
        ) {
          setValidUser(true);
        }
      }
    } catch (error) {
      console.error("Error in joinRoom:", error);
    }
  };

  const ExtractVacantSpace = (item) => {
    for (let i = 1; i <= 5; i++) {
      if (!item[`participants${i}`]) {
        emptyNumber?.push(i)
      }
    }
    return emptyNumber
  }

  const deletePerson = (SelectedGroupData, userId) => {
    var partNum = 0;
    for (let i = 1; i <= 5; i++) {
      if (
        SelectedGroupData[`participants${i}`]?.userId === userId
      ) {
        partNum = i;
      }
    }
    let ApiData = {
      groupName: SelectedGroupData?.group_name,
      userId: userId,
      partNumber: partNum,
    };

    axios
      .post(`${ApiUrl}/deleteUserInGroup`, ApiData)
      .then((res) => {
        getAllGroups();

        toastr.info(
          `${userId} ${t("is")} ${t("removed")} ${t("from")}   ${SelectedGroupData?.group_name}`
        );
        handleClose()
      })
      .catch((err) => {
        console.log(err);
        toastr.info(t("User is not present in the group"));
      });
    // remove person from group by socket id
  };


  const countOccurrences = (obj) => {
    let count = 0;
    if (localUserData?.userId != undefined)
      for (const key in obj) {
        if (key.startsWith("participants")) {
          if (obj[key]?.userId === localUserData?.userId) {
            // exitGroup()
          }
        }
      }
    return (console.log("num of count", count));
  };

  const exitGroup = () => {

    let ApiData = {
      userId: localUserData?.userId
    };
    axios
      .post(`${ApiUrl}/deleteChatUser`, ApiData)
      .then((res) => {
        // getAllGroups();
        localStorage.removeItem('user');
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
      });
    // remove person from group by socket id

  }



  if (modalVisible) {
    return (<AlertDismissible
      modalVisible={modalVisible}
      setModalVisible={(value) => { setModalVisible(value) }} />)
  }
  return !showAvatar ? (
    <div className="modal-body parentbody-list text-center">
      <div className="storylist-list chat-modal-boxes">
        {!GroupDataNew?.length > 0 && !GroupData?.length > 0 && !AllUsers?.length > 0 ? (
          <img src={SpinnerSvg} alt="" />

        ) : (
          groupdata?.length > 0 &&
          usersData?.length > 0 &&
          GroupDataNew?.map((item, index) => {
            if (index === GroupDataNew.length - 1 && item.group_name && emptyParticipantGroup?.length > 0) {
              // deleteGroupIfNoUser()
            }
            let participantCount = 0;

            // Iterate over object keys
            for (const key in item) {
              // Check if key starts with "participants" and has a truthy value
              if (key.startsWith("participants") && item[key]) {
                // Increment counter
                participantCount++;
              }
            }
            return (
              <div
                className={false ? "cardparent-list chatmodal1 chat-modul col-lg-3 col-md-6 col-sm-12 mx-1 my-3" : "cardparent-list chat-modul col-lg-3 col-md-6 col-sm-12 mx-1 my-3"}
                key={item?.group_name}
              >
                <div
                  className="card-start-list"

                >
                  <div className="cardBorder-list ">

                    <div className="chat-user-list-bg">
                      <div className="chat-content-view text-center">
                        {item.group_name}
                      </div>
                    </div>
                    <div className="chat_titles chat-user-list-bg">
                      <div className="chat-content-view11 text-center">
                        {item.subject_1 ? item.subject_1 : t("Subject 1")}
                      </div>
                    </div>
                    <div className="chat-user-list-bg">
                      <div className="chat-content-view text-center long_user_names">
                        <h6>
                          {item?.participants1
                            && item?.participants1?.name
                          }
                        </h6>
                        <h6>
                          {item?.participants2
                            && item?.participants2?.name
                          }
                        </h6>
                        <h6>
                          {item?.participants3
                            && item?.participants3?.name
                          }
                        </h6>
                        <h6>
                          {item?.participants4
                            && item?.participants4?.name
                          }
                        </h6>
                        <h6>
                          {item?.participants5
                            && item?.participants5?.name
                          }
                        </h6>
                        <h6>
                          {!item?.participants1
                            && '____'
                          }
                        </h6>
                        <h6>
                          {!item?.participants2
                            && '____'
                          }
                        </h6>
                        <h6>
                          {!item?.participants3
                            && '____'
                          }
                        </h6>
                        <h6>
                          {!item?.participants4
                            && '____'
                          }
                        </h6>
                        <h6>
                          {!item?.participants5
                            && '____'
                          }
                        </h6>
                      </div>
                    </div>
                    <div className="chat_titles chat-user-list-bg">
                      <div className="chat-content-view11 text-center">
                        {item.subject_2 ? item.subject_2 : t("Subject 2")}
                      </div>
                    </div>
                    {AllLanguageFromJson &&
                      AllLanguageFromJson.map((x, index) => {
                        return (
                          (x.BCP47 == item.language) &&
                          <div className="languageOnModal small mt-1"><b>{x.Native}</b></div>
                        )
                      })}

                    <div className="cardTitle-list cardbottomtitle">
                      <div className="cardTitleImage-list d-flex justify-content-between align-items-center">
                        {/* <div className="chat-user-list-bg">
                           <h6 className="cardTitles" id={`FullOrEmpty${item?.groupid}`}>
                            {item?.participants1 &&
                              item?.participants2 &&
                              item?.participants3 &&
                              item?.participants4 &&
                              item?.participants5
                              ? "Full"
                              : "Join"}
                          </h6> 
                          </div> */}
                        <div className="chat-user-list-bg sshh">
                          <div id={`FullOrEmpty${item?.groupid}`} className="chat-content-view text-center" onClick={() => {
                            joinRoom(item);
                          }}>
                            {item?.participants1 &&
                              item?.participants2 &&
                              item?.participants3 &&
                              item?.participants4 &&
                              item?.participants5
                              ? t("Full")
                              : t("join")}

                          </div>
                        </div>
                        <h6 className="cardTitles card_eyes"><img src={eye_svg} alt="" />
                          {participantCount}
                          {/* {item?.simulation ? item?.simulation : 1} */}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {isExist == true ? <button onClick={() => {
                  handleShow(item, localUserData?.userId);
                  // deletePerson(item, localUserData?.userId)
                }} className="cardTitles2">Leave Group</button> : null} */}
              </div>
            );
          })
        )}
      </div>
      <Modal
        id="chat-post-success"
        className="modal-popup-01 typestory_test_thank_modal chat-post-success"
        isOpen={show}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
      >
        <div
          role="document"
          className="modal-dialog modal-dialog-centered sociallogin-modal-dialog thankstype-modal-dialog typestory_test_thank_inner_modal"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <p className="text-uppercase">
                {t("Warning")}
              </p>
              <p>{t(`Are You Sure You Want To Leave Group`)} </p>

              <a
                onClick={handleClose}
                data-dismiss="modal"
                aria-label="Close"
                className="close-button-style"
              >
                <span></span>
              </a>

              <button
                onClick={() => {
                  deletePerson(warningModalData?.item, warningModalData?.localid)
                }}
                type="submit"
                className="btn-apply write-btn-bg cmn-submit-button"
              >
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  ) : validUser ? (
    <Chat
      username={localUserData?.name}
      selectedAvatar={localUserData?.avatar}
      room={room}
      emptyNumber={emptyNumber}
      isExistingrup={isExistingrup}
      // socket={socket}
      // clientsInGroup={clientsInGroup}
      validUser={validUser}
      SelectedGroupData={SelectedGroupData}
      groupisfull={groupisfull}
    />
  ) : (
    <SelectAvatar
      emptyNumber={emptyNumber}
      room={room}
      SelectedGroupData={SelectedGroupData}
      groupisfull={groupisfull}
    />
  );
};

export default GroupModal;
