export const English = {
  Explanation: "Explanation",
  Language: "Language",
  Jurisdiction: "Jurisdiction",
  Attention: "Attention",
  "LANGUAGE SELECTION": "LANGUAGE SELECTION",
  ACCESS: "ACCESS",
  "Terms of use": "Terms of use",
  REFUSE: "REFUSE",
  ACCEPT: "ACCEPT",
  READ: "READ",
  PUBLISH: "PUBLISH",
  CHAT: "CHAT",
  "comming soon": "coming soon",
  Other: "Other",
  Remark: "Remark",
  Suggestion: "Suggestion",
  Complaint: "Complaint",
  Manual: "Manual",
  SEARCH: "SEARCH",
  SHARE: "SHARE",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "This post has not been reviewed",
  SUBMIT: "SUBMIT",
  Topic: "Topic",
  "Published BY": "Published BY",
  English: "English",
  Country: "Country",
  City: "City",
  Place: "Place",
  "Subject 1": "Subject 1",
  "Subject 2": "Subject 2",
  "Subject 3": "Subject 3",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Enter Email Address",
  CHOOSE: "CHOOSE",
  By: "By",
  "End": "End",
  "Enter Your Story Content": "Enter Your Story Content",
  "UPLOAD IMAGES": "UPLOAD IMAGES",
  "Click or Drop here": "Click or Drop here",
  "Remove all images": "Remove all images",
  "Your post has been properly submitted":
    "Your post has been properly submitted",
  "and is published under": "and is published under",
  "Please copy this for your reference": "Please copy this for your reference",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "YOUR QUERY HAS BEEN PROPERLY SUBMITTED",
  "User Instructions": "User Instructions",
  Contact: "Contact",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Region or Province or County or State",
  and: "and",
  Date: "Date",
  Content: "Content",
  "TopicName is required": "TopicName is required",
  "Publisher Name is required": "Publisher Name is required",
  "Enter Your Feedback": "Enter Your Feedback",
  HereSays: "HereSays",
  Region: "Region",
  Province: "Province",
  Heard: "Heard",
  Shared: "Shared",
  Successfully: "Successfully",
};
export const Arabic = {
  Explanation: "توضيح",
  Language: "لغة",
  Jurisdiction: "الاختصاص القضائي",
  Attention: "انتباه",
  "LANGUAGE SELECTION": "اختيار اللغة",
  ACCESS: "وصول",
  "Terms of use": "شروط الاستخدام",
  REFUSE: "رفض",
  ACCEPT: "يقبل",
  READ: "يقرأ",
  PUBLISH: "ينشر",
  CHAT: "محادثة",
  "comming soon": "سآتي قريبا",
  Other: "آخر",
  Remark: "ملاحظة",
  Suggestion: "اقتراح",
  Complaint: "شكوى",
  Manual: "يدوي",
  SEARCH: "يبحث",
  SHARE: "يشارك",
  DENOUNCE: "التنديد",
  "This post has not been reviewed": "لم يتم مراجعة هذا المنشور",
  SUBMIT: "يُقدِّم",
  Topic: "عنوان",
  "Published BY": "نشرت من قبل",
  English: "إنجليزي",
  Country: "دولة",
  City: "مدينة",
  Place: "مكان",
  "Subject 1": "الموضوع 1",
  "Subject 2": "الموضوع 2",
  "Subject 3": "الموضوع 3",
  "Reference Id": "الرقم المرجعي",
  "Enter Email Address": "أدخل عنوان البريد الالكتروني",
  CHOOSE: "يختار",
  By: "بواسطة",
  "End": "النهاية",
  "Enter Your Story Content": "أدخل محتوى قصتك",
  "UPLOAD IMAGES": "تحميل الصور",
  "Click or Drop here": "انقر أو أفلت هنا",
  "Remove all images": "إزالة كافة الصور",
  "Your post has been properly submitted": "تم إرسال رسالتك بشكل صحيح",
  "and is published under": "ويتم نشره تحت",
  "Please copy this for your reference": "يرجى نسخ هذا للرجوع اليها",
  "ID:HERESAYS000": "المعرف: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "تم تقديم استفسارك بشكل صحيح",
  "User Instructions": "تعليمات المستخدم",
  Contact: "اتصال",
  "News or HereSays Herald": "أخبار أو هيرالد هيرالد",
  Disclaimer: "تنصل",
  "Region or Province or County or State":
    "المنطقة أو المقاطعة أو المقاطعة أو الولاية",
  and: "و",
  Date: "تاريخ",
  Content: "محتوى",
  "TopicName is required": "TopicName مطلوب",
  "Publisher Name is required": "اسم الناشر مطلوب",
  "Enter Your Feedback": "أدخل ملاحظاتك",
  HereSays: "هنا يقول",
  Region: "منطقة",
  Province: "مقاطعة",
  Heard: "سمع",
  Shared: "مشترك",
  Successfully: "ناجحلي",
};
export const Mandarin_Chinese = {
  Explanation: "解释",
  Language: "语言",
  Jurisdiction: "管辖权",
  Attention: "注意力",
  "LANGUAGE SELECTION": "语言选择",
  ACCESS: "使用权",
  "Terms of use": "使用条款",
  REFUSE: "拒绝",
  ACCEPT: "接受",
  READ: "读",
  PUBLISH: "发布",
  CHAT: "聊天",
  "comming soon": "即将推出",
  Other: "其他",
  Remark: "评论",
  Suggestion: "建议",
  Complaint: "抱怨",
  Manual: "手动的",
  SEARCH: "搜索",
  SHARE: "分享",
  DENOUNCE: "声讨",
  "This post has not been reviewed": "这篇文章还没有被审查",
  SUBMIT: "提交",
  Topic: "话题",
  "Published BY": "由...出版",
  English: "英语",
  Country: "国家",
  City: "城市",
  Place: "地方",
  "Subject 1": "主题 1",
  "Subject 2": "主题 2",
  "Subject 3": "主题 3",
  "Reference Id": "参考编号",
  "Enter Email Address": "请输入电邮地址",
  CHOOSE: "选择",
  By: "经过",
  "End": "结束",
  "Enter Your Story Content": "输入您的故事内容",
  "UPLOAD IMAGES": "上传图片",
  "Click or Drop here": "点击或拖放此处",
  "Remove all images": "删除所有图像",
  "Your post has been properly submitted": "您的帖子已正确提交",
  "and is published under": "并发表在",
  "Please copy this for your reference": "请复制此供您参考",
  "ID:HERESAYS000": "编号：HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "您的查询已正确提交",
  "User Instructions": "使用说明",
  Contact: "接触",
  "News or HereSays Herald": "新闻或 HereSays Herald",
  Disclaimer: "免责声明",
  "Region or Province or County or State": "地区或省或县或州",
  and: "和",
  Date: "日期",
  Content: "内容",
  "TopicName is required": "主题名称是必需的",
  "Publisher Name is required": "出版商名称是必需的",
  "Enter Your Feedback": "输入您的反馈",
  HereSays: "这里说",
  Region: "地区",
  Province: "省",
  Heard: "听到",
  Shared: "共享",
  Successfully: "成功者",
};
export const Spanish = {
  Explanation: "Explicación",
  Language: "Idioma",
  Jurisdiction: "Jurisdicción",
  Attention: "Atención",
  "LANGUAGE SELECTION": "SELECCIÓN DE IDIOMA",
  ACCESS: "ACCESO",
  "Terms of use": "Condiciones de uso",
  REFUSE: "RECHAZAR",
  ACCEPT: "ACEPTAR",
  READ: "LEER",
  PUBLISH: "PUBLICAR",
  CHAT: "CHARLAR",
  "comming soon": "próximamente",
  Other: "Otro",
  Remark: "Observación",
  Suggestion: "Sugerencia",
  Complaint: "Queja",
  Manual: "Manual",
  SEARCH: "BUSCAR",
  SHARE: "COMPARTIR",
  DENOUNCE: "DENUNCIAR",
  "This post has not been reviewed": "Esta publicación no ha sido revisada",
  SUBMIT: "ENTREGAR",
  Topic: "Tema",
  "Published BY": "Publicado por",
  English: "Inglés",
  Country: "País",
  City: "Ciudad",
  Place: "Lugar",
  "Subject 1": "Tema 1",
  "Subject 2": "sujeto 2",
  "Subject 3": "tema 3",
  "Reference Id": "Identificación de referencia",
  "Enter Email Address": "Introducir la dirección de correo electrónico",
  CHOOSE: "ELEGIR",
  By: "Por",
  "End": "El fin",
  "Enter Your Story Content": "Ingrese el contenido de su historia",
  "UPLOAD IMAGES": "SUBIR IMÁGENES",
  "Click or Drop here": "Haga clic o Suelte aquí",
  "Remove all images": "Eliminar todas las imágenes",
  "Your post has been properly submitted":
    "Su publicación se ha enviado correctamente.",
  "and is published under": "y se publica bajo",
  "Please copy this for your reference": "Copie esto para su referencia.",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "SU CONSULTA HA SIDO ENVIADA CORRECTAMENTE",
  "User Instructions": "Instrucciones para el usuario",
  Contact: "Contacto",
  "News or HereSays Herald": "Noticias o HereSays Herald",
  Disclaimer: "Descargo de responsabilidad",
  "Region or Province or County or State":
    "Región o Provincia o Condado o Estado",
  and: "y",
  Date: "Fecha",
  Content: "Contenido",
  "TopicName is required": "El nombre del tema es obligatorio",
  "Publisher Name is required": "El nombre del editor es obligatorio",
  "Enter Your Feedback": "Ingrese sus comentarios",
  HereSays: "Aquí dice",
  Region: "Región",
  Province: "Provincia",
  Heard: "Escuchó",
  Shared: "Compartido",
  Successfully: "exitosamente",
};
export const Russian = {
  Explanation: "Объяснение",
  Language: "Язык",
  Jurisdiction: "Юрисдикция",
  Attention: "Внимание",
  "LANGUAGE SELECTION": "ВЫБОР ЯЗЫКА",
  ACCESS: "ДОСТУП",
  "Terms of use": "Условия эксплуатации",
  REFUSE: "МУСОР",
  ACCEPT: "ПРИНИМАТЬ",
  READ: "ЧИТАТЬ",
  PUBLISH: "ПУБЛИКОВАТЬ",
  CHAT: "ЧАТ",
  "comming soon": "Скоро",
  Other: "Другой",
  Remark: "Примечание",
  Suggestion: "Предположение",
  Complaint: "Жалоба",
  Manual: "Руководство",
  SEARCH: "ПОИСК",
  SHARE: "ДЕЛИТЬСЯ",
  DENOUNCE: "ДЕНОНСИРОВАТЬ",
  "This post has not been reviewed": "Этот пост не был проверен",
  SUBMIT: "ПРЕДСТАВЛЯТЬ НА РАССМОТРЕНИЕ",
  Topic: "Тема",
  "Published BY": "Опубликовано",
  English: "Английский",
  Country: "Страна",
  City: "Город",
  Place: "Место",
  "Subject 1": "Тема 1",
  "Subject 2": "Тема 2",
  "Subject 3": "Тема 3",
  "Reference Id": "Идентификатор ссылки",
  "Enter Email Address": "Введите адрес электронной почты",
  CHOOSE: "ВЫБИРАТЬ",
  By: "К",
  "End": "Конец",
  "Enter Your Story Content": "Введите содержание вашей истории",
  "UPLOAD IMAGES": "ЗАГРУЗИТЬ ИЗОБРАЖЕНИЯ",
  "Click or Drop here": "Нажмите или перетащите сюда",
  "Remove all images": "Удалить все изображения",
  "Your post has been properly submitted":
    "Ваше сообщение было правильно отправлено",
  "and is published under": "и публикуется под",
  "Please copy this for your reference":
    "Пожалуйста, скопируйте это для справки",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ВАШ ЗАПРОС БЫЛ ПРАВИЛЬНО ОТПРАВЛЕН",
  "User Instructions": "Инструкции пользователя",
  Contact: "Контакт",
  "News or HereSays Herald": "Новости или Herald Herald",
  Disclaimer: "Отказ от ответственности",
  "Region or Province or County or State":
    "Регион или провинция или округ или штат",
  and: "и",
  Date: "Дата",
  Content: "Содержание",
  "TopicName is required": "Необходимо указать название темы.",
  "Publisher Name is required": "Укажите имя издателя.",
  "Enter Your Feedback": "Введите свой отзыв",
  HereSays: "Здесь говорит",
  Region: "Область",
  Province: "Провинция",
  Heard: "Слышал",
  Shared: "Общий",
  Successfully: "успешно",
};
export const Bengali = {
  Explanation: "ব্যাখ্যা",
  Language: "ভাষা",
  Jurisdiction: "এখতিয়ার",
  Attention: "মনোযোগ",
  "LANGUAGE SELECTION": "ভাষা নির্বাচন",
  ACCESS: "অ্যাক্সেস",
  "Terms of use": "ব্যবহারের শর্তাবলী",
  REFUSE: "প্রত্যাখ্যান",
  ACCEPT: "স্বীকার করুন",
  READ: "পড়ুন",
  PUBLISH: "প্রকাশ করুন",
  CHAT: "চ্যাট",
  "comming soon": "শীঘ্রই আসছে",
  Other: "অন্যান্য",
  Remark: "মন্তব্য",
  Suggestion: "সাজেশন",
  Complaint: "অভিযোগ",
  Manual: "ম্যানুয়াল",
  SEARCH: "অনুসন্ধান করুন",
  SHARE: "শেয়ার করুন",
  DENOUNCE: "নিন্দা করুন",
  "This post has not been reviewed": "এই পোস্ট পর্যালোচনা করা হয় নি",
  SUBMIT: "জমা দিন",
  Topic: "বিষয়",
  "Published BY": "দ্বারা প্রকাশিত",
  English: "ইংরেজি",
  Country: "দেশ",
  City: "শহর",
  Place: "স্থান",
  "Subject 1": "বিষয় 1",
  "Subject 2": "বিষয় 2",
  "Subject 3": "বিষয় 3",
  "Reference Id": "রেফারেন্স আইডি",
  "Enter Email Address": "ইমেইল অ্যাড্রেস দিন",
  CHOOSE: "পছন্দ করা",
  By: "দ্বারা",
  "End": "দ্য এন্ড",
  "Enter Your Story Content": "আপনার গল্প বিষয়বস্তু লিখুন",
  "UPLOAD IMAGES": "ছবি পাঠান",
  "Click or Drop here": "এখানে ক্লিক করুন বা ড্রপ করুন",
  "Remove all images": "সমস্ত ছবি সরান",
  "Your post has been properly submitted":
    "আপনার পোস্ট সঠিকভাবে জমা দেওয়া হয়েছে",
  "and is published under": "এবং অধীনে প্রকাশিত হয়",
  "Please copy this for your reference":
    "আপনার রেফারেন্সের জন্য এটি অনুগ্রহ করে অনুলিপি করুন",
  "ID:HERESAYS000": "আইডি:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "আপনার প্রশ্ন সঠিকভাবে জমা দেওয়া হয়েছে",
  "User Instructions": "ব্যবহারকারীর নির্দেশাবলী",
  Contact: "যোগাযোগ",
  "News or HereSays Herald": "খবর বা HereSays Herald",
  Disclaimer: "দাবিত্যাগ",
  "Region or Province or County or State":
    "অঞ্চল বা প্রদেশ বা কাউন্টি বা রাজ্য",
  and: "এবং",
  Date: "তারিখ",
  Content: "বিষয়বস্তু",
  "TopicName is required": "বিষয়ের নাম প্রয়োজন",
  "Publisher Name is required": "প্রকাশকের নাম আবশ্যক",
  "Enter Your Feedback": "আপনার মতামত লিখুন",
  HereSays: "এখানে বলে",
  Region: "অঞ্চল",
  Province: "প্রদেশ",
  Heard: "শুনেছি",
  Shared: "শেয়ার করা হয়েছে",
  Successfully: "সাকসেসফলি",
};
export const Portuguese = {
  Explanation: "Explicação",
  Language: "Linguagem",
  Jurisdiction: "Jurisdição",
  Attention: "Atenção",
  "LANGUAGE SELECTION": "SELEÇÃO DE IDIOMA",
  ACCESS: "ACESSO",
  "Terms of use": "Termos de uso",
  REFUSE: "RECUSAR",
  ACCEPT: "ACEITAR",
  READ: "LER",
  PUBLISH: "PUBLICAR",
  CHAT: "BATER PAPO",
  "comming soon": "em breve",
  Other: "Outro",
  Remark: "Observação",
  Suggestion: "Sugestão",
  Complaint: "Reclamação",
  Manual: "Manual",
  SEARCH: "PROCURAR",
  SHARE: "COMPARTILHAR",
  DENOUNCE: "DENUNCIAR",
  "This post has not been reviewed": "Esta postagem não foi revisada",
  SUBMIT: "ENVIAR",
  Topic: "Tema",
  "Published BY": "Publicado por",
  English: "Inglês",
  Country: "País",
  City: "Cidade",
  Place: "Lugar",
  "Subject 1": "Assunto 1",
  "Subject 2": "Assunto 2",
  "Subject 3": "Assunto 3",
  "Reference Id": "Referência ID",
  "Enter Email Address": "Insira o endereço de e-mail",
  CHOOSE: "ESCOLHER",
  By: "Por",
  "End": "O fim",
  "Enter Your Story Content": "Insira o conteúdo da sua história",
  "UPLOAD IMAGES": "ENVIAR IMAGENS",
  "Click or Drop here": "Clique ou Solte aqui",
  "Remove all images": "Remover todas as imagens",
  "Your post has been properly submitted":
    "Sua postagem foi enviada corretamente",
  "and is published under": "e é publicado sob",
  "Please copy this for your reference":
    "Por favor, copie isso para sua referência",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "SUA PERGUNTA FOI ENVIADA CORRETAMENTE",
  "User Instructions": "Instruções do usuário",
  Contact: "Contato",
  "News or HereSays Herald": "Notícias ou HereSays Herald",
  Disclaimer: "Isenção de responsabilidade",
  "Region or Province or County or State":
    "Região ou Província ou Condado ou Estado",
  and: "e",
  Date: "Data",
  Content: "Contente",
  "TopicName is required": "Nome do Tópico é obrigatório",
  "Publisher Name is required": "O nome do editor é obrigatório",
  "Enter Your Feedback": "Digite seu feedback",
  HereSays: "AquiDiz",
  Region: "Região",
  Province: "Província",
  Heard: "Ouviu",
  Shared: "Compartilhado",
  Successfully: "com sucesso",
};
export const Indonesian = {
  Explanation: "Penjelasan",
  Language: "Bahasa",
  Jurisdiction: "Yurisdiksi",
  Attention: "Perhatian",
  "LANGUAGE SELECTION": "PILIHAN BAHASA",
  ACCESS: "MENGAKSES",
  "Terms of use": "Syarat Penggunaan",
  REFUSE: "MENOLAK",
  ACCEPT: "MENERIMA",
  READ: "MEMBACA",
  PUBLISH: "MENERBITKAN",
  CHAT: "MENGOBROL",
  "comming soon": "segera",
  Other: "Lainnya",
  Remark: "Komentar",
  Suggestion: "Saran",
  Complaint: "Keluhan",
  Manual: "Manual",
  SEARCH: "MENCARI",
  SHARE: "MEMBAGIKAN",
  DENOUNCE: "MENCELA",
  "This post has not been reviewed": "Posting ini belum ditinjau",
  SUBMIT: "KIRIM",
  Topic: "Tema",
  "Published BY": "Diterbitkan oleh",
  English: "Bahasa inggris",
  Country: "Negara",
  City: "Kota",
  Place: "Tempat",
  "Subject 1": "Subyek 1",
  "Subject 2": "Subyek 2",
  "Subject 3": "Subyek 3",
  "Reference Id": "Nomor Referensi",
  "Enter Email Address": "Masukkan alamat email",
  CHOOSE: "MEMILIH",
  By: "Oleh",
  "End": "Tamat",
  "Enter Your Story Content": "Masukkan Konten Cerita Anda",
  "UPLOAD IMAGES": "UNGGAH GAMBAR",
  "Click or Drop here": "Klik atau Jatuhkan di sini",
  "Remove all images": "Hapus semua gambar",
  "Your post has been properly submitted":
    "Posting Anda telah dikirimkan dengan benar",
  "and is published under": "dan diterbitkan di bawah",
  "Please copy this for your reference":
    "Silakan salin ini untuk referensi Anda",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "PERTANYAAN ANDA TELAH DIKIRIM DENGAN BENAR",
  "User Instructions": "Instruksi Pengguna",
  Contact: "Kontak",
  "News or HereSays Herald": "Berita atau HereSays Herald",
  Disclaimer: "Penafian",
  "Region or Province or County or State":
    "Wilayah atau Provinsi atau Kabupaten atau Negara Bagian",
  and: "Dan",
  Date: "Tanggal",
  Content: "Isi",
  "TopicName is required": "NamaTopik wajib diisi",
  "Publisher Name is required": "Nama Penerbit wajib diisi",
  "Enter Your Feedback": "Masukkan Umpan Balik Anda",
  HereSays: "HereSays",
  Region: "Wilayah",
  Province: "Propinsi",
  Heard: "Mendengar",
  Shared: "Bersama",
  Successfully: "Berhasil",
};
export const French = {
  Explanation: "Explication",
  Language: "Langue",
  Jurisdiction: "Juridiction",
  Attention: "Attention",
  "LANGUAGE SELECTION": "SÉLECTION DE LA LANGUE",
  ACCESS: "ACCÈS",
  "Terms of use": "Conditions d'utilisation",
  REFUSE: "REFUSER",
  ACCEPT: "ACCEPTER",
  READ: "LIRE",
  PUBLISH: "PUBLIER",
  CHAT: "DISCUTER",
  "comming soon": "prochainement",
  Other: "Autre",
  Remark: "Remarque",
  Suggestion: "Suggestion",
  Complaint: "Plainte",
  Manual: "Manuel",
  SEARCH: "RECHERCHE",
  SHARE: "PARTAGER",
  DENOUNCE: "DÉNONCER",
  "This post has not been reviewed": "Ce message n'a pas été examiné",
  SUBMIT: "SOUMETTRE",
  Topic: "Sujet",
  "Published BY": "Publié par",
  English: "Anglais",
  Country: "Pays",
  City: "Ville",
  Place: "Lieu",
  "Subject 1": "Sujet 1",
  "Subject 2": "Sujet 2",
  "Subject 3": "Sujet 3",
  "Reference Id": "Pièce d'identité",
  "Enter Email Address": "Entrer l'adresse e-mail",
  CHOOSE: "CHOISIR",
  By: "Par",
  "End": "La fin",
  "Enter Your Story Content": "Entrez le contenu de votre histoire",
  "UPLOAD IMAGES": "IMPORTER DES IMAGES",
  "Click or Drop here": "Cliquez ou déposez ici",
  "Remove all images": "Supprimer toutes les images",
  "Your post has been properly submitted": "Votre message a bien été soumis",
  "and is published under": "et est publié sous",
  "Please copy this for your reference":
    "Veuillez copier ceci pour votre référence",
  "ID:HERESAYS000": "ID : ICI000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "VOTRE DEMANDE A ÉTÉ CORRECTEMENT SOUMISE",
  "User Instructions": "Mode d'emploi",
  Contact: "Contact",
  "News or HereSays Herald": "Nouvelles ou HereSays Herald",
  Disclaimer: "Clause de non-responsabilité",
  "Region or Province or County or State":
    "Région ou province ou comté ou état",
  and: "et",
  Date: "Date",
  Content: "Contenu",
  "TopicName is required": "Le nom du sujet est requis",
  "Publisher Name is required": "Le nom de l'éditeur est requis",
  "Enter Your Feedback": "Saisissez vos commentaires",
  HereSays: "Ici dit",
  Region: "Région",
  Province: "Province",
  Heard: "Entendu",
  Shared: "partagé",
  Successfully: "Avec succès",
};
export const Afrikaans = {
  Explanation: "Verduideliking",
  Language: "Taal",
  Jurisdiction: "Jurisdiksie",
  Attention: "Aandag",
  "LANGUAGE SELECTION": "TAALKEUSE",
  ACCESS: "TOEGANG",
  "Terms of use": "Gebruiksvoorwaardes",
  REFUSE: "WEIER",
  ACCEPT: "AANVAAR",
  READ: "LEES",
  PUBLISH: "PUBLISEER",
  CHAT: "KLETS",
  "comming soon": "binnekort kom",
  Other: "Ander",
  Remark: "Opmerking",
  Suggestion: "Voorstel",
  Complaint: "Klag",
  Manual: "Handleiding",
  SEARCH: "SOEK",
  SHARE: "DEEL",
  DENOUNCE: "VERKLARING",
  "This post has not been reviewed": "Hierdie plasing is nie hersien nie",
  SUBMIT: "INDIEN",
  Topic: "Onderwerp",
  "Published BY": "Gepubliseer DEUR",
  English: "Engels",
  Country: "Land",
  City: "Stad",
  Place: "Plek",
  "Subject 1": "Onderwerp 1",
  "Subject 2": "Onderwerp 2",
  "Subject 3": "Onderwerp 3",
  "Reference Id": "Verwysings-ID",
  "Enter Email Address": "Voer e-posadres in",
  CHOOSE: "KIES",
  By: "Deur",
  "End": "Die einde",
  "Enter Your Story Content": "Voer jou storie-inhoud in",
  "UPLOAD IMAGES": "Laai BEELDE OP",
  "Click or Drop here": "Klik of los hier",
  "Remove all images": "Verwyder alle beelde",
  "Your post has been properly submitted": "Jou pos is behoorlik ingedien",
  "and is published under": "en word gepubliseer onder",
  "Please copy this for your reference":
    "Kopieer dit asseblief vir u verwysing",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "JOU VRAAG IS BEHOORLIK INDIEN",
  "User Instructions": "Gebruikersinstruksies",
  Contact: "Kontak",
  "News or HereSays Herald": "Nuus of HereSê Herald",
  Disclaimer: "Vrywaring",
  "Region or Province or County or State":
    "Streek of Provinsie of County of Staat",
  and: "en",
  Date: "Datum",
  Content: "Inhoud",
  "TopicName is required": "Onderwerpnaam word vereis",
  "Publisher Name is required": "Uitgewernaam word vereis",
  "Enter Your Feedback": "Voer jou terugvoer in",
  HereSays: "Hier Sê",
  Region: "Streek",
  Province: "Provinsie",
  Heard: "Gehoor",
  Shared: "Gedeel",
  Successfully: "Suksesvol",
};
export const Basque = {
  "User Instructions": "Erabiltzailearen Argibideak",
  Contact: "Harremanetan jarri",
  "News or HereSays Herald": "Albisteak edo HereSays Herald",
  Disclaimer: "Erantzukizuna",
  "Region or Province or County or State":
    "Eskualdea edo Probintzia edo Konderria edo Estatua",
  and: "eta",
  Date: "Data",
  Content: "Edukia",
  "TopicName is required": "TopicName beharrezkoa da",
  "Publisher Name is required": "Argitaletxearen izena beharrezkoa da",
  "Enter Your Feedback": "Sartu zure iritzia",
  HereSays: "Hemen dio",
  Region: "Eskualdea",
  Province: "Probintzia",
  Heard: "Entzun",
  Shared: "Partekatua",
  Successfully: "Arrakastaz",
  Explanation: "Azalpena",
  Language: "Hizkuntza",
  Jurisdiction: "Jurisdikzioa",
  Attention: "Arreta",
  "LANGUAGE SELECTION": "HIZKUNTZA HAUTAKETA",
  ACCESS: "SARBIDEA",
  "Terms of use": "Erabilera baldintzak",
  REFUSE: "UKOA",
  ACCEPT: "ONARTU",
  READ: "IRAKURRI",
  PUBLISH: "ARGITARATZEA",
  CHAT: "TXAT",
  "comming soon": "laster etortzen",
  Other: "Bestela",
  Remark: "Oharra",
  Suggestion: "Iradokizuna",
  Complaint: "Salaketa",
  Manual: "Eskuliburua",
  SEARCH: "BILATU",
  SHARE: "PARTEKATU",
  DENOUNCE: "DENUNTZA",
  "This post has not been reviewed": "Mezu hau ez da berrikusi",
  SUBMIT: "BIDALI",
  Topic: "Gai",
  "Published BY": "BY argitaratua",
  English: "ingelesa",
  Country: "Herrialdea",
  City: "Hiria",
  Place: "Lekua",
  "Subject 1": "1. gaia",
  "Subject 2": "2. gaia",
  "Subject 3": "3. gaia",
  "Reference Id": "Erreferentzia ID",
  "Enter Email Address": "Sartu helbide elektronikoa",
  CHOOSE: "AUKERATU",
  By: "Nork",
  "End": "Amaiera",
  "Enter Your Story Content": "Sartu zure istorioaren edukia",
  "UPLOAD IMAGES": "IGO IRUDIAK",
  "Click or Drop here": "Egin klik edo Jaregin hemen",
  "Remove all images": "Kendu irudi guztiak",
  "Your post has been properly submitted": "Zure mezua behar bezala bidali da",
  "and is published under": "eta azpian argitaratzen da",
  "Please copy this for your reference":
    "Mesedez, kopiatu hau zure erreferentzia izateko",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ZURE KONTSULTA EGOKI BIDALIATZEN DA",
};
export const Catalan = {
  "User Instructions": "Instruccions per a l'usuari",
  Contact: "Contacte",
  "News or HereSays Herald": "News o HereSays Herald",
  Disclaimer: "Exempció de responsabilitat",
  "Region or Province or County or State": "Regió o província o comtat o estat",
  and: "i",
  Date: "Data",
  Content: "Contingut",
  "TopicName is required": "El nom del tema és obligatori",
  "Publisher Name is required": "El nom de l'editor és obligatori",
  "Enter Your Feedback": "Introduïu els vostres comentaris",
  HereSays: "Aquí Diu",
  Region: "Regió",
  Province: "Província",
  Heard: "Escoltat",
  Shared: "Compartit",
  Successfully: "Amb èxit",
  Explanation: "Explicació",
  Language: "Llenguatge",
  Jurisdiction: "Jurisdicció",
  Attention: "Atenció",
  "LANGUAGE SELECTION": "SELECCIÓ D'IDIOMES",
  ACCESS: "ACCÉS",
  "Terms of use": "Condicions d'ús",
  REFUSE: "NEGATIU",
  ACCEPT: "ACEPTAR",
  READ: "LLEGIR",
  PUBLISH: "PUBLICAR",
  CHAT: "XAT",
  "comming soon": "pròximament",
  Other: "Altres",
  Remark: "Observació",
  Suggestion: "Suggeriment",
  Complaint: "Queixa",
  Manual: "Manual",
  SEARCH: "CERCA",
  SHARE: "COMPARTIR",
  DENOUNCE: "DENUNCIA",
  "This post has not been reviewed": "Aquesta publicació no ha estat revisada",
  SUBMIT: "PRESENTAR",
  Topic: "Tema",
  "Published BY": "Publicat per",
  English: "Anglès",
  Country: "País",
  City: "ciutat",
  Place: "Lloc",
  "Subject 1": "Tema 1",
  "Subject 2": "Tema 2",
  "Subject 3": "Tema 3",
  "Reference Id": "Id. de referència",
  "Enter Email Address": "Introduïu l'adreça de correu",
  CHOOSE: "ESCOLLIR",
  By: "Per",
  "End": "El final",
  "Enter Your Story Content": "Introduïu el contingut de la vostra història",
  "UPLOAD IMAGES": "CARREGA IMATGES",
  "Click or Drop here": "Feu clic o deixeu anar aquí",
  "Remove all images": "Elimina totes les imatges",
  "Your post has been properly submitted":
    "La teva publicació s'ha enviat correctament",
  "and is published under": "i es publica sota",
  "Please copy this for your reference":
    "Si us plau, copieu-ho com a referència",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "LA SEVA CONSULTA S'HA ENVIAT CORRECTAMENT",
};
export const Czech = {
  "User Instructions": "Uživatelské pokyny",
  Contact: "Kontakt",
  "News or HereSays Herald": "News nebo HereSays Herald",
  Disclaimer: "Zřeknutí se odpovědnosti",
  "Region or Province or County or State":
    "Region nebo provincie nebo kraj nebo stát",
  and: "a",
  Date: "datum",
  Content: "Obsah",
  "TopicName is required": "Název tématu je povinný",
  "Publisher Name is required": "Jméno vydavatele je povinné",
  "Enter Your Feedback": "Zadejte svůj názor",
  HereSays: "Tady říká",
  Region: "Kraj",
  Province: "Provincie",
  Heard: "Slyšel",
  Shared: "Sdíleno",
  Successfully: "Úspěšně",
  Explanation: "Vysvětlení",
  Language: "Jazyk",
  Jurisdiction: "Jurisdikce",
  Attention: "Pozornost",
  "LANGUAGE SELECTION": "VÝBĚR JAZYKA",
  ACCESS: "PŘÍSTUP",
  "Terms of use": "Podmínky použití",
  REFUSE: "ODMÍTNOUT",
  ACCEPT: "AKCEPTOVAT",
  READ: "ČÍST",
  PUBLISH: "PUBLIKOVAT",
  CHAT: "POVÍDAT SI",
  "comming soon": "již brzy",
  Other: "jiný",
  Remark: "Poznámka",
  Suggestion: "Návrh",
  Complaint: "Stížnost",
  Manual: "Manuál",
  SEARCH: "VYHLEDÁVÁNÍ",
  SHARE: "PODÍL",
  DENOUNCE: "VYPOVĚDĚT",
  "This post has not been reviewed": "Tento příspěvek nebyl zkontrolován",
  SUBMIT: "PŘEDLOŽIT",
  Topic: "Téma",
  "Published BY": "Publikováno",
  English: "Angličtina",
  Country: "Země",
  City: "Město",
  Place: "Místo",
  "Subject 1": "Předmět 1",
  "Subject 2": "Předmět 2",
  "Subject 3": "Předmět 3",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Vložte e-mailovou adresu",
  CHOOSE: "VYBRAT",
  By: "Podle",
  "End": "Konec",
  "Enter Your Story Content": "Zadejte obsah svého příběhu",
  "UPLOAD IMAGES": "NAHRÁT OBRÁZKY",
  "Click or Drop here": "Klikněte nebo přetáhněte sem",
  "Remove all images": "Odstraňte všechny obrázky",
  "Your post has been properly submitted": "Váš příspěvek byl správně odeslán",
  "and is published under": "a je zveřejněn pod",
  "Please copy this for your reference":
    "Zkopírujte prosím toto pro vaši informaci",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "VÁŠ DOTAZ BYL ŘÁDNĚ ODESLÁN",
};
export const Dutch = {
  "User Instructions": "Gebruikersinstructies",
  Contact: "Contact",
  "News or HereSays Herald": "Nieuws of HereSays Herald",
  Disclaimer: "Vrijwaring",
  "Region or Province or County or State":
    "Regio of provincie of provincie of staat",
  and: "En",
  Date: "Datum",
  Content: "Inhoud",
  "TopicName is required": "Onderwerpnaam is vereist",
  "Publisher Name is required": "Uitgeversnaam is vereist",
  "Enter Your Feedback": "Voer uw feedback in",
  HereSays: "Hier zegt",
  Region: "Regio",
  Province: "Provincie",
  Heard: "Gehoord",
  Shared: "Gedeeld",
  Successfully: "Met succes",
  Explanation: "Uitleg",
  Language: "Taal",
  Jurisdiction: "Jurisdictie",
  Attention: "Aandacht",
  "LANGUAGE SELECTION": "TAAL SELECTIE",
  ACCESS: "TOEGANG",
  "Terms of use": "Gebruiksvoorwaarden",
  REFUSE: "WEIGEREN",
  ACCEPT: "AANVAARDEN",
  READ: "LEZEN",
  PUBLISH: "PUBLICEREN",
  CHAT: "CHATTEN",
  "comming soon": "binnenkort",
  Other: "Ander",
  Remark: "Opmerking",
  Suggestion: "Suggestie",
  Complaint: "Klacht",
  Manual: "Handmatig",
  SEARCH: "ZOEKOPDRACHT",
  SHARE: "DEEL",
  DENOUNCE: "AANGEVEN",
  "This post has not been reviewed": "Dit bericht is niet beoordeeld",
  SUBMIT: "INDIENEN",
  Topic: "Onderwerp",
  "Published BY": "Gepubliceerd door",
  English: "Engels",
  Country: "Land",
  City: "Stad",
  Place: "Plaats",
  "Subject 1": "Onderwerp 1",
  "Subject 2": "Onderwerp 2",
  "Subject 3": "Onderwerp 3",
  "Reference Id": "Referentie nummer",
  "Enter Email Address": "Vul email adres in",
  CHOOSE: "KIEZEN",
  By: "Door",
  "End": "Het einde",
  "Enter Your Story Content": "Voer uw verhaalinhoud in",
  "UPLOAD IMAGES": "UPLOAD AFBEELDINGEN",
  "Click or Drop here": "Klik of zet hier neer",
  "Remove all images": "Verwijder alle afbeeldingen",
  "Your post has been properly submitted": "Uw bericht is correct verzonden",
  "and is published under": "en wordt gepubliceerd onder",
  "Please copy this for your reference": "Kopieer dit voor uw referentie",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "UW VRAAG IS GOED INGEDIEND",
};
export const Finnish = {
  "User Instructions": "Käyttöohjeet",
  Contact: "Ottaa yhteyttä",
  "News or HereSays Herald": "News tai HereSays Herald",
  Disclaimer: "Vastuuvapauslauseke",
  "Region or Province or County or State":
    "Alue tai provinssi tai lääni tai osavaltio",
  and: "ja",
  Date: "Päivämäärä",
  Content: "Sisältö",
  "TopicName is required": "TopicName vaaditaan",
  "Publisher Name is required": "Julkaisijan nimi vaaditaan",
  "Enter Your Feedback": "Anna palautteesi",
  HereSays: "Tässä Sanoo",
  Region: "Alue",
  Province: "maakunta",
  Heard: "Kuultu",
  Shared: "Jaettu",
  Successfully: "Onnistuneesti",
  Explanation: "Selitys",
  Language: "Kieli",
  Jurisdiction: "Toimivalta",
  Attention: "Huomio",
  "LANGUAGE SELECTION": "KIELEN VALINTA",
  ACCESS: "PÄÄSY",
  "Terms of use": "Käyttöehdot",
  REFUSE: "KIELTÄYTYÄ",
  ACCEPT: "HYVÄKSYÄ",
  READ: "LUKEA",
  PUBLISH: "JULKAISTA",
  CHAT: "CHAT",
  "comming soon": "tulossa pian",
  Other: "muu",
  Remark: "Huomautus",
  Suggestion: "Ehdotus",
  Complaint: "Valitus",
  Manual: "Manuaalinen",
  SEARCH: "HAE",
  SHARE: "JAA",
  DENOUNCE: "TUOMIOITTAA",
  "This post has not been reviewed": "Tätä viestiä ei ole tarkistettu",
  SUBMIT: "LÄHETÄ",
  Topic: "Aihe",
  "Published BY": "Julkaissut",
  English: "Englanti",
  Country: "Maa",
  City: "Kaupunki",
  Place: "Paikka",
  "Subject 1": "Aihe 1",
  "Subject 2": "Aihe 2",
  "Subject 3": "Aihe 3",
  "Reference Id": "Viite Id",
  "Enter Email Address": "Anna sähköpostiosoite",
  CHOOSE: "VALITA",
  By: "Tekijä:",
  "End": "Loppu",
  "Enter Your Story Content": "Kirjoita tarinasi sisältö",
  "UPLOAD IMAGES": "LATA KUVIA",
  "Click or Drop here": "Napsauta tai pudota tähän",
  "Remove all images": "Poista kaikki kuvat",
  "Your post has been properly submitted": "Viestisi on lähetetty oikein",
  "and is published under": "ja on julkaistu alla",
  "Please copy this for your reference": "Kopioi tämä viitteellesi",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "KYSYMYKSISI ON LÄHETETTY OIKEASTI",
};
export const Galician = {
  "User Instructions": "Instrucións de usuario",
  Contact: "Contacto",
  "News or HereSays Herald": "News ou HereSays Herald",
  Disclaimer: "Exención de responsabilidade",
  "Region or Province or County or State":
    "Rexión ou provincia ou condado ou estado",
  and: "e",
  Date: "Data",
  Content: "Contido",
  "TopicName is required": "O nome do tema é necesario",
  "Publisher Name is required": "O nome do editor é necesario",
  "Enter Your Feedback": "Introduza o seu comentario",
  HereSays: "Aquí di",
  Region: "Rexión",
  Province: "Provincia",
  Heard: "Escoitado",
  Shared: "Compartido",
  Successfully: "Con éxito",
  Explanation: "Explicación",
  Language: "Lingua",
  Jurisdiction: "Xurisdicción",
  Attention: "Atención",
  "LANGUAGE SELECTION": "SELECCIÓN DA LINGUA",
  ACCESS: "ACCESO",
  "Terms of use": "Condicións de uso",
  REFUSE: "REXEITAR",
  ACCEPT: "ACEPTAR",
  READ: "LER",
  PUBLISH: "PUBLICAR",
  CHAT: "CHAT",
  "comming soon": "en breve",
  Other: "Outra",
  Remark: "Observación",
  Suggestion: "Suxestión",
  Complaint: "Queixa",
  Manual: "Manual",
  SEARCH: "BUSCAR",
  SHARE: "COMPARTIR",
  DENOUNCE: "DENUNCIA",
  "This post has not been reviewed": "Esta publicación non foi revisada",
  SUBMIT: "ENVIAR",
  Topic: "Tema",
  "Published BY": "Publicado POR",
  English: "inglés",
  Country: "País",
  City: "Cidade",
  Place: "Lugar",
  "Subject 1": "Tema 1",
  "Subject 2": "Tema 2",
  "Subject 3": "Tema 3",
  "Reference Id": "Referencia Id",
  "Enter Email Address": "Introduza o enderezo de correo electrónico",
  CHOOSE: "ESCOLLE",
  By: "Por",
  "End": "O Fin",
  "Enter Your Story Content": "Introduce o contido da túa historia",
  "UPLOAD IMAGES": "CARGA IMAXES",
  "Click or Drop here": "Fai clic ou solta aquí",
  "Remove all images": "Elimina todas as imaxes",
  "Your post has been properly submitted":
    "A túa publicación enviouse correctamente",
  "and is published under": "e publícase baixo",
  "Please copy this for your reference": "Copia isto como referencia",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "A SÚA CONSULTA FOI CORRECTAMENTE ENVIADA",
};
export const German = {
  "User Instructions": "Gebrauchsanweisung",
  Contact: "Kontakt",
  "News or HereSays Herald": "News oder HereSays Herald",
  Disclaimer: "Haftungsausschluss",
  "Region or Province or County or State":
    "Region oder Provinz oder Landkreis oder Staat",
  and: "Und",
  Date: "Datum",
  Content: "Inhalt",
  "TopicName is required": "Themenname ist erforderlich",
  "Publisher Name is required": "Publisher-Name ist erforderlich",
  "Enter Your Feedback": "Geben Sie Ihr Feedback ein",
  HereSays: "Hier sagt",
  Region: "Region",
  Province: "Provinz",
  Heard: "Gehört",
  Shared: "Geteilt",
  Successfully: "Erfolgreich",
  Explanation: "Erläuterung",
  Language: "Sprache",
  Jurisdiction: "Zuständigkeit",
  Attention: "Aufmerksamkeit",
  "LANGUAGE SELECTION": "SPRACHAUSWAHL",
  ACCESS: "ZUGANG",
  "Terms of use": "Nutzungsbedingungen",
  REFUSE: "VERWEIGERN",
  ACCEPT: "AKZEPTIEREN",
  READ: "LESEN",
  PUBLISH: "VERÖFFENTLICHEN",
  CHAT: "PLAUDERN",
  "comming soon": "in Kürze",
  Other: "Andere",
  Remark: "Anmerkung",
  Suggestion: "Anregung",
  Complaint: "Beschwerde",
  Manual: "Handbuch",
  SEARCH: "SUCHEN",
  SHARE: "AKTIE",
  DENOUNCE: "ANPRANGERN",
  "This post has not been reviewed": "Dieser Beitrag wurde nicht überprüft",
  SUBMIT: "EINREICHEN",
  Topic: "Thema",
  "Published BY": "Herausgegeben von",
  English: "Englisch",
  Country: "Land",
  City: "Stadt",
  Place: "Ort",
  "Subject 1": "Thema 1",
  "Subject 2": "Thema 2",
  "Subject 3": "Thema 3",
  "Reference Id": "Referenz ID",
  "Enter Email Address": "E-Mail Adresse eingeben",
  CHOOSE: "WÄHLEN",
  By: "Von",
  "End": "Das Ende",
  "Enter Your Story Content": "Geben Sie Ihren Story-Inhalt ein",
  "UPLOAD IMAGES": "BILDER HOCHLADEN",
  "Click or Drop here": "Hier klicken oder ablegen",
  "Remove all images": "Entfernen Sie alle Bilder",
  "Your post has been properly submitted":
    "Ihr Beitrag wurde ordnungsgemäß übermittelt",
  "and is published under": "und ist veröffentlicht unter",
  "Please copy this for your reference":
    "Bitte kopieren Sie diese als Referenz",
  "ID:HERESAYS000": "ID: HERSAGT000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "IHRE ANFRAGE WURDE ORDNUNGSGEMÄSS ABGEGEBEN",
};
export const Greek = {
  "User Instructions": "Οδηγίες χρήστη",
  Contact: "Επικοινωνία",
  "News or HereSays Herald": "News ή HereSays Herald",
  Disclaimer: "Αποποίηση ευθυνών",
  "Region or Province or County or State":
    "Περιφέρεια ή επαρχία ή κομητεία ή πολιτεία",
  and: "και",
  Date: "Ημερομηνία",
  Content: "Περιεχόμενο",
  "TopicName is required": "Το όνομα θέματος είναι υποχρεωτικό",
  "Publisher Name is required": "Απαιτείται όνομα εκδότη",
  "Enter Your Feedback": "Εισαγάγετε τα σχόλιά σας",
  HereSays: "HereSays",
  Region: "Περιοχή",
  Province: "Επαρχία",
  Heard: "Ακούστηκε",
  Shared: "Κοινή χρήση",
  Successfully: "Επιτυχώς",
  Explanation: "Εξήγηση",
  Language: "Γλώσσα",
  Jurisdiction: "Δικαιοδοσία",
  Attention: "Προσοχή",
  "LANGUAGE SELECTION": "ΕΠΙΛΟΓΗ ΓΛΩΣΣΑΣ",
  ACCESS: "ΠΡΟΣΒΑΣΗ",
  "Terms of use": "Οροι χρήσης",
  REFUSE: "ΑΡΝΗΘΕΙ",
  ACCEPT: "ΑΠΟΔΕΧΟΜΑΙ",
  READ: "ΑΝΑΓΝΩΣΗ",
  PUBLISH: "ΔΗΜΟΣΙΕΥΩ",
  CHAT: "ΚΟΥΒΕΝΤΑ",
  "comming soon": "σύντομα",
  Other: "Αλλα",
  Remark: "Παρατήρηση",
  Suggestion: "Πρόταση",
  Complaint: "Καταγγελία",
  Manual: "Εγχειρίδιο",
  SEARCH: "ΑΝΑΖΗΤΗΣΗ",
  SHARE: "ΜΕΡΙΔΙΟ",
  DENOUNCE: "ΚΑΤΑΓΓΕΛΩ",
  "This post has not been reviewed": "Αυτή η ανάρτηση δεν έχει ελεγχθεί",
  SUBMIT: "ΥΠΟΒΑΛΛΟΥΝ",
  Topic: "Θέμα",
  "Published BY": "Δημοσιεύτηκε ΑΠΟ",
  English: "Αγγλικά",
  Country: "Χώρα",
  City: "Πόλη",
  Place: "Θέση",
  "Subject 1": "Θέμα 1",
  "Subject 2": "Θέμα 2",
  "Subject 3": "Θέμα 3",
  "Reference Id": "Αναγν. Αναφοράς",
  "Enter Email Address": "Εισάγετε ηλεκτρονική διεύθυνση",
  CHOOSE: "ΕΠΙΛΕΓΩ",
  By: "Με",
  "End": "Το τέλος",
  "Enter Your Story Content": "Εισαγάγετε το περιεχόμενο της ιστορίας σας",
  "UPLOAD IMAGES": "ΑΝΕΒΑΣΤΕ ΕΙΚΟΝΕΣ",
  "Click or Drop here": "Κάντε κλικ ή αποθέστε εδώ",
  "Remove all images": "Αφαιρέστε όλες τις εικόνες",
  "Your post has been properly submitted":
    "Η ανάρτησή σας έχει υποβληθεί σωστά",
  "and is published under": "και δημοσιεύεται υπό",
  "Please copy this for your reference": "Παρακαλώ αντιγράψτε αυτό για αναφορά",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ΤΟ ΕΡΩΤΗΣΗ ΣΑΣ ΕΧΕΙ ΥΠΟΒΟΛΗ ΣΩΣΤΑ",
};
export const Hebrew = {
  "User Instructions": "הוראות למשתמש",
  Contact: "איש קשר",
  "News or HereSays Herald": "חדשות או HereSays Herald",
  Disclaimer: "כתב ויתור",
  "Region or Province or County or State": "אזור או מחוז או מחוז או מדינה",
  and: "ו",
  Date: "תַאֲרִיך",
  Content: "תוֹכֶן",
  "TopicName is required": "דרוש שם נושא",
  "Publisher Name is required": "נדרש שם בעל אתר",
  "Enter Your Feedback": "הזן את המשוב שלך",
  HereSays: "כאן אומר",
  Region: "אזור",
  Province: "מָחוֹז",
  Heard: "שמע",
  Shared: "מְשׁוּתָף",
  Successfully: "בְּהַצלָחָה",
  Explanation: "הֶסבֵּר",
  Language: "שפה",
  Jurisdiction: "סמכות שיפוט",
  Attention: "תשומת הלב",
  "LANGUAGE SELECTION": "בחירת שפה",
  ACCESS: "גִישָׁה",
  "Terms of use": "תנאי שימוש",
  REFUSE: "מסרב",
  ACCEPT: "לְקַבֵּל",
  READ: "לקרוא",
  PUBLISH: "לְפַרְסֵם",
  CHAT: "לְשׂוֹחֵחַ",
  "comming soon": "בקרוב",
  Other: "אַחֵר",
  Remark: "הֶעָרָה",
  Suggestion: "הַצָעָה",
  Complaint: "תְלוּנָה",
  Manual: "מדריך ל",
  SEARCH: "לחפש",
  SHARE: "לַחֲלוֹק",
  DENOUNCE: "לְהוֹקִיעַ",
  "This post has not been reviewed": "פוסט זה לא נבדק",
  SUBMIT: "שלח",
  Topic: "נוֹשֵׂא",
  "Published BY": "פורסם על ידי",
  English: "אנגלית",
  Country: "מדינה",
  City: "עִיר",
  Place: "מקום",
  "Subject 1": "נושא 1",
  "Subject 2": "נושא 2",
  "Subject 3": "נושא 3",
  "Reference Id": "זיהוי סימוכין",
  "Enter Email Address": "הזן כתובת אימייל",
  CHOOSE: "בחר",
  By: "על ידי",
  "End": "הסוף",
  "Enter Your Story Content": "הזן את תוכן הסיפור שלך",
  "UPLOAD IMAGES": "להעלות תמונות",
  "Click or Drop here": "לחץ או שחרר כאן",
  "Remove all images": "הסר את כל התמונות",
  "Your post has been properly submitted": "הפוסט שלך הוגש כהלכה",
  "and is published under": "ומתפרסם תחת",
  "Please copy this for your reference": "אנא העתק את זה לעיונך",
  "ID:HERESAYS000": "מזהה: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "השאילתה שלך הוגשה כהלכה",
};
export const Hungarian = {
  "User Instructions": "Felhasználói utasítások",
  Contact: "Kapcsolatba lépni",
  "News or HereSays Herald": "News vagy HereSays Herald",
  Disclaimer: "Jogi nyilatkozat",
  "Region or Province or County or State":
    "Régió vagy tartomány vagy megye vagy állam",
  and: "és",
  Date: "Dátum",
  Content: "Tartalom",
  "TopicName is required": "A TopicName megadása kötelező",
  "Publisher Name is required": "Meg kell adni a kiadó nevét",
  "Enter Your Feedback": "Írja be visszajelzését",
  HereSays: "IttSays",
  Region: "Vidék",
  Province: "Tartomány",
  Heard: "Hallott",
  Shared: "Megosztva",
  Successfully: "Sikeresen",
  Explanation: "Magyarázat",
  Language: "Nyelv",
  Jurisdiction: "Joghatóság",
  Attention: "Figyelem",
  "LANGUAGE SELECTION": "NYELVVÁLASZTÁS",
  ACCESS: "HOZZÁFÉRÉS",
  "Terms of use": "Használati feltételek",
  REFUSE: "MEGTAGADJA",
  ACCEPT: "ELFOGAD",
  READ: "OLVAS",
  PUBLISH: "KÖZZÉTÉTEL",
  CHAT: "CSEVEGÉS",
  "comming soon": "hamarosan",
  Other: "Egyéb",
  Remark: "Megjegyzés",
  Suggestion: "Javaslat",
  Complaint: "Panasz",
  Manual: "Kézikönyv",
  SEARCH: "KERESÉS",
  SHARE: "OSSZA MEG",
  DENOUNCE: "FEJLESZTÉS",
  "This post has not been reviewed": "Ezt a bejegyzést nem ellenőrizték",
  SUBMIT: "BEKÜLDÉS",
  Topic: "Téma",
  "Published BY": "Kiadta",
  English: "angol",
  Country: "Ország",
  City: "Város",
  Place: "Hely",
  "Subject 1": "1. tárgy",
  "Subject 2": "2. tárgy",
  "Subject 3": "3. tárgy",
  "Reference Id": "Hivatkozási azonosító",
  "Enter Email Address": "Adja meg az email címét",
  CHOOSE: "VÁLASZT",
  By: "Által",
  "End": "Vége",
  "Enter Your Story Content": "Írja be a történet tartalmát",
  "UPLOAD IMAGES": "KÉPEK FELTÖLTÉSE",
  "Click or Drop here": "Kattintson ide, vagy dobja ide",
  "Remove all images": "Távolítsa el az összes képet",
  "Your post has been properly submitted": "Bejegyzését megfelelően elküldte",
  "and is published under": "alatt jelenik meg",
  "Please copy this for your reference":
    "Kérjük, másolja ki ezt referenciaként",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "KÉRDÉSÉT MEGFELELŐEN BENYÚJTÁK",
};
export const Icelandic = {
  "User Instructions": "Notendaleiðbeiningar",
  Contact: "Hafðu samband",
  "News or HereSays Herald": "Fréttir eða HereSays Herald",
  Disclaimer: "Fyrirvari",
  "Region or Province or County or State": "Svæði eða hérað eða sýsla eða ríki",
  and: "og",
  Date: "Dagsetning",
  Content: "Efni",
  "TopicName is required": "Nafn efnis er áskilið",
  "Publisher Name is required": "Nafn útgefanda er áskilið",
  "Enter Your Feedback": "Sláðu inn athugasemd þína",
  HereSays: "Hér segir",
  Region: "Svæði",
  Province: "Hérað",
  Heard: "Heyrði",
  Shared: "Deilt",
  Successfully: "Með góðum árangri",
  Explanation: "Skýring",
  Language: "Tungumál",
  Jurisdiction: "Lögsaga",
  Attention: "Athygli",
  "LANGUAGE SELECTION": "TUNGUMALVAL",
  ACCESS: "AÐGANGUR",
  "Terms of use": "Notenda Skilmálar",
  REFUSE: "HAFIÐ",
  ACCEPT: "SAMÞYKKJA",
  READ: "LESIÐ",
  PUBLISH: "ÚTGÁFA",
  CHAT: "SPJALL",
  "comming soon": "kemur bráðum",
  Other: "Annað",
  Remark: "Athugasemd",
  Suggestion: "Tillaga",
  Complaint: "Kvörtun",
  Manual: "Handbók",
  SEARCH: "LEIT",
  SHARE: "DEILU",
  DENOUNCE: "Fordæma",
  "This post has not been reviewed": "Þessi færsla hefur ekki verið skoðuð",
  SUBMIT: "SENDA",
  Topic: "Umræðuefni",
  "Published BY": "Gefið út BY",
  English: "Enska",
  Country: "Land",
  City: "Borg",
  Place: "Staður",
  "Subject 1": "Viðfangsefni 1",
  "Subject 2": "Viðfangsefni 2",
  "Subject 3": "Viðfangsefni 3",
  "Reference Id": "Tilvísunarauðkenni",
  "Enter Email Address": "Sláðu inn netfang",
  CHOOSE: "VELDU",
  By: "By",
  "End": "Endirinn",
  "Enter Your Story Content": "Sláðu inn efni sögunnar",
  "UPLOAD IMAGES": "HLAÐA MYNDUM",
  "Click or Drop here": "Smelltu eða slepptu hér",
  "Remove all images": "Fjarlægðu allar myndir",
  "Your post has been properly submitted":
    "Færslan þín hefur verið rétt send inn",
  "and is published under": "og er birt undir",
  "Please copy this for your reference":
    "Vinsamlegast afritaðu þetta til viðmiðunar",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "Fyrirspurnin þín hefur verið rétt send",
};
export const Italian = {
  "User Instructions": "Istruzioni per l'utente",
  Contact: "Contatto",
  "News or HereSays Herald": "Notizie o HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Regione o Provincia o Contea o Stato",
  and: "E",
  Date: "Data",
  Content: "Contenuto",
  "TopicName is required": "TopicName è obbligatorio",
  "Publisher Name is required": "Il nome dell'editore è obbligatorio",
  "Enter Your Feedback": "Inserisci il tuo feedback",
  HereSays: "Qui dice",
  Region: "Regione",
  Province: "Provincia",
  Heard: "Sentito",
  Shared: "Condiviso",
  Successfully: "Con successo",
  Explanation: "Spiegazione",
  Language: "Lingua",
  Jurisdiction: "Giurisdizione",
  Attention: "Attenzione",
  "LANGUAGE SELECTION": "SELEZIONE DELLA LINGUA",
  ACCESS: "ACCESSO",
  "Terms of use": "Termini di utilizzo",
  REFUSE: "RIFIUTARE",
  ACCEPT: "ACCETTARE",
  READ: "LEGGERE",
  PUBLISH: "PUBBLICARE",
  CHAT: "CHIACCHIERATA",
  "comming soon": "presto in arrivo",
  Other: "Altro",
  Remark: "Nota",
  Suggestion: "Suggerimento",
  Complaint: "Rimostranza",
  Manual: "Manuale",
  SEARCH: "RICERCA",
  SHARE: "CONDIVIDERE",
  DENOUNCE: "DENUNCIARE",
  "This post has not been reviewed": "Questo post non è stato esaminato",
  SUBMIT: "INVIA",
  Topic: "Argomento",
  "Published BY": "Pubblicato da",
  English: "Inglese",
  Country: "Paese",
  City: "Città",
  Place: "Posto",
  "Subject 1": "Soggetto 1",
  "Subject 2": "Soggetto 2",
  "Subject 3": "Oggetto 3",
  "Reference Id": "Codice identificativo di riferimento",
  "Enter Email Address": "Inserisci l'indirizzo email",
  CHOOSE: "SCEGLIERE",
  By: "Di",
  "End": "Fine",
  "Enter Your Story Content": "Inserisci il contenuto della tua storia",
  "UPLOAD IMAGES": "CARICA IMMAGINI",
  "Click or Drop here": "Fare clic o rilasciare qui",
  "Remove all images": "Rimuovi tutte le immagini",
  "Your post has been properly submitted":
    "Il tuo post è stato inviato correttamente",
  "and is published under": "ed è pubblicato sotto",
  "Please copy this for your reference":
    "Si prega di copiarlo come riferimento",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "LA TUA DOMANDA È STATA INOLTRATA CORRETTAMENTE",
};
export const Japanese = {
  "User Instructions": "ユーザーの指示",
  Contact: "コンタクト",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "免責事項",
  "Region or Province or County or State": "地方、県、郡、州",
  and: "と",
  Date: "日にち",
  Content: "コンテンツ",
  "TopicName is required": "トピック名は必須です",
  "Publisher Name is required": "パブリッシャー名が必要です",
  "Enter Your Feedback": "フィードバックを入力してください",
  HereSays: "ヒアセズ",
  Region: "領域",
  Province: "州",
  Heard: "聞こえた",
  Shared: "共有",
  Successfully: "無事",
  Explanation: "説明",
  Language: "言語",
  Jurisdiction: "管轄",
  Attention: "注意",
  "LANGUAGE SELECTION": "言語選択",
  ACCESS: "アクセス",
  "Terms of use": "利用規約",
  REFUSE: "ごみ",
  ACCEPT: "受け入れる",
  READ: "読む",
  PUBLISH: "公開",
  CHAT: "チャット",
  "comming soon": "もうすぐ",
  Other: "他の",
  Remark: "述べる",
  Suggestion: "提案",
  Complaint: "苦情",
  Manual: "マニュアル",
  SEARCH: "検索",
  SHARE: "共有",
  DENOUNCE: "非難する",
  "This post has not been reviewed": "この投稿はレビューされていません",
  SUBMIT: "送信",
  Topic: "トピック",
  "Published BY": "発行者",
  English: "英語",
  Country: "国",
  City: "街",
  Place: "場所",
  "Subject 1": "主題 1",
  "Subject 2": "主題 2",
  "Subject 3": "科目 3",
  "Reference Id": "参照ID",
  "Enter Email Address": "メールアドレスを入力してください",
  CHOOSE: "選ぶ",
  By: "に",
  "End": "終わり",
  "Enter Your Story Content": "ストーリーの内容を入力してください",
  "UPLOAD IMAGES": "画像をアップロードする",
  "Click or Drop here": "ここをクリックまたはドロップ",
  "Remove all images": "すべての画像を削除",
  "Your post has been properly submitted": "あなたの投稿は正しく送信されました",
  "and is published under": "で公開されています",
  "Please copy this for your reference": "これをコピーして参考にしてください",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "あなたの質問は適切に送信されました",
};
export const Korean = {
  "User Instructions": "사용자 지침",
  Contact: "연락하다",
  "News or HereSays Herald": "뉴스 또는 HereSays Herald",
  Disclaimer: "부인 성명",
  "Region or Province or County or State": "지역 또는 도 또는 카운티 또는 주",
  and: "그리고",
  Date: "날짜",
  Content: "콘텐츠",
  "TopicName is required": "주제 이름이 필요합니다.",
  "Publisher Name is required": "게시자 이름이 필요합니다.",
  "Enter Your Feedback": "피드백을 입력하세요",
  HereSays: "HereSays",
  Region: "지역",
  Province: "주",
  Heard: "들었다",
  Shared: "공유",
  Successfully: "성공적으로",
  Explanation: "설명",
  Language: "언어",
  Jurisdiction: "관할권",
  Attention: "주목",
  "LANGUAGE SELECTION": "언어 선택",
  ACCESS: "입장",
  "Terms of use": "이용약관",
  REFUSE: "거절하다",
  ACCEPT: "수용하다",
  READ: "읽다",
  PUBLISH: "게시",
  CHAT: "채팅",
  "comming soon": "빨리오고",
  Other: "다른",
  Remark: "주목",
  Suggestion: "제안",
  Complaint: "불평",
  Manual: "수동",
  SEARCH: "찾다",
  SHARE: "공유하다",
  DENOUNCE: "고발하다",
  "This post has not been reviewed": "이 게시물은 검토되지 않았습니다",
  SUBMIT: "제출하다",
  Topic: "주제",
  "Published BY": "에 의해 출판 된",
  English: "영어",
  Country: "국가",
  City: "도시",
  Place: "장소",
  "Subject 1": "주제 1",
  "Subject 2": "주제 2",
  "Subject 3": "과목 3",
  "Reference Id": "참조 ID",
  "Enter Email Address": "이메일 주소 입력",
  CHOOSE: "선택하다",
  By: "에 의해",
  "End": "끝",
  "Enter Your Story Content": "스토리 콘텐츠 입력",
  "UPLOAD IMAGES": "이미지 업로드",
  "Click or Drop here": "여기를 클릭 또는 드롭",
  "Remove all images": "모든 이미지 제거",
  "Your post has been properly submitted":
    "귀하의 게시물이 제대로 제출되었습니다",
  "and is published under": "아래에 게시됩니다.",
  "Please copy this for your reference": "참조용으로 복사하십시오.",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "귀하의 질문이 제대로 제출되었습니다",
};
export const Latin = {
  "User Instructions": "User Instructiones",
  Contact: "Contactus",
  "News or HereSays Herald": "News or Heresays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Regionem vel Provinciam vel Comitatus vel rei publicae",
  and: "et",
  Date: "Date",
  Content: "Content",
  "TopicName is required": "TopicName non requiritur",
  "Publisher Name is required": "Publisher nomen est requiri",
  "Enter Your Feedback": "Intra tuum videre",
  HereSays: "Heresays",
  Region: "Regio",
  Province: "Provincia",
  Heard: "Audivit",
  Shared: "Shared",
  Successfully: "Feliciter",
  Explanation: "Explicatio",
  Language: "Lingua",
  Jurisdiction: "iurisdictio",
  Attention: "Operam",
  "LANGUAGE SELECTION": "LINGUA ELECTIO",
  ACCESS: "ACCESSU",
  "Terms of use": "Terms of use",
  REFUSE: "REPULSA",
  ACCEPT: "ACCIPIO",
  READ: "LEGERE",
  PUBLISH: "EDITIO",
  CHAT: "SERMO",
  "comming soon": "veniens mox",
  Other: "Other",
  Remark: "Animadverte",
  Suggestion: "Suggestion",
  Complaint: "Querela",
  Manual: "Manual",
  SEARCH: "QUAERERE",
  SHARE: "PARS",
  DENOUNCE: "DENUNTIO",
  "This post has not been reviewed": "Hoc post non recensuit",
  SUBMIT: "SUBMIT",
  Topic: "Topic",
  "Published BY": "Published BY",
  English: "Anglicus",
  Country: "Patriam",
  City: "Urbs",
  Place: "Place",
  "Subject 1": "Subiecti 1",
  "Subject 2": "Subject 2",
  "Subject 3": "Subiecti 3",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Intra Email Address",
  CHOOSE: "VOLO",
  By: "By",
  "End": "Finis",
  "Enter Your Story Content": "Intra fabulam tuam Content",
  "UPLOAD IMAGES": "UPLOAD IMAGINUM",
  "Click or Drop here": "Click or Iacta hic",
  "Remove all images": "Omnes imagines amove",
  "Your post has been properly submitted": "Tuum post est recte summitto",
  "and is published under": "et in editis",
  "Please copy this for your reference": "Placere referat ad effingo hoc tuum",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "Your question has been properly SUBMITTED",
};
export const Norwegian = {
  "User Instructions": "Brukerinstruksjoner",
  Contact: "Kontakt",
  "News or HereSays Herald": "Nyheter eller HereSays Herald",
  Disclaimer: "Ansvarsfraskrivelse",
  "Region or Province or County or State":
    "Region eller provins eller fylke eller stat",
  and: "og",
  Date: "Dato",
  Content: "Innhold",
  "TopicName is required": "Emnenavn er påkrevd",
  "Publisher Name is required": "Utgivernavn er obligatorisk",
  "Enter Your Feedback": "Skriv inn din tilbakemelding",
  HereSays: "Her sier",
  Region: "Region",
  Province: "Provins",
  Heard: "Hørt",
  Shared: "Delt",
  Successfully: "Vellykket",
  Explanation: "Forklaring",
  Language: "Språk",
  Jurisdiction: "Jurisdiksjon",
  Attention: "Merk følgende",
  "LANGUAGE SELECTION": "SPRÅKVALG",
  ACCESS: "ADGANG",
  "Terms of use": "Vilkår for bruk",
  REFUSE: "NEKTE",
  ACCEPT: "AKSEPTERER",
  READ: "LESE",
  PUBLISH: "PUBLISERE",
  CHAT: "CHAT",
  "comming soon": "kommer snart",
  Other: "Annen",
  Remark: "Bemerke",
  Suggestion: "Forslag",
  Complaint: "Klage",
  Manual: "Håndbok",
  SEARCH: "SØK",
  SHARE: "DELE",
  DENOUNCE: "FORKLARING",
  "This post has not been reviewed": "Dette innlegget har ikke blitt anmeldt",
  SUBMIT: "SENDE INN",
  Topic: "Emne",
  "Published BY": "Publisert av",
  English: "Engelsk",
  Country: "Land",
  City: "By",
  Place: "Plass",
  "Subject 1": "Emne 1",
  "Subject 2": "Emne 2",
  "Subject 3": "Emne 3",
  "Reference Id": "Referanse-ID",
  "Enter Email Address": "Skriv inn e-post adresse",
  CHOOSE: "VELGE",
  By: "Av",
  "End": "Slutten",
  "Enter Your Story Content": "Skriv inn historieinnholdet ditt",
  "UPLOAD IMAGES": "LAST OPP BILDER",
  "Click or Drop here": "Klikk eller slipp her",
  "Remove all images": "Fjern alle bilder",
  "Your post has been properly submitted":
    "Innlegget ditt er sendt inn på riktig måte",
  "and is published under": "og er publisert under",
  "Please copy this for your reference": "Vennligst kopier dette for referanse",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "SPØRSMÅLET DITT ER RIKTIG SENDT inn",
};
export const Polish = {
  "User Instructions": "Instrukcje użytkownika",
  Contact: "Kontakt",
  "News or HereSays Herald": "Wiadomości lub HereSays Herald",
  Disclaimer: "Zastrzeżenie",
  "Region or Province or County or State":
    "Region lub prowincja lub hrabstwo lub stan",
  and: "I",
  Date: "Data",
  Content: "Treść",
  "TopicName is required": "Nazwa tematu jest wymagana",
  "Publisher Name is required": "Nazwa wydawcy jest wymagana",
  "Enter Your Feedback": "Wpisz swoją opinię",
  HereSays: "Tutaj mówi",
  Region: "Region",
  Province: "Województwo",
  Heard: "Usłyszał",
  Shared: "Wspólny",
  Successfully: "Z powodzeniem",
  Explanation: "Wyjaśnienie",
  Language: "Język",
  Jurisdiction: "Jurysdykcja",
  Attention: "Uwaga",
  "LANGUAGE SELECTION": "WYBÓR JĘZYKA",
  ACCESS: "DOSTĘP",
  "Terms of use": "Warunki korzystania",
  REFUSE: "ODMAWIAĆ",
  ACCEPT: "ZAAKCEPTOWAĆ",
  READ: "CZYTAĆ",
  PUBLISH: "PUBLIKOWAĆ",
  CHAT: "CZAT",
  "comming soon": "wkrótce",
  Other: "Inny",
  Remark: "Uwaga",
  Suggestion: "Sugestia",
  Complaint: "Skarga",
  Manual: "podręcznik",
  SEARCH: "SZUKAJ",
  SHARE: "UDZIAŁ",
  DENOUNCE: "POTĘPIAĆ",
  "This post has not been reviewed": "Ten post nie został sprawdzony",
  SUBMIT: "SKŁADAĆ",
  Topic: "Temat",
  "Published BY": "Opublikowany przez",
  English: "język angielski",
  Country: "Kraj",
  City: "Miasto",
  Place: "Miejsce",
  "Subject 1": "Przedmiot 1",
  "Subject 2": "Przedmiot 2",
  "Subject 3": "Przedmiot 3",
  "Reference Id": "Numer referencyjny",
  "Enter Email Address": "Wprowadź adres e-mail",
  CHOOSE: "WYBIERAĆ",
  By: "Przez",
  "End": "Koniec",
  "Enter Your Story Content": "Wprowadź treść swojej historii",
  "UPLOAD IMAGES": "PRZESYŁAĆ ZDJĘCIA",
  "Click or Drop here": "Kliknij lub upuść tutaj",
  "Remove all images": "Usuń wszystkie obrazy",
  "Your post has been properly submitted":
    "Twój post został prawidłowo przesłany",
  "and is published under": "i jest publikowany pod",
  "Please copy this for your reference":
    "Proszę skopiować to w celach informacyjnych",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "TWOJE ZAPYTANIE ZOSTAŁO PRAWIDŁOWO ZŁOŻONE",
};
export const Romanian = {
  "User Instructions": "Instrucțiuni pentru utilizator",
  Contact: "a lua legatura",
  "News or HereSays Herald": "Știri sau aici spune Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Regiune sau provincie sau județ sau stat",
  and: "și",
  Date: "Data",
  Content: "Conţinut",
  "TopicName is required": "TopicName este obligatoriu",
  "Publisher Name is required": "Numele editorului este obligatoriu",
  "Enter Your Feedback": "Introduceți feedback-ul dvs",
  HereSays: "Aici Spune",
  Region: "Regiune",
  Province: "Provincie",
  Heard: "Auzit",
  Shared: "Impartit",
  Successfully: "Cu succes",
  Explanation: "Explicaţie",
  Language: "Limba",
  Jurisdiction: "Jurisdicția",
  Attention: "Atenţie",
  "LANGUAGE SELECTION": "ALEGEREA LIMBII",
  ACCESS: "ACCES",
  "Terms of use": "Termeni de utilizare",
  REFUSE: "REFUZA",
  ACCEPT: "ACCEPT",
  READ: "CITIT",
  PUBLISH: "PUBLICA",
  CHAT: "CONVERSAȚIE",
  "comming soon": "în curând",
  Other: "Alte",
  Remark: "Observație",
  Suggestion: "Sugestie",
  Complaint: "Plângere",
  Manual: "Manual",
  SEARCH: "CĂUTARE",
  SHARE: "ACȚIUNE",
  DENOUNCE: "DENUNŢA",
  "This post has not been reviewed": "Această postare nu a fost revizuită",
  SUBMIT: "TRIMITE",
  Topic: "Subiect",
  "Published BY": "Publicat de",
  English: "Engleză",
  Country: "Țară",
  City: "Oraș",
  Place: "Loc",
  "Subject 1": "Subiectul 1",
  "Subject 2": "Subiectul 2",
  "Subject 3": "Subiectul 3",
  "Reference Id": "ID de referinta",
  "Enter Email Address": "Introduceți adresa de e-mail",
  CHOOSE: "ALEGE",
  By: "De",
  "End": "Sfârșitul",
  "Enter Your Story Content": "Introduceți conținutul poveștii dvs",
  "UPLOAD IMAGES": "INCARCA IMAGINI",
  "Click or Drop here": "Faceți clic sau plasați aici",
  "Remove all images": "Eliminați toate imaginile",
  "Your post has been properly submitted":
    "Postarea dvs. a fost trimisă corect",
  "and is published under": "și este publicat sub",
  "Please copy this for your reference":
    "Vă rugăm să copiați acest lucru pentru referință",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ÎNTREBAREA DVS. A FOST TRIMITATĂ CORRECT",
};
export const Serbian = {
  "User Instructions": "Упутства за кориснике",
  Contact: "Контакт",
  "News or HereSays Herald": "Вести или ХереСаис Хералд",
  Disclaimer: "Одрицање од одговорности",
  "Region or Province or County or State":
    "Регион или покрајина или округ или држава",
  and: "и",
  Date: "Датум",
  Content: "Садржај",
  "TopicName is required": "Назив теме је обавезан",
  "Publisher Name is required": "Име издавача је обавезно",
  "Enter Your Feedback": "Унесите своје повратне информације",
  HereSays: "ХереСаис",
  Region: "Регион",
  Province: "Провинција",
  Heard: "Слушао",
  Shared: "Схаред",
  Successfully: "Успешно",
  Explanation: "Објашњење",
  Language: "Језик",
  Jurisdiction: "Надлежност",
  Attention: "Пажња",
  "LANGUAGE SELECTION": "ИЗБОР ЈЕЗИКА",
  ACCESS: "ПРИСТУП",
  "Terms of use": "Услови коришћења",
  REFUSE: "ОДБИТИ",
  ACCEPT: "АЦЦЕПТ",
  READ: "ЧИТАТИ",
  PUBLISH: "ОБЈАВИТИ",
  CHAT: "ЋАСКАЊЕ",
  "comming soon": "долази ускоро",
  Other: "Остало",
  Remark: "Напомена",
  Suggestion: "Предлог",
  Complaint: "Жалба",
  Manual: "Упутство",
  SEARCH: "ПРЕТРАГА",
  SHARE: "ОБЈАВИ",
  DENOUNCE: "ДЕНОУНЦЕ",
  "This post has not been reviewed": "Овај пост није прегледан",
  SUBMIT: "ПРИХВАТИ",
  Topic: "Тема",
  "Published BY": "Објавио",
  English: "енглески језик",
  Country: "Држава",
  City: "Цити",
  Place: "Место",
  "Subject 1": "Предмет 1",
  "Subject 2": "Предмет 2",
  "Subject 3": "Предмет 3",
  "Reference Id": "Референтни Ид",
  "Enter Email Address": "Унесите емаил адресу",
  CHOOSE: "ИЗАБЕРИ",
  By: "Од стране",
  "End": "Крај",
  "Enter Your Story Content": "Унесите садржај своје приче",
  "UPLOAD IMAGES": "УПЛОАД ИМАГЕС",
  "Click or Drop here": "Кликните или отпустите овде",
  "Remove all images": "Уклоните све слике",
  "Your post has been properly submitted": "Ваш пост је правилно послат",
  "and is published under": "и објављује се под",
  "Please copy this for your reference": "Копирајте ово за вашу референцу",
  "ID:HERESAYS000": "ИД:ХЕРЕСАИС000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ВАШ УПИТ ЈЕ ИСПРАВНО ДОСТАВЉЕН",
};
export const Slovak = {
  "User Instructions": "Používateľské pokyny",
  Contact: "Kontakt",
  "News or HereSays Herald": "News alebo HereSays Herald",
  Disclaimer: "Vylúčenie zodpovednosti",
  "Region or Province or County or State":
    "Región alebo provincia alebo kraj alebo štát",
  and: "a",
  Date: "Dátum",
  Content: "Obsah",
  "TopicName is required": "Vyžaduje sa názov témy",
  "Publisher Name is required": "Vyžaduje sa meno vydavateľa",
  "Enter Your Feedback": "Zadajte svoj názor",
  HereSays: "HereSays",
  Region: "región",
  Province: "Provincie",
  Heard: "Počul",
  Shared: "Zdieľané",
  Successfully: "Úspešne",
  Explanation: "Vysvetlenie",
  Language: "Jazyk",
  Jurisdiction: "Jurisdikcia",
  Attention: "Pozornosť",
  "LANGUAGE SELECTION": "VÝBER JAZYKA",
  ACCESS: "PRÍSTUP",
  "Terms of use": "Podmienky používania",
  REFUSE: "ODMIETNUŤ",
  ACCEPT: "SÚHLASIŤ",
  READ: "ČÍTAŤ",
  PUBLISH: "ZVEREJNIŤ",
  CHAT: "CHAT",
  "comming soon": "čoskoro",
  Other: "Iné",
  Remark: "Poznámka",
  Suggestion: "Návrh",
  Complaint: "Sťažnosť",
  Manual: "Manuálny",
  SEARCH: "VYHĽADÁVANIE",
  SHARE: "ZDIEĽAM",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Tento príspevok nebol skontrolovaný",
  SUBMIT: "PREDLOŽIŤ",
  Topic: "Téma",
  "Published BY": "Publikované",
  English: "Angličtina",
  Country: "Krajina",
  City: "Mesto",
  Place: "Miesto",
  "Subject 1": "Predmet 1",
  "Subject 2": "Predmet 2",
  "Subject 3": "Predmet 3",
  "Reference Id": "Referenčné ID",
  "Enter Email Address": "Vložte emailovú adresu",
  CHOOSE: "VYBERTE SI",
  By: "Autor:",
  "End": "Koniec",
  "Enter Your Story Content": "Zadajte obsah svojho príbehu",
  "UPLOAD IMAGES": "NAHRAŤ OBRÁZKY",
  "Click or Drop here": "Kliknite alebo presuňte sem",
  "Remove all images": "Odstráňte všetky obrázky",
  "Your post has been properly submitted": "Váš príspevok bol správne odoslaný",
  "and is published under": "a je zverejnený pod",
  "Please copy this for your reference": "Skopírujte si to pre vašu referenciu",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "VÁŠ DOTAZ BOL SPRÁVNE ODOSLANÝ",
};
export const Swedish = {
  "User Instructions": "Användarinstruktioner",
  Contact: "Kontakt",
  "News or HereSays Herald": "Nyheter eller HereSays Herald",
  Disclaimer: "varning",
  "Region or Province or County or State":
    "Region eller provins eller län eller stat",
  and: "och",
  Date: "Datum",
  Content: "Innehåll",
  "TopicName is required": "Ämnesnamn krävs",
  "Publisher Name is required": "Utgivarens namn krävs",
  "Enter Your Feedback": "Ange din feedback",
  HereSays: "Här Säger",
  Region: "Område",
  Province: "Provins",
  Heard: "Hörde",
  Shared: "Delad",
  Successfully: "Framgångsrikt",
  Explanation: "Förklaring",
  Language: "Språk",
  Jurisdiction: "Jurisdiktion",
  Attention: "Uppmärksamhet",
  "LANGUAGE SELECTION": "SPRÅKVAL",
  ACCESS: "TILLGÅNG",
  "Terms of use": "Villkor",
  REFUSE: "VÄGRA",
  ACCEPT: "ACCEPTERA",
  READ: "LÄSA",
  PUBLISH: "PUBLICERA",
  CHAT: "CHATT",
  "comming soon": "kommer snart",
  Other: "Övrig",
  Remark: "Anmärkning",
  Suggestion: "Förslag",
  Complaint: "Klagomål",
  Manual: "Manuell",
  SEARCH: "SÖK",
  SHARE: "DELA MED SIG",
  DENOUNCE: "FÖRDÖMA",
  "This post has not been reviewed": "Det här inlägget har inte granskats",
  SUBMIT: "SKICKA IN",
  Topic: "Ämne",
  "Published BY": "Publicerad av",
  English: "engelsk",
  Country: "Land",
  City: "Stad",
  Place: "Plats",
  "Subject 1": "Ämne 1",
  "Subject 2": "Ämne 2",
  "Subject 3": "Ämne 3",
  "Reference Id": "Referens-ID",
  "Enter Email Address": "Skriv in epostadress",
  CHOOSE: "VÄLJA",
  By: "Förbi",
  "End": "Slutet",
  "Enter Your Story Content": "Ange ditt berättelseinnehåll",
  "UPLOAD IMAGES": "LADDA UPP BILDER",
  "Click or Drop here": "Klicka eller släpp här",
  "Remove all images": "Ta bort alla bilder",
  "Your post has been properly submitted": "Ditt inlägg har skickats korrekt",
  "and is published under": "och publiceras under",
  "Please copy this for your reference":
    "Vänligen kopiera detta för din referens",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "DIN FÖRFRÅGAN HAR SKICKATS KORREKT",
};
export const Turkish = {
  "User Instructions": "Kullanıcı Talimatları",
  Contact: "Temas etmek",
  "News or HereSays Herald": "Haberler veya HereSays Herald",
  Disclaimer: "Feragatname",
  "Region or Province or County or State":
    "Bölge veya İl veya İlçe veya Eyalet",
  and: "Ve",
  Date: "Tarih",
  Content: "İçerik",
  "TopicName is required": "KonuAdı gerekli",
  "Publisher Name is required": "Yayıncı Adı gerekli",
  "Enter Your Feedback": "Geri Bildiriminizi Girin",
  HereSays: "İşteDiyor ki",
  Region: "Bölge",
  Province: "Vilayet",
  Heard: "Duyulmuş",
  Shared: "Paylaşılan",
  Successfully: "başarıyla",
  Explanation: "Açıklama",
  Language: "Dil",
  Jurisdiction: "yargı",
  Attention: "Dikkat",
  "LANGUAGE SELECTION": "DİL SEÇİMİ",
  ACCESS: "ERİŞİM",
  "Terms of use": "Kullanım Şartları",
  REFUSE: "REDDETMEK",
  ACCEPT: "KABUL ETMEK",
  READ: "OKUMAK",
  PUBLISH: "YAYINLA",
  CHAT: "SOHBET",
  "comming soon": "çok yakında",
  Other: "Diğer",
  Remark: "açıklama",
  Suggestion: "Telkin",
  Complaint: "Şikayet",
  Manual: "Manuel",
  SEARCH: "ARAMAK",
  SHARE: "PAYLAŞMAK",
  DENOUNCE: "İTHALAT",
  "This post has not been reviewed": "Bu gönderi incelenmedi",
  SUBMIT: "GÖNDERMEK",
  Topic: "Başlık",
  "Published BY": "Tarafından yayınlandı",
  English: "İngilizce",
  Country: "Ülke",
  City: "Şehir",
  Place: "Yer",
  "Subject 1": "konu 1",
  "Subject 2": "konu 2",
  "Subject 3": "konu 3",
  "Reference Id": "Referans Kimliği",
  "Enter Email Address": "Email adresini gir",
  CHOOSE: "SEÇMEK",
  By: "İle",
  "End": "Son",
  "Enter Your Story Content": "Hikaye İçeriğinizi Girin",
  "UPLOAD IMAGES": "GÖRSEL YÜKLE",
  "Click or Drop here": "Buraya tıklayın veya bırakın",
  "Remove all images": "Tüm resimleri kaldır",
  "Your post has been properly submitted":
    "Gönderiniz düzgün bir şekilde gönderildi",
  "and is published under": "ve altında yayınlanmaktadır.",
  "Please copy this for your reference":
    "Lütfen referansınız için bunu kopyalayın",
  "ID:HERESAYS000": "Kimlik:HESAYLAR000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "SORUNUZ UYGUN ŞEKİLDE GÖNDERİLDİ",
};
export const Zulu = {
  "User Instructions": "Imiyalo yomsebenzisi",
  Contact: "Oxhumana naye",
  "News or HereSays Herald": "Izindaba noma HereSays Herald",
  Disclaimer: "Umshwana wokuzihlangula",
  "Region or Province or County or State":
    "Isifunda noma Isifunda noma Isifunda noma Isifunda",
  and: "futhi",
  Date: "Usuku",
  Content: "Okuqukethwe",
  "TopicName is required": "I-TopicName iyadingeka",
  "Publisher Name is required": "Igama lomshicileli liyadingeka",
  "Enter Your Feedback": "Faka Impendulo Yakho",
  HereSays: "LaphaUthi",
  Region: "Isifunda",
  Province: "Isifundazwe",
  Heard: "Kuzwiwe",
  Shared: "Kwabiwe",
  Successfully: "Kuphumelele",
  Explanation: "Incazelo",
  Language: "Ulimi",
  Jurisdiction: "Igunya",
  Attention: "Ukunaka",
  "LANGUAGE SELECTION": "UKUKHETHA ULIMI",
  ACCESS: "UKUFINYELELA",
  "Terms of use": "Imigomo Yokusebenzisa",
  REFUSE: "NQABA",
  ACCEPT: "YAMUKELA",
  READ: "FUNDA",
  PUBLISH: "Shicilela",
  CHAT: "INGXOXO",
  "comming soon": "iyeza maduze",
  Other: "Okunye",
  Remark: "Phawula",
  Suggestion: "Ukusikisela",
  Complaint: "Isikhalazo",
  Manual: "Imanuwali",
  SEARCH: "SESHA",
  SHARE: "YABELANA",
  DENOUNCE: "KHUZA",
  "This post has not been reviewed": "Lokhu okuthunyelwe akukabuyekezwa",
  SUBMIT: "THUMELA",
  Topic: "Isihloko",
  "Published BY": "Ishicilelwe ngu",
  English: "IsiNgisi",
  Country: "Izwe",
  City: "Idolobha",
  Place: "Indawo",
  "Subject 1": "Isihloko 1",
  "Subject 2": "Isihloko 2",
  "Subject 3": "Isihloko 3",
  "Reference Id": "I-Id yesithenjwa",
  "Enter Email Address": "Faka Ikheli Le-imeyili",
  CHOOSE: "KHETHA",
  By: "Ngu",
  "End": "Isiphetho",
  "Enter Your Story Content": "Faka Okuqukethwe Kwendaba Yakho",
  "UPLOAD IMAGES": "LAYISHA IZITHOMBE",
  "Click or Drop here": "Chofoza noma Beka lapha",
  "Remove all images": "Susa zonke izithombe",
  "Your post has been properly submitted":
    "Okuthunyelwe kwakho kuthunyelwe ngendlela efanele",
  "and is published under": "futhi ishicilelwe ngaphansi",
  "Please copy this for your reference":
    "Sicela ukopishe lokhu ukuze uthole ireferensi yakho",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "UMBUZO WAKHO UTHUNYELWE KAHLE",
};
export const Albanian = {
  "User Instructions": "Udhëzimet e përdoruesit",
  Contact: "Kontaktoni",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Mohim përgjegjësie",
  "Region or Province or County or State":
    "Rajoni ose krahinë ose qark ose shtet",
  and: "dhe",
  Date: "Data",
  Content: "përmbajtja",
  "TopicName is required": "Kërkohet Emri i Temës",
  "Publisher Name is required": "Kërkohet emri i botuesit",
  "Enter Your Feedback": "Shkruani komentet tuaja",
  HereSays: "Këtu Thotë",
  Region: "Rajon",
  Province: "Provinca",
  Heard: "Dëgjuar",
  Shared: "Të përbashkëta",
  Successfully: "Me sukses",
  "User Instructions": "Udhëzimet e përdoruesit",
  Contact: "Kontaktoni",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Mohim përgjegjësie",
  "Region or Province or County or State":
    "Rajoni ose krahinë ose qark ose shtet",
  and: "dhe",
  Date: "Data",
  Content: "përmbajtja",
  "TopicName is required": "Kërkohet Emri i Temës",
  "Publisher Name is required": "Kërkohet emri i botuesit",
  "Enter Your Feedback": "Shkruani komentet tuaja",
  HereSays: "Këtu Thotë",
  Region: "Rajon",
  Province: "Provinca",
  Heard: "Dëgjuar",
  Shared: "Të përbashkëta",
  Successfully: "Me sukses",
  Explanation: "Shpjegim",
  Language: "Gjuhe",
  Jurisdiction: "Juridiksioni",
  Attention: "Kujdes",
  "LANGUAGE SELECTION": "PËRZGJEDHJA E GJUHËS",
  ACCESS: "AKSES",
  "Terms of use": "Kushtet e përdorimit",
  REFUSE: "REFUZONI",
  ACCEPT: "PRANOJ",
  READ: "LEXO",
  PUBLISH: "PUBLIKONI",
  CHAT: "BISEDA",
  "comming soon": "së shpejti",
  Other: "Të tjera",
  Remark: "Vërejtje",
  Suggestion: "Sugjerim",
  Complaint: "Ankesa",
  Manual: "Manual",
  SEARCH: "KËRKONI",
  SHARE: "SHPERNDAJE",
  DENOUNCE: "DENONCIM",
  "This post has not been reviewed": "Ky postim nuk është shqyrtuar",
  SUBMIT: "DORËZOJ",
  Topic: "Tema",
  "Published BY": "Publikuar nga",
  English: "anglisht",
  Country: "Vendi",
  City: "Qyteti",
  Place: "Vendi",
  "Subject 1": "Lënda 1",
  "Subject 2": "Lënda 2",
  "Subject 3": "Lënda 3",
  "Reference Id": "ID-ja e referencës",
  "Enter Email Address": "Fut adresën e emailit",
  CHOOSE: "ZGJIDHNI",
  By: "Nga",
  "End": "Fund",
  "Enter Your Story Content": "Futni përmbajtjen tuaj të tregimit",
  "UPLOAD IMAGES": "NGARKONI IMAZHET",
  "Click or Drop here": "Klikoni ose hidhni këtu",
  "Remove all images": "Hiq të gjitha imazhet",
  "Your post has been properly submitted":
    "Postimi juaj është dorëzuar siç duhet",
  "and is published under": "dhe botohet nën",
  "Please copy this for your reference": "Ju lutemi kopjoni këtë për referencë",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "KËRKESA JUAJ ESHTE DORËZUAR DUKE DORËZUAR",
};
export const Amharic = {
  "User Instructions": "የተጠቃሚ መመሪያዎች",
  Contact: "ተገናኝ",
  "News or HereSays Herald": "ዜና ወይም ሄሬስ ሄራልድ",
  Disclaimer: "ማስተባበያ",
  "Region or Province or County or State": "ክልል ወይም ግዛት ወይም አውራጃ ወይም ግዛት",
  and: "እና",
  Date: "ቀን",
  Content: "ይዘት",
  "TopicName is required": "ርዕስ ስም ያስፈልጋል",
  "Publisher Name is required": "የአሳታሚ ስም ያስፈልጋል",
  "Enter Your Feedback": "ግብረ መልስዎን ያስገቡ",
  HereSays: "እዚህ ይላል።",
  Region: "ክልል",
  Province: "ክፍለ ሀገር",
  Heard: "ተሰማ",
  Shared: "ተጋርቷል።",
  Successfully: "በተሳካ ሁኔታ",
  Explanation: "ማብራሪያ",
  Language: "ቋንቋ",
  Jurisdiction: "ስልጣን",
  Attention: "ትኩረት",
  "LANGUAGE SELECTION": "የቋንቋ ምርጫ",
  ACCESS: "መዳረሻ",
  "Terms of use": "የአጠቃቀም መመሪያ",
  REFUSE: "እምቢ ማለት",
  ACCEPT: "ተቀበል",
  READ: "አንብብ",
  PUBLISH: "አትም",
  CHAT: "ቻት",
  "comming soon": "በቅርቡ",
  Other: "ሌላ",
  Remark: "አስተያየት",
  Suggestion: "ጥቆማ",
  Complaint: "ቅሬታ",
  Manual: "መመሪያ",
  SEARCH: "ፈልግ",
  SHARE: "ሼር ያድርጉ",
  DENOUNCE: "ውግዘት",
  "This post has not been reviewed": "ይህ ልጥፍ አልተገመገመም።",
  SUBMIT: "አስገባ",
  Topic: "ርዕስ",
  "Published BY": "የታተመ በ",
  English: "እንግሊዝኛ",
  Country: "ሀገር",
  City: "ከተማ",
  Place: "ቦታ",
  "Subject 1": "ርዕሰ ጉዳይ 1",
  "Subject 2": "ርዕሰ ጉዳይ 2",
  "Subject 3": "ርዕሰ ጉዳይ 3",
  "Reference Id": "የማጣቀሻ መታወቂያ",
  "Enter Email Address": "ኢሜል አድራሻ አስገባ",
  CHOOSE: "ምረጥ",
  By: "በ",
  "End": "መጨረሻ",
  "Enter Your Story Content": "የእርስዎን ታሪክ ይዘት ያስገቡ",
  "UPLOAD IMAGES": "ምስሎችን ስቀል",
  "Click or Drop here": "ጠቅ ያድርጉ ወይም እዚህ ጣል ያድርጉ",
  "Remove all images": "ሁሉንም ምስሎች አስወግድ",
  "Your post has been properly submitted": "ልጥፍዎ በትክክል ገብቷል።",
  "and is published under": "እና ስር ታትሟል",
  "Please copy this for your reference": "እባክዎ ይህንን ለማጣቀሻዎ ይቅዱ",
  "ID:HERESAYS000": "መታወቂያ፡HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ጥያቄዎ በትክክል ገብቷል።",
};
export const Armenian = {
  "User Instructions": "Օգտագործողի ցուցումներ",
  Contact: "Կապ",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Հրաժարում պատասխանատվությունից",
  "Region or Province or County or State":
    "Տարածաշրջան կամ նահանգ կամ շրջան կամ նահանգ",
  and: "և",
  Date: "Ամսաթիվ",
  Content: "Բովանդակություն",
  "TopicName is required": "Թեմայի անունը պահանջվում է",
  "Publisher Name is required": "Հրատարակչի անունը պարտադիր է",
  "Enter Your Feedback": "Մուտքագրեք Ձեր կարծիքը",
  HereSays: "Ահա Ասում է",
  Region: "Տարածաշրջան",
  Province: "Նահանգ",
  Heard: "Լսվեց",
  Shared: "Համօգտագործված է",
  Successfully: "Հաջողությամբ",
  Explanation: "Բացատրություն",
  Language: "Լեզու",
  Jurisdiction: "Իրավասություն",
  Attention: "Ուշադրություն",
  "LANGUAGE SELECTION": "ԼԵԶՎԻ ԸՆՏՐՈՒԹՅՈՒՆ",
  ACCESS: "ՄՈՒՏՔ",
  "Terms of use": "Օգտվելու կանոններ",
  REFUSE: "ՀՐԱԺԱՐՎԵԼ",
  ACCEPT: "ԸՆԴՈՒՆԵԼ",
  READ: "ԿԱՐԴԱԼ",
  PUBLISH: "ՀՐԱՊԱՐԱԿԵԼ",
  CHAT: "ԶՐՈՒՅՑ",
  "comming soon": "շուտով",
  Other: "Այլ",
  Remark: "Դիտողություն",
  Suggestion: "Առաջարկություն",
  Complaint: "Բողոք",
  Manual: "Ձեռնարկ",
  SEARCH: "ՈՐՈՆՈՒՄ",
  SHARE: "ԿԻՍՎԵԼ",
  DENOUNCE: "ՀԱՅՏԱՐԱՐԵԼ",
  "This post has not been reviewed": "Այս գրառումը չի վերանայվել",
  SUBMIT: "ՆԵՐԿԱՅԱՑՆԵԼ",
  Topic: "Թեմա",
  "Published BY": "Հրատարակված ԿՈՂՄԻ",
  English: "Անգլերեն",
  Country: "Երկիր",
  City: "Քաղաք",
  Place: "Տեղ",
  "Subject 1": "Թեմա 1",
  "Subject 2": "Թեմա 2",
  "Subject 3": "Թեմա 3",
  "Reference Id": "Հղման ID",
  "Enter Email Address": "Մուտքագրեք էլփոստի հասցեն",
  CHOOSE: "ԸՆՏՐԵԼ",
  By: "Ըստ",
  "End": "Վերջ",
  "Enter Your Story Content": "Մուտքագրեք ձեր պատմության բովանդակությունը",
  "UPLOAD IMAGES": "ԲԵՌՆԵԼ ՊԱՏԿԵՐՆԵՐ",
  "Click or Drop here": "Սեղմեք կամ թողեք այստեղ",
  "Remove all images": "Հեռացրեք բոլոր պատկերները",
  "Your post has been properly submitted":
    "Ձեր գրառումը պատշաճ կերպով ներկայացվել է",
  "and is published under": "և հրապարակվում է տակ",
  "Please copy this for your reference":
    "Խնդրում ենք պատճենել սա ձեր տեղեկանքի համար",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ՁԵՐ ՀԱՐՑՈՒՄԸ ՊԱՇՏՊԱՆԻ Է ՆԵՐԿԱՅԱՑՎԵԼ",
};
export const Assamese = {
  "User Instructions": "ব্যৱহাৰকাৰীৰ নিৰ্দেশনা",
  Contact: "যোগাযোগ কৰক",
  "News or HereSays Herald": "বাতৰি বা ইয়াতছেজ হেৰাল্ড",
  Disclaimer: "অস্বীকাৰ",
  "Region or Province or County or State":
    "অঞ্চল বা প্ৰদেশ বা কাউন্টি বা ৰাজ্য",
  and: "আৰু",
  Date: "তাৰিখ",
  Content: "বিষয়",
  "TopicName is required": "TopicName ৰ প্ৰয়োজন",
  "Publisher Name is required": "প্ৰকাশকৰ নাম প্ৰয়োজনীয়",
  "Enter Your Feedback": "আপোনাৰ মতামত প্ৰৱেশ কৰক",
  HereSays: "ইয়াত কয়",
  Region: "অঞ্চল",
  Province: "প্ৰদেশ",
  Heard: "শুনা",
  Shared: "শ্বেয়াৰ কৰা হৈছে",
  Successfully: "সফলতাৰে",
  Explanation: "ব্যাখ্যা",
  Language: "ভাষা",
  Jurisdiction: "বিচাৰ ক্ষমতা",
  Attention: "ধ্যান দিয়া",
  "LANGUAGE SELECTION": "ভাষা নিৰ্বাচন",
  ACCESS: "প্ৰৱেশাধিকাৰ",
  "Terms of use": "ব্যৱহাৰৰ চৰ্তসমূহ",
  REFUSE: "অস্বীকাৰ কৰা",
  ACCEPT: "গ্ৰহণ কৰা",
  READ: "পঢ়া",
  PUBLISH: "PUBLISH",
  CHAT: "চাট",
  "comming soon": "অতি সোনকালে আহি আছে",
  Other: "অন্যান্য",
  Remark: "মন্তব্য",
  Suggestion: "পৰামৰ্শ",
  Complaint: "গোচৰ",
  Manual: "হাতপুথি",
  SEARCH: "সন্ধান",
  SHARE: "ভাগ-বতৰা কৰা",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "এই পোষ্টটো পৰ্যালোচনা কৰা হোৱা নাই",
  SUBMIT: "দাখিল কৰক",
  Topic: "বিষয়",
  "Published BY": "প্ৰকাশিত BY",
  English: "ইংৰাজী",
  Country: "দেশ",
  City: "চহৰ",
  Place: "স্থান",
  "Subject 1": "বিষয় ১",
  "Subject 2": "বিষয় ২",
  "Subject 3": "বিষয় ৩",
  "Reference Id": "ৰেফাৰেন্স Id",
  "Enter Email Address": "ইমেইল ঠিকনা দিয়ক",
  CHOOSE: "বাছনি কৰক",
  By: "দ্বাৰা",
  "End": "শেষলৈ",
  "Enter Your Story Content": "আপোনাৰ গল্পৰ বিষয়বস্তু প্ৰৱেশ কৰক",
  "UPLOAD IMAGES": "IMAGES আপলোড কৰক",
  "Click or Drop here": "ইয়াত ক্লিক বা ড্ৰপ কৰক",
  "Remove all images": "সকলো ছবি আঁতৰাই পেলাওক",
  "Your post has been properly submitted":
    "আপোনাৰ পোষ্টটো সঠিকভাৱে জমা দিয়া হৈছে",
  "and is published under": "আৰু তলত প্ৰকাশ কৰা হয়",
  "Please copy this for your reference":
    "অনুগ্ৰহ কৰি আপোনাৰ উল্লেখৰ বাবে এইটো কপি কৰক",
  "ID:HERESAYS000": "আইডি:ইয়াত000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "আপোনাৰ প্ৰশ্ন সঠিকভাৱে জমা দিয়া হৈছে",
};
export const Aymara = {
  "User Instructions": "Usuario ukan yatichäwipa",
  Contact: "Kuntaktu",
  "News or HereSays Herald": "Yatiyäwinak jan ukax AkanSays Herald",
  Disclaimer: "Ukax mä juk’a pachanakanwa",
  "Region or Province or County or State":
    "Región jan ukaxa Provincia jan ukaxa Condado jan ukaxa Estado",
  and: "ukat",
  Date: "Uru",
  Content: "Utjiir",
  "TopicName is required": "TopicName ukax wakisiwa",
  "Publisher Name is required": "Yatiyirin Sutipax wakisiwa",
  "Enter Your Feedback": "Ukax mä juk’a pachanakanwa",
  HereSays: "AkaSasa",
  Region: "Chiqa",
  Province: "Provincia",
  Heard: "Ist'änwa",
  Shared: "Uñt’ayata",
  Successfully: "Ukax wali askiwa",
  Explanation: "Qhananchawi",
  Language: "Aru",
  Jurisdiction: "Jurisdicción ukaxa",
  Attention: "Ist'aña",
  "LANGUAGE SELECTION": "ARUNAKA AJLLIÑATAKI",
  ACCESS: "AKSISU",
  "Terms of use": "Uñakipañataki kamachinaka",
  REFUSE: "JANIW SAÑA",
  ACCEPT: "IYAWSAÑA",
  READ: "ULLAÑA",
  PUBLISH: "PUBLICIÓN ukax mä juk’a pachanakanwa",
  CHAT: "ARUSKIPA",
  "comming soon": "niyaw jutani",
  Other: "Yaqha",
  Remark: "Uñakipt’aña",
  Suggestion: "Amuyt'a",
  Complaint: "Kija",
  Manual: "Amparampi",
  SEARCH: "THAQHATA",
  SHARE: "UÑT'AYAÑA",
  DENOUNCE: "DENUNCIA UKA UTJAÑAPATAKI",
  "This post has not been reviewed": "Aka post ukax janiw uñakipatakiti",
  SUBMIT: "APAYAÑA",
  Topic: "Tima",
  "Published BY": "Uñt’ayata BY",
  English: "inklisa",
  Country: "Marka",
  City: "Jach'a marka",
  Place: "Chiqa",
  "Subject 1": "Yatichäwi 1.1",
  "Subject 2": "Yatichäwi 2.1",
  "Subject 3": "Yatichäwi 3.1",
  "Reference Id": "Uñakipt’añataki Id",
  "Enter Email Address": "Dirección de Correo electrónico ukar mantañamawa",
  CHOOSE: "AJLLIÑA",
  By: "Ukata",
  "End": "Tukuyañataki",
  "Enter Your Story Content": "Sarnaqäwiman Contenido ukar mantañamawa",
  "UPLOAD IMAGES": "IMAGENAKAX AKHAM UTJAÑAPAWA",
  "Click or Drop here": "Aka tuqir ch’iqt’am jan ukax Drop ukar ch’iqt’am",
  "Remove all images": "Taqi uñacht’awinaka apsuña",
  "Your post has been properly submitted": "Uka post ukax suma uñt’ayatawa",
  "and is published under": "ukatxa akham uñt’ayatarakiwa",
  "Please copy this for your reference": "Akax referencia ukar copiaña",
  "ID:HERESAYS000": "ID:AKA CHIQAÑCHAWI000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "JISKT’AWINAKANXA SUM UTJAÑAPAWA",
};
export const Azerbaijani = {
  "User Instructions": "İstifadəçi Təlimatları",
  Contact: "Əlaqə",
  "News or HereSays Herald": "News və ya HereSays Herald",
  Disclaimer: "İmtina",
  "Region or Province or County or State":
    "Region və ya Vilayət və ya İlçe və ya Dövlət",
  and: "və",
  Date: "Tarix",
  Content: "Məzmun",
  "TopicName is required": "Mövzu Adı tələb olunur",
  "Publisher Name is required": "Nəşriyyatçı Adı tələb olunur",
  "Enter Your Feedback": "Rəyinizi daxil edin",
  HereSays: "Burada deyir",
  Region: "Region",
  Province: "Vilayət",
  Heard: "Eşitdim",
  Shared: "Paylaşıldı",
  Successfully: "Uğurla",
  Explanation: "İzahat",
  Language: "Dil",
  Jurisdiction: "Yurisdiksiya",
  Attention: "Diqqət",
  "LANGUAGE SELECTION": "DİL SEÇİMİ",
  ACCESS: "GİRİŞ",
  "Terms of use": "İstifadə qaydaları",
  REFUSE: "İMTİNA EDİN",
  ACCEPT: "QƏBUL EDİN",
  READ: "OXUYUN",
  PUBLISH: "NƏŞR EDİN",
  CHAT: "CHAT",
  "comming soon": "tezliklə",
  Other: "Digər",
  Remark: "Qeyd",
  Suggestion: "Təklif",
  Complaint: "Şikayət",
  Manual: "Manual",
  SEARCH: "AXTAR",
  SHARE: "PAYLAŞIN",
  DENOUNCE: "QEYD EDİN",
  "This post has not been reviewed": "Bu yazı nəzərdən keçirilməyib",
  SUBMIT: "TƏQDİM",
  Topic: "Mövzu",
  "Published BY": "BY nəşr etdi",
  English: "İngilis dili",
  Country: "ölkə",
  City: "Şəhər",
  Place: "yer",
  "Subject 1": "Mövzu 1",
  "Subject 2": "Mövzu 2",
  "Subject 3": "Mövzu 3",
  "Reference Id": "İstinad ID",
  "Enter Email Address": "E-poçt ünvanını daxil edin",
  CHOOSE: "SEÇİN",
  By: "By",
  "End": "Son",
  "Enter Your Story Content": "Hekayə məzmununuzu daxil edin",
  "UPLOAD IMAGES": "ŞƏKİLLƏR YÜKLƏ",
  "Click or Drop here": "Bura klikləyin və ya buraxın",
  "Remove all images": "Bütün şəkilləri silin",
  "Your post has been properly submitted":
    "Postunuz düzgün şəkildə təqdim edilib",
  "and is published under": "və altında dərc olunur",
  "Please copy this for your reference":
    "Zəhmət olmasa, istinad üçün bunu kopyalayın",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "SORĞUNUZ DÜZGÜN TƏQDİM OLUNUB",
};
export const Bambara = {
  "User Instructions": "Baarakɛlaw ka cikanw",
  Contact: "Ka wele",
  "News or HereSays Herald": "Kibaru walima YanKo Herald",
  Disclaimer: "Kɔrɔfɔli",
  "Region or Province or County or State":
    "Mara walima mara walima mara walima Jamana",
  and: "ani",
  Date: "Don",
  Content: "Kɔnɔnafɛn",
  "TopicName is required": "TopicName ye wajibi ye",
  "Publisher Name is required": "Weleweledala Tɔgɔ de wajibiyalen don",
  "Enter Your Feedback": "Aw ye aw ka hakilinaw don",
  HereSays: "Nin yeA fɔ",
  Region: "Màra",
  Province: "Marali",
  Heard: "Mɛn na",
  Shared: "U bɛ tila ɲɔgɔn na",
  Successfully: "A ye ɲɛtaa sɔrɔ",
  Explanation: "Ɲɛfɔli",
  Language: "Kan",
  Jurisdiction: "Kiiritigɛlaw ka fanga",
  Attention: "Janto",
  "LANGUAGE SELECTION": "KAN SƆRƆLI",
  ACCESS: "KA SE A MA",
  "Terms of use": "Baarakɛcogo sariyaw",
  REFUSE: "KA BAN",
  ACCEPT: "KA SƆN",
  READ: "KA KALAN",
  PUBLISH: "PUBLISH (BƆRƆBƆLI).",
  CHAT: "KA MASALA",
  "comming soon": "bɛ na sɔɔni",
  Other: "Dɔ wɛrɛ",
  Remark: "Kɔlɔsili kɛ",
  Suggestion: "Poropozisiyɔn",
  Complaint: "Makasi",
  Manual: "Min bɛ fɛn bolo la",
  SEARCH: "ƝINI",
  SHARE: "NIYƆRƆ",
  DENOUNCE: "ƝƐNABƆLI",
  "This post has not been reviewed": "Nin post in ma lajɛ",
  SUBMIT: "KA KOLO",
  Topic: "Dakun",
  "Published BY": "A bɔra BY",
  English: "Angilɛtɛri",
  Country: "Jamana",
  City: "Duguba",
  Place: "Sigiyɔrɔ",
  "Subject 1": "Barokun 1nan",
  "Subject 2": "Barokun 2nan",
  "Subject 3": "Barokun 3nan",
  "Reference Id": "Ɲɛfɔli Id",
  "Enter Email Address": "Email Adrɛsi sɛbɛn",
  CHOOSE: "SUGANDI",
  By: "Ni a ye",
  "End": "A laban",
  "Enter Your Story Content": "I ka maana kɔnɔkow don",
  "UPLOAD IMAGES": "IMAGES AW KA UPLOAD",
  "Click or Drop here": "I ka digi walima Drop yan",
  "Remove all images": "Jaw bɛɛ bɔ yen",
  "Your post has been properly submitted": "Aw ka poste (poste) bilala ka ɲɛ",
  "and is published under": "ani a bɛ bɔ a jukɔrɔ",
  "Please copy this for your reference":
    "Aw ye nin kopi kɛ walasa aw ka se ka kunnafoni sɔrɔ",
  "ID:HERESAYS000": "ID:ƝƐNAJƐW000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "AW KA Ɲininkali BƐ SƆRƆ ka ɲɛ",
};
export const Belarusian = {
  "User Instructions": "Інструкцыя карыстальніка",
  Contact: "Кантакт",
  "News or HereSays Herald": "Навіны або HereSays Herald",
  Disclaimer: "Адмова ад адказнасці",
  "Region or Province or County or State": "Рэгіён, правінцыя, акруга або штат",
  and: "і",
  Date: "Дата",
  Content: "Змест",
  "TopicName is required": "Патрабуецца назва тэмы",
  "Publisher Name is required": "Патрабуецца імя выдаўца",
  "Enter Your Feedback": "Увядзіце свой водгук",
  HereSays: "HereSays",
  Region: "Рэгіён",
  Province: "правінцыя",
  Heard: "Чуў",
  Shared: "Падзяліўся",
  Successfully: "Паспяхова",
  Explanation: "Тлумачэнне",
  Language: "мова",
  Jurisdiction: "Падсуднасць",
  Attention: "Увага",
  "LANGUAGE SELECTION": "ВЫБАР МОВЫ",
  ACCESS: "ДОСТУП",
  "Terms of use": "Правілы выкарыстання",
  REFUSE: "АДМОВА",
  ACCEPT: "ПРЫНЯЦЬ",
  READ: "ЧЫТАЦЬ",
  PUBLISH: "ПУБЛІКАВАЦЬ",
  CHAT: "ЧАТ",
  "comming soon": "хутка",
  Other: "Іншае",
  Remark: "Заўвага",
  Suggestion: "Прапанова",
  Complaint: "Скарга",
  Manual: "Дапаможнік",
  SEARCH: "ПОШУК",
  SHARE: "ПАДЗЯЛІЦЕСЯ",
  DENOUNCE: "ДАНАНСАВАЦЬ",
  "This post has not been reviewed": "Гэта паведамленне не было разгледжана",
  SUBMIT: "АДПРАВІЦЬ",
  Topic: "Тэма",
  "Published BY": "Апублікавана BY",
  English: "англійская",
  Country: "Краіна",
  City: "Горад",
  Place: "Месца",
  "Subject 1": "Тэма 1",
  "Subject 2": "Тэма 2",
  "Subject 3": "Тэма 3",
  "Reference Id": "Ідэнтыфікатар спасылкі",
  "Enter Email Address": "Увядзіце адрас электроннай пошты",
  CHOOSE: "ВЫБІРАЦЬ",
  By: "Па",
  "End": "Канец",
  "Enter Your Story Content": "Увядзіце змест вашай гісторыі",
  "UPLOAD IMAGES": "ЗАГРУЗІЦЬ ВЫЯВЫ",
  "Click or Drop here": "Націсніце або апусціце сюды",
  "Remove all images": "Выдаліць усе выявы",
  "Your post has been properly submitted":
    "Ваша паведамленне было адпраўлена належным чынам",
  "and is published under": "і публікуецца пад",
  "Please copy this for your reference":
    "Калі ласка, скапіруйце гэта для даведкі",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ВАШ ЗАПЫТ БЫЎ АДСТАЎЛЕН ПРАВІЛЬНА",
};
export const Bhojpuri = {
  "User Instructions": "उपयोगकर्ता के निर्देश दिहल गइल बा",
  Contact: "संपर्क",
  "News or HereSays Herald": "न्यूज भा हियरसेज हेराल्ड",
  Disclaimer: "अस्वीकरण के बा",
  "Region or Province or County or State":
    "क्षेत्र भा प्रांत भा काउंटी भा राज्य के",
  and: "औरी",
  Date: "तारीख",
  Content: "सामग्री",
  "TopicName is required": "TopicName के जरूरत बा",
  "Publisher Name is required": "प्रकाशक के नाम जरूरी बा",
  "Enter Your Feedback": "आपन प्रतिक्रिया दर्ज करीं",
  HereSays: "इहाँकहले बा",
  Region: "इलाका",
  Province: "प्रान्त",
  Heard: "सुनाइल",
  Shared: "साझा कइल गइल बा",
  Successfully: "सफलता से मिलल बा",
  Explanation: "व्याख्या",
  Language: "भाखा",
  Jurisdiction: "अधिकार क्षेत्र के बा",
  Attention: "धेयान",
  "LANGUAGE SELECTION": "भाषा के चयन के बारे में बतावल गइल बा",
  ACCESS: "पहुँचल",
  "Terms of use": "उपयोग के शर्त बा",
  REFUSE: "मना क दिहल",
  ACCEPT: "मान लीं",
  READ: "पढ़ल",
  PUBLISH: "प्रकाशित कइल जाव",
  CHAT: "बातचित",
  "comming soon": "जल्दिए आवे वाला बा",
  Other: "दोसर",
  Remark: "टिप्पणी कइल जाव",
  Suggestion: "सुझाव",
  Complaint: "सिकायत",
  Manual: "हाथ से कईल",
  SEARCH: "तलाशी",
  SHARE: "बाँटीं",
  DENOUNCE: "निंदा कइल जाव",
  "This post has not been reviewed": "एह पोस्ट के समीक्षा नइखे भइल",
  SUBMIT: "जमा करीं",
  Topic: "विषय",
  "Published BY": "प्रकाशित भइल बा BY",
  English: "अंगरेजी",
  Country: "देश",
  City: "शहर",
  Place: "जगह",
  "Subject 1": "विषय 1 के बा",
  "Subject 2": "विषय 2 के बा",
  "Subject 3": "विषय 3 के बा",
  "Reference Id": "संदर्भ आईडी के बा",
  "Enter Email Address": "ईमेल पता दर्ज करीं",
  CHOOSE: "चुनीं",
  By: "द्वारा",
  "End": "अंत के बात बा",
  "Enter Your Story Content": "आपन कहानी के सामग्री दर्ज करीं",
  "UPLOAD IMAGES": "छवि अपलोड करीं",
  "Click or Drop here": "इहाँ क्लिक करीं भा ड्रॉप करीं",
  "Remove all images": "सभ छवि के हटा लीं",
  "Your post has been properly submitted": "राउर पोस्ट ठीक से जमा हो गइल बा",
  "and is published under": "आ के तहत प्रकाशित होला",
  "Please copy this for your reference":
    "कृपया एकरा के अपना संदर्भ खातिर कॉपी करीं",
  "ID:HERESAYS000": "आईडी:एहीसे 000 के बा",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "राउर क्वेरी ठीक से जमा हो गइल बा",
};
export const Bosnian = {
  "User Instructions": "Uputstva za korisnike",
  Contact: "Kontakt",
  "News or HereSays Herald": "Vijesti ili HereSays Herald",
  Disclaimer: "Odricanje od odgovornosti",
  "Region or Province or County or State":
    "Regija ili pokrajina ili županija ili država",
  and: "i",
  Date: "Datum",
  Content: "Sadržaj",
  "TopicName is required": "Naziv teme je obavezan",
  "Publisher Name is required": "Ime izdavača je obavezno",
  "Enter Your Feedback": "Unesite svoje povratne informacije",
  HereSays: "HereSays",
  Region: "Region",
  Province: "Provincija",
  Heard: "Čuo",
  Shared: "Shared",
  Successfully: "Uspješno",
  Explanation: "Objašnjenje",
  Language: "Jezik",
  Jurisdiction: "Nadležnost",
  Attention: "Pažnja",
  "LANGUAGE SELECTION": "IZBOR JEZIKA",
  ACCESS: "PRISTUP",
  "Terms of use": "Pravila korištenja",
  REFUSE: "REFUSE",
  ACCEPT: "ACCEPT",
  READ: "PROČITAJTE",
  PUBLISH: "OBJAVITI",
  CHAT: "CHAT",
  "comming soon": "dolazi uskoro",
  Other: "Ostalo",
  Remark: "Napomena",
  Suggestion: "Sugestija",
  Complaint: "Žalba",
  Manual: "Manual",
  SEARCH: "TRAŽI",
  SHARE: "SHARE",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Ovaj post nije pregledan",
  SUBMIT: "SUBMIT",
  Topic: "Tema",
  "Published BY": "Published BY",
  English: "engleski",
  Country: "Država",
  City: "Grad",
  Place: "Mjesto",
  "Subject 1": "Predmet 1",
  "Subject 2": "Predmet 2",
  "Subject 3": "Predmet 3",
  "Reference Id": "Referentni Id",
  "Enter Email Address": "Unesite adresu e-pošte",
  CHOOSE: "ODABIRATI",
  By: "By",
  "End": "Kraj",
  "Enter Your Story Content": "Unesite sadržaj svoje priče",
  "UPLOAD IMAGES": "UPLOAD IMAGES",
  "Click or Drop here": "Kliknite ili ispustite ovdje",
  "Remove all images": "Uklonite sve slike",
  "Your post has been properly submitted": "Vaša objava je pravilno poslana",
  "and is published under": "i objavljeno je pod",
  "Please copy this for your reference":
    "Molimo kopirajte ovo za vašu referencu",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "VAŠ UPIT JE ISPRAVNO POSAN",
};
export const Bulgarian = {
  "User Instructions": "Инструкции за потребителя",
  Contact: "Контакт",
  "News or HereSays Herald": "Новини или HereSays Herald",
  Disclaimer: "Опровержение",
  "Region or Province or County or State": "Регион, провинция, окръг или щат",
  and: "и",
  Date: "Дата",
  Content: "Съдържание",
  "TopicName is required": "Изисква се име на тема",
  "Publisher Name is required": "Изисква се име на издател",
  "Enter Your Feedback": "Въведете вашите отзиви",
  HereSays: "HereSays",
  Region: "Регион",
  Province: "Провинция",
  Heard: "Чух",
  Shared: "Споделено",
  Successfully: "Успешно",
  Explanation: "Обяснение",
  Language: "език",
  Jurisdiction: "Юрисдикция",
  Attention: "внимание",
  "LANGUAGE SELECTION": "ИЗБОР НА ЕЗИК",
  ACCESS: "ДОСТЪП",
  "Terms of use": "Условия за ползване",
  REFUSE: "ОТКАЗВАМ",
  ACCEPT: "ПРИЕМАМ",
  READ: "ПРОЧЕТИ",
  PUBLISH: "ПУБЛИКУВАНЕ",
  CHAT: "ЧАТ",
  "comming soon": "Очаквайте скоро",
  Other: "други",
  Remark: "Забележка",
  Suggestion: "Внушение",
  Complaint: "Жалба",
  Manual: "Ръководство",
  SEARCH: "ТЪРСЕНЕ",
  SHARE: "ДЯЛ",
  DENOUNCE: "ОБЯВЯВАНЕ",
  "This post has not been reviewed": "Тази публикация не е прегледана",
  SUBMIT: "ИЗПРАЩАНЕ",
  Topic: "Тема",
  "Published BY": "Публикувани от",
  English: "Английски",
  Country: "Държава",
  City: "град",
  Place: "място",
  "Subject 1": "Предмет 1",
  "Subject 2": "Тема 2",
  "Subject 3": "Тема 3",
  "Reference Id": "Референтен номер",
  "Enter Email Address": "Въведете имейл адрес",
  CHOOSE: "ИЗБИРАМ",
  By: "от",
  "End": "Край",
  "Enter Your Story Content": "Въведете съдържанието на вашата история",
  "UPLOAD IMAGES": "КАЧВАНЕ НА ИЗОБРАЖЕНИЯ",
  "Click or Drop here": "Щракнете или пуснете тук",
  "Remove all images": "Премахнете всички изображения",
  "Your post has been properly submitted":
    "Вашата публикация е изпратена правилно",
  "and is published under": "и е публикуван под",
  "Please copy this for your reference": "Моля, копирайте това за справка",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ВАШЕТО ЗАПИТВАНЕ Е ПРАВИЛНО ИЗПРАТЕНО",
};
export const Cebuano = {
  "User Instructions": "Istruzzioni per l'utilizatori",
  Contact: "Cuntattu",
  "News or HereSays Herald": "News o HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Regione o Pruvincia o County o Statu",
  and: "è",
  Date: "Data",
  Content: "Cuntinutu",
  "TopicName is required": "TopicName hè necessariu",
  "Publisher Name is required": "U nome di l'editore hè necessariu",
  "Enter Your Feedback": "Inserite u vostru Feedback",
  HereSays: "Quì dice",
  Region: "Regione",
  Province: "Pruvincia",
  Heard: "Intesu",
  Shared: "spartutu",
  Successfully: "Successu",
  Explanation: "Katin-awan",
  Language: "Pinulongan",
  Jurisdiction: "hurisdiksyon",
  Attention: "Atensyon",
  "LANGUAGE SELECTION": "PAGPILI SA PINULONGAN",
  ACCESS: "AKSES",
  "Terms of use": "Mga termino sa paggamit",
  REFUSE: "MAGBALIK",
  ACCEPT: "DAWATA",
  READ: "BASAHA",
  PUBLISH: "PUBLISH",
  CHAT: "CHAT",
  "comming soon": "hapit na",
  Other: "Ang uban",
  Remark: "Sulti",
  Suggestion: "Sugyot",
  Complaint: "Reklamo",
  Manual: "Manwal",
  SEARCH: "PANGITA",
  SHARE: "IBAHAGI",
  DENOUNCE: "PAGSULOD",
  "This post has not been reviewed": "Kini nga post wala gisusi",
  SUBMIT: "Isumiter",
  Topic: "Hilisgutan",
  "Published BY": "Gipatik NI",
  English: "English",
  Country: "Nasud",
  City: "Dakbayan",
  Place: "Dapit",
  "Subject 1": "Subject 1",
  "Subject 2": "Hilisgutan 2",
  "Subject 3": "Hilisgutan 3",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Pagsulod sa Email Address",
  CHOOSE: "PILI",
  By: "Pinaagi sa",
  "End": "Ang Katapusan",
  "Enter Your Story Content": "Pagsulod sa Imong Sugilanon nga Kontento",
  "UPLOAD IMAGES": "I-UPLOAD ANG MGA LARAWAN",
  "Click or Drop here": "I-klik o Ihulog dinhi",
  "Remove all images": "Kuhaa ang tanang mga hulagway",
  "Your post has been properly submitted": "Ang imong post husto nga gisumite",
  "and is published under": "ug gimantala ubos sa",
  "Please copy this for your reference":
    "Palihug kopyaha kini alang sa imong pakisayran",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ANG IMONG PANGUTANA NAKASULOD NA",
};
export const Corsican = {
  "User Instructions": "Upute za korištenje",
  Contact: "Kontakt",
  "News or HereSays Herald": "Vijesti ili HereSays Herald",
  Disclaimer: "Odricanje",
  "Region or Province or County or State":
    "Regija ili pokrajina ili okrug ili država",
  and: "i",
  Date: "Datum",
  Content: "Sadržaj",
  "TopicName is required": "TopicName je obavezan",
  "Publisher Name is required": "Ime izdavača je obavezno",
  "Enter Your Feedback": "Unesite svoje povratne informacije",
  HereSays: "HereSays",
  Region: "Regija",
  Province: "Pokrajina",
  Heard: "čuo",
  Shared: "Podijeljeno",
  Successfully: "Uspješno",
  Explanation: "Spiegazione",
  Language: "Lingua",
  Jurisdiction: "Giurisdizione",
  Attention: "Attenzione",
  "LANGUAGE SELECTION": "SELEZIONE LINGUA",
  ACCESS: "ACCESSO",
  "Terms of use": "Termini di usu",
  REFUSE: "REFUSE",
  ACCEPT: "ACCETTU",
  READ: "LEGGIU",
  PUBLISH: "PUBBLICATE",
  CHAT: "CHAT",
  "comming soon": "à prestu",
  Other: "Altru",
  Remark: "Rimarca",
  Suggestion: "Suggerimentu",
  Complaint: "Lagnanza",
  Manual: "Manuale",
  SEARCH: "RICERCA",
  SHARE: "SHARE",
  DENOUNCE: "DENUNCIA",
  "This post has not been reviewed": "Stu postu ùn hè statu rivisatu",
  SUBMIT: "SUBMIT",
  Topic: "Sugettu",
  "Published BY": "Publicatu da",
  English: "Inglese",
  Country: "Paese",
  City: "Cità",
  Place: "Locu",
  "Subject 1": "Sugettu 1",
  "Subject 2": "Sugettu 2",
  "Subject 3": "Sugettu 3",
  "Reference Id": "Id. di riferenza",
  "Enter Email Address": "Inserite l'indirizzu email",
  CHOOSE: "SCEGLIE",
  By: "By",
  "End": "A fine",
  "Enter Your Story Content": "Entre in u vostru cuntenutu di a storia",
  "UPLOAD IMAGES": "CARICA IMMAGINI",
  "Click or Drop here": "Cliccate o lasciate quì",
  "Remove all images": "Eliminate tutte l'imaghjini",
  "Your post has been properly submitted":
    "U vostru postu hè statu sottumessu bè",
  "and is published under": "è hè publicatu sottu",
  "Please copy this for your reference":
    "Per piacè copiate questu per a vostra riferenza",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "A VOSTRA DOMANDA HA SUTTA SOTTOMETA CORRETTA",
};
export const Croatian = {
  "User Instructions": "Brugervejledning",
  Contact: "Kontakt",
  "News or HereSays Herald": "Nyheder eller HereSays Herald",
  Disclaimer: "Ansvarsfraskrivelse",
  "Region or Province or County or State":
    "Region eller provins eller amt eller stat",
  and: "og",
  Date: "Dato",
  Content: "Indhold",
  "TopicName is required": "Emnenavn er påkrævet",
  "Publisher Name is required": "Udgivernavn er påkrævet",
  "Enter Your Feedback": "Indtast din feedback",
  HereSays: "Her siger",
  Region: "Område",
  Province: "Provins",
  Heard: "Hørt",
  Shared: "Delt",
  Successfully: "Succesfuldt",
  Explanation: "Obrazloženje",
  Language: "Jezik",
  Jurisdiction: "Nadležnost",
  Attention: "Pažnja",
  "LANGUAGE SELECTION": "ODABIR JEZIKA",
  ACCESS: "PRISTUP",
  "Terms of use": "Uvjeti korištenja",
  REFUSE: "ODBITI",
  ACCEPT: "PRIHVATITI",
  READ: "ČITATI",
  PUBLISH: "OBJAVITI",
  CHAT: "RAZGOVOR",
  "comming soon": "dolazi uskoro",
  Other: "ostalo",
  Remark: "Napomena",
  Suggestion: "Prijedlog",
  Complaint: "Žalba",
  Manual: "Priručnik",
  SEARCH: "TRAŽI",
  SHARE: "UDIO",
  DENOUNCE: "OTKAZATI",
  "This post has not been reviewed": "Ovaj post nije pregledan",
  SUBMIT: "PODNIJETI",
  Topic: "Tema",
  "Published BY": "Objavio BY",
  English: "Engleski",
  Country: "Zemlja",
  City: "Grad",
  Place: "Mjesto",
  "Subject 1": "Predmet 1",
  "Subject 2": "Predmet 2",
  "Subject 3": "Predmet 3",
  "Reference Id": "Referentni ID",
  "Enter Email Address": "Unesite adresu e-pošte",
  CHOOSE: "BIRAJTE",
  By: "Po",
  "End": "Kraj",
  "Enter Your Story Content": "Unesite sadržaj svoje priče",
  "UPLOAD IMAGES": "PRENOSI SLIKE",
  "Click or Drop here": "Kliknite ili ispustite ovdje",
  "Remove all images": "Ukloni sve slike",
  "Your post has been properly submitted": "Vaša je objava ispravno poslana",
  "and is published under": "a objavljen je pod",
  "Please copy this for your reference": "Kopirajte ovo za svoju referencu",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "VAŠ UPIT JE ISPRAVNO POSLAN",
};
export const Danish = {
  "User Instructions": "Brugervejledning",
  Contact: "Kontakt",
  "News or HereSays Herald": "Nyheder eller HereSays Herald",
  Disclaimer: "Ansvarsfraskrivelse",
  "Region or Province or County or State":
    "Region eller provins eller amt eller stat",
  and: "og",
  Date: "Dato",
  Content: "Indhold",
  "TopicName is required": "Emnenavn er påkrævet",
  "Publisher Name is required": "Udgivernavn er påkrævet",
  "Enter Your Feedback": "Indtast din feedback",
  HereSays: "Her siger",
  Region: "Område",
  Province: "Provins",
  Heard: "Hørt",
  Shared: "Delt",
  Successfully: "Succesfuldt",
  Explanation: "Forklaring",
  Language: "Sprog",
  Jurisdiction: "Jurisdiktion",
  Attention: "Opmærksomhed",
  "LANGUAGE SELECTION": "SPROG UDVALG",
  ACCESS: "ADGANG",
  "Terms of use": "Vilkår for brug",
  REFUSE: "NÆGTE",
  ACCEPT: "ACCEPTERE",
  READ: "LÆS",
  PUBLISH: "OFFENTLIGGØRE",
  CHAT: "SNAK",
  "comming soon": "kommer snart",
  Other: "Andet",
  Remark: "Bemærkning",
  Suggestion: "Forslag",
  Complaint: "Klage",
  Manual: "brugervejledning",
  SEARCH: "SØG",
  SHARE: "DEL",
  DENOUNCE: "OPSIGE",
  "This post has not been reviewed": "Dette indlæg er ikke blevet gennemgået",
  SUBMIT: "INDSEND",
  Topic: "Emne",
  "Published BY": "Udgivet af",
  English: "engelsk",
  Country: "Land",
  City: "By",
  Place: "Placere",
  "Subject 1": "Emne 1",
  "Subject 2": "Emne 2",
  "Subject 3": "Emne 3",
  "Reference Id": "Reference-id",
  "Enter Email Address": "Indtast email-adresse",
  CHOOSE: "VÆLGE",
  By: "Ved",
  "End": "Slutningen",
  "Enter Your Story Content": "Indtast dit historieindhold",
  "UPLOAD IMAGES": "UPLOAD BILLEDER",
  "Click or Drop here": "Klik eller slip her",
  "Remove all images": "Fjern alle billeder",
  "Your post has been properly submitted":
    "Dit indlæg er blevet korrekt indsendt",
  "and is published under": "og er udgivet under",
  "Please copy this for your reference":
    "Venligst kopier dette til din reference",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "DIN FORESPØRGSEL ER KORREKT INDSENDT",
};
export const Dhivehi = {
  "User Instructions": "ޔޫޒަރ އިންސްޓްރަކްޝަންސް",
  Contact: "ކޮންޓެކްޓް",
  "News or HereSays Herald": "ނިއުސް ނުވަތަ ހިއާސޭސް ހެރަލްޑް",
  Disclaimer: "ޑިސްކްލޭމް",
  "Region or Province or County or State":
    "ސަރަޙައްދު ނުވަތަ އަތޮޅު ނުވަތަ ކައުންޓީ ނުވަތަ ސްޓޭޓް",
  and: "އަދި",
  Date: "ތާރީޚް",
  Content: "ކޮންޓެންޓް",
  "TopicName is required": "TopicName ބޭނުންވެއެވެ",
  "Publisher Name is required": "ޕަބްލިޝަރުގެ ނަން ބޭނުންވާނެއެވެ",
  "Enter Your Feedback": "ތިބާގެ ފީޑްބެކް ލިޔެލާށެވެ",
  HereSays: "މިހެންބުނަނީ",
  Region: "ސަރަހައްދު",
  Province: "ޕްރޮވިންސް",
  Heard: "އަޑުއިވުން",
  Shared: "ހިއްސާކޮށްފައި",
  Successfully: "ކާމިޔާބުކަމާއެކު",
  Explanation: "ތަފްޞީލު",
  Language: "ބަސް",
  Jurisdiction: "އިޚްތިޞާޞް",
  Attention: "ސަމާލުކަން",
  "LANGUAGE SELECTION": "ބަސް ހޮވުން",
  ACCESS: "އެކްސަސް",
  "Terms of use": "ބޭނުންކުރުމުގެ ޝަރުތުތައް",
  REFUSE: "ދެކޮޅު",
  ACCEPT: "ޤަބޫލުކުރުން",
  READ: "ކިޔުން",
  PUBLISH: "ޝާއިޢުކުރުން",
  CHAT: "ވާހަކަދެއްކުން",
  "comming soon": "އަންނަނީ ވަރަށް އަވަހަށް",
  Other: "އެހެން",
  Remark: "ރިމާކް",
  Suggestion: "ޚިޔާލު",
  Complaint: "ޝަކުވާ",
  Manual: "މެނުއަލް",
  SEARCH: "ހޯދުން",
  SHARE: "ޙިއްސާ",
  DENOUNCE: "ކުށްވެރިކުރުން",
  "This post has not been reviewed": "މި ޕޯސްޓް ރިވިއުކޮށްފައެއް ނުވެއެވެ",
  SUBMIT: "ހުށަހެޅުން",
  Topic: "މައުޟޫއު",
  "Published BY": "ޝާއިޢުކޮށްފައިވަނީ ބައި",
  English: "އިނގިރޭސި",
  Country: "ޤައުމު",
  City: "ޝަހަރު",
  Place: "ތަން",
  "Subject 1": "މައުޟޫޢު 1",
  "Subject 2": "މައުޟޫޢު 2",
  "Subject 3": "މައުޟޫޢު 3",
  "Reference Id": "ރެފަރެންސް އައިޑީ",
  "Enter Email Address": "އީމެއިލް އެޑްރެސް ލިޔެލާށެވެ",
  CHOOSE: "އިޚްތިޔާރު",
  By: "ބައި...",
  "End": "ދަ އެންޑް",
  "Enter Your Story Content": "ތިބާގެ ވާހަކަ ކޮންޓެންޓް ލިޔެލާށެވެ",
  "UPLOAD IMAGES": "އަޕްލޯޑް އިމޭޖްސް",
  "Click or Drop here": "މިތަނަށް ކްލިކް ނުވަތަ ޑްރޮޕް ކޮށްލާށެވެ",
  "Remove all images": "ހުރިހާ ތަސްވީރެއް ނައްތާލާށެވެ",
  "Your post has been properly submitted":
    "ތިޔަ ޕޯސްޓް ރަނގަޅަށް ހުށަހަޅާފައި އެބަހުރި",
  "and is published under": "އަދި ޝާއިއުކޮށްފައިވަނީ ގެ ދަށުންނެވެ",
  "Please copy this for your reference":
    "ތިޔަބޭފުޅުންގެ ރިފަރެންސަށް މިކަން ކޮޕީ ކުރައްވާށެވެ",
  "ID:HERESAYS000": "އައިޑީ:ހެރީސޭސް000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ތިޔަބޭފުޅުންގެ ސުވާލު ރަނގަޅަށް ހުށަހަޅާފައިވެއެވެ",
};
export const Dogri = {
  "User Instructions": "उपयोगकर्ता निर्देश",
  Contact: "राबता",
  "News or HereSays Herald": "खबर या इत्थे कहंदा है हेराल्ड",
  Disclaimer: "अस्वीकरण दी",
  "Region or Province or County or State":
    "क्षेत्र या प्रांत या काउंटी या राज्य",
  and: "ते",
  Date: "तरीक",
  Content: "समग्गरी",
  "TopicName is required": "TopicName दी लोड़ ऐ",
  "Publisher Name is required": "प्रकाशक दा नांऽ जरूरी ऐ",
  "Enter Your Feedback": "अपनी प्रतिक्रिया दर्ज करो",
  HereSays: "इत्थेकहदा है",
  Region: "खेत्तर",
  Province: "सूबा",
  Heard: "सुनेआ",
  Shared: "साझा कीता",
  Successfully: "सफलतापूर्वक",
  Explanation: "बजाह्‌त",
  Language: "भाशा",
  Jurisdiction: "न्यायक्षेत्र",
  Attention: "ध्यान",
  "LANGUAGE SELECTION": "भाशा चयन करना",
  ACCESS: "पुज्ज",
  "Terms of use": "इस्तेमाल दी शर्तें",
  REFUSE: "मना करना",
  ACCEPT: "मंजूर करो",
  READ: "पढ़ो",
  PUBLISH: "PUBLISH करो",
  CHAT: "चैट",
  "comming soon": "जल्द ही आने वाला",
  Other: "होर",
  Remark: "टिप्पणी करो",
  Suggestion: "मशबरा",
  Complaint: "शकैत",
  Manual: "हत्थी",
  SEARCH: "तपाश",
  SHARE: "सांझ",
  DENOUNCE: "निंदा करना",
  "This post has not been reviewed": "इस पोस्ट दी समीक्षा नेईं कीती गेई ऐ",
  SUBMIT: "दर्ज करो",
  Topic: "बिशे",
  "Published BY": "प्रकाशित BY",
  English: "अंगरेजी",
  Country: "देश",
  City: "शैहर",
  Place: "थाहर",
  "Subject 1": "विषय 1",
  "Subject 2": "विषय 2",
  "Subject 3": "विषय 3",
  "Reference Id": "संदर्भ आईडी",
  "Enter Email Address": "ईमेल पता दर्ज करो",
  CHOOSE: "चुनो",
  By: "राहें",
  "End": "अंत दा",
  "Enter Your Story Content": "अपनी कहानी सामग्री दर्ज करो",
  "UPLOAD IMAGES": "छवियां अपलोड करो",
  "Click or Drop here": "इत्थें क्लिक करो जां ड्रॉप करो",
  "Remove all images": "सारी छवियां हटाओ",
  "Your post has been properly submitted":
    "तुंदी पोस्ट ठीक ढंगै कन्नै जमा होई गेई ऐ",
  "and is published under": "ते इसदे अंतर्गत प्रकाशत कीता जंदा ऐ",
  "Please copy this for your reference":
    "कृपा करियै अपने संदर्भ आस्तै इसदी नकल करो",
  "ID:HERESAYS000": "आईडी:एहीसे 000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "तुंदी क्वेरी ठीक ढंगै कन्नै जमा कीती गेई ऐ",
};
export const Esperanto = {
  "User Instructions": "Uzanto-Instrukcioj",
  Contact: "Kontaktu",
  "News or HereSays Herald": "Novaĵoj aŭ Ĉi tieDiras Heroldo",
  Disclaimer: "Malgarantio",
  "Region or Province or County or State":
    "Regiono aŭ Provinco aŭ Distrikto aŭ Ŝtato",
  and: "kaj",
  Date: "Dato",
  Content: "Enhavo",
  "TopicName is required": "TopicName estas postulata",
  "Publisher Name is required": "Nomo de Eldonisto estas postulata",
  "Enter Your Feedback": "Enigu Vian Rimarkon",
  HereSays: "Ĉi tieDiras",
  Region: "Regiono",
  Province: "Provinco",
  Heard: "Aŭdis",
  Shared: "Kunhavita",
  Successfully: "Sukcese",
  Explanation: "Klarigo",
  Language: "Lingvo",
  Jurisdiction: "Jurisdikcio",
  Attention: "Atentu",
  "LANGUAGE SELECTION": "LINGVO-ELEKTO",
  ACCESS: "ALIRO",
  "Terms of use": "Kondiĉoj de Uzado",
  REFUSE: "Rifuzo",
  ACCEPT: "Akcepti",
  READ: "LEGU",
  PUBLISH: "PUBLIKIGI",
  CHAT: "BABILI",
  "comming soon": "baldaŭ venos",
  Other: "Alia",
  Remark: "Rimarko",
  Suggestion: "Sugesto",
  Complaint: "Plendo",
  Manual: "Manlibro",
  SEARCH: "SERĈI",
  SHARE: "KUNHAVIGI",
  DENOUNCE: "DENUNCIO",
  "This post has not been reviewed": "Ĉi tiu afiŝo ne estis reviziita",
  SUBMIT: "SUBMETI",
  Topic: "Temo",
  "Published BY": "Eldonita DE",
  English: "la angla",
  Country: "Lando",
  City: "Urbo",
  Place: "Loko",
  "Subject 1": "Temo 1",
  "Subject 2": "Temo 2",
  "Subject 3": "Temo 3",
  "Reference Id": "Referenca Id",
  "Enter Email Address": "Enigu Retpoŝtadreson",
  CHOOSE: "ELEKTU",
  By: "De",
  "End": "Fine",
  "Enter Your Story Content": "Enigu Vian Rakontan Enhavon",
  "UPLOAD IMAGES": "ALŝutu BILDOJN",
  "Click or Drop here": "Klaku aŭ Faligi ĉi tie",
  "Remove all images": "Forigu ĉiujn bildojn",
  "Your post has been properly submitted": "Via afiŝo estas ĝuste sendita",
  "and is published under": "kaj estas publikigita sub",
  "Please copy this for your reference":
    "Bonvolu kopii ĉi tion por via referenco",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "VIA DEMANDO ESTAS ĜUGE SENMETITA",
};
export const Estonian = {
  "User Instructions": "Kasutusjuhised",
  Contact: "Võtke ühendust",
  "News or HereSays Herald": "News või HereSays Herald",
  Disclaimer: "Vastutusest loobumine",
  "Region or Province or County or State":
    "Piirkond või provints või maakond või osariik",
  and: "ja",
  Date: "Kuupäev",
  Content: "Sisu",
  "TopicName is required": "Teema nimi on kohustuslik",
  "Publisher Name is required": "Väljaandja nimi on kohustuslik",
  "Enter Your Feedback": "Sisestage oma tagasiside",
  HereSays: "Siin ütleb",
  Region: "Piirkond",
  Province: "provints",
  Heard: "Kuulnud",
  Shared: "Jagatud",
  Successfully: "Edukalt",
  Explanation: "Selgitus",
  Language: "Keel",
  Jurisdiction: "Jurisdiktsioon",
  Attention: "Tähelepanu",
  "LANGUAGE SELECTION": "KEELE VALIK",
  ACCESS: "JUURDEPÄÄS",
  "Terms of use": "Kasutustingimused",
  REFUSE: "KEELDADA",
  ACCEPT: "NÕUSTU",
  READ: "LOE",
  PUBLISH: "AVALDA",
  CHAT: "VESTLUS",
  "comming soon": "tuleb varsti",
  Other: "muud",
  Remark: "Märkus",
  Suggestion: "Soovitus",
  Complaint: "Kaebus",
  Manual: "Käsiraamat",
  SEARCH: "OTSING",
  SHARE: "JAGA",
  DENOUNCE: "HÄVITA",
  "This post has not been reviewed": "Seda postitust pole üle vaadatud",
  SUBMIT: "ESITA",
  Topic: "Teema",
  "Published BY": "Avaldanud BY",
  English: "Inglise",
  Country: "Riik",
  City: "Linn",
  Place: "Koht",
  "Subject 1": "Teema 1",
  "Subject 2": "Teema 2",
  "Subject 3": "Teema 3",
  "Reference Id": "Viite ID",
  "Enter Email Address": "Sisesta e-posti aadress",
  CHOOSE: "VALI",
  By: "Kõrval",
  "End": "Lõpp",
  "Enter Your Story Content": "Sisestage oma loo sisu",
  "UPLOAD IMAGES": "LAADI ÜLES PILTID",
  "Click or Drop here": "Klõpsake või kukutage siia",
  "Remove all images": "Eemaldage kõik pildid",
  "Your post has been properly submitted": "Teie postitus on õigesti esitatud",
  "and is published under": "ja avaldatakse all",
  "Please copy this for your reference": "Palun kopeerige see viitamiseks",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "TEIE PÄRING ON KORRALIKULT ESITATUD",
};
export const Ewe = {
  "User Instructions": "Zãla ƒe Mɔfiamewo",
  Contact: "Kadodo",
  "News or HereSays Herald": "Nyadzɔdzɔ alo Afi SiaEgblɔ Herald",
  Disclaimer: "Adzɔgbeɖeɖe",
  "Region or Province or County or State":
    "Nuto alo Nutome alo Nutome alo Dukɔ",
  and: "kple",
  Date: "Ŋkeke",
  Content: "Eme nuwo",
  "TopicName is required": "TopicName hiã",
  "Publisher Name is required": "Gbeƒãɖela ƒe Ŋkɔ hiã",
  "Enter Your Feedback": "Ŋlɔ Wò Nyaŋuɖoɖo",
  HereSays: "AfisiaEgblɔ",
  Region: "Nuto",
  Province: "Nutoga",
  Heard: "See",
  Shared: "Womae",
  Successfully: "Dzidzedzekpɔkpɔtɔe",
  Explanation: "Numeɖeɖe",
  Language: "Gbegbᴐgblᴐ",
  Jurisdiction: "Ʋɔnudrɔ̃ƒe ƒe ŋusẽ",
  Attention: "Ŋuɖoɖo",
  "LANGUAGE SELECTION": "GBƆGBƆ ƑE TIATIA",
  ACCESS: "MᴐNU",
  "Terms of use": "Zãzã ƒe ɖoɖowo",
  REFUSE: "GBE",
  ACCEPT: "LƆ̃ ƉE EDZI",
  READ: "XLẼ",
  PUBLISH: "PUBLISH ƑE NUÐEÐEŊUTI",
  CHAT: "ƉO DZE",
  "comming soon": "gbɔna kpuie",
  Other: "Bubu",
  Remark: "De dzesii",
  Suggestion: "Nudodoɖa",
  Complaint: "Nyatoto",
  Manual: "Asi nu",
  SEARCH: "DI",
  SHARE: "MA",
  DENOUNCE: "NYAGBLƆÐI ƑE NUÐEÐEŊUTI",
  "This post has not been reviewed": "Wometo nya sia me o",
  SUBMIT: "TSᴐE YI",
  Topic: "Nyati",
  "Published BY": "Wotae BY",
  English: "iŋlisi",
  Country: "Dukᴐ",
  City: "Dugã",
  Place: "Teƒe",
  "Subject 1": "Nyati 1 lia",
  "Subject 2": "Nyati 2 lia",
  "Subject 3": "Nyati 3 lia",
  "Reference Id": "Nufiame Id",
  "Enter Email Address": "Ŋlɔ Email Adrɛs ɖe eme",
  CHOOSE: "TIA",
  By: "To esia dzi",
  "End": "Nuwuwua",
  "Enter Your Story Content": "Ŋlɔ Wò Ŋutinya me Nyatakakawo",
  "UPLOAD IMAGES": "ƉE NƆNƆMEWO ÐE NU SIWO ME",
  "Click or Drop here": "Zi afisia dzi alo Dae ɖe afisia",
  "Remove all images": "Ðe nɔnɔmetatawo katã ɖa",
  "Your post has been properly submitted": "Wotsɔ wò nyatakakaa ɖo ɖa nyuie",
  "and is published under": "eye wotae ɖe ete",
  "Please copy this for your reference": "Taflatse kɔpi esia hena wò numekuku",
  "ID:HERESAYS000": "ID:NYAWO ƑE NYAGBLƆÐIWO000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "WOÐO WÒ BIABIA ƑE NUÐEÐEŊUTI ÐO BE WÒNYE",
};
export const Filipino = {
  "User Instructions": "Mga Tagubilin sa Gumagamit",
  Contact: "Makipag-ugnayan",
  "News or HereSays Herald": "Balita o HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Rehiyon o Lalawigan o County o Estado",
  and: "at",
  Date: "Petsa",
  Content: "Nilalaman",
  "TopicName is required": "Kinakailangan ang TopicName",
  "Publisher Name is required": "Kinakailangan ang Pangalan ng Publisher",
  "Enter Your Feedback": "Ilagay ang Iyong Feedback",
  HereSays: "DitoSays",
  Region: "Rehiyon",
  Province: "Lalawigan",
  Heard: "Narinig",
  Shared: "Ibinahagi",
  Successfully: "Matagumpay",
  Explanation: "Paliwanag",
  Language: "Wika",
  Jurisdiction: "hurisdiksyon",
  Attention: "Pansin",
  "LANGUAGE SELECTION": "PAGPILI NG WIKA",
  ACCESS: "ACCESS",
  "Terms of use": "Mga Tuntunin ng Paggamit",
  REFUSE: "TANGGI",
  ACCEPT: "TANGGAPIN",
  READ: "BASAHIN",
  PUBLISH: "I-publish",
  CHAT: "CHAT",
  "comming soon": "paparating na",
  Other: "Iba pa",
  Remark: "Puna",
  Suggestion: "Mungkahi",
  Complaint: "Reklamo",
  Manual: "Manwal",
  SEARCH: "PAGHAHANAP",
  SHARE: "IBAHAGI",
  DENOUNCE: "TUMINGI",
  "This post has not been reviewed": "Ang post na ito ay hindi nasuri",
  SUBMIT: "IPASA",
  Topic: "Paksa",
  "Published BY": "Inilathala ni",
  English: "Ingles",
  Country: "Bansa",
  City: "lungsod",
  Place: "Lugar",
  "Subject 1": "Paksa 1",
  "Subject 2": "Paksa 2",
  "Subject 3": "Paksa 3",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Ilagay ang email address",
  CHOOSE: "PUMILI",
  By: "Sa pamamagitan ng",
  "End": "Wakas",
  "Enter Your Story Content": "Ilagay ang Nilalaman ng Iyong Kwento",
  "UPLOAD IMAGES": "MAG-UPLOAD NG MGA LARAWAN",
  "Click or Drop here": "Mag-click o Mag-drop dito",
  "Remove all images": "Alisin ang lahat ng mga larawan",
  "Your post has been properly submitted":
    "Naisumite nang maayos ang iyong post",
  "and is published under": "at nai-publish sa ilalim ng",
  "Please copy this for your reference":
    "Mangyaring kopyahin ito para sa iyong sanggunian",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ANG IYONG TANONG AY NAISUMITE nang wasto",
};
export const Frisian = {
  "User Instructions": "Brûkersynstruksjes",
  Contact: "Kontakt",
  "News or HereSays Herald": "Nijs of HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Regio of Provinsje of County of State",
  and: "en",
  Date: "Datum",
  Content: "Ynhâld",
  "TopicName is required": "TopicName is ferplicht",
  "Publisher Name is required": "Publisher Name is fereaske",
  "Enter Your Feedback": "Fier jo feedback yn",
  HereSays: "HereSays",
  Region: "Regio",
  Province: "Provinsje",
  Heard: "Heard",
  Shared: "Dielde",
  Successfully: "Mei sukses",
  Explanation: "Ferklearring",
  Language: "Taal",
  Jurisdiction: "Rjochtspraak",
  Attention: "Oandacht",
  "LANGUAGE SELECTION": "TAALSELEKJE",
  ACCESS: "TAGONG",
  "Terms of use": "Betingsten fan gebrûk",
  REFUSE: "WEGERJE",
  ACCEPT: "OANNIMME",
  READ: "LÊZE",
  PUBLISH: "PUBLISHJE",
  CHAT: "CHAT",
  "comming soon": "meikoarten",
  Other: "Oar",
  Remark: "Opmerking",
  Suggestion: "Suggestje",
  Complaint: "Klacht",
  Manual: "Hantlieding",
  SEARCH: "SYKJE",
  SHARE: "DIELE",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Dizze post is net hifke",
  SUBMIT: "SUBMIT",
  Topic: "Ûnderwerp",
  "Published BY": "Publisearre troch",
  English: "Ingelsk",
  Country: "Lân",
  City: "Stêd",
  Place: "Plak",
  "Subject 1": "Underwerp 1",
  "Subject 2": "Underwerp 2",
  "Subject 3": "Underwerp 3",
  "Reference Id": "Referinsje ID",
  "Enter Email Address": "Fier e-postadres yn",
  CHOOSE: "KIEZE",
  By: "Troch",
  "End": "It ein",
  "Enter Your Story Content": "Fier jo ferhaalynhâld yn",
  "UPLOAD IMAGES": "UPLOAD Ofbyldings",
  "Click or Drop here": "Klikje of drop hjir",
  "Remove all images": "Fuortsmite alle ôfbyldings",
  "Your post has been properly submitted": "Jo post is goed yntsjinne",
  "and is published under": "en wurdt publisearre ûnder",
  "Please copy this for your reference":
    "Kopiearje dit asjebleaft foar jo referinsje",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "YOUR QUERY IS GOED yntsjinne",
};
export const Georgian = {
  "User Instructions": "მომხმარებლის ინსტრუქციები",
  Contact: "კონტაქტი",
  "News or HereSays Herald": "ახალი ამბები ან HereSays Herald",
  Disclaimer: "პასუხისმგებლობის უარყოფა",
  "Region or Province or County or State":
    "რეგიონი ან პროვინცია ან ოლქი ან შტატი",
  and: "და",
  Date: "თარიღი",
  Content: "შინაარსი",
  "TopicName is required": "თემის სახელი აუცილებელია",
  "Publisher Name is required": "აუცილებელია გამომცემლის სახელი",
  "Enter Your Feedback": "შეიყვანეთ თქვენი გამოხმაურება",
  HereSays: "აქ ამბობს",
  Region: "რეგიონი",
  Province: "პროვინცია",
  Heard: "გაისმა",
  Shared: "გაზიარებული",
  Successfully: "Წარმატებით",
  Explanation: "ახსნა",
  Language: "Ენა",
  Jurisdiction: "იურისდიქცია",
  Attention: "ყურადღება",
  "LANGUAGE SELECTION": "ენის არჩევა",
  ACCESS: "წვდომა",
  "Terms of use": "Მოხმარების პირობები",
  REFUSE: "უარი",
  ACCEPT: "მიღება",
  READ: "წაიკითხეთ",
  PUBLISH: "გამოქვეყნება",
  CHAT: "ᲡᲐᲡᲐᲣᲑᲠᲝ",
  "comming soon": "მალე იქნება",
  Other: "სხვა",
  Remark: "შენიშვნა",
  Suggestion: "წინადადება",
  Complaint: "საჩივარი",
  Manual: "სახელმძღვანელო",
  SEARCH: "ძიება",
  SHARE: "გააზიარეთ",
  DENOUNCE: "დაგმო",
  "This post has not been reviewed": "ეს პოსტი არ არის განხილული",
  SUBMIT: "გაგზავნა",
  Topic: "Თემა",
  "Published BY": "Გამოქვეყნებულია ............. მიერ",
  English: "ინგლისური",
  Country: "ქვეყანა",
  City: "ქალაქი",
  Place: "ადგილი",
  "Subject 1": "საგანი 1",
  "Subject 2": "საგანი 2",
  "Subject 3": "საგანი 3",
  "Reference Id": "მითითების ID",
  "Enter Email Address": "Შეიყვანე ელ. ფოსტის მისამართი",
  CHOOSE: "არჩევა",
  By: "ავტორი",
  "End": "Დასასრული",
  "Enter Your Story Content": "შეიყვანეთ თქვენი მოთხრობის შინაარსი",
  "UPLOAD IMAGES": "ატვირთეთ სურათები",
  "Click or Drop here": "დააწკაპუნეთ ან ჩამოაგდეთ აქ",
  "Remove all images": "წაშალეთ ყველა სურათი",
  "Your post has been properly submitted":
    "თქვენი პოსტი სწორად არის წარმოდგენილი",
  "and is published under": "და გამოქვეყნებულია ქვეშ",
  "Please copy this for your reference":
    "გთხოვთ დააკოპიროთ ეს თქვენი მითითებისთვის",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "თქვენი შეკითხვა სათანადოდ იქნა წარმოდგენილი",
};
export const Guarani = {
  "User Instructions": "Pojoapy puruhára rehegua",
  Contact: "Jekuaaverã",
  "News or HereSays Herald": "Marandu térã Ko'ápeHe'i Herald",
  Disclaimer: "Ñe’ẽñemi ñemboyke",
  "Region or Province or County or State":
    "Región térã Provincia térã Condado térã Estado",
  and: "ha",
  Date: "Fecha",
  Content: "Pypegua",
  "TopicName is required": "TopicName oñeikotevẽ",
  "Publisher Name is required": "Oñeikotevẽ Publicador Réra",
  "Enter Your Feedback": "Eike Nde Ñe’ẽñemi",
  HereSays: "Ko'ápeHe'i",
  Region: "Tavapehẽ",
  Province: "Tetãpehẽ",
  Heard: "Ohendúkuri",
  Shared: "Oñemboja’o",
  Successfully: "Osẽ porã",
  Explanation: "Myesakãrã",
  Language: "Ñe'ẽ",
  Jurisdiction: "Jurisdicción rehegua",
  Attention: "Jesareko",
  "LANGUAGE SELECTION": "ÑE’Ẽ JEPORU",
  ACCESS: "JEIKE",
  "Terms of use": "Ñe’ẽme’ẽ ojeporúva rehegua",
  REFUSE: "PORUJEY",
  ACCEPT: "MONEĨ",
  READ: "LEE",
  PUBLISH: "PUBLICACIÓN rehegua",
  CHAT: "MONGETA",
  "comming soon": "oútava pya’e",
  Other: "Ambue",
  Remark: "Ñe’ẽñemi",
  Suggestion: "Momarandu",
  Complaint: "Ñemombe'u",
  Manual: "Popeguáva",
  SEARCH: "JEHEKA",
  SHARE: "MBOJA'O",
  DENOUNCE: "DENUNCIACIÓN rehegua",
  "This post has not been reviewed": "Ko kuatiahaipyre ndojehechái gueteri",
  SUBMIT: "RAHAUKA",
  Topic: "Ñe'ẽrã",
  "Published BY": "Omoherakuãva BY",
  English: "inglés",
  Country: "Tetã",
  City: "Táva",
  Place: "Tenda",
  "Subject 1": "Tema rehegua 1.1",
  "Subject 2": "Tema rehegua 2.1",
  "Subject 3": "Tema rehegua 3.1",
  "Reference Id": "Referencia Id rehegua",
  "Enter Email Address": "Emoinge Dirección de Correo Electrónico",
  CHOOSE: "PORAVO",
  By: "Rupi",
  "End": "Pe Ipaha",
  "Enter Your Story Content": "Eike Nde Rembiasakue Contenido",
  "UPLOAD IMAGES": "EMOĨ TA'ÃNGAKUÉRA",
  "Click or Drop here": "Emboguejy térã Emboguejy ko’ápe",
  "Remove all images": "Ojepe’a opaite ta’ãngamýi",
  "Your post has been properly submitted": "Nde kuatiañe’ẽ oñemondo porãma",
  "and is published under": "ha oñemoherakuã guýpe",
  "Please copy this for your reference": "Ekopia ko mba’e nde referencia-rã",
  "ID:HERESAYS000": "ID:KO'ÁPE000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "NE PORANDU OÑEMBOGUAPY HEKOPETE",
};
export const Gujarati = {
  "User Instructions": "વપરાશકર્તા સૂચનાઓ",
  Contact: "સંપર્ક કરો",
  "News or HereSays Herald": "સમાચાર અથવા HereSays હેરાલ્ડ",
  Disclaimer: "અસ્વીકરણ",
  "Region or Province or County or State":
    "પ્રદેશ અથવા પ્રાંત અથવા કાઉન્ટી અથવા રાજ્ય",
  and: "અને",
  Date: "તારીખ",
  Content: "સામગ્રી",
  "TopicName is required": "વિષયનું નામ જરૂરી છે",
  "Publisher Name is required": "પ્રકાશકનું નામ જરૂરી છે",
  "Enter Your Feedback": "તમારો પ્રતિસાદ દાખલ કરો",
  HereSays: "અહીં કહે છે",
  Region: "પ્રદેશ",
  Province: "પ્રાંત",
  Heard: "સાંભળ્યું",
  Shared: "શેર કરેલ",
  Successfully: "સફળતાપૂર્વક",
  Explanation: "સમજૂતી",
  Language: "ભાષા",
  Jurisdiction: "અધિકારક્ષેત્ર",
  Attention: "ધ્યાન",
  "LANGUAGE SELECTION": "ભાષાની પસંદગી",
  ACCESS: "એક્સેસ",
  "Terms of use": "વાપરવાના નિયમો",
  REFUSE: "ઇનકાર કરો",
  ACCEPT: "સ્વીકારો",
  READ: "વાંચવું",
  PUBLISH: "પ્રકાશિત કરો",
  CHAT: "ચેટ",
  "comming soon": "નજીક્ના સમય માં",
  Other: "અન્ય",
  Remark: "ટિપ્પણી",
  Suggestion: "સૂચન",
  Complaint: "ફરિયાદ",
  Manual: "મેન્યુઅલ",
  SEARCH: "શોધો",
  SHARE: "શેર કરો",
  DENOUNCE: "નિંદા કરો",
  "This post has not been reviewed": "આ પોસ્ટની સમીક્ષા કરવામાં આવી નથી",
  SUBMIT: "સબમિટ કરો",
  Topic: "વિષય",
  "Published BY": "દ્વારા પ્રકાશિત",
  English: "અંગ્રેજી",
  Country: "દેશ",
  City: "શહેર",
  Place: "સ્થળ",
  "Subject 1": "વિષય 1",
  "Subject 2": "વિષય 2",
  "Subject 3": "વિષય 3",
  "Reference Id": "સંદર્ભ ID",
  "Enter Email Address": "ઇમેઇલ સરનામું દાખલ કરો",
  CHOOSE: "પસંદ કરો",
  By: "દ્વારા",
  "End": "સમાપ્ત",
  "Enter Your Story Content": "તમારી વાર્તા સામગ્રી દાખલ કરો",
  "UPLOAD IMAGES": "છબીઓ અપલોડ કરો",
  "Click or Drop here": "અહીં ક્લિક કરો અથવા છોડો",
  "Remove all images": "બધી છબીઓ દૂર કરો",
  "Your post has been properly submitted":
    "તમારી પોસ્ટ યોગ્ય રીતે સબમિટ કરવામાં આવી છે",
  "and is published under": "અને હેઠળ પ્રકાશિત થાય છે",
  "Please copy this for your reference":
    "કૃપા કરીને તમારા સંદર્ભ માટે આની નકલ કરો",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "તમારી ક્વેરી યોગ્ય રીતે સબમિટ કરવામાં આવી છે",
};
export const Haitian_Creole = {
  "User Instructions": "Enstriksyon Itilizatè yo",
  Contact: "Kontakte",
  "News or HereSays Herald": "Nouvèl oswa HereSays Herald",
  Disclaimer: "Limit responsabilite nou",
  "Region or Province or County or State":
    "Rejyon oswa Pwovens oswa Konte oswa Eta",
  and: "epi",
  Date: "Dat",
  Content: "Kontni",
  "TopicName is required": "TopicName obligatwa",
  "Publisher Name is required": "Non Piblikatè obligatwa",
  "Enter Your Feedback": "Antre Feedback ou",
  HereSays: "Isit la di",
  Region: "Rejyon",
  Province: "Pwovens",
  Heard: "Tande",
  Shared: "Pataje",
  Successfully: "Siksè",
  Explanation: "Eksplikasyon",
  Language: "Lang",
  Jurisdiction: "Jiridiksyon",
  Attention: "Atansyon",
  "LANGUAGE SELECTION": "SELEKSYON LANG",
  ACCESS: "AKSÈ",
  "Terms of use": "Tèm itilizasyon",
  REFUSE: "REFIZE",
  ACCEPT: "AKSEPTE",
  READ: "LI",
  PUBLISH: "Pibliye",
  CHAT: "CHAT",
  "comming soon": "vini byento",
  Other: "Lòt",
  Remark: "Remak",
  Suggestion: "Sijesyon",
  Complaint: "Plent",
  Manual: "Manyèl",
  SEARCH: "RECHÈCHE",
  SHARE: "PATAJE",
  DENOUNCE: "DENONSE",
  "This post has not been reviewed": "Pòs sa a pa te revize",
  SUBMIT: "SOUMET",
  Topic: "Sijè",
  "Published BY": "Pibliye BY",
  English: "angle",
  Country: "Peyi",
  City: "Vil",
  Place: "Kote",
  "Subject 1": "Sijè 1",
  "Subject 2": "Sijè 2",
  "Subject 3": "Sijè 3",
  "Reference Id": "Id referans",
  "Enter Email Address": "Antre Adrès Imèl",
  CHOOSE: "CHWAZI",
  By: "Pa",
  "End": "Fen a",
  "Enter Your Story Content": "Antre kontni istwa ou a",
  "UPLOAD IMAGES": "TELECHARGE IMAJ",
  "Click or Drop here": "Klike oswa Drop isit la",
  "Remove all images": "Retire tout imaj yo",
  "Your post has been properly submitted": "Pòs ou a te byen soumèt",
  "and is published under": "epi li pibliye anba",
  "Please copy this for your reference": "Tanpri kopye sa a pou referans ou",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "TE KÒDYON OU BYEN SOUMET",
};
export const Hausa = {
  "User Instructions": "Umarnin mai amfani",
  Contact: "Tuntuɓar",
  "News or HereSays Herald": "Labarai ko HereInji Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State": "Yanki ko Lardi ko Lardi ko Jiha",
  and: "kuma",
  Date: "Kwanan wata",
  Content: "Abun ciki",
  "TopicName is required": "Ana buƙatar Sunan Jigon",
  "Publisher Name is required": "Ana buƙatar Sunan Mai bugawa",
  "Enter Your Feedback": "Shigar da Ra'ayin ku",
  HereSays: "Anan Cewa",
  Region: "Yanki",
  Province: "Lardi",
  Heard: "An ji",
  Shared: "Raba",
  Successfully: "Nasara",
  Explanation: "Bayani",
  Language: "Harshe",
  Jurisdiction: "Hukunci",
  Attention: "Hankali",
  "LANGUAGE SELECTION": "ZABEN HARSHE",
  ACCESS: "SHIGA",
  "Terms of use": "Sharuɗɗan amfani",
  REFUSE: "KI",
  ACCEPT: "YARDA",
  READ: "KARANTA",
  PUBLISH: "BUGA",
  CHAT: "CHAT",
  "comming soon": "mai zuwa nan ba da jimawa ba",
  Other: "Sauran",
  Remark: "Magana",
  Suggestion: "Shawara",
  Complaint: "Koka",
  Manual: "Manual",
  SEARCH: "BINCIKE",
  SHARE: "SHARE",
  DENOUNCE: "TSOKACI",
  "This post has not been reviewed": "Ba a sake duba wannan sakon ba",
  SUBMIT: "BUKATA",
  Topic: "Taken",
  "Published BY": "Wallafa BY",
  English: "Turanci",
  Country: "Ƙasa",
  City: "Garin",
  Place: "Wuri",
  "Subject 1": "Maudu'i 1",
  "Subject 2": "Maudu'i 2",
  "Subject 3": "Maudu'i 3",
  "Reference Id": "Bayanin Id",
  "Enter Email Address": "Shigar da Adireshin Imel",
  CHOOSE: "ZABI",
  By: "By",
  "End": "Karshen",
  "Enter Your Story Content": "Shigar da Abubuwan Labarin ku",
  "UPLOAD IMAGES": "Loda Hotuna",
  "Click or Drop here": "Danna ko Ajiye nan",
  "Remove all images": "Cire duk hotuna",
  "Your post has been properly submitted": "An ƙaddamar da sakon ku da kyau",
  "and is published under": "kuma an buga a karkashin",
  "Please copy this for your reference":
    "Da fatan za a kwafi wannan don tunani",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "AN GABATAR DA TAMBAYOYINKA DA KYAU",
};
export const Hawaiian = {
  "User Instructions": "Nā kuhikuhi mea hoʻohana",
  Contact: "Hoʻopili",
  "News or HereSays Herald": "Nūhou a i ʻole HereSays Herald",
  Disclaimer: "Hoʻolele",
  "Region or Province or County or State":
    "ʻĀpana a i ʻole kalana a i ʻole kalana a mokuʻāina paha",
  and: "a",
  Date: "Lā",
  Content: "Maʻiʻo",
  "TopicName is required": "Pono ʻo TopicName",
  "Publisher Name is required": "Pono ka inoa o ka mea hoʻopuka",
  "Enter Your Feedback": "E komo i kāu Manaʻo Manaʻo",
  HereSays: "Ke'ōlelo nei",
  Region: "Māhele",
  Province: "Panalaau",
  Heard: "Lohe",
  Shared: "Kaʻana like",
  Successfully: "Ua lanakila",
  Explanation: "Wehewehe",
  Language: "ʻŌlelo",
  Jurisdiction: "Manaʻo mana",
  Attention: "Nānā",
  "LANGUAGE SELECTION": "KOHO OLELO",
  ACCESS: "KAHIKI",
  "Terms of use": "Nā ʻōlelo hoʻohana",
  REFUSE: "HOOLE",
  ACCEPT: "E ALOHA",
  READ: "HELUHELU",
  PUBLISH: "HOOLAHA",
  CHAT: "KĀKĀLĀ",
  "comming soon": "e hiki koke mai ana",
  Other: "'ē aʻe",
  Remark: "ʻŌlelo",
  Suggestion: "Manaʻo",
  Complaint: "Hoʻopiʻi",
  Manual: "Manual",
  SEARCH: "HULI",
  SHARE: "Kaʻana like",
  DENOUNCE: "HOOLAHA",
  "This post has not been reviewed": "ʻAʻole i nānā ʻia kēia pou",
  SUBMIT: "WAIWAI",
  Topic: "Kumuhana",
  "Published BY": "Hoʻopuka ʻia e",
  English: "Pelekania",
  Country: "ʻāina",
  City: "Kulanakauhale",
  Place: "Wahi",
  "Subject 1": "Kumuhana 1",
  "Subject 2": "Kumuhana 2",
  "Subject 3": "Kumuhana 3",
  "Reference Id": "Kuhikuhi Id",
  "Enter Email Address": "E hoʻokomo i ka leka uila",
  CHOOSE: "E KOHO",
  By: "Na",
  "End": "Ka hopena",
  "Enter Your Story Content": "E hoʻokomo i kāu ʻike moʻolelo",
  "UPLOAD IMAGES": "Hoʻouka i nā kiʻi",
  "Click or Drop here": "Kaomi a hāʻule ma ʻaneʻi",
  "Remove all images": "Wehe i nā kiʻi a pau",
  "Your post has been properly submitted": "Ua waiho pono ʻia kāu leka",
  "and is published under": "a ua paʻi ʻia ma lalo",
  "Please copy this for your reference":
    "E ʻoluʻolu e kope i kēia no kāu kuhikuhi",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "UA WAIA MAI KOU NINAU",
};
export const Hindi = {
  "User Instructions": "उपयोगकर्ता निर्देश",
  Contact: "संपर्क",
  "News or HereSays Herald": "समाचार या हेराल्ड कहते हैं",
  Disclaimer: "अस्वीकरण",
  "Region or Province or County or State":
    "क्षेत्र या प्रांत या काउंटी या राज्य",
  and: "और",
  Date: "तारीख",
  Content: "संतुष्ट",
  "TopicName is required": "विषय का नाम आवश्यक है",
  "Publisher Name is required": "प्रकाशक का नाम आवश्यक है",
  "Enter Your Feedback": "अपनी प्रतिक्रिया दर्ज करें",
  HereSays: "यहाँ कहते हैं",
  Region: "क्षेत्र",
  Province: "प्रांत",
  Heard: "सुना",
  Shared: "साझा",
  Successfully: "सफलतापूर्वक",
  Explanation: "व्याख्या",
  Language: "भाषा",
  Jurisdiction: "क्षेत्राधिकार",
  Attention: "ध्यान",
  "LANGUAGE SELECTION": "भाषा चयन",
  ACCESS: "पहुँच",
  "Terms of use": "उपयोग की शर्तें",
  REFUSE: "अस्वीकार करना",
  ACCEPT: "स्वीकार करना",
  READ: "पढ़ना",
  PUBLISH: "प्रकाशित",
  CHAT: "बात करना",
  "comming soon": "जल्द आ रहा",
  Other: "अन्य",
  Remark: "टिप्पणी",
  Suggestion: "सुझाव",
  Complaint: "शिकायत",
  Manual: "नियमावली",
  SEARCH: "खोज",
  SHARE: "शेयर करना",
  DENOUNCE: "आरोप लगा देना",
  "This post has not been reviewed": "इस पोस्ट की समीक्षा नहीं की गई है",
  SUBMIT: "जमा करना",
  Topic: "विषय",
  "Published BY": "द्वारा प्रकाशित",
  English: "अंग्रेज़ी",
  Country: "देश",
  City: "शहर",
  Place: "जगह",
  "Subject 1": "विषय 1",
  "Subject 2": "विषय 2",
  "Subject 3": "विषय 3",
  "Reference Id": "संदर्भ पहचान पत्र",
  "Enter Email Address": "ईमेल पता दर्ज करें",
  CHOOSE: "चुनना",
  By: "द्वारा",
  "End": "समाप्त",
  "Enter Your Story Content": "अपनी कहानी सामग्री दर्ज करें",
  "UPLOAD IMAGES": "तश्वीरें अपलोड करो",
  "Click or Drop here": "यहां क्लिक करें या ड्रॉप करें",
  "Remove all images": "सभी छवियों को हटा दें",
  "Your post has been properly submitted":
    "आपकी पोस्ट ठीक से सबमिट कर दी गई है",
  "and is published under": "और के तहत प्रकाशित किया गया है",
  "Please copy this for your reference":
    "कृपया इसे अपने संदर्भ के लिए कॉपी करें",
  "ID:HERESAYS000": "आईडी: हेरेसेज़000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "आपकी क्वेरी ठीक से सबमिट की गई है",
};
export const Hmong = {
  "User Instructions": "Cov lus qhia siv",
  Contact: "Hu rau",
  "News or HereSays Herald": "Xov xwm los yog HereSays Herald",
  Disclaimer: "Tsis lees paub",
  "Region or Province or County or State":
    "Region los yog Xeev los yog Lub Nroog lossis Xeev",
  and: "thiab",
  Date: "Hnub tim",
  Content: "Cov ntsiab lus",
  "TopicName is required": "Yuav tsum tau TopicName",
  "Publisher Name is required": "Yuav tsum muaj lub npe tshaj tawm",
  "Enter Your Feedback": "Sau Koj Cov Lus Teb",
  HereSays: "Ntawm no hais",
  Region: "Thaj tsam",
  Province: "Xeev",
  Heard: "Hnov",
  Shared: "Sib koom",
  Successfully: "Ua tiav",
  Explanation: "Kev piav qhia",
  Language: "Lus",
  Jurisdiction: "Kev txiav txim",
  Attention: "Nco ntsoov",
  "LANGUAGE SELECTION": "LANGUAGE xaiv",
  ACCESS: "Nkag mus",
  "Terms of use": "Cov ntsiab lus ntawm kev siv",
  REFUSE: "TSIS TAU",
  ACCEPT: "ACCEPT",
  READ: "NYEEM",
  PUBLISH: "Tshaj tawm",
  CHAT: "CHAT",
  "comming soon": "los sai sai no",
  Other: "Lwm yam",
  Remark: "Lus ceeb toom",
  Suggestion: "Tswv yim",
  Complaint: "Kev tsis txaus siab",
  Manual: "Phau ntawv",
  SEARCH: "Nrhiav",
  SHARE: "SHARE",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Cov ncej no tsis tau tshuaj xyuas",
  SUBMIT: "SUBMIT",
  Topic: "Ntsiab lus",
  "Published BY": "Luam tawm BY",
  English: "Lus Askiv",
  Country: "Lub teb chaws",
  City: "Lub nroog",
  Place: "Qhov chaw",
  "Subject 1": "Yam 1",
  "Subject 2": "Yam 2",
  "Subject 3": "Yam 3",
  "Reference Id": "Siv Id",
  "Enter Email Address": "Sau Email Chaw Nyob",
  CHOOSE: "Xaiv",
  By: "Los ntawm",
  "End": "Qhov Kawg",
  "Enter Your Story Content": "Sau Koj Zaj Dab Neeg Cov ntsiab lus",
  "UPLOAD IMAGES": "UPLOAD cov duab",
  "Click or Drop here": "Nyem los yog tso rau ntawm no",
  "Remove all images": "Tshem tag nrho cov duab",
  "Your post has been properly submitted": "Koj tus ncej tau raug xa mus zoo",
  "and is published under": "thiab luam tawm nyob rau hauv",
  "Please copy this for your reference": "Thov luam qhov no rau koj siv",
  "ID:HERESAYS000": "ID: YOG 000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "Koj cov lus nug tau raug xa mus kom raug",
};
export const Igbo = {
  "User Instructions": "Ntuziaka onye ọrụ",
  Contact: "Kpọtụrụ",
  "News or HereSays Herald": "Akụkọ ma ọ bụ HereSays Herald",
  Disclaimer: "Nkwuputa",
  "Region or Province or County or State":
    "Mpaghara ma ọ bụ Province ma ọ bụ Ogbe ma ọ bụ Steeti",
  and: "na",
  Date: "Ụbọchị",
  Content: "Ọdịnaya",
  "TopicName is required": "Aha isiokwu achọrọ",
  "Publisher Name is required": "Aha onye mbipụta chọrọ",
  "Enter Your Feedback": "Tinye nzaghachi gị",
  HereSays: "Ebe a na-ekwu",
  Region: "Mpaghara",
  Province: "Ógbè",
  Heard: "Nụrụ",
  Shared: "Ekekọrịtara",
  Successfully: "Na-aga nke ọma",
  Explanation: "Nkọwa",
  Language: "Asụsụ",
  Jurisdiction: "Ikike",
  Attention: "Nlebara anya",
  "LANGUAGE SELECTION": "Nhọrọ asụsụ",
  ACCESS: "Nweta",
  "Terms of use": "Usoro ojiji",
  REFUSE: "JỤRỤ",
  ACCEPT: "NAbata",
  READ: "GỤỌ",
  PUBLISH: "Bipụta",
  CHAT: "Mkparịta ụka",
  "comming soon": "na-abịa ngwa ngwa",
  Other: "Ndị ọzọ",
  Remark: "kwuo",
  Suggestion: "Aro",
  Complaint: "Mkpesa",
  Manual: "Akwụkwọ ntuziaka",
  SEARCH: "Chọọ",
  SHARE: "KEWA",
  DENOUNCE: "Mkpesa",
  "This post has not been reviewed": "enyochabeghị ọkwa a",
  SUBMIT: "Nyefee",
  Topic: "Isiokwu",
  "Published BY": "E bipụtara ya",
  English: "Bekee",
  Country: "Obodo",
  City: "Obodo",
  Place: "Ebe",
  "Subject 1": "Isiokwu 1",
  "Subject 2": "Isiokwu 2",
  "Subject 3": "Isiokwu 3",
  "Reference Id": "Id ntụaka",
  "Enter Email Address": "Tinye adreesị ozi-e",
  CHOOSE: "Họrọ",
  By: "Site na",
  "End": "Njedebe",
  "Enter Your Story Content": "Tinye ọdịnaya Akụkọ gị",
  "UPLOAD IMAGES": "Bulite onyonyo",
  "Click or Drop here": "Pịa ma ọ bụ dobe ebe a",
  "Remove all images": "Wepu onyonyo niile",
  "Your post has been properly submitted": "E nyefeela akwụkwọ ozi gị nke ọma",
  "and is published under": "na-ebipụta n'okpuru",
  "Please copy this for your reference": "Biko detuo nke a maka ntụnye aka gị",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "Eferela ajụjụ gị nke ọma",
};
export const Ilocano = {
  "User Instructions": "Panangiwanwan ti Agus-usar",
  Contact: "Kontaken",
  "News or HereSays Herald": "Damag wenno DitoyKuna ti Herald",
  Disclaimer: "Disclaimer ti panaglabsing",
  "Region or Province or County or State":
    "Rehion wenno Probinsia wenno Kondado wenno Estado",
  and: "ken",
  Date: "Petsa",
  Content: "Linaon",
  "TopicName is required": "Masapul ti TopicName",
  "Publisher Name is required": "Masapul ti Nagan ti Publisher",
  "Enter Your Feedback": "Iserrek ti Feedback-mo",
  HereSays: "DitoyKunana",
  Region: "Rehion",
  Province: "Probinsia",
  Heard: "Nangngeg",
  Shared: "Nabingbingay",
  Successfully: "Naballigi",
  Explanation: "Panangilawlawag",
  Language: "Lengguahe",
  Jurisdiction: "Hurisdiksion ti Hurisdiksion",
  Attention: "Panangikaso",
  "LANGUAGE SELECTION": "PANANGPILI ITI LANGUAGE",
  ACCESS: "ACCESS",
  "Terms of use": "Kondision ti panagusar",
  REFUSE: "AGMADI",
  ACCEPT: "AWATEN",
  READ: "BASAEN",
  PUBLISH: "PUBLISH",
  CHAT: "CHAT",
  "comming soon": "umay iti mabiit",
  Other: "Sabali pay",
  Remark: "Remark",
  Suggestion: "Singasing",
  Complaint: "Reklamo",
  Manual: "Giya",
  SEARCH: "AGBIROK",
  SHARE: "IPARABUR",
  DENOUNCE: "PANANGDENONSA",
  "This post has not been reviewed": "Saan pay a narepaso daytoy a post",
  SUBMIT: "IDATAG",
  Topic: "Maad",
  "Published BY": "Naipablaak NI",
  English: "Ingles",
  Country: "Pagilian",
  City: "Siudad",
  Place: "Lugar",
  "Subject 1": "Subject 1",
  "Subject 2": "Subject 2",
  "Subject 3": "Subject 3",
  "Reference Id": "Reperensia nga Id",
  "Enter Email Address": "Isurat ti Email Address",
  CHOOSE: "AGPILI",
  By: "Babaen",
  "End": "Ti Panungpalan",
  "Enter Your Story Content": "Iserrek ti Linaon ti Estoriam",
  "UPLOAD IMAGES": "I-UPLOAD TI LADAWAN",
  "Click or Drop here": "I-click wenno I-drop ditoy",
  "Remove all images": "Ikkaten amin a ladawan",
  "Your post has been properly submitted":
    "Umiso ti pannakaisubmitir ti postmo",
  "and is published under": "ken naipablaak iti babaen ti",
  "Please copy this for your reference":
    "Pangngaasiyo ta kopiaenyo daytoy para iti reperensiayo",
  "ID:HERESAYS000": "ID:DAGITI DAYTOY000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "UMISO A NAISUBMITE TI SALUDSODMO",
};
export const Irish = {
  "User Instructions": "Treoracha Úsáideora",
  Contact: "Teagmháil",
  "News or HereSays Herald": "Nuacht nó HereSays Herald",
  Disclaimer: "Séanadh",
  "Region or Province or County or State": "Réigiún nó Cúige nó Contae nó Stát",
  and: "agus",
  Date: "Dáta",
  Content: "Ábhar",
  "TopicName is required": "Tá TopicName ag teastáil",
  "Publisher Name is required": "Tá Ainm Foilsitheoir ag teastáil",
  "Enter Your Feedback": "Cuir isteach d'Aiseolas",
  HereSays: "Seo Says",
  Region: "Réigiún",
  Province: "Cúige",
  Heard: "Chuala",
  Shared: "Roinnte",
  Successfully: "Go rathúil",
  Explanation: "Míniú",
  Language: "Teanga",
  Jurisdiction: "Dlínse",
  Attention: "Aird",
  "LANGUAGE SELECTION": "ROGHNÚ TEANGA",
  ACCESS: "ROCHTAIN",
  "Terms of use": "Téarmaí Úsáide",
  REFUSE: "DIÚLTÚ",
  ACCEPT: "GLACADH",
  READ: "LÉIGH",
  PUBLISH: "FHOILSIÚ",
  CHAT: "COMHRÁ",
  "comming soon": "ag teacht go luath",
  Other: "Eile",
  Remark: "Athmharc",
  Suggestion: "Moladh",
  Complaint: "Gearán",
  Manual: "Lámhleabhar",
  SEARCH: "CUARDACH",
  SHARE: "SHARE",
  DENOUNCE: "DENOUNC",
  "This post has not been reviewed":
    "Níl athbhreithniú déanta ar an bpostáil seo",
  SUBMIT: "ISTEACH",
  Topic: "Topaic",
  "Published BY": "Arna fhoilsiú AG",
  English: "Béarla",
  Country: "Tír",
  City: "Cathair",
  Place: "Áit",
  "Subject 1": "Ábhar 1",
  "Subject 2": "Ábhar 2",
  "Subject 3": "Ábhar 3",
  "Reference Id": "Aitheantas Tagartha",
  "Enter Email Address": "Cuir isteach Seoladh Ríomhphoist",
  CHOOSE: "CHOOSE",
  By: "Le",
  "End": "An deireadh",
  "Enter Your Story Content": "Iontráil Ábhar do Scéal",
  "UPLOAD IMAGES": "UCHTÁLA ÍOMHÁ",
  "Click or Drop here": "Cliceáil nó Buail anseo",
  "Remove all images": "Bain gach íomhá",
  "Your post has been properly submitted":
    "Cuireadh do phostáil isteach i gceart",
  "and is published under": "agus tá sé foilsithe faoi",
  "Please copy this for your reference": "Cóipeáil é seo mar do thagairt",
  "ID:HERESAYS000": "ID: ANSEO 000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "CUIREADH DO FIOSRÚCHÁN ISTEACH I gCÁS",
};
export const Javanese = {
  "User Instructions": "Pandhuan pangguna",
  Contact: "Kontak",
  "News or HereSays Herald": "News utawa HereSays Herald",
  Disclaimer: "Penafian",
  "Region or Province or County or State":
    "Wilayah utawa Provinsi utawa Kabupaten utawa Negara",
  and: "lan",
  Date: "Tanggal",
  Content: "Isi",
  "TopicName is required": "TopicName dibutuhake",
  "Publisher Name is required": "Jeneng Penerbit dibutuhake",
  "Enter Your Feedback": "Ketik Umpan Balik Panjenengan",
  HereSays: "KeneSays",
  Region: "wilayah",
  Province: "Provinsi",
  Heard: "Krungu",
  Shared: "Dibagi",
  Successfully: "kasil",
  Explanation: "Panjelasan",
  Language: "Basa",
  Jurisdiction: "Yurisdiksi",
  Attention: "manungsa waé",
  "LANGUAGE SELECTION": "PILIHAN BAHASA",
  ACCESS: "AKSES",
  "Terms of use": "Katentuan Pangginaan supados langkung",
  REFUSE: "NOLAK",
  ACCEPT: "NAMPUN",
  READ: "MACA",
  PUBLISH: "nerbitake",
  CHAT: "CHAT",
  "comming soon": "mbesuk",
  Other: "Liyane",
  Remark: "Tetembungane",
  Suggestion: "saran",
  Complaint: "sambat",
  Manual: "Manual",
  SEARCH: "GOLEKSI",
  SHARE: "BAGIKAN",
  DENOUNCE: "PANDU",
  "This post has not been reviewed": "Kiriman iki durung dideleng",
  SUBMIT: "SUBMIT",
  Topic: "Topik",
  "Published BY": "Diterbitake BY",
  English: "Inggris",
  Country: "negara",
  City: "kutha",
  Place: "Panggonan",
  "Subject 1": "Topik 1",
  "Subject 2": "Topik 2",
  "Subject 3": "Topik 3",
  "Reference Id": "Referensi Id",
  "Enter Email Address": "Ketik Alamat Email",
  CHOOSE: "PILIH",
  By: "Miturut",
  "End": "Pungkasan",
  "Enter Your Story Content": "Ketik Konten Crita Panjenengan",
  "UPLOAD IMAGES": "UNGGAH GAMBAR",
  "Click or Drop here": "Klik utawa Selehake kene",
  "Remove all images": "Mbusak kabeh gambar",
  "Your post has been properly submitted":
    "Kiriman sampeyan wis dikirim kanthi bener",
  "and is published under": "lan diterbitake ing",
  "Please copy this for your reference": "Mangga nyalin iki kanggo referensi",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "QUERY Panjenengan wis dikirim kanthi bener",
};
export const Kannada = {
  "User Instructions": "ಬಳಕೆದಾರರ ಸೂಚನೆಗಳು",
  Contact: "ಸಂಪರ್ಕಿಸಿ",
  "News or HereSays Herald": "ಸುದ್ದಿ ಅಥವಾ ಇಲ್ಲಿ ಹೇಳುತ್ತದೆ ಹೆರಾಲ್ಡ್",
  Disclaimer: "ಹಕ್ಕು ನಿರಾಕರಣೆ",
  "Region or Province or County or State":
    "ಪ್ರದೇಶ ಅಥವಾ ಪ್ರಾಂತ್ಯ ಅಥವಾ ಕೌಂಟಿ ಅಥವಾ ರಾಜ್ಯ",
  and: "ಮತ್ತು",
  Date: "ದಿನಾಂಕ",
  Content: "ವಿಷಯ",
  "TopicName is required": "TopicName ಅಗತ್ಯವಿದೆ",
  "Publisher Name is required": "ಪ್ರಕಾಶಕರ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
  "Enter Your Feedback": "ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ನಮೂದಿಸಿ",
  HereSays: "ಇಲ್ಲಿ ಹೇಳುತ್ತಾರೆ",
  Region: "ಪ್ರದೇಶ",
  Province: "ಪ್ರಾಂತ್ಯ",
  Heard: "ಕೇಳಿದೆ",
  Shared: "ಹಂಚಿಕೊಂಡಿದ್ದಾರೆ",
  Successfully: "ಯಶಸ್ವಿಯಾಗಿ",
  Explanation: "ವಿವರಣೆ",
  Language: "ಭಾಷೆ",
  Jurisdiction: "ನ್ಯಾಯವ್ಯಾಪ್ತಿ",
  Attention: "ಗಮನ",
  "LANGUAGE SELECTION": "ಭಾಷೆಯ ಆಯ್ಕೆ",
  ACCESS: "ಪ್ರವೇಶ",
  "Terms of use": "ಬಳಕೆಯ ನಿಯಮಗಳು",
  REFUSE: "ನಿರಾಕರಿಸು",
  ACCEPT: "ಒಪ್ಪಿಕೊಳ್ಳಿ",
  READ: "ಓದು",
  PUBLISH: "ಪ್ರಕಟಿಸಿ",
  CHAT: "ಚಾಟ್",
  "comming soon": "ಶೀಘ್ರದಲ್ಲೇ ಬರುತ್ತಿದೆ",
  Other: "ಇತರೆ",
  Remark: "ಟೀಕೆ",
  Suggestion: "ಸಲಹೆ",
  Complaint: "ದೂರು",
  Manual: "ಕೈಪಿಡಿ",
  SEARCH: "ಹುಡುಕಿ KANNADA",
  SHARE: "ಶೇರ್ ಮಾಡಿ",
  DENOUNCE: "ಖಂಡಿಸು",
  "This post has not been reviewed": "ಈ ಪೋಸ್ಟ್ ಅನ್ನು ಪರಿಶೀಲಿಸಲಾಗಿಲ್ಲ",
  SUBMIT: "ಸಲ್ಲಿಸು",
  Topic: "ವಿಷಯ",
  "Published BY": "ಪ್ರಕಟಿಸಿದವರು",
  English: "ಆಂಗ್ಲ",
  Country: "ದೇಶ",
  City: "ನಗರ",
  Place: "ಸ್ಥಳ",
  "Subject 1": "ವಿಷಯ 1",
  "Subject 2": "ವಿಷಯ 2",
  "Subject 3": "ವಿಷಯ 3",
  "Reference Id": "ಉಲ್ಲೇಖ ಐಡಿ",
  "Enter Email Address": "ಇಮೇಲ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
  CHOOSE: "ಆರಿಸಿ",
  By: "ಮೂಲಕ",
  "End": "ಅಂತ್ಯ",
  "Enter Your Story Content": "ನಿಮ್ಮ ಕಥೆಯ ವಿಷಯವನ್ನು ನಮೂದಿಸಿ",
  "UPLOAD IMAGES": "ಚಿತ್ರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
  "Click or Drop here": "ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ ಅಥವಾ ಡ್ರಾಪ್ ಮಾಡಿ",
  "Remove all images": "ಎಲ್ಲಾ ಚಿತ್ರಗಳನ್ನು ತೆಗೆದುಹಾಕಿ",
  "Your post has been properly submitted":
    "ನಿಮ್ಮ ಪೋಸ್ಟ್ ಅನ್ನು ಸರಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ",
  "and is published under": "ಮತ್ತು ಅಡಿಯಲ್ಲಿ ಪ್ರಕಟಿಸಲಾಗಿದೆ",
  "Please copy this for your reference":
    "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಉಲ್ಲೇಖಕ್ಕಾಗಿ ಇದನ್ನು ನಕಲಿಸಿ",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ನಿಮ್ಮ ಪ್ರಶ್ನೆಯನ್ನು ಸರಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ",
};
export const Kazakh = {
  "User Instructions": "Пайдаланушы нұсқаулары",
  Contact: "Байланыс",
  "News or HereSays Herald": "Жаңалықтар немесе HereSays Herald",
  Disclaimer: "Жауапкершіліктен бас тарту",
  "Region or Province or County or State":
    "Аймақ немесе облыс немесе округ немесе штат",
  and: "және",
  Date: "Күн",
  Content: "Мазмұны",
  "TopicName is required": "Тақырып атауы қажет",
  "Publisher Name is required": "Жариялаушының аты қажет",
  "Enter Your Feedback": "Пікіріңізді енгізіңіз",
  HereSays: "Міне дейді",
  Region: "Аймақ",
  Province: "Облыс",
  Heard: "Естіді",
  Shared: "Ортақ",
  Successfully: "Сәтті",
  Explanation: "Түсіндіру",
  Language: "Тіл",
  Jurisdiction: "Юрисдикция",
  Attention: "Назар аударыңыз",
  "LANGUAGE SELECTION": "ТІЛДІ ТАҢДАУ",
  ACCESS: "ҚОСУ",
  "Terms of use": "Қолдану ережелері",
  REFUSE: "БАС ТАРТУ",
  ACCEPT: "ҚАБЫЛДАУ",
  READ: "ОҚУ",
  PUBLISH: "ЖАРИЯЛАУ",
  CHAT: "ЧАТ",
  "comming soon": "Жуырда",
  Other: "Басқа",
  Remark: "Ескерту",
  Suggestion: "Ұсыныс",
  Complaint: "Шағым",
  Manual: "Нұсқаулық",
  SEARCH: "ІЗДЕУ",
  SHARE: "БӨЛІСУ",
  DENOUNCE: "АЙТАЛАУ",
  "This post has not been reviewed": "Бұл пост қаралмаған",
  SUBMIT: "ЖІБЕРУ",
  Topic: "Тақырып",
  "Published BY": "Жариялаған",
  English: "Ағылшын",
  Country: "Ел",
  City: "Қала",
  Place: "Орын",
  "Subject 1": "1-тақырып",
  "Subject 2": "Тақырып 2",
  "Subject 3": "3-тақырып",
  "Reference Id": "Анықтама идентификаторы",
  "Enter Email Address": "Электрондық пошта мекенжайын енгізіңіз",
  CHOOSE: "ТАҢДАУ",
  By: "Авторы",
  "End": "Соңы",
  "Enter Your Story Content": "Story мазмұнын енгізіңіз",
  "UPLOAD IMAGES": "СУРЕТТЕРДІ ЖҮКТЕП АЛУ",
  "Click or Drop here": "Мұнда басыңыз немесе тастаңыз",
  "Remove all images": "Барлық кескіндерді жойыңыз",
  "Your post has been properly submitted": "Постыңыз дұрыс жіберілді",
  "and is published under": "және астында жарияланады",
  "Please copy this for your reference": "Анықтама үшін осыны көшіріп алыңыз",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "СҰРАУЫҢЫЗ ДҰРЫС ЖІБЕРІЛДІ",
};
export const Khmer = {
  "User Instructions": "ការណែនាំអ្នកប្រើប្រាស់",
  Contact: "ទំនាក់ទំនង",
  "News or HereSays Herald": "ព័ត៌មាន ឬ HereSays Herald",
  Disclaimer: "ការបដិសេធ",
  "Region or Province or County or State": "តំបន់ ឬខេត្ត ឬខេត្ត ឬរដ្ឋ",
  and: "និង",
  Date: "កាលបរិច្ឆេទ",
  Content: "មាតិកា",
  "TopicName is required": "ឈ្មោះប្រធានបទត្រូវបានទាមទារ",
  "Publisher Name is required": "ឈ្មោះអ្នកបោះពុម្ពត្រូវបានទាមទារ",
  "Enter Your Feedback": "បញ្ចូលមតិកែលម្អរបស់អ្នក។",
  HereSays: "ទីនេះ",
  Region: "តំបន់",
  Province: "ខេត្ត",
  Heard: "ឮ",
  Shared: "បានចែករំលែក",
  Successfully: "ដោយជោគជ័យ",
  Explanation: "ការពន្យល់",
  Language: "ភាសា",
  Jurisdiction: "យុត្តាធិការ",
  Attention: "ការយកចិត្តទុកដាក់",
  "LANGUAGE SELECTION": "ការជ្រើសរើសភាសា",
  ACCESS: "ការចូលប្រើ",
  "Terms of use": "ល័ក្ខខ័ណ្ឌនៃការប្រើប្រាស់",
  REFUSE: "បដិសេធ",
  ACCEPT: "ទទួលយក",
  READ: "អាន",
  PUBLISH: "បោះពុម្ពផ្សាយ",
  CHAT: "ជជែក",
  "comming soon": "មកដល់ឆាប់ៗនេះ",
  Other: "ផ្សេងទៀត",
  Remark: "ចំណាំ",
  Suggestion: "សំណូមពរ",
  Complaint: "ពាក្យបណ្តឹង",
  Manual: "ហត្ថកម្ម",
  SEARCH: "ស្វែងរក",
  SHARE: "SHARE",
  DENOUNCE: "បដិសេធ",
  "This post has not been reviewed": "ប្រកាសនេះមិនត្រូវបានពិនិត្យទេ។",
  SUBMIT: "បញ្ជូន",
  Topic: "ប្រធានបទ",
  "Published BY": "ចេញផ្សាយ​ដោយ",
  English: "ភាសាអង់គ្លេស",
  Country: "ប្រទេស",
  City: "ទីក្រុង",
  Place: "កន្លែង",
  "Subject 1": "ប្រធានបទ ១",
  "Subject 2": "ប្រធានបទ ២",
  "Subject 3": "ប្រធានបទ ៣",
  "Reference Id": "លេខសម្គាល់ឯកសារយោង",
  "Enter Email Address": "បញ្ចូលអាសយដ្ឋានអ៊ីមែល",
  CHOOSE: "ជ្រើសរើស",
  By: "ដោយ",
  "End": "ចប់",
  "Enter Your Story Content": "បញ្ចូលខ្លឹមសាររឿងរបស់អ្នក។",
  "UPLOAD IMAGES": "បង្ហោះរូបភាព",
  "Click or Drop here": "ចុច ឬទម្លាក់នៅទីនេះ",
  "Remove all images": "លុបរូបភាពទាំងអស់។",
  "Your post has been properly submitted":
    "ប្រកាសរបស់អ្នកត្រូវបានដាក់ជូនយ៉ាងត្រឹមត្រូវ។",
  "and is published under": "ហើយត្រូវបានបោះពុម្ពនៅក្រោម",
  "Please copy this for your reference": "សូមចម្លងវាទុកជាឯកសារយោងរបស់អ្នក។",
  "ID:HERESAYS000": "លេខសម្គាល់៖ HRESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "សំណួររបស់អ្នកត្រូវបានដាក់ជូនយ៉ាងត្រឹមត្រូវ។",
};
export const Kinyarwanda = {
  "User Instructions": "Amabwiriza y'abakoresha",
  Contact: "Twandikire",
  "News or HereSays Herald": "Amakuru cyangwa Hano Bavuga Herald",
  Disclaimer: "Inshingano",
  "Region or Province or County or State":
    "Intara cyangwa Intara cyangwa Intara cyangwa Leta",
  and: "na",
  Date: "Itariki",
  Content: "Ibirimo",
  "TopicName is required": "TopicName irakenewe",
  "Publisher Name is required": "Izina ry'umwanditsi rirakenewe",
  "Enter Your Feedback": "Injira Igitekerezo cyawe",
  HereSays: "Hano",
  Region: "Intara",
  Province: "Intara",
  Heard: "Yumvise",
  Shared: "Basangiye",
  Successfully: "Intsinzi",
  Explanation: "Ibisobanuro",
  Language: "Ururimi",
  Jurisdiction: "Ububasha",
  Attention: "Icyitonderwa",
  "LANGUAGE SELECTION": "GUHITAMO URURIMI",
  ACCESS: "ICYEMEZO",
  "Terms of use": "Amategeko yo gukoresha",
  REFUSE: "WANGA",
  ACCEPT: "Emera",
  READ: "SOMA",
  PUBLISH: "ITANGAZO",
  CHAT: "CHAT",
  "comming soon": "gutangira vuba",
  Other: "Ibindi",
  Remark: "Ongera wibuke",
  Suggestion: "Igitekerezo",
  Complaint: "Ikirego",
  Manual: "Igitabo",
  SEARCH: "SHAKA",
  SHARE: "SHARE",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Iyi nyandiko ntabwo yasubiwemo",
  SUBMIT: "SUBMIT",
  Topic: "Ingingo",
  "Published BY": "Byanditswe na BY",
  English: "Icyongereza",
  Country: "Igihugu",
  City: "Umujyi",
  Place: "Ikibanza",
  "Subject 1": "Ingingo ya 1",
  "Subject 2": "Ingingo ya 2",
  "Subject 3": "Ingingo ya 3",
  "Reference Id": "Reba Id",
  "Enter Email Address": "Injira imeri imeri",
  CHOOSE: "HITAMO",
  By: "Na",
  "End": "Iherezo",
  "Enter Your Story Content": "Injira Ibirimo",
  "UPLOAD IMAGES": "SHAKA AMASHUSHO",
  "Click or Drop here": "Kanda cyangwa Kureka hano",
  "Remove all images": "Kuraho amashusho yose",
  "Your post has been properly submitted": "Inyandiko yawe yatanzwe neza",
  "and is published under": "kandi bigatangazwa munsi",
  "Please copy this for your reference":
    "Nyamuneka wandukure ibi kugirango ubone ibisobanuro",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "IKIBAZO CYANYU CYATANZWE CYANE",
};
export const Konkani = {
  "User Instructions": "वापरप्याचीं सुचोवण्यो",
  Contact: "संपर्क",
  "News or HereSays Herald": "खबरो वा हांगा म्हणटा हेराल्ड",
  Disclaimer: "अस्वीकृती करप",
  "Region or Province or County or State":
    "प्रदेश वा प्रांत वा काउंटी वा राज्य",
  and: "आनी",
  Date: "तारीख",
  Content: "सामुग्री",
  "TopicName is required": "TopicName गरजेचें आसा",
  "Publisher Name is required": "प्रकाशकाचें नांव गरजेचें",
  "Enter Your Feedback": "तुमचो प्रतिसाद दाखयात",
  HereSays: "हांगा म्हणटा",
  Region: "प्रांत",
  Province: "प्रांत",
  Heard: "आयकप",
  Shared: "वांटून घेतिल्लें",
  Successfully: "येसस्वीपणान",
  Explanation: "स्पश्टीकरण",
  Language: "भास",
  Jurisdiction: "अधिकारक्षेत्र",
  Attention: "लक्ष दिवप",
  "LANGUAGE SELECTION": "भाशेची निवड करप",
  ACCESS: "प्रवेश",
  "Terms of use": "वापराच्यो अटी",
  REFUSE: "न्हयकार",
  ACCEPT: "स्विकार",
  READ: "वाचप",
  PUBLISH: "PUBLISH करप",
  CHAT: "चॅट",
  "comming soon": "बेगीन येतलें",
  Other: "हेर",
  Remark: "टिप्पणी करात",
  Suggestion: "सुचोवणी",
  Complaint: "कागाळ",
  Manual: "मॅन्युअल",
  SEARCH: "सोद",
  SHARE: "वांटो",
  DENOUNCE: "निंदा करप",
  "This post has not been reviewed": "ह्या पोस्टाचो नियाळ जावंक ना",
  SUBMIT: "सुवादीन करप",
  Topic: "विशय",
  "Published BY": "प्रकाशीत केल्लें BY",
  English: "इंग्लीश",
  Country: "देश",
  City: "शार",
  Place: "सुवात",
  "Subject 1": "विशय 1",
  "Subject 2": "विशय 2",
  "Subject 3": "विशय 3",
  "Reference Id": "संदर्भ आयडी",
  "Enter Email Address": "ईमेल पत्तो दाखयात",
  CHOOSE: "वेंचचें",
  By: "वरवीं",
  "End": "द एन्ड",
  "Enter Your Story Content": "तुमची कथा आशय दाखयात",
  "UPLOAD IMAGES": "IMAGES अपलोड करात",
  "Click or Drop here": "हांगा क्लिक करात वा ड्रॉप करात",
  "Remove all images": "सगळ्यो प्रतिमा काडून उडोवच्यो",
  "Your post has been properly submitted": "तुमचें पोस्ट सारकें सादर जालां",
  "and is published under": "आनी ह्या खाला उजवाडाक येता",
  "Please copy this for your reference":
    "उपकार करून तुमच्या संदर्भा खातीर हाची कॉपी करात",
  "ID:HERESAYS000": "आयडी:अतिशय000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "तुमची क्वेरी योग्य रितीन सादर केल्या",
};
export const Krio = {
  "User Instructions": "Instrɔkshɔn dɛn fɔ Yuz",
  Contact: "Kɔl",
  "News or HereSays Herald": "Nyus ɔ Na yaSe Herald",
  Disclaimer: "Disklɛmayshɔn",
  "Region or Province or County or State": "Rijyɔn ɔ Prɔvins ɔ Kaonti ɔ Stet",
  and: "ɛn",
  Date: "Det",
  Content: "Satisfay",
  "TopicName is required": "TopicName na di tin we yu nid",
  "Publisher Name is required": "Pɔblisha in Nem nid fɔ de",
  "Enter Your Feedback": "Ɛntay Yu Fidbak",
  HereSays: "Na yaSe",
  Region: "Eria",
  Province: "Distrikt",
  Heard: "Dɔn yɛri",
  Shared: "Dɛn kin sheb am",
  Successfully: "Saksesful wan",
  Explanation: "Fɔ ɛksplen",
  Language: "Langwej",
  Jurisdiction: "Di say we dɛn de jɔj",
  Attention: "Atɛnshɔn",
  "LANGUAGE SELECTION": "LANGUAGE SƐLƐKSHƆN",
  ACCESS: "FƐN",
  "Terms of use": "Tɛm dɛn fɔ yuz am",
  REFUSE: "NƆ GRI",
  ACCEPT: "GRI",
  READ: "RID",
  PUBLISH: "PƆBLƐSH",
  CHAT: "CHAT",
  "comming soon": "we de kam jisnɔ",
  Other: "Ɔda",
  Remark: "Rimak",
  Suggestion: "Advays",
  Complaint: "Kɔmplen",
  Manual: "Buk",
  SEARCH: "LUK FƆ",
  SHARE: "SHƐB",
  DENOUNCE: "DƐNƆNS",
  "This post has not been reviewed": "Dis post nɔ bin dɔn rivyu",
  SUBMIT: "RƐDI FƆ DE ƆNDA",
  Topic: "Tɔpik",
  "Published BY": "Na BY we dɛn pul am",
  English: "Inglish",
  Country: "Kɔntri",
  City: "Siti",
  Place: "Ples",
  "Subject 1": "Sɔbjɛkt 1",
  "Subject 2": "Sɔbjɛkt 2. Di wan we de tɔk bɔt",
  "Subject 3": "Sɔbjɛkt 3",
  "Reference Id": "Rifrɛns Id",
  "Enter Email Address": "Ɛntay Imel Adrɛs",
  CHOOSE: "PIK",
  By: "Bay we",
  "End": "Di Ɛnd",
  "Enter Your Story Content": "Ɛntay Yu Stori Kɔntinɛnt",
  "UPLOAD IMAGES": "UPLOAD IMƐJ DƐN",
  "Click or Drop here": "Klik ɔ Drɔp ya",
  "Remove all images": "Rimov ɔl di pikchɔ dɛn",
  "Your post has been properly submitted": "Dɛn dɔn sɛn yu post fayn fayn wan",
  "and is published under": "ɛn dɛn de pablish am ɔnda",
  "Please copy this for your reference": "Duya kɔpi dis fɔ yu rɛfrɛns",
  "ID:HERESAYS000": "ID:DεN DεN DεN DεN DεN",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "DƐN DƆN SƆBMIT YU KWƐRI FƆ DU",
};
export const Kurdish = {
  "User Instructions": "Telîmatên Bikarhêner",
  Contact: "Têkelî",
  "News or HereSays Herald": "Nûçe an HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Herêm an parêzgeh an wîlayet an eyalet",
  and: "û",
  Date: "Rojek",
  Content: "Dilşad",
  "TopicName is required": "Navê Mijar pêwîst e",
  "Publisher Name is required": "Navê Weşanger pêwîst e",
  "Enter Your Feedback": "Bersivên xwe binivîse",
  HereSays: "HereSays",
  Region: "Herêm",
  Province: "Herêm",
  Heard: "bihîst",
  Shared: "Shared",
  Successfully: "Serkeftin",
  Explanation: "Daxûyanî",
  Language: "Ziman",
  Jurisdiction: "Dadî",
  Attention: "Baldarî",
  "LANGUAGE SELECTION": "HILBIJARTINA ZIMAN",
  ACCESS: "NAVKETIN",
  "Terms of use": "Mercên bikaranînê",
  REFUSE: "REFZKIRIN",
  ACCEPT: "BAWERANÎN",
  READ: "XWENDIN",
  PUBLISH: "WEŞANDIN",
  CHAT: "GALGALKIRIN",
  "comming soon": "van nêzîkan",
  Other: "Yên din",
  Remark: "Bingotin",
  Suggestion: "Pêşnîyar",
  Complaint: "Gilî",
  Manual: "Destî",
  SEARCH: "GERR",
  SHARE: "PAR",
  DENOUNCE: "ÎXBARKIRIN",
  "This post has not been reviewed": "Ev post nehatiye nirxandin",
  SUBMIT: "NERMIJÎN",
  Topic: "Mijar",
  "Published BY": "Hat weşandin BY",
  English: "Îngilîzî",
  Country: "Welat",
  City: "Bajar",
  Place: "Cîh",
  "Subject 1": "Mijar 1",
  "Subject 2": "Mijar 2",
  "Subject 3": "Mijara 3",
  "Reference Id": "Nasnameya Referansê",
  "Enter Email Address": "Navnîşana E-nameyê binivîse",
  CHOOSE: "HELBIJARTIN",
  By: "Ji",
  "End": "Dawî",
  "Enter Your Story Content": "Naveroka Çîroka xwe binivîse",
  "UPLOAD IMAGES": "WÊNE BIKIN",
  "Click or Drop here": "Li vir bikirtînin an dakêşin",
  "Remove all images": "Hemî wêneyan jêbirin",
  "Your post has been properly submitted": "Posta we bi rêkûpêk hatiye şandin",
  "and is published under": "û li jêr tê weşandin",
  "Please copy this for your reference":
    "Ji kerema xwe vê ji bo referansa xwe kopî bikin",
  "ID:HERESAYS000": "Nasname:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "PIRSGIRÊKA WE BI RÊBAZ HATÛNIRIN",
};
export const Kurdish_Sorani = {
  "User Instructions": "ڕێنمایی بەکارهێنەر",
  Contact: "پەیوەندی",
  "News or HereSays Herald": "هەواڵ یان لێرەدەڵێت هیرالد",
  Disclaimer: "ڕەتکردنەوەی بەرپرسیاریەتی",
  "Region or Province or County or State":
    "هەرێم یان پارێزگا یان پارێزگا یان ویلایەت",
  and: "و",
  Date: "ڕێکەوت",
  Content: "ناوەڕۆک",
  "TopicName is required": "TopicName پێویستە",
  "Publisher Name is required": "ناوی بڵاوکەرەوە پێویستە",
  "Enter Your Feedback": "فیدباکەکەت بنووسە",
  HereSays: "لێرەدەڵێت",
  Region: "هەرێم",
  Province: "پارێزگا",
  Heard: "بیستی",
  Shared: "هاوبەش",
  Successfully: "بە سەرکەوتوویی",
  Explanation: "ڕوونکردنەوە",
  Language: "زمان",
  Jurisdiction: "دەسەڵاتی دادوەری",
  Attention: "سەرنج",
  "LANGUAGE SELECTION": "هەڵبژاردنی زمان",
  ACCESS: "دەسگەیشتن",
  "Terms of use": "مەرجەکانی بەکارهێنان",
  REFUSE: "ڕەتکردنەوە",
  ACCEPT: "پەسەندکردن",
  READ: "خوێندنەوە",
  PUBLISH: "بڵاوکردنەوە",
  CHAT: "چات",
  "comming soon": "بەمزووانە",
  Other: "ئی تر",
  Remark: "تێبینی",
  Suggestion: "پێشنیار",
  Complaint: "سکاڵا",
  Manual: "بەدەستی",
  SEARCH: "گەڕان",
  SHARE: "هاوبەشکردن",
  DENOUNCE: "ئیدانەکردن",
  "This post has not been reviewed": "ئەم پۆستە پێداچوونەوەی بۆ نەکراوە",
  SUBMIT: "پێشکەشکردن",
  Topic: "بابەت",
  "Published BY": "بڵاوکراوەتەوە لە لایەن",
  English: "ئینگلیزی",
  Country: "وڵات",
  City: "شار",
  Place: "شوێن",
  "Subject 1": "بابەت 1",
  "Subject 2": "بابەت ٢",
  "Subject 3": "بابەت ٣",
  "Reference Id": "ناسنامەی ئاماژە",
  "Enter Email Address": "ئیمەیڵ داخڵ بکە",
  CHOOSE: "هەڵبژاردن",
  By: "تا",
  "End": "کۆتایی",
  "Enter Your Story Content": "ناوەڕۆکی چیرۆکەکەت دابنێ",
  "UPLOAD IMAGES": "وێنەکان باربکە",
  "Click or Drop here": "لێرە کلیک بکە یان دابەزێنە",
  "Remove all images": "هەموو وێنەکان لاببە",
  "Your post has been properly submitted": "پۆستەکەت بە باشی پێشکەش کراوە",
  "and is published under": "و لە ژێر",
  "Please copy this for your reference":
    "تکایە ئەمە کۆپی بکە بۆ ئەوەی ئاماژەت پێبدەیت",
  "ID:HERESAYS000": "ناسنامە:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "پرسیارەکەت بە باشی پێشکەش کراوە",
};
export const Kyrgyz = {
  "User Instructions": "Колдонуучунун нускамалары",
  Contact: "Байланыш",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Жоопкерчиликтен баш тартуу",
  "Region or Province or County or State": "Район же облус же округ же штат",
  and: "жана",
  Date: "Дата",
  Content: "Мазмун",
  "TopicName is required": "Тема аты талап кылынат",
  "Publisher Name is required": "Жарыялоочунун аты талап кылынат",
  "Enter Your Feedback": "Пикириңизди киргизиңиз",
  HereSays: "Мына дейт",
  Region: "Регион",
  Province: "Провинция",
  Heard: "Укту",
  Shared: "Бөлүшүлгөн",
  Successfully: "ийгиликтүү",
  Explanation: "Түшүндүрмө",
  Language: "Тил",
  Jurisdiction: "Юрисдикция",
  Attention: "Көңүл буруңуз",
  "LANGUAGE SELECTION": "ТИЛ ТАНДОО",
  ACCESS: "ACCESS",
  "Terms of use": "Пайдалануу шарттары",
  REFUSE: "БАШТАЛУУ",
  ACCEPT: "КАБЫЛ АЛ",
  READ: "ОКУ",
  PUBLISH: "ЖАРЫЯЛА",
  CHAT: "ЧАТ",
  "comming soon": "жакында",
  Other: "Башка",
  Remark: "Эскертүү",
  Suggestion: "Сунуш",
  Complaint: "Арыз",
  Manual: "Кол",
  SEARCH: "ИЗДӨӨ",
  SHARE: "БӨЛҮШҮҮ",
  DENOUNCE: "АЙТКОО",
  "This post has not been reviewed": "Бул пост карала элек",
  SUBMIT: "ТАПШЫРУУ",
  Topic: "Тема",
  "Published BY": "BY тарабынан жарыяланган",
  English: "Англисче",
  Country: "Өлкө",
  City: "City",
  Place: "Place",
  "Subject 1": "Тема 1",
  "Subject 2": "Тема 2",
  "Subject 3": "Тема 3",
  "Reference Id": "Шилтеме ID",
  "Enter Email Address": "Электрондук почта дарегин киргизиңиз",
  CHOOSE: "ТАНДОО",
  By: "By",
  "End": "Аягы",
  "Enter Your Story Content": "Окуяңыздын мазмунун киргизиңиз",
  "UPLOAD IMAGES": "СҮРӨТТӨРДҮ ЖҮКТӨӨ",
  "Click or Drop here": "Бул жерди басыңыз же таштаңыз",
  "Remove all images": "Бардык сүрөттөрдү алып салуу",
  "Your post has been properly submitted": "Постуңуз туура тапшырылды",
  "and is published under": "жана астында жарыяланган",
  "Please copy this for your reference": "Сураныч, шилтеме үчүн муну көчүрүңүз",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "СУРООҢУЗ ТИЙИШИ ТАПШЫРЫЛДЫ",
};
export const Lao = {
  "User Instructions": "ຄໍາແນະນໍາຂອງຜູ້ໃຊ້",
  Contact: "ຕິດຕໍ່",
  "News or HereSays Herald": "ຂ່າວ ຫຼື HereSays Herald",
  Disclaimer: "ປະຕິເສດຄວາມຮັບຜິດຊອບ",
  "Region or Province or County or State": "ເຂດ ຫຼື ແຂວງ ຫຼື ເຂດ ຫຼື ລັດ",
  and: "ແລະ",
  Date: "ວັນທີ",
  Content: "ເນື້ອໃນ",
  "TopicName is required": "ຕ້ອງການຊື່ຫົວຂໍ້",
  "Publisher Name is required": "ຕ້ອງລະບຸຊື່ຜູ້ເຜີຍແຜ່",
  "Enter Your Feedback": "ໃສ່ ຄຳ ຕິຊົມຂອງເຈົ້າ",
  HereSays: "HereSys",
  Region: "ພາກພື້ນ",
  Province: "ແຂວງ",
  Heard: "ໄດ້ຍິນ",
  Shared: "ແບ່ງປັນ",
  Successfully: "ສຳເລັດແລ້ວ",
  Explanation: "ຄໍາອະທິບາຍ",
  Language: "ພາສາ",
  Jurisdiction: "ອຳນາດການປົກຄອງ",
  Attention: "ເອົາໃຈໃສ່",
  "LANGUAGE SELECTION": "ການເລືອກພາສາ",
  ACCESS: "ການເຂົ້າເຖິງ",
  "Terms of use": "ເງື່ອນໄຂການນໍາໃຊ້",
  REFUSE: "ປະຕິເສດ",
  ACCEPT: "ຍອມ​ຮັບ",
  READ: "ອ່ານ",
  PUBLISH: "ເຜີຍແຜ່",
  CHAT: "ສົນທະນາ",
  "comming soon": "ໄວໆ​ນີ້",
  Other: "ອື່ນໆ",
  Remark: "ຂໍ້ສັງເກດ",
  Suggestion: "ຄຳແນະນຳ",
  Complaint: "ຮ້ອງທຸກ",
  Manual: "ຄູ່ມື",
  SEARCH: "ຄົ້ນຫາ",
  SHARE: "ແບ່ງປັນ",
  DENOUNCE: "ປະນາມ",
  "This post has not been reviewed": "ໂພສນີ້ບໍ່ໄດ້ຖືກກວດສອບ",
  SUBMIT: "ສົ່ງ",
  Topic: "ຫົວຂໍ້",
  "Published BY": "ຈັດພີມມາໂດຍ",
  English: "ພາສາອັງກິດ",
  Country: "ປະເທດ",
  City: "ເມືອງ",
  Place: "ສະຖານທີ່",
  "Subject 1": "ວິຊາ 1",
  "Subject 2": "ວິຊາ 2",
  "Subject 3": "ວິຊາ 3",
  "Reference Id": "ໝາຍເລກອ້າງອີງ",
  "Enter Email Address": "ໃສ່ທີ່ຢູ່ອີເມວ",
  CHOOSE: "ເລືອກ",
  By: "ໂດຍ",
  "End": "ຈົບ",
  "Enter Your Story Content": "ໃສ່ເນື້ອໃນເລື່ອງຂອງທ່ານ",
  "UPLOAD IMAGES": "ອັບໂຫຼດຮູບ",
  "Click or Drop here": "ກົດ ຫຼື ວາງທີ່ນີ້",
  "Remove all images": "ລຶບຮູບທັງໝົດອອກ",
  "Your post has been properly submitted": "ໂພສຂອງເຈົ້າຖືກສົ່ງຢ່າງຖືກຕ້ອງ",
  "and is published under": "ແລະ​ຖືກ​ຈັດ​ພີມ​ມາ​ພາຍ​ໃຕ້​ການ​",
  "Please copy this for your reference":
    "ກະລຸນາສຳເນົາອັນນີ້ເພື່ອອ້າງອີງຂອງທ່ານ",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ຄໍາຖາມຂອງທ່ານໄດ້ຖືກສົ່ງຢ່າງຖືກຕ້ອງ",
};
export const Latvian = {
  "User Instructions": "Lietotāja instrukcijas",
  Contact: "Sazināties",
  "News or HereSays Herald": "Ziņas vai HereSays Herald",
  Disclaimer: "Atruna",
  "Region or Province or County or State":
    "Reģions vai province vai apgabals vai valsts",
  and: "un",
  Date: "Datums",
  Content: "Saturs",
  "TopicName is required": "Obligāti jānorāda tēmas nosaukums",
  "Publisher Name is required": "Jānorāda izdevēja vārds",
  "Enter Your Feedback": "Ievadiet savu atsauksmi",
  HereSays: "Šeit saka",
  Region: "Novads",
  Province: "Provincē",
  Heard: "Dzirdēts",
  Shared: "Dalīts",
  Successfully: "Veiksmīgi",
  Explanation: "Paskaidrojums",
  Language: "Valoda",
  Jurisdiction: "Jurisdikcija",
  Attention: "Uzmanību",
  "LANGUAGE SELECTION": "VALODAS IZVĒLE",
  ACCESS: "PIEKĻUVE",
  "Terms of use": "Lietošanas noteikumi",
  REFUSE: "ATTEIKTIES",
  ACCEPT: "AKCEPT",
  READ: "LASĪT",
  PUBLISH: "PUBLICĒT",
  CHAT: "ČAT",
  "comming soon": "drīzumā",
  Other: "Cits",
  Remark: "Piezīme",
  Suggestion: "Ieteikums",
  Complaint: "Sūdzība",
  Manual: "Rokasgrāmata",
  SEARCH: "MEKLĒT",
  SHARE: "DALĪTIES",
  DENOUNCE: "DENONSĒT",
  "This post has not been reviewed": "Šī ziņa nav pārskatīta",
  SUBMIT: "IESNIEGT",
  Topic: "Temats",
  "Published BY": "Publicēja:",
  English: "Angļu",
  Country: "Valsts",
  City: "Pilsēta",
  Place: "Vieta",
  "Subject 1": "1. priekšmets",
  "Subject 2": "2. priekšmets",
  "Subject 3": "3. priekšmets",
  "Reference Id": "Atsauces ID",
  "Enter Email Address": "Ievadiet e-pasta adresi",
  CHOOSE: "IZVĒLIES",
  By: "Autors",
  "End": "Beigas",
  "Enter Your Story Content": "Ievadiet sava stāsta saturu",
  "UPLOAD IMAGES": "AUGšupielādējiet attēlus",
  "Click or Drop here": "Noklikšķiniet vai nometiet šeit",
  "Remove all images": "Noņemiet visus attēlus",
  "Your post has been properly submitted": "Jūsu ziņa ir pareizi iesniegta",
  "and is published under": "un ir publicēts zem",
  "Please copy this for your reference": "Lūdzu, nokopējiet to savai uzziņai",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "JŪSU JAUTĀJUMS IR PAREIZI IESNIEGTS",
};
export const Lingala = {
  "User Instructions": "Malako ya Mosaleli",
  Contact: "Kokutana",
  "News or HereSays Herald": "Sango to AwaElobi Herald",
  Disclaimer: "Koloba na likambo te",
  "Region or Province or County or State": "Etuka to Etuka to Etuka to Etuka",
  and: "mpe",
  Date: "Dati",
  Content: "Makambo eza na kati",
  "TopicName is required": "TopicName esengeli",
  "Publisher Name is required": "Esengeli Kombo ya Mosakoli",
  "Enter Your Feedback": "Tyá makanisi na yo",
  HereSays: "AwaElobi",
  Region: "Etuka",
  Province: "Provense",
  Heard: "Koyoka",
  Shared: "Bakabolaka yango",
  Successfully: "Na elonga",
  Explanation: "Ndimbola",
  Language: "Lokota",
  Jurisdiction: "Bokonzi ya bokonzi",
  Attention: "Likebi",
  "LANGUAGE SELECTION": "PONA MONOKO",
  ACCESS: "KOKOTA",
  "Terms of use": "Mibeko ya kosalela",
  REFUSE: "KOBOYA",
  ACCEPT: "KONDIMA",
  READ: "KOTANGA",
  PUBLISH: "PUBLIC LIBOSO",
  CHAT: "KOSOLOLA",
  "comming soon": "ekoya kala mingi te",
  Other: "Mosusu",
  Remark: "Liloba ya koloba",
  Suggestion: "Likanisi",
  Complaint: "Komilela",
  Manual: "Mokanda",
  SEARCH: "KOLUKA",
  SHARE: "KOKABOLA",
  DENOUNCE: "KO DÉNONCE",
  "This post has not been reviewed": "Post oyo etalisami te",
  SUBMIT: "KOTINDA",
  Topic: "Moto ya likambo",
  "Published BY": "Ebimisami na BY",
  English: "Lingelesi",
  Country: "Mboka",
  City: "Engumba",
  Place: "Esika",
  "Subject 1": "Likambo ya ntina 1",
  "Subject 2": "Likambo ya ntina 2",
  "Subject 3": "Likambo ya ntina 3",
  "Reference Id": "Id ya botalisi",
  "Enter Email Address": "Tyá Adrɛsi ya Email",
  CHOOSE: "KOPONA",
  By: "Pene",
  "End": "Nsuka",
  "Enter Your Story Content": "Tyá Makambo oyo ezali na lisolo na yo",
  "UPLOAD IMAGES": "TÉCHARGER BA IMAGES",
  "Click or Drop here": "Finá to Bwaka awa",
  "Remove all images": "Longola bililingi nyonso",
  "Your post has been properly submitted": "Poste na yo esili kotindama malamu",
  "and is published under": "mpe ebimisami na nse ya",
  "Please copy this for your reference": "Svp copier oyo pona référence na yo",
  "ID:HERESAYS000": "ID:MAKAMBO YA MAKASI000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "QUERY NA YO ESIMBAMI BIEN",
};
export const Lithuanian = {
  "User Instructions": "Vartotojo instrukcijos",
  Contact: "kontaktas",
  "News or HereSays Herald": "Naujienos arba HereSays Herald",
  Disclaimer: "Atsisakymas",
  "Region or Province or County or State":
    "Regionas arba provincija, apskritis arba valstija",
  and: "ir",
  Date: "Data",
  Content: "Turinys",
  "TopicName is required": "Būtina nurodyti temos pavadinimą",
  "Publisher Name is required": "Reikalingas leidėjo vardas",
  "Enter Your Feedback": "Įveskite savo atsiliepimą",
  HereSays: "Čia Sako",
  Region: "Regionas",
  Province: "Provincija",
  Heard: "Išgirdo",
  Shared: "Bendrinama",
  Successfully: "Sėkmingai",
  Explanation: "Paaiškinimas",
  Language: "Kalba",
  Jurisdiction: "Jurisdikcija",
  Attention: "Dėmesio",
  "LANGUAGE SELECTION": "KALBOS PASIRINKIMAS",
  ACCESS: "PRIEIGOS",
  "Terms of use": "Naudojimo sąlygos",
  REFUSE: "ATSISAKYTI",
  ACCEPT: "PRIIMTI",
  READ: "SKAITYTI",
  PUBLISH: "PASKELBTI",
  CHAT: "POkalbis",
  "comming soon": "greitai",
  Other: "Kita",
  Remark: "Pastaba",
  Suggestion: "Pasiūlymas",
  Complaint: "Skundas",
  Manual: "vadovas",
  SEARCH: "PAIEŠKA",
  SHARE: "DALINTIS",
  DENOUNCE: "DENONSUOTI",
  "This post has not been reviewed": "Šis įrašas nebuvo peržiūrėtas",
  SUBMIT: "PATEIKTI",
  Topic: "Tema",
  "Published BY": "Paskelbė BY",
  English: "Anglų",
  Country: "Šalis",
  City: "Miestas",
  Place: "Vieta",
  "Subject 1": "1 tema",
  "Subject 2": "2 tema",
  "Subject 3": "3 tema",
  "Reference Id": "Nuorodos ID",
  "Enter Email Address": "Įveskite el. pašto adresą",
  CHOOSE: "PASIRINKTI",
  By: "Autorius",
  "End": "Pabaiga",
  "Enter Your Story Content": "Įveskite savo istorijos turinį",
  "UPLOAD IMAGES": "ĮKELTI VAIZDUS",
  "Click or Drop here": "Spustelėkite arba numeskite čia",
  "Remove all images": "Pašalinti visus vaizdus",
  "Your post has been properly submitted":
    "Jūsų įrašas buvo tinkamai pateiktas",
  "and is published under": "ir yra paskelbtas",
  "Please copy this for your reference": "Nukopijuokite tai savo nuorodai",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "JŪSŲ UŽklausa TINKAMI PATEIKTA",
};
export const Luganda = {
  "User Instructions": "Ebiragiro by’Omukozesa",
  Contact: "Okutuukirira",
  "News or HereSays Herald": "Amawulire oba WanoAgamba Herald",
  Disclaimer: "Okwegaana",
  "Region or Province or County or State":
    "Ekitundu oba Ssaza oba Ssaza oba Essaza",
  and: "ne",
  Date: "Olunaku olw'omweezi",
  Content: "Okwesiima",
  "TopicName is required": "TopicName yeetaagibwa",
  "Publisher Name is required": "Erinnya ly’Omubuulizi lyetaagisa",
  "Enter Your Feedback": "Yingiza Endowooza Yo",
  HereSays: "WanoBw'agamba",
  Region: "Ekifo",
  Province: "Ettwale",
  Heard: "Awulira",
  Shared: "Bagabana",
  Successfully: "Obuwanguzi",
  Explanation: "Okunnyonnyola",
  Language: "Olulimi",
  Jurisdiction: "Obuyinza bw’obuyinza",
  Attention: "Okutereera",
  "LANGUAGE SELECTION": "OKULONDA OLULIMI",
  ACCESS: "OKWETUUSAKO",
  "Terms of use": "Ebiragiro by’okukozesa",
  REFUSE: "OKUGAANA",
  ACCEPT: "OKUKKIRIZA",
  READ: "OKUSOMA",
  PUBLISH: "PUBLISH",
  CHAT: "OKUNYUMYA",
  "comming soon": "kijja mu bbanga ttono",
  Other: "Lala",
  Remark: "Remark",
  Suggestion: "Okuteesa",
  Complaint: "Okwemulugunya",
  Manual: "Maniyo",
  SEARCH: "OKUNOONYA",
  SHARE: "OMUGABO",
  DENOUNCE: "OKULWANYISA",
  "This post has not been reviewed": "Post eno tennatunulwamu",
  SUBMIT: "OKUWAAYO",
  Topic: "Omulamwa",
  "Published BY": "Efulumiziddwa BY",
  English: "Olungereeza",
  Country: "Eggwanga",
  City: "Ekibuga",
  Place: "Ekifo",
  "Subject 1": "Essomo 1",
  "Subject 2": "Essomo 2",
  "Subject 3": "Essomo 3",
  "Reference Id": "Ebiwandiiko ebijuliziddwa Id",
  "Enter Email Address": "Yingiza Endagiriro ya Email",
  CHOOSE: "OKULONDA",
  By: "Okujjako",
  "End": "Enkomerero",
  "Enter Your Story Content": "Yingiza Ebirimu mu Mboozi Yo",
  "UPLOAD IMAGES": "UPLOAD EBIFAANANYI",
  "Click or Drop here": "Nyiga oba Drop wano",
  "Remove all images": "Ggyawo ebifaananyi byonna",
  "Your post has been properly submitted": "Post yo eweereddwayo bulungi",
  "and is published under": "era efulumizibwa wansi wa",
  "Please copy this for your reference":
    "Nsaba okoppa kino osobole okukikozesa",
  "ID:HERESAYS000": "ID:WANO000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "EKIBUUZO KYO KIWEEDDWA BULI",
};
export const Luxembourgish = {
  "User Instructions": "Benotzer Uweisungen",
  Contact: "Kontakt",
  "News or HereSays Herald": "News oder HereSays Herald",
  Disclaimer: "Verzichterklärung",
  "Region or Province or County or State":
    "Regioun oder Provënz oder Grofschaft oder Staat",
  and: "an",
  Date: "Datum",
  Content: "Inhalt",
  "TopicName is required": "ThemaName ass erfuerderlech",
  "Publisher Name is required": "Verlagsnumm ass erfuerderlech",
  "Enter Your Feedback": "Gitt Äre Feedback",
  HereSays: "Hei seet",
  Region: "Regioun",
  Province: "Provënz",
  Heard: "Héiert",
  Shared: "Gedeelt",
  Successfully: "Erfollegräich",
  Explanation: "Erklärung",
  Language: "Sprooch",
  Jurisdiction: "Juridictioun",
  Attention: "Opgepasst",
  "LANGUAGE SELECTION": "SPROOCH SELECTION",
  ACCESS: "ZOUGANG",
  "Terms of use": "Benotzungsconditiounen",
  REFUSE: "REFUEREN",
  ACCEPT: "AKZEPTEREN",
  READ: "VIRLIESEN",
  PUBLISH: "PUBLÉIEREN",
  CHAT: "CHAT",
  "comming soon": "kënnt geschwënn",
  Other: "Aner",
  Remark: "Bemierkung",
  Suggestion: "Virschlag",
  Complaint: "Plainte",
  Manual: "Manuell",
  SEARCH: "SEARCH",
  SHARE: "DEELEN",
  DENOUNCE: "DENUNZEN",
  "This post has not been reviewed": "Dëse Post gouf net iwwerpréift",
  SUBMIT: "SUBMIT",
  Topic: "Thema",
  "Published BY": "Verëffentlecht BY",
  English: "Englesch",
  Country: "Land",
  City: "Stad",
  Place: "Plaz",
  "Subject 1": "Sujet 1",
  "Subject 2": "Sujet 2",
  "Subject 3": "Sujet 3",
  "Reference Id": "Referenz Id",
  "Enter Email Address": "Gitt Email Adress",
  CHOOSE: "WËLLT",
  By: "Vun",
  "End": "D'Enn",
  "Enter Your Story Content": "Gitt Äre Geschicht Inhalt",
  "UPLOAD IMAGES": "UPLOAD BILLER",
  "Click or Drop here": "Klickt oder Drop hei",
  "Remove all images": "Ewechzehuelen all Biller",
  "Your post has been properly submitted": "Äre Post gouf richteg presentéiert",
  "and is published under": "a gëtt ënner publizéiert",
  "Please copy this for your reference": "Kopéiert w.e.g. dëst fir Är Referenz",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ÄR Ufro ass richteg aginn",
};
export const Macedonian = {
  "User Instructions": "Упатства за корисникот",
  Contact: "Контакт",
  "News or HereSays Herald": "Вести или HereSays Herald",
  Disclaimer: "Одрекување",
  "Region or Province or County or State":
    "Регион или провинција или округ или држава",
  and: "и",
  Date: "Датум",
  Content: "содржина",
  "TopicName is required": "Името на темата е потребно",
  "Publisher Name is required": "Потребно е името на издавачот",
  "Enter Your Feedback": "Внесете ги вашите повратни информации",
  HereSays: "Еве Вели",
  Region: "Регионот",
  Province: "Провинција",
  Heard: "Слушна",
  Shared: "Споделени",
  Successfully: "Успешно",
  Explanation: "Објаснување",
  Language: "Јазик",
  Jurisdiction: "Надлежност",
  Attention: "Внимание",
  "LANGUAGE SELECTION": "ИЗБОР НА ЈАЗИК",
  ACCESS: "ПРИСТАП",
  "Terms of use": "Услови за користење",
  REFUSE: "ОДБИЈ",
  ACCEPT: "ПРИФАТИ",
  READ: "ПРОЧИТАЈ",
  PUBLISH: "ОБЈАВИ",
  CHAT: "ЧАТ",
  "comming soon": "доаѓа наскоро",
  Other: "Друго",
  Remark: "Забелешка",
  Suggestion: "Предлог",
  Complaint: "Жалба",
  Manual: "Прирачник",
  SEARCH: "ПРЕБАРУВАЊЕ",
  SHARE: "СПОДЕЛИ",
  DENOUNCE: "ОДОБРЕНИ",
  "This post has not been reviewed": "Овој пост не е прегледан",
  SUBMIT: "ПОДНЕСЕТЕ",
  Topic: "Тема",
  "Published BY": "Објавено ОД",
  English: "Англиски",
  Country: "Земја",
  City: "Градот",
  Place: "Место",
  "Subject 1": "Предмет 1",
  "Subject 2": "Предмет 2",
  "Subject 3": "Предмет 3",
  "Reference Id": "Референтен ID",
  "Enter Email Address": "Внесете адреса за е-пошта",
  CHOOSE: "ИЗБЕРЕТЕ",
  By: "Од страна на",
  "End": "Крај",
  "Enter Your Story Content": "Внесете ја содржината на вашата приказна",
  "UPLOAD IMAGES": "ПОСТАВЕТЕ СЛИКИ",
  "Click or Drop here": "Кликнете или пуштете овде",
  "Remove all images": "Отстранете ги сите слики",
  "Your post has been properly submitted": "Вашата објава е правилно испратена",
  "and is published under": "и се објавува под",
  "Please copy this for your reference":
    "Ве молиме копирајте го ова за ваша референца",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ВАШЕТО ПРАШАЊЕ Е ПРАВИЛНО ПОДНЕСЕНО",
};
export const Maithili = {
  "User Instructions": "उपयोगकर्ता निर्देश",
  Contact: "संपर्क करनाइ",
  "News or HereSays Herald": "समाचार या यहाँहेराल्ड कहते हैं",
  Disclaimer: "अस्वीकरण",
  "Region or Province or County or State":
    "क्षेत्र या प्रांत या काउंटी या राज्य",
  and: "आओर",
  Date: "तारीख",
  Content: "सामग्री",
  "TopicName is required": "TopicName आवश्यक अछि",
  "Publisher Name is required": "प्रकाशक नाम आवश्यक अछि",
  "Enter Your Feedback": "अपन प्रतिक्रिया दर्ज करू",
  HereSays: "एतयकहैत अछि",
  Region: "क्षेत्र",
  Province: "राज्य",
  Heard: "सुनल",
  Shared: "साझा कयल गेल",
  Successfully: "सफलतापूर्वक",
  Explanation: "वर्णन",
  Language: "भाषा",
  Jurisdiction: "न्यायक्षेत्र",
  Attention: "ध्यान दिय",
  "LANGUAGE SELECTION": "भाषा चयन",
  ACCESS: "पहुंच",
  "Terms of use": "उपयोग की शर्तें",
  REFUSE: "इन्कार",
  ACCEPT: "स्वीकार",
  READ: "पढ़ू",
  PUBLISH: "PUBLISH",
  CHAT: "बातचीत",
  "comming soon": "जल्दिये आबि रहल अछि",
  Other: "दोसर",
  Remark: "टिप्पणी",
  Suggestion: "सलाह",
  Complaint: "शिकायत",
  Manual: "हाथ सं कएल गेल",
  SEARCH: "खोजनाइ",
  SHARE: "साझा करु",
  DENOUNCE: "निंदा करब",
  "This post has not been reviewed": "एहि पोस्टक समीक्षा नहि भेल अछि",
  SUBMIT: "जमा",
  Topic: "विषय",
  "Published BY": "प्रकाशित BY",
  English: "अंग्रेजी",
  Country: "देश",
  City: "नगर",
  Place: "स्थान",
  "Subject 1": "विषय १",
  "Subject 2": "विषय 2",
  "Subject 3": "विषय 3",
  "Reference Id": "संदर्भ आईडी",
  "Enter Email Address": "ईमेल पता दर्ज करू",
  CHOOSE: "चुनू",
  By: "द्वारा",
  "End": "अंत",
  "Enter Your Story Content": "अपन कथा सामग्री दर्ज करू",
  "UPLOAD IMAGES": "छवि अपलोड करू",
  "Click or Drop here": "एतय क्लिक करू वा ड्रॉप करू",
  "Remove all images": "सभ छवि हटाउ",
  "Your post has been properly submitted": "अहाँक पोस्ट ठीक स जमा भ गेल अछि",
  "and is published under": "आ अन्तर्गत प्रकाशित होइत अछि",
  "Please copy this for your reference": "कृपया अपन संदर्भ लेल एकरा कॉपी करू",
  "ID:HERESAYS000": "आईडी:एतय 000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "अहाँक QUERY ठीक स जमा भ गेल अछि",
};
export const Malagasy = {
  "User Instructions": "Torolàlana ho an'ny mpampiasa",
  Contact: "Contact",
  "News or HereSays Herald": "Vaovao na HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Faritra na Faritany na Faritra na Fanjakana",
  and: "SY",
  Date: "Daty",
  Content: "Votoaty",
  "TopicName is required": "Ilaina ny anarana Topic",
  "Publisher Name is required": "Ilaina ny Anaran'ny Mpanonta",
  "Enter Your Feedback": "Ampidiro ny hevitrao",
  HereSays: "HereSays",
  Region: "Region",
  Province: "-tokony eran'ny fanjakana",
  Heard: "reny",
  Shared: "Nozaraina",
  Successfully: "soa aman-tsara",
  Explanation: "fanazavana",
  Language: "Fiteny",
  Jurisdiction: "fahefana",
  Attention: "TSARA",
  "LANGUAGE SELECTION": "FIFIDIANANA TENY",
  ACCESS: "ACCESS",
  "Terms of use": "Fepetra fampiasana",
  REFUSE: "tsy mety",
  ACCEPT: "MANAIKY",
  READ: "HAMAKY",
  PUBLISH: "torio",
  CHAT: "hiresaka",
  "comming soon": "ho avy tsy ho ela",
  Other: "Hafa",
  Remark: "fanamarihana",
  Suggestion: "SOSO-KEVITRA",
  Complaint: "METY HITARAINA",
  Manual: "BOKY",
  SEARCH: "karohy",
  SHARE: "anjara",
  DENOUNCE: "manameloka",
  "This post has not been reviewed": "Tsy nojerena ity lahatsoratra ity",
  SUBMIT: "manaiky",
  Topic: "Lohahevitra",
  "Published BY": "Navoakan'ny",
  English: "anglisy",
  Country: "Firenena",
  City: "Tanàna",
  Place: "Place",
  "Subject 1": "Lohahevitra 1",
  "Subject 2": "Lohahevitra 2",
  "Subject 3": "Lohahevitra 3",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Ampidiro ny adiresy mailaka",
  CHOOSE: "FIDIO",
  By: "ny",
  "End": "Tapitra",
  "Enter Your Story Content": "Ampidiro ny votoatin'ny tantaranao",
  "UPLOAD IMAGES": "Ampidiro ny sary",
  "Click or Drop here": "Tsindrio na Atsaharo eto",
  "Remove all images": "Esory ny sary rehetra",
  "Your post has been properly submitted":
    "Nalefa araka ny tokony ho izy ny hafatrao",
  "and is published under": "ary navoaka eo ambany",
  "Please copy this for your reference": "Adikao azafady ity ho fanondroanao",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "NATAO TSARA NY FANONTANIANAO",
};
export const Malay = {
  "User Instructions": "Arahan Pengguna",
  Contact: "Kenalan",
  "News or HereSays Herald": "Berita atau HereSays Herald",
  Disclaimer: "Penafian",
  "Region or Province or County or State":
    "Wilayah atau Wilayah atau Daerah atau Negeri",
  and: "dan",
  Date: "Tarikh",
  Content: "Kandungan",
  "TopicName is required": "TopicName diperlukan",
  "Publisher Name is required": "Nama Penerbit diperlukan",
  "Enter Your Feedback": "Masukkan Maklum Balas Anda",
  HereSays: "Disini Berkata",
  Region: "Wilayah",
  Province: "Wilayah",
  Heard: "Terdengar",
  Shared: "Dikongsi",
  Successfully: "Berjaya",
  Explanation: "Penjelasan",
  Language: "Bahasa",
  Jurisdiction: "Bidang kuasa",
  Attention: "Perhatian",
  "LANGUAGE SELECTION": "PEMILIHAN BAHASA",
  ACCESS: "AKSES",
  "Terms of use": "Syarat penggunaan",
  REFUSE: "MENOLAK",
  ACCEPT: "TERIMA",
  READ: "BACA",
  PUBLISH: "MENERBITKAN",
  CHAT: "SEMBANG",
  "comming soon": "akan datang",
  Other: "Lain-lain",
  Remark: "Teguran",
  Suggestion: "Cadangan",
  Complaint: "Aduan",
  Manual: "Manual",
  SEARCH: "CARIAN",
  SHARE: "KONGSI",
  DENOUNCE: "KEcam",
  "This post has not been reviewed": "Catatan ini belum disemak",
  SUBMIT: "SERAH",
  Topic: "Topik",
  "Published BY": "Diterbitkan oleh",
  English: "Inggeris",
  Country: "Negara",
  City: "Bandar",
  Place: "tempat",
  "Subject 1": "Subjek 1",
  "Subject 2": "Subjek 2",
  "Subject 3": "Subjek 3",
  "Reference Id": "Id Rujukan",
  "Enter Email Address": "Masukkan alamat email",
  CHOOSE: "PILIH",
  By: "Oleh",
  "End": "Tamat",
  "Enter Your Story Content": "Masukkan Kandungan Cerita Anda",
  "UPLOAD IMAGES": "MUAT NAIK GAMBAR",
  "Click or Drop here": "Klik atau Lepaskan di sini",
  "Remove all images": "Alih keluar semua imej",
  "Your post has been properly submitted":
    "Siaran anda telah diserahkan dengan betul",
  "and is published under": "dan diterbitkan di bawah",
  "Please copy this for your reference": "Sila salin ini untuk rujukan anda",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "PERTANYAAN ANDA TELAH DISERAHKAN DENGAN BETUL",
};
export const Malayalam = {
  "User Instructions": "ഉപയോക്തൃ നിർദ്ദേശങ്ങൾ",
  Contact: "ബന്ധപ്പെടുക",
  "News or HereSays Herald": "വാർത്ത അല്ലെങ്കിൽ ഹെറാൾഡ് ഇവിടെ പറയുന്നു",
  Disclaimer: "നിരാകരണം",
  "Region or Province or County or State":
    "പ്രദേശം അല്ലെങ്കിൽ പ്രവിശ്യ അല്ലെങ്കിൽ കൗണ്ടി അല്ലെങ്കിൽ സംസ്ഥാനം",
  and: "ഒപ്പം",
  Date: "തീയതി",
  Content: "ഉള്ളടക്കം",
  "TopicName is required": "TopicName ആവശ്യമാണ്",
  "Publisher Name is required": "പ്രസാധകന്റെ പേര് ആവശ്യമാണ്",
  "Enter Your Feedback": "നിങ്ങളുടെ ഫീഡ്ബാക്ക് നൽകുക",
  HereSays: "ഇവിടെ പറയുന്നു",
  Region: "പ്രദേശം",
  Province: "പ്രവിശ്യ",
  Heard: "കേട്ടു",
  Shared: "പങ്കിട്ടു",
  Successfully: "വിജയകരമായി",
  Explanation: "വിശദീകരണം",
  Language: "ഭാഷ",
  Jurisdiction: "അധികാരപരിധി",
  Attention: "ശ്രദ്ധ",
  "LANGUAGE SELECTION": "ഭാഷാ തിരഞ്ഞെടുപ്പ്",
  ACCESS: "പ്രവേശനം",
  "Terms of use": "ഉപയോഗ നിബന്ധനകൾ",
  REFUSE: "നിരസിക്കുക",
  ACCEPT: "സ്വീകരിക്കുക",
  READ: "വായിക്കുക",
  PUBLISH: "പ്രസിദ്ധീകരിക്കുക",
  CHAT: "ചാറ്റ്",
  "comming soon": "ഉടന് വരുന്നു",
  Other: "മറ്റുള്ളവ",
  Remark: "പരാമർശം",
  Suggestion: "നിർദ്ദേശം",
  Complaint: "പരാതി",
  Manual: "മാനുവൽ",
  SEARCH: "തിരയുക",
  SHARE: "ഷെയർ ചെയ്യുക",
  DENOUNCE: "അപലപിക്കുക",
  "This post has not been reviewed": "ഈ പോസ്റ്റ് അവലോകനം ചെയ്തിട്ടില്ല",
  SUBMIT: "സമർപ്പിക്കുക",
  Topic: "വിഷയം",
  "Published BY": "പ്രസിദ്ധീകരിച്ചത്",
  English: "ഇംഗ്ലീഷ്",
  Country: "രാജ്യം",
  City: "നഗരം",
  Place: "സ്ഥലം",
  "Subject 1": "വിഷയം 1",
  "Subject 2": "വിഷയം 2",
  "Subject 3": "വിഷയം 3",
  "Reference Id": "റഫറൻസ് ഐഡി",
  "Enter Email Address": "ഇ - മെയിൽ വിലാസം രേഖപ്പെടുത്തുക",
  CHOOSE: "തിരഞ്ഞെടുക്കുക",
  By: "എഴുതിയത്",
  "End": "അവസാനം",
  "Enter Your Story Content": "നിങ്ങളുടെ സ്റ്റോറി ഉള്ളടക്കം നൽകുക",
  "UPLOAD IMAGES": "ഇമേജുകൾ അപ്‌ലോഡ് ചെയ്യുക",
  "Click or Drop here": "ഇവിടെ ക്ലിക്ക് ചെയ്യുക അല്ലെങ്കിൽ ഡ്രോപ്പ് ചെയ്യുക",
  "Remove all images": "എല്ലാ ചിത്രങ്ങളും നീക്കം ചെയ്യുക",
  "Your post has been properly submitted":
    "നിങ്ങളുടെ പോസ്റ്റ് ശരിയായി സമർപ്പിച്ചു",
  "and is published under": "എന്നിവയ്ക്ക് കീഴിൽ പ്രസിദ്ധീകരിക്കുന്നു",
  "Please copy this for your reference": "നിങ്ങളുടെ റഫറൻസിനായി ഇത് പകർത്തുക",
  "ID:HERESAYS000": "ഐഡി:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "നിങ്ങളുടെ ചോദ്യം ശരിയായി സമർപ്പിച്ചു",
};
export const Maltese = {
  "User Instructions": "Istruzzjonijiet għall-Utent",
  Contact: "Kuntatt",
  "News or HereSays Herald": "Aħbarijiet jew HereSays Herald",
  Disclaimer: "Ċaħda",
  "Region or Province or County or State":
    "Reġjun jew Provinċja jew Kontea jew Stat",
  and: "u",
  Date: "Data",
  Content: "Kontenut",
  "TopicName is required": "TopicName huwa meħtieġ",
  "Publisher Name is required": "Isem tal-Pubblikatur huwa meħtieġ",
  "Enter Your Feedback": "Ikteb il-Feedback Tiegħek",
  HereSays: "Hawn Jgħid",
  Region: "Reġjun",
  Province: "Provinċja",
  Heard: "Jinstemgħu",
  Shared: "Maqsuma",
  Successfully: "B'suċċess",
  Explanation: "Spjegazzjoni",
  Language: "Lingwa",
  Jurisdiction: "Ġurisdizzjoni",
  Attention: "Attenzjoni",
  "LANGUAGE SELECTION": "GĦAŻLA TAL-LINGWA",
  ACCESS: "AĊĊESS",
  "Terms of use": "Termini ta 'Użu",
  REFUSE: "IRIRFUT",
  ACCEPT: "AĊĊETTA",
  READ: "AQRA",
  PUBLISH: "PUBBLIKA",
  CHAT: "CHAT",
  "comming soon": "dalwaqt",
  Other: "Oħrajn",
  Remark: "Rimarka",
  Suggestion: "Suġġeriment",
  Complaint: "Ilment",
  Manual: "Manwal",
  SEARCH: "FITTEX",
  SHARE: "SHARE",
  DENOUNCE: "DENUNĊJA",
  "This post has not been reviewed": "Din il-kariga ma ġietx riveduta",
  SUBMIT: "SOTTOTTA",
  Topic: "Suġġett",
  "Published BY": "Ippubblikat MINN",
  English: "Ingliż",
  Country: "Pajjiż",
  City: "Belt",
  Place: "Post",
  "Subject 1": "Suġġett 1",
  "Subject 2": "Suġġett 2",
  "Subject 3": "Suġġett 3",
  "Reference Id": "Referenza Id",
  "Enter Email Address": "Daħħal Indirizz Email",
  CHOOSE: "AGĦŻEL",
  By: "Permezz",
  "End": "Tmiem",
  "Enter Your Story Content": "Daħħal il-Kontenut tal-Istorja Tiegħek",
  "UPLOAD IMAGES": "TALLAGĦ L-IMMAĠNI",
  "Click or Drop here": "Ikklikkja jew Waqqa' hawn",
  "Remove all images": "Neħħi l-immaġini kollha",
  "Your post has been properly submitted":
    "Il-kariga tiegħek ġiet sottomessa kif suppost",
  "and is published under": "u huwa ppubblikat taħt",
  "Please copy this for your reference":
    "Jekk jogħġbok ikkopja dan għar-referenza tiegħek",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "IL-MISTOQSIJA TIEGĦEK ĠIET SOTTOMESSA B'MOD B'MOD",
};
export const Maori = {
  "User Instructions": "!!!>>> Failed to fetch <<<!!!",
  Contact: "Whakapā",
  "News or HereSays Herald": "Nga korero a HereSays Herald",
  Disclaimer: "Whakakahoretanga",
  "Region or Province or County or State":
    "Rohe, Porowini, Kaute ranei, State ranei",
  and: "a",
  Date: "Rā",
  Content: "Ihirangi",
  "TopicName is required": "E hiahiatia ana te Ingoa Kaupapa",
  "Publisher Name is required": "Kei te hiahiatia te Ingoa Kaiwhakaputa",
  "Enter Your Feedback": "Whakauruhia to Urupare",
  HereSays: "Anei te korero",
  Region: "Rohe",
  Province: "Porowini",
  Heard: "I rongo",
  Shared: "Tiri",
  Successfully: "I angitu",
  Explanation: "Whakamaramatanga",
  Language: "Reo",
  Jurisdiction: "Te mana whakahaere",
  Attention: "Te aro",
  "LANGUAGE SELECTION": "TE KORERO REO",
  ACCESS: "KAUPAPA",
  "Terms of use": "Nga tikanga whakamahi",
  REFUSE: "WHAKAARO",
  ACCEPT: "Whakaae",
  READ: "PĀNUI",
  PUBLISH: "PANUITANGA",
  CHAT: "KORERO",
  "comming soon": "e haere mai ana",
  Other: "Ētahi atu",
  Remark: "Korero",
  Suggestion: "He whakaaro",
  Complaint: "amuamu",
  Manual: "ā-ringa",
  SEARCH: "RAPU",
  SHARE: "WHAKAMAHI",
  DENOUNCE: "WHAKATANGA",
  "This post has not been reviewed": "Ko tenei panui kaore ano kia arotakehia",
  SUBMIT: "TUKU",
  Topic: "Kaupapa",
  "Published BY": "I taia e",
  English: "Ingarihi",
  Country: "Whenua",
  City: "Taone",
  Place: "Wahi",
  "Subject 1": "Kaupapa 1",
  "Subject 2": "Kaupapa 2",
  "Subject 3": "Kaupapa 3",
  "Reference Id": "Tohutoro Id",
  "Enter Email Address": "Whakauruhia te Wāhitau Īmēra",
  CHOOSE: "KOWHITI",
  By: "Na",
  "End": "Te Whakamutunga",
  "Enter Your Story Content": "Whakauruhia To Ihirangi Korero",
  "UPLOAD IMAGES": "WHAKATOKANGA WHAKAMAHI",
  "Click or Drop here": "Paatohia, Whakataka ranei ki konei",
  "Remove all images": "Tangohia nga whakaahua katoa",
  "Your post has been properly submitted": "Kua tika te tuku o to panui",
  "and is published under": "a kua taia ki raro",
  "Please copy this for your reference": "Tena koa kapehia tenei hei tohutoro",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "KUA TINO TUKUA TO Uiui",
};
export const Marathi = {
  "User Instructions": "वापरकर्ता सूचना",
  Contact: "संपर्क करा",
  "News or HereSays Herald": "बातम्या किंवा HereSays हेराल्ड",
  Disclaimer: "अस्वीकरण",
  "Region or Province or County or State":
    "प्रदेश किंवा प्रांत किंवा काउंटी किंवा राज्य",
  and: "आणि",
  Date: "तारीख",
  Content: "सामग्री",
  "TopicName is required": "विषयाचे नाव आवश्यक आहे",
  "Publisher Name is required": "प्रकाशकाचे नाव आवश्यक आहे",
  "Enter Your Feedback": "तुमचा अभिप्राय प्रविष्ट करा",
  HereSays: "येथे म्हणतात",
  Region: "प्रदेश",
  Province: "प्रांत",
  Heard: "ऐकले",
  Shared: "शेअर केले",
  Successfully: "यशस्वीपणे",
  Explanation: "स्पष्टीकरण",
  Language: "इंग्रजी",
  Jurisdiction: "अधिकारक्षेत्र",
  Attention: "लक्ष द्या",
  "LANGUAGE SELECTION": "भाषा निवड",
  ACCESS: "प्रवेश",
  "Terms of use": "वापरण्याच्या अटी",
  REFUSE: "नकार द्या",
  ACCEPT: "स्वीकारा",
  READ: "वाचा",
  PUBLISH: "प्रकाशित करा",
  CHAT: "चॅट",
  "comming soon": "लवकरच येत आहे",
  Other: "इतर",
  Remark: "शेरा",
  Suggestion: "सूचना",
  Complaint: "तक्रार",
  Manual: "मॅन्युअल",
  SEARCH: "शोधा",
  SHARE: "शेअर करा",
  DENOUNCE: "दोष देणे",
  "This post has not been reviewed": "या पोस्टचे पुनरावलोकन केले गेले नाही",
  SUBMIT: "प्रस्तुत करणे",
  Topic: "विषय",
  "Published BY": "द्वारे प्रकाशित",
  English: "इंग्रजी",
  Country: "देश",
  City: "शहर",
  Place: "ठिकाण",
  "Subject 1": "विषय १",
  "Subject 2": "विषय २",
  "Subject 3": "विषय 3",
  "Reference Id": "संदर्भ आयडी",
  "Enter Email Address": "ईमेल पत्ता प्रविष्ट करा",
  CHOOSE: "निवडा",
  By: "द्वारे",
  "End": "द एंड",
  "Enter Your Story Content": "तुमची कथा सामग्री प्रविष्ट करा",
  "UPLOAD IMAGES": "प्रतिमा अपलोड करा",
  "Click or Drop here": "येथे क्लिक करा किंवा ड्रॉप करा",
  "Remove all images": "सर्व प्रतिमा काढा",
  "Your post has been properly submitted":
    "तुमची पोस्ट योग्यरित्या सबमिट केली गेली आहे",
  "and is published under": "आणि अंतर्गत प्रकाशित केले आहे",
  "Please copy this for your reference": "कृपया आपल्या संदर्भासाठी हे कॉपी करा",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "तुमची क्वेरी योग्यरित्या सबमिट केली गेली आहे",
};
export const Mizo = {
  "User Instructions": "User Instruction te chu a hnuaia mi ang hian a ni",
  Contact: "Inbepawp",
  "News or HereSays Herald": "Chanchin emaw Hetah emawSys Herald",
  Disclaimer: "Thuhriltu",
  "Region or Province or County or State":
    "Region emaw Province emaw County emaw State emaw",
  and: "leh",
  Date: "Tarikh",
  Content: "Lungawi",
  "TopicName is required": "TopicName a ngai a ni",
  "Publisher Name is required": "Publisher Name a ngai a ni",
  "Enter Your Feedback": "I Feedback kha ziak lut rawh",
  HereSays: "Hetah hianA sawi",
  Region: "Rambung",
  Province: "Rambung",
  Heard: "Hria",
  Shared: "Share a ni",
  Successfully: "Hlawhtling takin",
  Explanation: "Hrilhfiahna",
  Language: "Tawng",
  Jurisdiction: "Thuneihna (Jurisdiction) a ni",
  Attention: "Ngaihven",
  "LANGUAGE SELECTION": "ṬAWNG THIL THLENG THIN",
  ACCESS: "LUT",
  "Terms of use": "Hman dan tur thuthlung",
  REFUSE: "HNIAL",
  ACCEPT: "PAWM",
  READ: "CHHIAR",
  PUBLISH: "PUBLISH tih a ni",
  CHAT: "INBIA",
  "comming soon": "a lo thleng thuai dawn",
  Other: "Thildang",
  Remark: "Thuhriltu",
  Suggestion: "Kawhhmuh",
  Complaint: "Sawiselna",
  Manual: "Kuta thawh",
  SEARCH: "ZAWNG",
  SHARE: "INTAWM",
  DENOUNCE: "DENOUNCE tih a ni",
  "This post has not been reviewed": "He post hi review a la ni lo",
  SUBMIT: "THEHLUT",
  Topic: "Thupui",
  "Published BY": "BY tihchhuah a ni",
  English: "Sap",
  Country: "Ram",
  City: "Khawpui",
  Place: "Hmun",
  "Subject 1": "Thupui 1. Thupui 1",
  "Subject 2": "Thupui 2. A rilru a hah lutuk",
  "Subject 3": "Thupui 3-na",
  "Reference Id": "Thuhmahruai Id",
  "Enter Email Address": "Email Address tih kha ziak lut rawh",
  CHOOSE: "THLANG",
  By: "Tu",
  "End": "A Tâwpna",
  "Enter Your Story Content": "I Story Content kha ziak lut rawh",
  "UPLOAD IMAGES": "IMAGES UPLOAD ANG CHE",
  "Click or Drop here": "Hetah hian click emaw Drop emaw rawh",
  "Remove all images": "Image zawng zawng chu paih vek rawh",
  "Your post has been properly submitted":
    "I post chu mumal takin i submit tawh a ni",
  "and is published under": "leh tihchhuah a ni bawk",
  "Please copy this for your reference": "I reference atan hei hi copy la",
  "ID:HERESAYS000": "ID:THIL THLENG THIN000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "I QUERY HI THIL THLENG THIN TAK TAK A NI",
};
export const Mongolian = {
  "User Instructions": "Хэрэглэгчийн заавар",
  Contact: "Холбоо барих",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Татгалзах",
  "Region or Province or County or State":
    "Бүс нутаг эсвэл муж эсвэл муж эсвэл муж",
  and: "болон",
  Date: "Огноо",
  Content: "Агуулга",
  "TopicName is required": "Сэдвийн нэр шаардлагатай",
  "Publisher Name is required": "Нийтлэгчийн нэр шаардлагатай",
  "Enter Your Feedback": "Санал хүсэлтээ оруулна уу",
  HereSays: "Энд хэлж байна",
  Region: "Бүс нутаг",
  Province: "муж",
  Heard: "Сонссон",
  Shared: "Хуваалцсан",
  Successfully: "Амжилттай",
  Explanation: "Тайлбар",
  Language: "Хэл",
  Jurisdiction: "Эрх мэдэл",
  Attention: "Анхаар",
  "LANGUAGE SELECTION": "ХЭЛ СОНГОЛТ",
  ACCESS: "ХАНДАХ",
  "Terms of use": "Хэрэглэх нөхцөл",
  REFUSE: "ТАТГАЛЗАХ",
  ACCEPT: "ХҮЛЭЭН АВАХ",
  READ: "УНШИХ",
  PUBLISH: "НИЙТЛЭХ",
  CHAT: "ЧАТ",
  "comming soon": "тун удахгүй",
  Other: "Бусад",
  Remark: "Тайлбар",
  Suggestion: "Санал",
  Complaint: "Гомдол",
  Manual: "Гарын авлага",
  SEARCH: "ХАЙХ",
  SHARE: "SHARE",
  DENOUNCE: "ЗОРИУЛАХ",
  "This post has not been reviewed": "Энэ нийтлэлийг хянаж үзээгүй байна",
  SUBMIT: "ИЛГЭЭХ",
  Topic: "Сэдэв",
  "Published BY": "Нийтэлсэн",
  English: "Англи",
  Country: "Улс",
  City: "Хот",
  Place: "Газар",
  "Subject 1": "Сэдэв 1",
  "Subject 2": "Сэдэв 2",
  "Subject 3": "Сэдэв 3",
  "Reference Id": "Лавлагааны дугаар",
  "Enter Email Address": "Цахим хаягаа оруулна уу",
  CHOOSE: "СОНГОХ",
  By: "By",
  "End": "Төгсөв",
  "Enter Your Story Content": "Өөрийн түүхийн агуулгыг оруулна уу",
  "UPLOAD IMAGES": "ЗУРАГ БАЙРУУЛАХ",
  "Click or Drop here": "Энд дарж эсвэл буулгана уу",
  "Remove all images": "Бүх зургийг устгана уу",
  "Your post has been properly submitted":
    "Таны нийтлэл зохих ёсоор илгээгдсэн байна",
  "and is published under": "дор нийтлэгдсэн байна",
  "Please copy this for your reference": "Үүнийг лавлагаа болгон хуулж авна уу",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ТАНЫ АСУУЛГА ЗОХИОН БАЙГУУЛЛАА",
};
export const Myanmar_Burmese = {
  "User Instructions": "အသုံးပြုသူညွှန်ကြားချက်များ",
  Contact: "ဆက်သွယ်ရန်",
  "News or HereSays Herald": "သတင်း သို့မဟုတ် HereSays Herald",
  Disclaimer: "ရှင်းလင်းချက်",
  "Region or Province or County or State":
    "တိုင်းဒေသကြီး သို့မဟုတ် ပြည်နယ် သို့မဟုတ် ခရိုင် သို့မဟုတ် ပြည်နယ်",
  and: "နှင့်",
  Date: "ရက်စွဲ",
  Content: "အကြောင်းအရာ",
  "TopicName is required": "TopicName လိုအပ်ပါသည်။",
  "Publisher Name is required": "ထုတ်ဝေသူအမည် လိုအပ်သည်။",
  "Enter Your Feedback": "သင်၏ အကြံပြုချက်ကို ထည့်သွင်းပါ။",
  HereSays: "ဟောဒီမှာ",
  Region: "တိုင်းဒေသကြီး",
  Province: "ပြည်နယ်",
  Heard: "ကြားတယ်။",
  Shared: "မျှဝေခဲ့သည်။",
  Successfully: "အောင်မြင်စွာ",
  Explanation: "ရှင်းလင်းချက်",
  Language: "ဘာသာစကား",
  Jurisdiction: "တရားစီရင်ပိုင်ခွင့်",
  Attention: "အာရုံ",
  "LANGUAGE SELECTION": "ဘာသာစကား ရွေးချယ်မှု",
  ACCESS: "ဝင်ရောက်ခွင့်",
  "Terms of use": "သတ်မှတ်ချက်များ",
  REFUSE: "ငြင်းဆိုပါ။",
  ACCEPT: "လက်ခံပါ။",
  READ: "ဖတ်ရန်",
  PUBLISH: "ထုတ်ပြန်ပါ။",
  CHAT: "စကားပြောခန်း",
  "comming soon": "မကြာမီလာမည်",
  Other: "တခြား",
  Remark: "မှတ်ချက်",
  Suggestion: "အကြံပြုချက်",
  Complaint: "မကျေနပ်",
  Manual: "လူကိုယ်တိုင်",
  SEARCH: "ရှာဖွေရန်",
  SHARE: "SHARE လိုက်ပါ။",
  DENOUNCE: "ရှုတ်ချခြင်း။",
  "This post has not been reviewed": "ဤပို့စ်ကို မသုံးသပ်ရသေးပါ။",
  SUBMIT: "တင်သွင်းပါ။",
  Topic: "အကြောင်းအရာ",
  "Published BY": "ထုတ်ဝေသည်။",
  English: "အင်္ဂလိပ်စာ",
  Country: "တိုင်းပြည်",
  City: "မြို",
  Place: "နေရာ",
  "Subject 1": "ဘာသာရပ် ၁",
  "Subject 2": "ဘာသာရပ် ၂",
  "Subject 3": "ဘာသာရပ် ၃",
  "Reference Id": "အကိုးအကား ID",
  "Enter Email Address": "အီးမေးလ်လိပ်စာရိုက်ထည့်ပါ။",
  CHOOSE: "ရွေးချယ်ပါ။",
  By: "အားဖြင့်",
  "End": "ပြီးပါပြီ",
  "Enter Your Story Content": "သင့်ဇာတ်လမ်းအကြောင်းအရာကို ထည့်သွင်းပါ။",
  "UPLOAD IMAGES": "ပုံများ အပ်လုဒ်လုပ်ပါ။",
  "Click or Drop here": "ဤနေရာကို နှိပ်ပါ သို့မဟုတ် ချပေးပါ။",
  "Remove all images": "ပုံအားလုံးကို ဖယ်ရှားပါ။",
  "Your post has been properly submitted":
    "သင့်ပို့စ်ကို မှန်ကန်စွာ တင်ပြီးပါပြီ။",
  "and is published under": "အောက်တွင်ဖော်ပြထားသည်။",
  "Please copy this for your reference": "သင့်အကိုးအကားအတွက် ဤအရာကို ကူးယူပါ။",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "သင့်မေးခွန်းကို မှန်ကန်စွာ တင်ပြပြီးပါပြီ။",
};
export const Nepali = {
  "User Instructions": "प्रयोगकर्ता निर्देशनहरू",
  Contact: "सम्पर्क गर्नुहोस्",
  "News or HereSays Herald": "समाचार वा HereSays हेराल्ड",
  Disclaimer: "अस्वीकरण",
  "Region or Province or County or State":
    "क्षेत्र वा प्रान्त वा काउन्टी वा राज्य",
  and: "र",
  Date: "मिति",
  Content: "सामग्री",
  "TopicName is required": "TopicName आवश्यक छ",
  "Publisher Name is required": "प्रकाशकको ​​नाम आवश्यक छ",
  "Enter Your Feedback": "आफ्नो प्रतिक्रिया प्रविष्ट गर्नुहोस्",
  HereSays: "यहाँ भन्छन्",
  Region: "क्षेत्र",
  Province: "प्रान्त",
  Heard: "सुनियो",
  Shared: "साझेदारी गरियो",
  Successfully: "सफलतापूर्वक",
  Explanation: "व्याख्या",
  Language: "भाषा",
  Jurisdiction: "अधिकार क्षेत्र",
  Attention: "ध्यान",
  "LANGUAGE SELECTION": "भाषा चयन",
  ACCESS: "पहुँच",
  "Terms of use": "प्रयोगका सर्तहरू",
  REFUSE: "अस्वीकार गर्नुहोस्",
  ACCEPT: "स्वीकार गर्नुहोस्",
  READ: "पढ्नुहोस्",
  PUBLISH: "प्रकाशित गर्नुहोस्",
  CHAT: "च्याट",
  "comming soon": "चाँडै आउँदैछ",
  Other: "अन्य",
  Remark: "टिप्पणी",
  Suggestion: "सुझाव",
  Complaint: "गुनासो",
  Manual: "म्यानुअल",
  SEARCH: "खोज्नुहोस्",
  SHARE: "SHARE",
  DENOUNCE: "निन्दा गर्नुहोस्",
  "This post has not been reviewed": "यो पोस्ट समीक्षा गरिएको छैन",
  SUBMIT: "पेश गर्नुहोस्",
  Topic: "विषय",
  "Published BY": "द्वारा प्रकाशित",
  English: "अंग्रेजी",
  Country: "देश",
  City: "सहर",
  Place: "स्थान",
  "Subject 1": "विषय १",
  "Subject 2": "विषय २",
  "Subject 3": "विषय ३",
  "Reference Id": "सन्दर्भ आईडी",
  "Enter Email Address": "इमेल ठेगाना प्रविष्ट गर्नुहोस्",
  CHOOSE: "छनौट गर्नुहोस्",
  By: "द्वारा",
  "End": "समाप्त",
  "Enter Your Story Content": "आफ्नो कथा सामग्री प्रविष्ट गर्नुहोस्",
  "UPLOAD IMAGES": "छविहरू अपलोड गर्नुहोस्",
  "Click or Drop here": "यहाँ क्लिक गर्नुहोस् वा छोड्नुहोस्",
  "Remove all images": "सबै छविहरू हटाउनुहोस्",
  "Your post has been properly submitted":
    "तपाईको पोष्ट राम्रोसँग पेश गरिएको छ",
  "and is published under": "र अन्तर्गत प्रकाशित छ",
  "Please copy this for your reference":
    "कृपया तपाईंको सन्दर्भको लागि यसलाई प्रतिलिपि गर्नुहोस्",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "तपाईंको प्रश्न सही रूपमा पेश गरिएको छ",
};
export const Nyanja_Chichewa = {
  "User Instructions": "Malangizo Ogwiritsa Ntchito",
  Contact: "Contact",
  "News or HereSays Herald": "News kapena HereSays Herald",
  Disclaimer: "Chodzikanira",
  "Region or Province or County or State":
    "Chigawo kapena Chigawo kapena County kapena State",
  and: "ndi",
  Date: "Tsiku",
  Content: "Zamkatimu",
  "TopicName is required": "TopicName ndiyofunika",
  "Publisher Name is required": "Dzina la wosindikiza ndilofunika",
  "Enter Your Feedback": "Lowetsani Ndemanga Zanu",
  HereSays: "ApaSays",
  Region: "Chigawo",
  Province: "Chigawo",
  Heard: "Wamva",
  Shared: "Zogawana",
  Successfully: "Bwinobwino",
  Explanation: "Kufotokozera",
  Language: "Chiyankhulo",
  Jurisdiction: "Ulamuliro",
  Attention: "Chidwi",
  "LANGUAGE SELECTION": "KUSANKHA CHINENERO",
  ACCESS: "KUPEZEKA",
  "Terms of use": "Mgwirizano pazakagwiritsidwe",
  REFUSE: "KANANI",
  ACCEPT: "VOMEREZANI",
  READ: "WERENGANI",
  PUBLISH: "SIKIZANI",
  CHAT: "CHAT",
  "comming soon": "ikubwera posachedwa",
  Other: "Zina",
  Remark: "Ndemanga",
  Suggestion: "Malingaliro",
  Complaint: "Kudandaula",
  Manual: "Pamanja",
  SEARCH: "FUFUZANI",
  SHARE: "GAWANI",
  DENOUNCE: "DONSE",
  "This post has not been reviewed": "Cholembachi sichinawunikidwe",
  SUBMIT: "TUMIKIRANI",
  Topic: "Mutu",
  "Published BY": "Lofalitsidwa ndi",
  English: "Chingerezi",
  Country: "Dziko",
  City: "Mzinda",
  Place: "Malo",
  "Subject 1": "Mutu 1",
  "Subject 2": "Mutu 2",
  "Subject 3": "Mutu 3",
  "Reference Id": "Id yolozera",
  "Enter Email Address": "Lowetsani Imelo Adilesi",
  CHOOSE: "SANKHANI",
  By: "Wolemba",
  "End": "Kumapeto",
  "Enter Your Story Content": "Lowetsani Nkhani Yanu",
  "UPLOAD IMAGES": "ULWANI ZITHUNZI",
  "Click or Drop here": "Dinani kapena Ikani apa",
  "Remove all images": "Chotsani zithunzi zonse",
  "Your post has been properly submitted": "Zolemba zanu zatumizidwa moyenera",
  "and is published under": "ndipo imasindikizidwa pansi",
  "Please copy this for your reference":
    "Chonde koperani izi kuti mugwiritse ntchito",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "FUNSO LANU LAPEREKEDWA MOYENERA",
};
export const Odia_Oriya = {
  "User Instructions": "ଉପଯୋଗକର୍ତ୍ତା ନିର୍ଦ୍ଦେଶାବଳୀ |",
  Contact: "ଯୋଗାଯୋଗ କରନ୍ତୁ |",
  "News or HereSays Herald": "ନ୍ୟୁଜ୍ କିମ୍ବା ହେରେସ୍ ହେରାଲ୍ଡ |",
  Disclaimer: "ପ୍ରତ୍ୟାଖ୍ୟାନ |",
  "Region or Province or County or State":
    "ଅଞ୍ଚଳ କିମ୍ବା ପ୍ରଦେଶ କିମ୍ବା କାଉଣ୍ଟି କିମ୍ବା ରାଜ୍ୟ |",
  and: "ଏବଂ",
  Date: "ତାରିଖ",
  Content: "ବିଷୟବସ୍ତୁ",
  "TopicName is required": "TopicName ଆବଶ୍ୟକ |",
  "Publisher Name is required": "ପ୍ରକାଶକ ନାମ ଆବଶ୍ୟକ |",
  "Enter Your Feedback": "ଆପଣଙ୍କର ମତାମତ ପ୍ରବେଶ କରନ୍ତୁ |",
  HereSays: "ଏଠାରେ କୁହନ୍ତି |",
  Region: "ଅଞ୍ଚଳ",
  Province: "ପ୍ରଦେଶ",
  Heard: "ଶୁଣ",
  Shared: "ଅଂଶୀଦାର |",
  Successfully: "ସଫଳତାର ସହିତ |",
  Explanation: "ବ୍ୟାଖ୍ୟା",
  Language: "ଭାଷା",
  Jurisdiction: "ଅଧିକାର",
  Attention: "ଧ୍ୟାନ |",
  "LANGUAGE SELECTION": "ଭାଷା ଚୟନ",
  ACCESS: "ACCESS",
  "Terms of use": "ବ୍ୟବହାର ସର୍ତ୍ତାବଳୀ",
  REFUSE: "ପ୍ରତ୍ୟାଖ୍ୟାନ |",
  ACCEPT: "ACCEPT",
  READ: "ପ AD ଼ନ୍ତୁ |",
  PUBLISH: "ପ୍ରକାଶନ |",
  CHAT: "CHAT",
  "comming soon": "ଶୀଘ୍ର ଆସୁଅଛି",
  Other: "ଅନ୍ୟମାନେ |",
  Remark: "ଧ୍ୟାନ ଦିଅନ୍ତୁ |",
  Suggestion: "ପରାମର୍ଶ |",
  Complaint: "ଅଭିଯୋଗ",
  Manual: "ମାନୁଆଲ୍ |",
  SEARCH: "ସନ୍ଧାନ |",
  SHARE: "ଅଂଶୀଦାର କରନ୍ତୁ |",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "ଏହି ପୋଷ୍ଟର ସମୀକ୍ଷା କରାଯାଇ ନାହିଁ |",
  SUBMIT: "ସବମିଟ୍ |",
  Topic: "ବିଷୟ",
  "Published BY": "ପ୍ରକାଶିତ BY",
  English: "ଇଂରାଜୀ |",
  Country: "ଦେଶ",
  City: "ସହର",
  Place: "ସ୍ଥାନ",
  "Subject 1": "ବିଷୟ 1",
  "Subject 2": "ବିଷୟ 2",
  "Subject 3": "ବିଷୟ 3",
  "Reference Id": "ସନ୍ଦର୍ଭ Id",
  "Enter Email Address": "ଇମେଲ୍ ଠିକଣା ପ୍ରବେଶ କରନ୍ତୁ |",
  CHOOSE: "ବାଛନ୍ତୁ |",
  By: "ଦ୍ By ାରା",
  "End": "ସମାପ୍ତ",
  "Enter Your Story Content": "ତୁମର କାହାଣୀ ବିଷୟବସ୍ତୁ ପ୍ରବେଶ କର |",
  "UPLOAD IMAGES": "ପ୍ରତିଛବିଗୁଡିକ ଅପଲୋଡ୍ କରନ୍ତୁ |",
  "Click or Drop here": "ଏଠାରେ କ୍ଲିକ୍ କରନ୍ତୁ କିମ୍ବା ଡ୍ରପ୍ କରନ୍ତୁ |",
  "Remove all images": "ସମସ୍ତ ପ୍ରତିଛବିଗୁଡିକ ଅପସାରଣ କରନ୍ତୁ |",
  "Your post has been properly submitted":
    "ତୁମର ପୋଷ୍ଟ ସଠିକ୍ ଭାବରେ ଉପସ୍ଥାପିତ ହୋଇଛି |",
  "and is published under": "ଏବଂ ତଳେ ପ୍ରକାଶିତ |",
  "Please copy this for your reference":
    "ଆପଣଙ୍କର ସନ୍ଦର୍ଭ ପାଇଁ ଦୟାକରି ଏହାକୁ କପି କରନ୍ତୁ |",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ତୁମର ପ୍ରଶ୍ନଟି ସଠିକ୍ ଭାବରେ ଉପସ୍ଥାପିତ ହୋଇଛି |",
};
export const Oromo = {
  "User Instructions": "Qajeelfama Fayyadamtootaa",
  Contact: "Quunnamuu",
  "News or HereSays Herald": "Oduu ykn KunooJedha Herald",
  Disclaimer: "Itti gaafatamummaa",
  "Region or Province or County or State":
    "Naannoo ykn Godina ykn Naannoo ykn Naannoo",
  and: "fi",
  Date: "Guyyaa",
  Content: "Qabiyyee",
  "TopicName is required": "TopicName jechuun barbaachisaadha",
  "Publisher Name is required": "Maqaa Maxxansaa barbaachisa",
  "Enter Your Feedback": "Yaada Keessan Galchaa",
  HereSays: "KunooJedha",
  Region: "Naannoo",
  Province: "Godina",
  Heard: "Dhaga'e",
  Shared: "Qoodame",
  Successfully: "Milkaa'inaan",
  Explanation: "Ibsa",
  Language: "Afaan",
  Jurisdiction: "Aangoo murtii",
  Attention: "Xiyyeeffannoo",
  "LANGUAGE SELECTION": "FILANNOO AFAAN",
  ACCESS: "ARGACHUU",
  "Terms of use": "Haala itti fayyadama",
  REFUSE: "DIDUU",
  ACCEPT: "SIMACHUU",
  READ: "DUBBISUU",
  PUBLISH: "MAXXANSA",
  CHAT: "WALIIN DUBBII",
  "comming soon": "yeroo dhiyootti dhufaa jira",
  Other: "Kan biraa",
  Remark: "Yaada",
  Suggestion: "Yaada kennamu",
  Complaint: "Mufii",
  Manual: "Kan harkaanii",
  SEARCH: "BARBAADUU",
  SHARE: "QOODUU",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Barreeffamni kun hin ilaalamne",
  SUBMIT: "GALCHUU",
  Topic: "Mataduree",
  "Published BY": "Maxxanfame BY",
  English: "Afaan Ingilizii",
  Country: "Biyya",
  City: "Magaalaa",
  Place: "Iddoo",
  "Subject 1": "Mata duree 1.",
  "Subject 2": "Mata duree 2.1",
  "Subject 3": "Mata duree 3.",
  "Reference Id": "Id Wabii",
  "Enter Email Address": "Teessoo Imeelii Galchi",
  CHOOSE: "FILACHUU",
  By: "N",
  "End": "Xumura",
  "Enter Your Story Content": "Qabiyyee Seenaa Keessan Galchi",
  "UPLOAD IMAGES": "SUURAA OLKA'AA",
  "Click or Drop here": "Asirratti cuqaasaa ykn Gadi dhiisaa",
  "Remove all images": "Fakkiiwwan hunda haqi",
  "Your post has been properly submitted":
    "Barreeffamni keessan sirnaan dhiyaateera",
  "and is published under": "jalatti maxxanfamee jira",
  "Please copy this for your reference":
    "Mee kana akka wabii keessaniif copy godhaa",
  "ID:HERESAYS000": "ID:AKKASUMAA000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "GAAFFIIN KEESSAN SIRRIITTI DHIYAATEE JIRA",
};
export const Pashto = {
  "User Instructions": "د کارن لارښوونې",
  Contact: "اړیکه",
  "News or HereSays Herald": "خبرونه یا HereSays Herald",
  Disclaimer: "ردول",
  "Region or Province or County or State": "سیمه یا ولایت یا ولایت یا ایالت",
  and: "او",
  Date: "نیټه",
  Content: "منځپانګه",
  "TopicName is required": "د موضوع نوم اړین دی",
  "Publisher Name is required": "د خپرونکي نوم اړین دی",
  "Enter Your Feedback": "خپل فیډبیک دننه کړئ",
  HereSays: "دلته وايي",
  Region: "سیمه",
  Province: "ولایت",
  Heard: "اورېدلي",
  Shared: "شریک کړل",
  Successfully: "په بریالیتوب سره",
  Explanation: "تشریح",
  Language: "ژبه",
  Jurisdiction: "قضاوت",
  Attention: "پاملرنه",
  "LANGUAGE SELECTION": "د ژبې انتخاب",
  ACCESS: "لاسرسی",
  "Terms of use": "د کارولو شرایط",
  REFUSE: "انکار",
  ACCEPT: "قبول کړه",
  READ: "لوستل",
  PUBLISH: "خپرول",
  CHAT: "CHAT",
  "comming soon": "ژر راځي",
  Other: "نور",
  Remark: "تبصره",
  Suggestion: "وړاندیز",
  Complaint: "شکایت",
  Manual: "لارښود",
  SEARCH: "لټون",
  SHARE: "شریک کړئ",
  DENOUNCE: "غندنه",
  "This post has not been reviewed": "دا پوسټ بیاکتنه نه ده شوې",
  SUBMIT: "سپارل",
  Topic: "موضوع",
  "Published BY": "لخوا خپور شوی",
  English: "انګلیسي",
  Country: "هیواد",
  City: "ښار",
  Place: "ځای",
  "Subject 1": "موضوع 1",
  "Subject 2": "موضوع 2",
  "Subject 3": "موضوع 3",
  "Reference Id": "د حوالې ID",
  "Enter Email Address": "د بریښنالیک پته دننه کړئ",
  CHOOSE: "غوره کړئ",
  By: "لخوا",
  "End": "ختم",
  "Enter Your Story Content": "د خپلې کیسې مینځپانګه دننه کړئ",
  "UPLOAD IMAGES": "انځورونه پورته کړئ",
  "Click or Drop here": "دلته کلیک وکړئ یا پریږدئ",
  "Remove all images": "ټول انځورونه لرې کړئ",
  "Your post has been properly submitted": "ستاسو پوسټ په سمه توګه سپارل شوی",
  "and is published under": "او لاندې خپور شوی دی",
  "Please copy this for your reference":
    "مهرباني وکړئ دا ستاسو د حوالې لپاره کاپي کړئ",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ستاسو پوښتنه په سمه توګه سپارل شوې ده",
};
export const Persian = {
  "User Instructions": "دستورالعمل های کاربر",
  Contact: "مخاطب",
  "News or HereSays Herald": "News یا HereSays Herald",
  Disclaimer: "سلب مسئولیت",
  "Region or Province or County or State": "منطقه یا استان یا شهرستان یا ایالت",
  and: "و",
  Date: "تاریخ",
  Content: "محتوا",
  "TopicName is required": "نام موضوع مورد نیاز است",
  "Publisher Name is required": "نام ناشر الزامی است",
  "Enter Your Feedback": "بازخورد خود را وارد کنید",
  HereSays: "اینجا می گوید",
  Region: "منطقه",
  Province: "استان",
  Heard: "شنیده شد",
  Shared: "به اشتراک گذاشته شده است",
  Successfully: "با موفقیت",
  Explanation: "توضیح",
  Language: "زبان",
  Jurisdiction: "صلاحیت قضایی",
  Attention: "توجه",
  "LANGUAGE SELECTION": "انتخاب زبان",
  ACCESS: "دسترسی",
  "Terms of use": "شرایط استفاده",
  REFUSE: "امتناع کنید",
  ACCEPT: "تایید کنید",
  READ: "خواندن",
  PUBLISH: "انتشار",
  CHAT: "چت",
  "comming soon": "به زودی",
  Other: "دیگر",
  Remark: "تذکر دهید",
  Suggestion: "پیشنهاد",
  Complaint: "شکایت",
  Manual: "کتابچه راهنمای",
  SEARCH: "جستجو کردن",
  SHARE: "اشتراک گذاری",
  DENOUNCE: "محکوم کردن",
  "This post has not been reviewed": "این پست بررسی نشده است",
  SUBMIT: "ارسال",
  Topic: "موضوع",
  "Published BY": "منتشر شده توسط",
  English: "انگلیسی",
  Country: "کشور",
  City: "شهر",
  Place: "محل",
  "Subject 1": "موضوع 1",
  "Subject 2": "موضوع 2",
  "Subject 3": "موضوع 3",
  "Reference Id": "شناسه مرجع",
  "Enter Email Address": "آدرس ایمیل را وارد کن",
  CHOOSE: "انتخاب کنید",
  By: "توسط",
  "End": "پایان",
  "Enter Your Story Content": "محتوای داستان خود را وارد کنید",
  "UPLOAD IMAGES": "آپلود تصاویر",
  "Click or Drop here": "اینجا کلیک کنید یا رها کنید",
  "Remove all images": "تمام تصاویر را حذف کنید",
  "Your post has been properly submitted": "پست شما به درستی ارسال شده است",
  "and is published under": "و تحت عنوان منتشر شده است",
  "Please copy this for your reference": "لطفا این را برای مرجع خود کپی کنید",
  "ID:HERESAYS000": "شناسه:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "درخواست شما به درستی ارسال شده است",
};
export const Punjabi = {
  "User Instructions": "ਉਪਭੋਗਤਾ ਨਿਰਦੇਸ਼",
  Contact: "ਸੰਪਰਕ ਕਰੋ",
  "News or HereSays Herald": "ਨਿਊਜ਼ ਜਾਂ HereSays Herald",
  Disclaimer: "ਬੇਦਾਅਵਾ",
  "Region or Province or County or State": "ਖੇਤਰ ਜਾਂ ਸੂਬਾ ਜਾਂ ਕਾਉਂਟੀ ਜਾਂ ਰਾਜ",
  and: "ਅਤੇ",
  Date: "ਤਾਰੀਖ਼",
  Content: "ਸਮੱਗਰੀ",
  "TopicName is required": "ਵਿਸ਼ੇ ਦਾ ਨਾਮ ਲੋੜੀਂਦਾ ਹੈ",
  "Publisher Name is required": "ਪ੍ਰਕਾਸ਼ਕ ਦਾ ਨਾਮ ਲੋੜੀਂਦਾ ਹੈ",
  "Enter Your Feedback": "ਆਪਣਾ ਫੀਡਬੈਕ ਦਾਖਲ ਕਰੋ",
  HereSays: "ਇੱਥੇ ਕਹਿੰਦੇ ਹਨ",
  Region: "ਖੇਤਰ",
  Province: "ਸੂਬਾ",
  Heard: "ਸੁਣਿਆ",
  Shared: "ਸਾਂਝਾ ਕੀਤਾ",
  Successfully: "ਸਫਲਤਾਪੂਰਵਕ",
  Explanation: "ਵਿਆਖਿਆ",
  Language: "ਭਾਸ਼ਾ",
  Jurisdiction: "ਅਧਿਕਾਰ ਖੇਤਰ",
  Attention: "ਧਿਆਨ",
  "LANGUAGE SELECTION": "ਭਾਸ਼ਾ ਦੀ ਚੋਣ",
  ACCESS: "ਪਹੁੰਚ",
  "Terms of use": "ਵਰਤੋ ਦੀਆਂ ਸ਼ਰਤਾਂ",
  REFUSE: "ਇਨਕਾਰ",
  ACCEPT: "ਸਵੀਕਾਰ ਕਰੋ",
  READ: "ਪੜ੍ਹੋ",
  PUBLISH: "ਪ੍ਰਕਾਸ਼ਿਤ ਕਰੋ",
  CHAT: "ਚੈਟ",
  "comming soon": "ਜਲਦੀ ਆ ਰਿਹਾ ਹੈ",
  Other: "ਹੋਰ",
  Remark: "ਟਿੱਪਣੀ",
  Suggestion: "ਸੁਝਾਅ",
  Complaint: "ਸ਼ਿਕਾਇਤ",
  Manual: "ਮੈਨੁਅਲ",
  SEARCH: "ਖੋਜ ਕਰੋ",
  SHARE: "ਸ਼ੇਅਰ ਕਰੋ",
  DENOUNCE: "ਨਿੰਦਾ ਕਰੋ",
  "This post has not been reviewed": "ਇਸ ਪੋਸਟ ਦੀ ਸਮੀਖਿਆ ਨਹੀਂ ਕੀਤੀ ਗਈ ਹੈ",
  SUBMIT: "ਜਮ੍ਹਾਂ ਕਰੋ",
  Topic: "ਵਿਸ਼ਾ",
  "Published BY": "ਦੁਆਰਾ ਪ੍ਰਕਾਸ਼ਿਤ",
  English: "ਅੰਗਰੇਜ਼ੀ",
  Country: "ਦੇਸ਼",
  City: "ਸ਼ਹਿਰ",
  Place: "ਸਥਾਨ",
  "Subject 1": "ਵਿਸ਼ਾ 1",
  "Subject 2": "ਵਿਸ਼ਾ 2",
  "Subject 3": "ਵਿਸ਼ਾ 3",
  "Reference Id": "ਹਵਾਲਾ ਆਈ.ਡੀ",
  "Enter Email Address": "ਈਮੇਲ ਪਤਾ ਦਰਜ ਕਰੋ",
  CHOOSE: "ਚੁਣੋ",
  By: "ਨਾਲ",
  "End": "ਖ਼ਤਮ",
  "Enter Your Story Content": "ਆਪਣੀ ਕਹਾਣੀ ਸਮੱਗਰੀ ਦਾਖਲ ਕਰੋ",
  "UPLOAD IMAGES": "ਤਸਵੀਰਾਂ ਅੱਪਲੋਡ ਕਰੋ",
  "Click or Drop here": "ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ ਜਾਂ ਛੱਡੋ",
  "Remove all images": "ਸਾਰੀਆਂ ਤਸਵੀਰਾਂ ਹਟਾਓ",
  "Your post has been properly submitted":
    "ਤੁਹਾਡੀ ਪੋਸਟ ਸਹੀ ਢੰਗ ਨਾਲ ਦਰਜ ਕੀਤੀ ਗਈ ਹੈ",
  "and is published under": "ਅਤੇ ਅਧੀਨ ਪ੍ਰਕਾਸ਼ਿਤ ਕੀਤਾ ਗਿਆ ਹੈ",
  "Please copy this for your reference":
    "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਹਵਾਲੇ ਲਈ ਇਸ ਨੂੰ ਕਾਪੀ ਕਰੋ",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ਤੁਹਾਡੀ ਪੁੱਛਗਿੱਛ ਸਹੀ ਢੰਗ ਨਾਲ ਸਪੁਰਦ ਕੀਤੀ ਗਈ ਹੈ",
};
export const Quechua = {
  "User Instructions": "Usuariopa kamachikuyninkuna",
  Contact: "Tupaqmasi",
  "News or HereSays Herald": "Noticias o KaypiSin Herald",
  Disclaimer: "Mana ruway atiy",
  "Region or Province or County or State":
    "Región utaq Provincia utaq Condado utaq Estado",
  and: "chaymanta",
  Date: "Imay pacha",
  Content: "Winay",
  "TopicName is required": "TopicName nisqatan necesitakun",
  "Publisher Name is required": "Willakuqpa Sutinmi necesitakun",
  "Enter Your Feedback": "Yaykuchiy Rimanakuyniykita",
  HereSays: "KaypiNi",
  Region: "Suyu",
  Province: "Provincia",
  Heard: "Rinri",
  Shared: "Compartido",
  Successfully: "Allinta ruwaspa",
  Explanation: "Willay",
  Language: "Simi",
  Jurisdiction: "Jurisdicción nisqa",
  Attention: "Yuyachiy",
  "LANGUAGE SELECTION": "SIMI AKLLAY",
  ACCESS: "YAYKUNA",
  "Terms of use": "Términos de uso nisqa",
  REFUSE: "PUCHUQKUNA",
  ACCEPT: "UYAKUY",
  READ: "ÑAWINCHAY",
  PUBLISH: "PUBLICIÓN",
  CHAT: "RIMANAKUY",
  "comming soon": "pisi tiempollamanta hamuq",
  Other: "Huk",
  Remark: "Remarca nisqa",
  Suggestion: "Kunay",
  Complaint: "Willarikuy",
  Manual: "Makiwan",
  SEARCH: "MASKAY",
  SHARE: "QUNAKUY",
  DENOUNCE: "DENUNCIA",
  "This post has not been reviewed": "Kay qillqasqaqa manam qhawarisqachu",
  SUBMIT: "APACHIY",
  Topic: "Rimay",
  "Published BY": "Publicado BY",
  English: "Ingles Simi",
  Country: "Hatun llaqta",
  City: "Llaqta",
  Place: "Kiti",
  "Subject 1": "Tema 1. 1.1",
  "Subject 2": "Tema 2. 1.1",
  "Subject 3": "Tema 3. 1.1",
  "Reference Id": "Referencia Id",
  "Enter Email Address": "Dirección de Correo electrónico nisqapi qillqay",
  CHOOSE: "AKLLAY",
  By: "Por",
  "End": "Tukukuy",
  "Enter Your Story Content": "Willakuyniykipi Contenidota qillqay",
  "UPLOAD IMAGES": "IMAGENKUNA APROGAY",
  "Click or Drop here": "Kaypi ñit’iy utaq urmachiy",
  "Remove all images": "Llapan siq’ikunata hurquy",
  "Your post has been properly submitted":
    "Qillqasqaykiqa allintam apachisqa kachkan",
  "and is published under": "hinaspapas kay urapi lluqsimun",
  "Please copy this for your reference":
    "Ama hina kaspa, kayta copiay referenciaykipaq",
  "ID:HERESAYS000": "ID:KUNAMANTA000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "TAPUYNIYKIQA ALLINMI KAMACHISQA",
};
export const Samoan = {
  "User Instructions": "Fa'atonuga a le Fa'aoga",
  Contact: "Fa'afeso'ota'i",
  "News or HereSays Herald": "Tala Fou poo HereSays Herald",
  Disclaimer: "Fa'ailoga",
  "Region or Province or County or State":
    "Itulagi po'o le Itumalo po'o le Itumalo po'o le Setete",
  and: "ma",
  Date: "Aso",
  Content: "Anotusi",
  "TopicName is required": "E mana'omia le TopicName",
  "Publisher Name is required": "E mana'omia le Igoa o le Lomitusi",
  "Enter Your Feedback": "Ulufale mai Lau Manatu Fa'aalia",
  HereSays: "Lenei Fai mai",
  Region: "Itulagi",
  Province: "Itumalo",
  Heard: "Faalogo",
  Shared: "Fa'asoa",
  Successfully: "Ma le manuia",
  Explanation: "Fa'amatalaga",
  Language: "Gagana",
  Jurisdiction: "Puleaga",
  Attention: "Fa'alogo",
  "LANGUAGE SELECTION": "FILIFILIGA GAGANA",
  ACCESS: "AVANOA",
  "Terms of use": "Tulaga fa'aoga",
  REFUSE: "MUSU",
  ACCEPT: "TALIA",
  READ: "FAITAU",
  PUBLISH: "LOLOGI",
  CHAT: "TALA",
  "comming soon": "ua lata mai",
  Other: "Isi",
  Remark: "Fa'amatalaga",
  Suggestion: "Fautuaga",
  Complaint: "Fa'asea",
  Manual: "Tusi Lesona",
  SEARCH: "SILI",
  SHARE: "FA'AFEAGAI",
  DENOUNCE: "TAGATA",
  "This post has not been reviewed": "E le'i toe iloiloina lenei pou",
  SUBMIT: "GALUEGA",
  Topic: "Autu",
  "Published BY": "Lolomiina E",
  English: "Igilisi",
  Country: "Atunuu",
  City: "Aai",
  Place: "Nofoaga",
  "Subject 1": "Mataupu 1",
  "Subject 2": "Mataupu 2",
  "Subject 3": "Mataupu 3",
  "Reference Id": "Fa'asinoga Id",
  "Enter Email Address": "Ulufale tuatusi imeli",
  CHOOSE: "FILIFILI",
  By: "E",
  "End": "O le faaiuga",
  "Enter Your Story Content": "Ulufale i totonu o lau Tala",
  "UPLOAD IMAGES": "LA'U ATA",
  "Click or Drop here": "Kiliki pe Pa'u iinei",
  "Remove all images": "Aveese uma ata",
  "Your post has been properly submitted": "Ua uma ona tu'uina atu lau pou",
  "and is published under": "ma ua lomia i lalo",
  "Please copy this for your reference":
    "Fa'amolemole kopi lenei mea mo lau fa'amatalaga",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "UA FAATUATUA MAI LAU FESILI",
};
export const Sanskrit = {
  "User Instructions": "उपयोक्तृनिर्देशाः",
  Contact: "संपर्कः",
  "News or HereSays Herald": "समाचारः वा अत्र हेराल्ड् वदति",
  Disclaimer: "अस्वीकरणम्",
  "Region or Province or County or State":
    "क्षेत्रं वा प्रान्तं वा काउण्टी वा राज्यम्",
  and: "तथा",
  Date: "दिनाङ्कः",
  Content: "विषयः",
  "TopicName is required": "TopicName आवश्यकम् अस्ति",
  "Publisher Name is required": "प्रकाशकस्य नाम आवश्यकम् अस्ति",
  "Enter Your Feedback": "स्वस्य प्रतिक्रियां प्रविशतु",
  HereSays: "अत्र वदति",
  Region: "क्षेत्र",
  Province: "प्रांत",
  Heard: "श्रुत",
  Shared: "साझा",
  Successfully: "सफलतया",
  Explanation: "विवरण",
  Language: "भाषा",
  Jurisdiction: "न्यायक्षेत्रम्",
  Attention: "अवधानम्‌",
  "LANGUAGE SELECTION": "भाषाचयनम्",
  ACCESS: "अभिगमः",
  "Terms of use": "उपयोगस्य शर्ताः",
  REFUSE: "अस्वीकार",
  ACCEPT: "स्वीकरोतु",
  READ: "पठतु",
  PUBLISH: "PUBLISH",
  CHAT: "संवाद",
  "comming soon": "शीघ्रमेव आगच्छति",
  Other: "इतर",
  Remark: "टिप्पणी",
  Suggestion: "परामर्श",
  Complaint: "अनुबद्ध",
  Manual: "शास्त्र",
  SEARCH: "अन्वेषण",
  SHARE: "संविभागः",
  DENOUNCE: "DENOUNCE इति",
  "This post has not been reviewed": "अस्य पदस्य समीक्षा न कृता",
  SUBMIT: "उपस्थापयतु",
  Topic: "विषय",
  "Published BY": "प्रकाशित BY",
  English: "आंग्ल",
  Country: "देशः",
  City: "नगरी",
  Place: "स्थानम्‌",
  "Subject 1": "विषयः १",
  "Subject 2": "विषयः २",
  "Subject 3": "विषय 3",
  "Reference Id": "सन्दर्भ Id",
  "Enter Email Address": "ईमेल पता प्रविष्टं कुर्वन्तु",
  CHOOSE: "चिनोतु",
  By: "इत्यनेन",
  "End": "अन्तम्",
  "Enter Your Story Content": "स्वकथासामग्री प्रविशतु",
  "UPLOAD IMAGES": "UPLOAD IMAGES",
  "Click or Drop here": "अत्र क्लिक् कुर्वन्तु अथवा Drop कुर्वन्तु",
  "Remove all images": "सर्वाणि चित्राणि निष्कासयन्तु",
  "Your post has been properly submitted": "भवतः पदं सम्यक् प्रदत्तम् अस्ति",
  "and is published under": "अन्तर्गतं च प्रकाशितं भवति",
  "Please copy this for your reference":
    "कृपया स्वसन्दर्भार्थं एतत् प्रतिलिख्यताम्",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "भवतः प्रश्नः सम्यक् प्रदत्तः अस्ति",
};
export const Scots_Gaelic = {
  "User Instructions": "Stiùireadh Cleachdaiche",
  Contact: "Cuir fios",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Àicheadh",
  "Region or Province or County or State":
    "Roinn no Mòr-roinn no Siorramachd no Stàit",
  and: "agus",
  Date: "Ceann-latha",
  Content: "Susbaint",
  "TopicName is required": "Tha TopicName a dhìth",
  "Publisher Name is required": "Tha feum air Ainm Foillsichear",
  "Enter Your Feedback": "Cuir a-steach do bheachdan air ais",
  HereSays: "Seo Says",
  Region: "Roinn",
  Province: "Mòr-roinn",
  Heard: "Chuala",
  Shared: "Roinnte",
  Successfully: "Gu soirbheachail",
  Explanation: "Mìneachadh",
  Language: "Cànain",
  Jurisdiction: "Uachdranas",
  Attention: "An aire",
  "LANGUAGE SELECTION": "ROGHAINN CÀNAIN",
  ACCESS: "ROCHDAIN",
  "Terms of use": "Teirmean cleachdaidh",
  REFUSE: "Diùltadh",
  ACCEPT: "GLACADH",
  READ: "LEUGHADH",
  PUBLISH: "FOILLSEACHADH",
  CHAT: "CATHAIR",
  "comming soon": "a' tighinn a dh'aithghearr",
  Other: "Eile",
  Remark: "Facal",
  Suggestion: "Moladh",
  Complaint: "Gearan",
  Manual: "Leabhar-làimhe",
  SEARCH: "SEALLADH",
  SHARE: "ROINN",
  DENOUNCE: "DÌONADH",
  "This post has not been reviewed": "Cha deach an dreuchd seo ath-sgrùdadh",
  SUBMIT: "CUIMHNEACHADH",
  Topic: "Cuspair",
  "Published BY": "Air fhoillseachadh LE",
  English: "Sasannach",
  Country: "Dùthaich",
  City: "Cathair-bhaile",
  Place: "Àite",
  "Subject 1": "Cuspair 1",
  "Subject 2": "Cuspair 2",
  "Subject 3": "Cuspair 3",
  "Reference Id": "ID iomraidh",
  "Enter Email Address": "Cuir a-steach Seòladh Post-d",
  CHOOSE: "ROGHAINN",
  By: "Le bhith",
  "End": "An deireadh",
  "Enter Your Story Content": "Cuir a-steach susbaint na sgeulachd agad",
  "UPLOAD IMAGES": "Ìomhaighean A luchdachadh suas",
  "Click or Drop here": "Cliog no leig às an seo",
  "Remove all images": "Thoir air falbh a h-uile dealbh",
  "Your post has been properly submitted":
    "Chaidh am post agad a chuir a-steach ceart",
  "and is published under": "agus air fhoillseachadh fo",
  "Please copy this for your reference":
    "Feuch an dèan thu lethbhreac den seo airson d’ iomradh",
  "ID:HERESAYS000": "ID: AN SEO 000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "THA DO CHEIST AIR TACHARTAS A CHUIR A CHUIR A CHUIDEACHD",
};
export const Sepedi = {
  "User Instructions": "Ditaelo tša Modiriši",
  Contact: "Kgokaganyo",
  "News or HereSays Herald": "Ditaba goba MoGo re Herald",
  Disclaimer: "Tlhagišo ya go itokolla",
  "Region or Province or County or State":
    "Selete goba Profense goba Selete goba Mmušo",
  and: "le",
  Date: "Letšatšikgwedi",
  Content: "Diteng",
  "TopicName is required": "Leina la Sehlogo le a nyakega",
  "Publisher Name is required": "Leina la Mogoeledi le a nyakega",
  "Enter Your Feedback": "Tsenya Ditshwayotshwayo tša Gago",
  HereSays: "MonaO re",
  Region: "Selete",
  Province: "Profense",
  Heard: "Kwele",
  Shared: "E abelanwa",
  Successfully: "Ka katlego",
  Explanation: "Tlhalošo",
  Language: "Polelo",
  Jurisdiction: "Taolo ya taolo",
  Attention: "Tlhokomelo",
  "LANGUAGE SELECTION": "KGETHO YA POLELO",
  ACCESS: "FIHLELELA",
  "Terms of use": "Dipeelano tša tšhomišo",
  REFUSE: "GANA",
  ACCEPT: "AMOGELA",
  READ: "BALA",
  PUBLISH: "KGATIŠA",
  CHAT: "BOLEDIŠANA",
  "comming soon": "e tlago kgauswinyane",
  Other: "Nngwe",
  Remark: "Tlhokomedišo",
  Suggestion: "Tšhišinyo",
  Complaint: "Pelaelo",
  Manual: "Manyuale",
  SEARCH: "NYAKA",
  SHARE: "ABELANA",
  DENOUNCE: "GO HLOKOMELA",
  "This post has not been reviewed": "Poso ye ga se ya hlahlobja",
  SUBMIT: "TLIŠA",
  Topic: "Hlogotaba",
  "Published BY": "E phatlaladitšwe KA",
  English: "Seisimane",
  Country: "Naga",
  City: "Toropokgolo",
  Place: "Lefelo",
  "Subject 1": "Sehlogo sa 1.1",
  "Subject 2": "Sehlogo sa 2",
  "Subject 3": "Sehlogo sa 3",
  "Reference Id": "Id ya Tšhupetšo",
  "Enter Email Address": "Kenya Aterese ya Imeile",
  CHOOSE: "KGETHA",
  By: "Ka",
  "End": "Bofelo bja Bofelo",
  "Enter Your Story Content": "Tsenya Diteng tša Pale ya Gago",
  "UPLOAD IMAGES": "UPLOAD DISWANTŠHO",
  "Click or Drop here": "Tobetsa goba Lahlela mo",
  "Remove all images": "Tlosa diswantšho ka moka",
  "Your post has been properly submitted": "Poso ya gago e rometšwe gabotse",
  "and is published under": "gomme e phatlalatšwa ka fase ga",
  "Please copy this for your reference":
    "Hle kopiša se bakeng sa tšhupetšo ya gago",
  "ID:HERESAYS000": "ID:MATLHOMISO000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "POTŠIŠO YA GAGO E ROMELETŠWE KA SWANA",
};
export const Sesotho = {
  "User Instructions": "Litaelo tsa mosebelisi",
  Contact: "Ikopanye",
  "News or HereSays Herald": "Litaba kapa HereSays Herald",
  Disclaimer: "Boithorollo",
  "Region or Province or County or State":
    "Sebaka kapa Porofense kapa Setereke kapa Naha",
  and: "le",
  Date: "Letsatsi",
  Content: "Litaba",
  "TopicName is required": "TopicName ea hlokahala",
  "Publisher Name is required": "Lebitso la Mohatisi lea hlokahala",
  "Enter Your Feedback": "Kenya Maikutlo a Hao",
  HereSays: "HereSays",
  Region: "Sebaka",
  Province: "Profinseng",
  Heard: "Utloa",
  Shared: "E arolelanoeng",
  Successfully: "Ka katleho",
  Explanation: "Tlhaloso",
  Language: "Puo",
  Jurisdiction: "Bolaoli",
  Attention: "Tlhokomeliso",
  "LANGUAGE SELECTION": "KHETHA PUO",
  ACCESS: "FINYELLO",
  "Terms of use": "Lipehelo tsa tšebeliso",
  REFUSE: "KHANYA",
  ACCEPT: "AMOHELA",
  READ: "BALA",
  PUBLISH: "PHATLALATSA",
  CHAT: "KHABANE",
  "comming soon": "e tlang haufinyane",
  Other: "Tse ding",
  Remark: "Ela hloko",
  Suggestion: "Tlhahiso",
  Complaint: "Tletlebo",
  Manual: "Bukana",
  SEARCH: "BATLA",
  SHARE: "KABELO",
  DENOUNCE: "NYATSO",
  "This post has not been reviewed": "Poso ena ha e so hlahlojoe",
  SUBMIT: "NYELISA",
  Topic: "Sehlooho",
  "Published BY": "E hatisitsoe KE",
  English: "Senyesemane",
  Country: "Naha",
  City: "Motse",
  Place: "Sebaka",
  "Subject 1": "Sehlooho sa 1",
  "Subject 2": "Sehlooho sa 2",
  "Subject 3": "Sehlooho sa 3",
  "Reference Id": "Id Reference",
  "Enter Email Address": "Kenya Aterese ea Imeile",
  CHOOSE: "KHETHA",
  By: "Ka",
  "End": "Qetello",
  "Enter Your Story Content": "Kenya Litaba tsa Pale ea Hau",
  "UPLOAD IMAGES": "RELETSENG LITŠOANTŠO",
  "Click or Drop here": "Tobetsa kapa Theola mona",
  "Remove all images": "Tlosa litšoantšo tsohle",
  "Your post has been properly submitted": "Poso ea hau e kentsoe ka nepo",
  "and is published under": "mme e phatlalatswa tlasa",
  "Please copy this for your reference":
    "Ka kopo, kopitsa sena molemong oa hau",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "POTSO EA HAO E FUMANETSOE KA NTLE",
};
export const Shona = {
  "User Instructions": "Mirayiridzo Yemushandisi",
  Contact: "Contact",
  "News or HereSays Herald": "Nhau kana HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Dunhu kana Ruwa kana Ruwa kana Nyika",
  and: "uye",
  Date: "Date",
  Content: "Content",
  "TopicName is required": "TopicName inodiwa",
  "Publisher Name is required": "Zita remuparidzi rinodiwa",
  "Enter Your Feedback": "Pinda Mhinduro Yako",
  HereSays: "HereSays",
  Region: "Dunhu",
  Province: "Province",
  Heard: "Heard",
  Shared: "Shared",
  Successfully: "Zvakabudirira",
  Explanation: "Tsanangudzo",
  Language: "Mutauro",
  Jurisdiction: "Jurisdiction",
  Attention: "Attention",
  "LANGUAGE SELECTION": "KUSARUDZA MUTAURO",
  ACCESS: "ACCESS",
  "Terms of use": "Mitemo yekushandisa",
  REFUSE: "RAMBA",
  ACCEPT: "BVUMA",
  READ: "VERENGA",
  PUBLISH: "SHANDISA",
  CHAT: "CHAT",
  "comming soon": "kuuya nokukurumidza",
  Other: "Zvimwe",
  Remark: "Remark",
  Suggestion: "Suggestion",
  Complaint: "Chichemo",
  Manual: "Manual",
  SEARCH: "SEARCH",
  SHARE: "SHARE",
  DENOUNCE: "SHOURA",
  "This post has not been reviewed": "Ichi chinyorwa hachina kuongororwa",
  SUBMIT: "SUBMIT",
  Topic: "Topic",
  "Published BY": "Rakabudiswa BY",
  English: "Chirungu",
  Country: "Nyika",
  City: "Guta",
  Place: "Nzvimbo",
  "Subject 1": "Chidzidzo 1",
  "Subject 2": "Chidzidzo chechipiri",
  "Subject 3": "Chidzidzo chetatu",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Isa Email Kero",
  CHOOSE: "SARUDZA",
  By: "By",
  "End": "Magumo",
  "Enter Your Story Content": "Pinda Nyaya Yako Yemukati",
  "UPLOAD IMAGES": "ISA MIFANANIDZO",
  "Click or Drop here": "Dzvanya kana Donhedza pano",
  "Remove all images": "Bvisa mifananidzo yese",
  "Your post has been properly submitted": "Zvawatumira zvatumirwa nemazvo",
  "and is published under": "uye inoburitswa pasi",
  "Please copy this for your reference":
    "Ndokumbira ukope izvi kuti uwane referensi yako",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "MUBVUNZO WAKO WAPINDURWA ZVAKANAKA",
};
export const Sindhi = {
  "User Instructions": "استعمال ڪندڙ هدايتون",
  Contact: "رابطو",
  "News or HereSays Herald": "خبر يا HereSays Herald",
  Disclaimer: "رد ڪرڻ",
  "Region or Province or County or State": "علائقو يا صوبو يا ملڪ يا رياست",
  and: "۽",
  Date: "تاريخ",
  Content: "مواد",
  "TopicName is required": "موضوع جو نالو گھربل آھي",
  "Publisher Name is required": "ناشر جو نالو گھربل آھي",
  "Enter Your Feedback": "پنھنجي راءِ داخل ڪريو",
  HereSays: "هتي چوي ٿو",
  Region: "علائقو",
  Province: "صوبو",
  Heard: "ٻڌو",
  Shared: "شيئر ڪيل",
  Successfully: "ڪاميابي سان",
  Explanation: "وضاحت",
  Language: "ٻولي",
  Jurisdiction: "جهيڙو",
  Attention: "ڌيان ڏيڻ",
  "LANGUAGE SELECTION": "ٻولي جي چونڊ",
  ACCESS: "رسائي",
  "Terms of use": "استعمال جا شرط",
  REFUSE: "انڪار ڪرڻ",
  ACCEPT: "قبول ڪريو",
  READ: "پڙهو",
  PUBLISH: "شايع ڪريو",
  CHAT: "چيٽ",
  "comming soon": "تمام جلد",
  Other: "ٻيو",
  Remark: "تبصرو",
  Suggestion: "تجويز",
  Complaint: "شڪايت",
  Manual: "دستوري",
  SEARCH: "ڳولهيو",
  SHARE: "شيئر ڪريو",
  DENOUNCE: "مذمت",
  "This post has not been reviewed": "هن پوسٽ جو جائزو نه ورتو ويو آهي",
  SUBMIT: "جمع ڪرايو",
  Topic: "موضوع",
  "Published BY": "پاران شايع ٿيل",
  English: "انگريزي",
  Country: "ملڪ",
  City: "شهر",
  Place: "جاءِ",
  "Subject 1": "مضمون 1",
  "Subject 2": "مضمون 2",
  "Subject 3": "مضمون 3",
  "Reference Id": "حوالو Id",
  "Enter Email Address": "اي ميل ايڊريس داخل ڪريو",
  CHOOSE: "چونڊيو",
  By: "پاران",
  "End": "اختتام",
  "Enter Your Story Content": "پنھنجي ڪهاڻي جو مواد داخل ڪريو",
  "UPLOAD IMAGES": "اپلوڊ تصويرون",
  "Click or Drop here": "هتي ڪلڪ ڪريو يا ڇڏي ڏيو",
  "Remove all images": "سڀني تصويرن کي هٽايو",
  "Your post has been properly submitted":
    "توهان جي پوسٽ صحيح طور تي جمع ڪئي وئي آهي",
  "and is published under": "۽ هيٺ شايع ٿيل آهي",
  "Please copy this for your reference":
    "مهرباني ڪري هن کي نقل ڪريو توهان جي حوالي لاء",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "توھان جو سوال صحيح طور تي جمع ڪيو ويو آھي",
};
export const Sinhala_Sinhalese = {
  "User Instructions": "පරිශීලක උපදෙස්",
  Contact: "අමතන්න",
  "News or HereSays Herald": "පුවත් හෝ HereSays Herald",
  Disclaimer: "වියාචනය",
  "Region or Province or County or State":
    "කලාපය හෝ පළාත හෝ ප්‍රාන්තය හෝ ප්‍රාන්තය",
  and: "සහ",
  Date: "දිනය",
  Content: "අන්තර්ගතය",
  "TopicName is required": "TopicName අවශ්‍යයි",
  "Publisher Name is required": "ප්‍රකාශකයාගේ නම අවශ්‍යයි",
  "Enter Your Feedback": "ඔබේ ප්‍රතිපෝෂණය ඇතුළත් කරන්න",
  HereSays: "මෙන්න මෙහෙම කියනවා",
  Region: "කලාපයේ",
  Province: "පළාත",
  Heard: "අසා ඇත",
  Shared: "බෙදාගෙන ඇත",
  Successfully: "සාර්ථකව",
  Explanation: "පැහැදිලි කිරීම",
  Language: "භාෂාව",
  Jurisdiction: "අධිකරණ බලය",
  Attention: "අවධානය",
  "LANGUAGE SELECTION": "භාෂා තේරීම",
  ACCESS: "ප්රවේශ",
  "Terms of use": "භාවිත නියම",
  REFUSE: "ප්රතික්ෂේප කරන්න",
  ACCEPT: "පිළිගන්න",
  READ: "කියවන්න",
  PUBLISH: "ප්‍රකාශනය කරන්න",
  CHAT: "චැට්",
  "comming soon": "ළඟදීම එනවා",
  Other: "අනික්",
  Remark: "සටහන් කරන්න",
  Suggestion: "යෝජනාව",
  Complaint: "පැමිණිල්ලක්",
  Manual: "අත්පොත",
  SEARCH: "සෙවීම",
  SHARE: "බෙදාගන්න",
  DENOUNCE: "හෙලා දකිනවා",
  "This post has not been reviewed": "මෙම පළ කිරීම සමාලෝචනය කර නොමැත",
  SUBMIT: "ඉදිරිපත් කරන්න",
  Topic: "මාතෘකාව",
  "Published BY": "විසින් ප්රකාශයට පත් කර",
  English: "ඉංග්රීසි",
  Country: "රට",
  City: "නගරය",
  Place: "ස්ථානය",
  "Subject 1": "විෂය 1",
  "Subject 2": "විෂය 2",
  "Subject 3": "විෂය 3",
  "Reference Id": "යොමු හැඳුනුම්පත",
  "Enter Email Address": "ඊමේල් ලිපිනය ඇතුලත් කරන්න",
  CHOOSE: "තෝරා",
  By: "විසින්",
  "End": "අවසානය",
  "Enter Your Story Content": "ඔබේ කතාවේ අන්තර්ගතය ඇතුළත් කරන්න",
  "UPLOAD IMAGES": "පින්තූර උඩුගත කරන්න",
  "Click or Drop here": "මෙහි ක්ලික් කරන්න හෝ හෙළන්න",
  "Remove all images": "සියලුම පින්තූර ඉවත් කරන්න",
  "Your post has been properly submitted":
    "ඔබගේ පළ කිරීම නිවැරදිව ඉදිරිපත් කර ඇත",
  "and is published under": "යටතේ ප්‍රකාශයට පත් කෙරේ",
  "Please copy this for your reference":
    "කරුණාකර ඔබගේ යොමුව සඳහා මෙය පිටපත් කරන්න",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ඔබගේ විමසුම නිසි ලෙස ඉදිරිපත් කර ඇත",
};
export const Slovenian = {
  "User Instructions": "Uporabniška navodila",
  Contact: "Kontakt",
  "News or HereSays Herald": "News ali HereSays Herald",
  Disclaimer: "Zavrnitev odgovornosti",
  "Region or Province or County or State":
    "Regija ali provinca ali okrožje ali država",
  and: "in",
  Date: "Datum",
  Content: "Vsebina",
  "TopicName is required": "Ime teme je obvezno",
  "Publisher Name is required": "Zahtevano je ime založnika",
  "Enter Your Feedback": "Vnesite svoje povratne informacije",
  HereSays: "HereSays",
  Region: "Regija",
  Province: "Pokrajina",
  Heard: "slišal",
  Shared: "V skupni rabi",
  Successfully: "Uspešno",
  Explanation: "Pojasnilo",
  Language: "Jezik",
  Jurisdiction: "Pristojnost",
  Attention: "Pozor",
  "LANGUAGE SELECTION": "IZBIRA JEZIKA",
  ACCESS: "DOSTOP",
  "Terms of use": "Pogoji uporabe",
  REFUSE: "ZAVRNITE",
  ACCEPT: "SPREJEM",
  READ: "PREBERI",
  PUBLISH: "OBJAVI",
  CHAT: "KLEPET",
  "comming soon": "prihaja kmalu",
  Other: "drugo",
  Remark: "Opomba",
  Suggestion: "Predlog",
  Complaint: "Pritožba",
  Manual: "Priročnik",
  SEARCH: "ISKANJE",
  SHARE: "DELITI",
  DENOUNCE: "ODLOČITE",
  "This post has not been reviewed": "Ta objava ni bila pregledana",
  SUBMIT: "POŠLJI",
  Topic: "Tema",
  "Published BY": "Objavil BY",
  English: "angleščina",
  Country: "Država",
  City: "Mesto",
  Place: "Kraj",
  "Subject 1": "Zadeva 1",
  "Subject 2": "Zadeva 2",
  "Subject 3": "Zadeva 3",
  "Reference Id": "Referenčni ID",
  "Enter Email Address": "Vnesite e-poštni naslov",
  CHOOSE: "IZBERI",
  By: "Avtor:",
  "End": "Konec",
  "Enter Your Story Content": "Vnesite vsebino svoje zgodbe",
  "UPLOAD IMAGES": "NALOŽI SLIKE",
  "Click or Drop here": "Kliknite ali spustite sem",
  "Remove all images": "Odstrani vse slike",
  "Your post has been properly submitted":
    "Vaša objava je bila pravilno poslana",
  "and is published under": "in je objavljen pod",
  "Please copy this for your reference": "Kopirajte to za svojo referenco",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "VAŠE VPRAŠANJE JE PRAVILNO ODDANO",
};
export const Somali = {
  "User Instructions": "Tilmaamaha Isticmaalaha",
  Contact: "La xidhiidh",
  "News or HereSays Herald": "Wararka ama HereSays Herald",
  Disclaimer: "Afeef",
  "Region or Province or County or State":
    "Gobol ama gobol ama degmo ama gobol",
  and: "iyo",
  Date: "Taariikhda",
  Content: "Nuxurka",
  "TopicName is required": "Mawduuca Magaca ayaa loo baahan yahay",
  "Publisher Name is required": "Magaca daabacaha ayaa loo baahan yahay",
  "Enter Your Feedback": "Geli Jawaab celintaada",
  HereSays: "Halkan waxa uu leeyahay",
  Region: "Gobolka",
  Province: "Gobolka",
  Heard: "Maqlay",
  Shared: "La wadaagay",
  Successfully: "Si guul leh",
  Explanation: "Sharaxaad",
  Language: "Luuqad",
  Jurisdiction: "Xukunka",
  Attention: "Fiiro gaar ah",
  "LANGUAGE SELECTION": "XULASHADA LUQADDA",
  ACCESS: "hel",
  "Terms of use": "Shuruudaha isticmaalka",
  REFUSE: "DIIDO",
  ACCEPT: "AQBAL",
  READ: "AKHRISO",
  PUBLISH: "DAabici",
  CHAT: "SHEEKAYSI",
  "comming soon": "dhawaan iman doona",
  Other: "Mid kale",
  Remark: "Odhaah",
  Suggestion: "Soo jeedin",
  Complaint: "Cabasho",
  Manual: "Buugga",
  SEARCH: "BAADI",
  SHARE: "SHARE garee",
  DENOUNCE: "CEYNTA",
  "This post has not been reviewed": "Qoraalkan dib looma eegin",
  SUBMIT: "Gudbi",
  Topic: "Mawduuca",
  "Published BY": "Daabacay",
  English: "Ingiriis",
  Country: "Dalka",
  City: "Magaalada",
  Place: "Goobta",
  "Subject 1": "Mawduuca 1",
  "Subject 2": "Mawduuca 2",
  "Subject 3": "Mawduuca 3",
  "Reference Id": "Tixraaca Id",
  "Enter Email Address": "Geli ciwaanka iimaylka",
  CHOOSE: "DOORO",
  By: "By",
  "End": "Dhamaadka",
  "Enter Your Story Content": "Gali Nuxurka Sheekadaada",
  "UPLOAD IMAGES": "SOO rar SAWIRRO",
  "Click or Drop here": "Guji ama ku rid halkan",
  "Remove all images": "Ka saar dhammaan sawirada",
  "Your post has been properly submitted":
    "Boostada si sax ah ayaa loo gudbiyay",
  "and is published under": "waxaana lagu daabacay hoos",
  "Please copy this for your reference": "Fadlan ku koobiyee kan tixraac ahaan",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "Wayddiintaadii si sax ah ayaa loo gudbiyay",
};
export const Sundanese = {
  "User Instructions": "Parentah pamaké",
  Contact: "Kontak",
  "News or HereSays Herald": "News atanapi HereSays Herald",
  Disclaimer: "Bantahan",
  "Region or Province or County or State":
    "Wewengkon atawa Propinsi atawa County atawa Propinsi",
  and: "jeung",
  Date: "Kaping",
  Content: "eusi",
  "TopicName is required": "TopicName diperlukeun",
  "Publisher Name is required": "Ngaran penerbit diperlukeun",
  "Enter Your Feedback": "Lebetkeun Eupan Balik anjeun",
  HereSays: "Di dieu",
  Region: "Wewengkon",
  Province: "Propinsi",
  Heard: "Kadéngé",
  Shared: "Dibagikeun",
  Successfully: "Berhasil",
  Explanation: "Katerangan",
  Language: "Basa",
  Jurisdiction: "Yurisdiksi",
  Attention: "Perhatian",
  "LANGUAGE SELECTION": "PILIHAN BASA",
  ACCESS: "AKSES",
  "Terms of use": "Syarat panggunaan",
  REFUSE: "NOLAK",
  ACCEPT: "NAMPA",
  READ: "BACA",
  PUBLISH: "PUBLISKEUN",
  CHAT: "Obrolan",
  "comming soon": "comming pas",
  Other: "Lain",
  Remark: "Nyarios",
  Suggestion: "bongbolongan",
  Complaint: "ngawadul",
  Manual: "Manual",
  SEARCH: "Pilarian",
  SHARE: "Bagikeun",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "pos ieu teu acan reviewed",
  SUBMIT: "NYAMPUNKEUN",
  Topic: "Jejer",
  "Published BY": "Diterbitkeun BY",
  English: "Inggris",
  Country: "Nagara",
  City: "Kota",
  Place: "Tempat",
  "Subject 1": "Jejer 1",
  "Subject 2": "Jejer 2",
  "Subject 3": "Subjek 3",
  "Reference Id": "Id Rujukan",
  "Enter Email Address": "Lebetkeun Alamat Surélék",
  CHOOSE: "PILIH",
  By: "Ku",
  "End": "Tungtungna",
  "Enter Your Story Content": "Lebetkeun Eusi Carita Anjeun",
  "UPLOAD IMAGES": "Unggah gambar",
  "Click or Drop here": "Klik atanapi Leupaskeun di dieu",
  "Remove all images": "Cabut sadaya gambar",
  "Your post has been properly submitted":
    "Tulisan anjeun parantos leres dikintunkeun",
  "and is published under": "sarta diterbitkeun dina",
  "Please copy this for your reference":
    "Mangga nyalin ieu pikeun rujukan anjeun",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "QUERY anjeun geus bener dikirimkeun",
};
export const Swahili = {
  "User Instructions": "Maelekezo ya Mtumiaji",
  Contact: "Wasiliana",
  "News or HereSays Herald": "Habari au HereSays Herald",
  Disclaimer: "Kanusho",
  "Region or Province or County or State": "Mkoa au Mkoa au Kata au Jimbo",
  and: "na",
  Date: "Tarehe",
  Content: "Maudhui",
  "TopicName is required": "TopicName inahitajika",
  "Publisher Name is required": "Jina la Mchapishaji linahitajika",
  "Enter Your Feedback": "Weka Maoni Yako",
  HereSays: "HapaAnasema",
  Region: "Mkoa",
  Province: "Mkoa",
  Heard: "Imesikika",
  Shared: "Imeshirikiwa",
  Successfully: "Imefanikiwa",
  Explanation: "Maelezo",
  Language: "Lugha",
  Jurisdiction: "Mamlaka",
  Attention: "Tahadhari",
  "LANGUAGE SELECTION": "UCHAGUZI WA LUGHA",
  ACCESS: "KUFIKIA",
  "Terms of use": "Masharti ya matumizi",
  REFUSE: "KATAA",
  ACCEPT: "KUBALI",
  READ: "SOMA",
  PUBLISH: "KUCHAPISHA",
  CHAT: "CHAT",
  "comming soon": "inakuja hivi karibuni",
  Other: "Nyingine",
  Remark: "Toa maoni",
  Suggestion: "Pendekezo",
  Complaint: "Malalamiko",
  Manual: "Mwongozo",
  SEARCH: "TAFUTA",
  SHARE: "SHIRIKI",
  DENOUNCE: "TENA",
  "This post has not been reviewed": "Chapisho hili halijakaguliwa",
  SUBMIT: "WASILISHA",
  Topic: "Mada",
  "Published BY": "Imechapishwa NA",
  English: "Kiingereza",
  Country: "Nchi",
  City: "Jiji",
  Place: "Mahali",
  "Subject 1": "Somo la 1",
  "Subject 2": "Somo la 2",
  "Subject 3": "Somo la 3",
  "Reference Id": "Kitambulisho cha Marejeleo",
  "Enter Email Address": "Weka Anwani ya Barua Pepe",
  CHOOSE: "CHAGUA",
  By: "Na",
  "End": "Mwisho",
  "Enter Your Story Content": "Ingiza Maudhui Yako Hadithi",
  "UPLOAD IMAGES": "PAKIA PICHA",
  "Click or Drop here": "Bofya au Achia hapa",
  "Remove all images": "Ondoa picha zote",
  "Your post has been properly submitted":
    "Chapisho lako limewasilishwa ipasavyo",
  "and is published under": "na inachapishwa chini ya",
  "Please copy this for your reference":
    "Tafadhali nakili hii kwa marejeleo yako",
  "ID:HERESAYS000": "KITAMBULISHO:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "SWALI LAKO IMEWASILISHWA VIZURI",
};
export const Tagalog_Filipino = {
  "User Instructions": "Mga Tagubilin sa Gumagamit",
  Contact: "Makipag-ugnayan",
  "News or HereSays Herald": "Balita o HereSays Herald",
  Disclaimer: "Disclaimer",
  "Region or Province or County or State":
    "Rehiyon o Lalawigan o County o Estado",
  and: "at",
  Date: "Petsa",
  Content: "Nilalaman",
  "TopicName is required": "Kinakailangan ang TopicName",
  "Publisher Name is required": "Kinakailangan ang Pangalan ng Publisher",
  "Enter Your Feedback": "Ilagay ang Iyong Feedback",
  HereSays: "DitoSays",
  Region: "Rehiyon",
  Province: "Lalawigan",
  Heard: "Narinig",
  Shared: "Ibinahagi",
  Successfully: "Matagumpay",
  Explanation: "Paliwanag",
  Language: "Wika",
  Jurisdiction: "hurisdiksyon",
  Attention: "Pansin",
  "LANGUAGE SELECTION": "PAGPILI NG WIKA",
  ACCESS: "ACCESS",
  "Terms of use": "Mga Tuntunin ng Paggamit",
  REFUSE: "TANGGI",
  ACCEPT: "TANGGAPIN",
  READ: "BASAHIN",
  PUBLISH: "I-publish",
  CHAT: "CHAT",
  "comming soon": "paparating na",
  Other: "Iba pa",
  Remark: "Puna",
  Suggestion: "Mungkahi",
  Complaint: "Reklamo",
  Manual: "Manwal",
  SEARCH: "PAGHAHANAP",
  SHARE: "IBAHAGI",
  DENOUNCE: "PAGTUTURA",
  "This post has not been reviewed": "Ang post na ito ay hindi nasuri",
  SUBMIT: "IPASA",
  Topic: "Paksa",
  "Published BY": "Inilathala ni",
  English: "Ingles",
  Country: "Bansa",
  City: "lungsod",
  Place: "Lugar",
  "Subject 1": "Paksa 1",
  "Subject 2": "Paksa 2",
  "Subject 3": "Paksa 3",
  "Reference Id": "Reference Id",
  "Enter Email Address": "Ilagay ang email address",
  CHOOSE: "PUMILI",
  By: "Sa pamamagitan ng",
  "End": "Wakas",
  "Enter Your Story Content": "Ilagay ang Nilalaman ng Iyong Kwento",
  "UPLOAD IMAGES": "MAG-UPLOAD NG MGA LARAWAN",
  "Click or Drop here": "Mag-click o Mag-drop dito",
  "Remove all images": "Alisin ang lahat ng mga larawan",
  "Your post has been properly submitted":
    "Naisumite nang maayos ang iyong post",
  "and is published under": "at nai-publish sa ilalim ng",
  "Please copy this for your reference":
    "Mangyaring kopyahin ito para sa iyong sanggunian",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "ANG IYONG TANONG AY NAISUMITE nang wasto",
};
export const Tajik = {
  "User Instructions": "Дастурҳои корбар",
  Contact: "Тамос",
  "News or HereSays Herald": "News or HereSays Herald",
  Disclaimer: "Радди масъулият",
  "Region or Province or County or State": "Вилоят ё вилоят ё вилоят ё иёлот",
  and: "ва",
  Date: "Сана",
  Content: "Мундариҷа",
  "TopicName is required": "Номи мавзӯъ лозим аст",
  "Publisher Name is required": "Номи ношир лозим аст",
  "Enter Your Feedback": "Фикрҳои худро ворид кунед",
  HereSays: "Ина мегуяд",
  Region: "вилоят",
  Province: "вилоят",
  Heard: "Шунидем",
  Shared: "Мубодила",
  Successfully: "бомуваффакият",
  Explanation: "Шарҳ",
  Language: "Забон",
  Jurisdiction: "Ҳуқуқӣ",
  Attention: "Диққат",
  "LANGUAGE SELECTION": "ИНТИХОБИ ЗАБОН",
  ACCESS: "ДАСТРАС",
  "Terms of use": "Шартҳои истифода",
  REFUSE: "РАД КУНЕД",
  ACCEPT: "КАБУЛ КУНЕД",
  READ: "ХОНЕД",
  PUBLISH: "НАШР КУНЕД",
  CHAT: "ЧАТ",
  "comming soon": "ба наздикӣ",
  Other: "Дигар",
  Remark: "Эзоҳ",
  Suggestion: "Пешниҳод",
  Complaint: "Шикоят",
  Manual: "Дастур",
  SEARCH: "Ҷустуҷӯ",
  SHARE: "ПОДЕЛИТЬСЯ",
  DENOUNCE: "ЭЪТИБОР КУНЕД",
  "This post has not been reviewed": "Ин пост баррасӣ нашудааст",
  SUBMIT: "ПУРСИШ КУНЕД",
  Topic: "Мавзӯъ",
  "Published BY": "Нашр аз ҷониби",
  English: "англисӣ",
  Country: "кишвар",
  City: "Шаҳр",
  Place: "Ҷойгир",
  "Subject 1": "Мавзӯи 1",
  "Subject 2": "Мавзӯи 2",
  "Subject 3": "Мавзӯи 3",
  "Reference Id": "Id истинод",
  "Enter Email Address": "Суроғаи почтаи электрониро ворид кунед",
  CHOOSE: "ИНТИХОБ КУНЕД",
  By: "Аз ҷониби",
  "End": "Поён",
  "Enter Your Story Content": "Мазмуни ҳикояи худро ворид кунед",
  "UPLOAD IMAGES": "ТААСвирҳоро бор кунед",
  "Click or Drop here": "Дар ин ҷо клик кунед ё гузоред",
  "Remove all images": "Ҳама тасвирҳоро нест кунед",
  "Your post has been properly submitted":
    "Паёми шумо дуруст пешниҳод карда шуд",
  "and is published under": "ва зери он нашр мешавад",
  "Please copy this for your reference":
    "Лутфан инро барои истинод нусхабардорӣ кунед",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ПУРСИШИ ШУМО ДУРУСТ ПУРСИШ ШУД",
};
export const Tamil = {
  "User Instructions": "பயனர் வழிமுறைகள்",
  Contact: "தொடர்பு கொள்ளவும்",
  "News or HereSays Herald": "நியூஸ் அல்லது ஹியர்ஸ் ஹெரால்ட்",
  Disclaimer: "மறுப்பு",
  "Region or Province or County or State":
    "பிராந்தியம் அல்லது மாகாணம் அல்லது மாவட்டம் அல்லது மாநிலம்",
  and: "மற்றும்",
  Date: "தேதி",
  Content: "உள்ளடக்கம்",
  "TopicName is required": "TopicName தேவை",
  "Publisher Name is required": "வெளியீட்டாளர் பெயர் தேவை",
  "Enter Your Feedback": "உங்கள் கருத்தை உள்ளிடவும்",
  HereSays: "இங்கே கூறுகிறார்",
  Region: "பிராந்தியம்",
  Province: "மாகாணம்",
  Heard: "கேள்விப்பட்டேன்",
  Shared: "பகிரப்பட்டது",
  Successfully: "வெற்றிகரமாக",
  Explanation: "விளக்கம்",
  Language: "மொழி",
  Jurisdiction: "அதிகார வரம்பு",
  Attention: "கவனம்",
  "LANGUAGE SELECTION": "மொழி தேர்வு",
  ACCESS: "அணுகல்",
  "Terms of use": "பயன்பாட்டு விதிமுறைகளை",
  REFUSE: "மறு",
  ACCEPT: "ஏற்றுக்கொள்",
  READ: "படி",
  PUBLISH: "வெளியிடு",
  CHAT: "அரட்டை",
  "comming soon": "விரைவில்",
  Other: "மற்றவை",
  Remark: "கருத்து",
  Suggestion: "பரிந்துரை",
  Complaint: "புகார்",
  Manual: "கையேடு",
  SEARCH: "தேடு",
  SHARE: "பகிர்",
  DENOUNCE: "கண்டனம்",
  "This post has not been reviewed": "இந்த இடுகை மதிப்பாய்வு செய்யப்படவில்லை",
  SUBMIT: "சமர்ப்பிக்கவும்",
  Topic: "தலைப்பு",
  "Published BY": "வெளியிட்டது",
  English: "ஆங்கிலம்",
  Country: "நாடு",
  City: "நகரம்",
  Place: "இடம்",
  "Subject 1": "பொருள் 1",
  "Subject 2": "பொருள் 2",
  "Subject 3": "பொருள் 3",
  "Reference Id": "குறிப்பு ஐடி",
  "Enter Email Address": "மின்னஞ்சல் முகவரியை உள்ளிடவும்",
  CHOOSE: "தேர்வு",
  By: "மூலம்",
  "End": "முற்றும்",
  "Enter Your Story Content": "உங்கள் கதையின் உள்ளடக்கத்தை உள்ளிடவும்",
  "UPLOAD IMAGES": "படங்களை பதிவேற்றவும்",
  "Click or Drop here": "இங்கே கிளிக் செய்யவும் அல்லது கைவிடவும்",
  "Remove all images": "அனைத்து படங்களையும் அகற்று",
  "Your post has been properly submitted":
    "உங்கள் இடுகை சரியாக சமர்ப்பிக்கப்பட்டது",
  "and is published under": "மற்றும் கீழ் வெளியிடப்படுகிறது",
  "Please copy this for your reference":
    "உங்கள் குறிப்புக்காக இதை நகலெடுக்கவும்",
  "ID:HERESAYS000": "ஐடி:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "உங்கள் வினவல் சரியாகச் சமர்ப்பிக்கப்பட்டது",
};
export const Tatar = {
  "User Instructions": "Кулланучы күрсәтмәләре",
  Contact: "Контакт",
  "News or HereSays Herald": "Яңалыклар яки HereSays Herald",
  Disclaimer: "Аннан баш тарту",
  "Region or Province or County or State":
    "Төбәк яки өлкә яки округ яки дәүләт",
  and: "һәм",
  Date: "Дата",
  Content: "Эчтәлек",
  "TopicName is required": "TopicName кирәк",
  "Publisher Name is required": "Нәшрият исеме кирәк",
  "Enter Your Feedback": "Фикерләрегезне кертегез",
  HereSays: "Монда",
  Region: "Төбәк",
  Province: "Өлкә",
  Heard: "Ишет",
  Shared: "Уртак",
  Successfully: "Уңышлы",
  Explanation: "Аңлатма",
  Language: "Тел",
  Jurisdiction: "Urisрисдикция",
  Attention: "Игътибар",
  "LANGUAGE SELECTION": "ТЕЛ САЙЛАУ",
  ACCESS: "ACCESS",
  "Terms of use": "Куллану шартлары",
  REFUSE: "РЕФУС",
  ACCEPT: "Кабул итү",
  READ: "УКЫ",
  PUBLISH: "Басма",
  CHAT: "CHAT",
  "comming soon": "тиздән",
  Other: "Бүтәннәр",
  Remark: "Искәрмә",
  Suggestion: "Тәкъдим",
  Complaint: "Шикаять",
  Manual: "Дәреслек",
  SEARCH: "Эзләү",
  SHARE: "Бүлешү",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Бу язма каралмаган",
  SUBMIT: "САБМИТ",
  Topic: "Тема",
  "Published BY": "Басылган",
  English: "Инглиз",
  Country: "Ил",
  City: "Шәһәр",
  Place: "Урын",
  "Subject 1": "Тема 1",
  "Subject 2": "2 нче тема",
  "Subject 3": "3 нче тема",
  "Reference Id": "Белешмә Id",
  "Enter Email Address": "Электрон почта адресын кертегез",
  CHOOSE: "САЙЛА",
  By: "Byәр сүзнең",
  "End": "Тәмам",
  "Enter Your Story Content": "Сезнең хикәя эчтәлеген кертегез",
  "UPLOAD IMAGES": "Рәсемнәрне йөкләү",
  "Click or Drop here": "Монда басыгыз яки ташлагыз",
  "Remove all images": "Барлык рәсемнәрне бетерегез",
  "Your post has been properly submitted": "Сезнең язма дөрес тапшырылды",
  "and is published under": "астында бастырыла",
  "Please copy this for your reference": "Зинһар, моны белешмә өчен күчерегез",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "СОРАУЫ .ЫЗ ТАПШЫРЫЛГАН",
};
export const Telugu = {
  "User Instructions": "వినియోగదారు సూచనలు",
  Contact: "సంప్రదించండి",
  "News or HereSays Herald": "న్యూస్ లేదా హియర్‌సేస్ హెరాల్డ్",
  Disclaimer: "నిరాకరణ",
  "Region or Province or County or State":
    "ప్రాంతం లేదా ప్రావిన్స్ లేదా కౌంటీ లేదా రాష్ట్రం",
  and: "మరియు",
  Date: "తేదీ",
  Content: "విషయము",
  "TopicName is required": "TopicName అవసరం",
  "Publisher Name is required": "ప్రచురణకర్త పేరు అవసరం",
  "Enter Your Feedback": "మీ అభిప్రాయాన్ని నమోదు చేయండి",
  HereSays: "ఇక్కడ చెప్పారు",
  Region: "ప్రాంతం",
  Province: "ప్రావిన్స్",
  Heard: "విన్నాను",
  Shared: "భాగస్వామ్యం చేయబడింది",
  Successfully: "విజయవంతంగా",
  Explanation: "వివరణ",
  Language: "భాష",
  Jurisdiction: "అధికార పరిధి",
  Attention: "శ్రద్ధ",
  "LANGUAGE SELECTION": "భాష ఎంపిక",
  ACCESS: "యాక్సెస్",
  "Terms of use": "ఉపయోగ నిబంధనలు",
  REFUSE: "తిరస్కరించు",
  ACCEPT: "అంగీకరించు",
  READ: "చదవండి",
  PUBLISH: "ప్రచురించు",
  CHAT: "చాట్",
  "comming soon": "త్వరలొ వచ్చును",
  Other: "ఇతర",
  Remark: "వ్యాఖ్య",
  Suggestion: "సూచన",
  Complaint: "ఫిర్యాదు",
  Manual: "మాన్యువల్",
  SEARCH: "వెతకండి",
  SHARE: "షేర్ చేయండి",
  DENOUNCE: "ఖండించండి",
  "This post has not been reviewed": "ఈ పోస్ట్ సమీక్షించబడలేదు",
  SUBMIT: "సమర్పించండి",
  Topic: "అంశం",
  "Published BY": "ద్వారా ప్రచురించబడింది",
  English: "ఆంగ్ల",
  Country: "దేశం",
  City: "నగరం",
  Place: "స్థలం",
  "Subject 1": "విషయం 1",
  "Subject 2": "విషయం 2",
  "Subject 3": "విషయం 3",
  "Reference Id": "సూచన ID",
  "Enter Email Address": "ఈ - మెయిల్ అడ్రస్ నింపండి",
  CHOOSE: "ఎంచుకోండి",
  By: "ద్వారా",
  "End": "ముగింపు",
  "Enter Your Story Content": "మీ స్టోరీ కంటెంట్‌ని నమోదు చేయండి",
  "UPLOAD IMAGES": "చిత్రాలను అప్‌లోడ్ చేయండి",
  "Click or Drop here": "ఇక్కడ క్లిక్ చేయండి లేదా వదలండి",
  "Remove all images": "అన్ని చిత్రాలను తీసివేయండి",
  "Your post has been properly submitted": "మీ పోస్ట్ సరిగ్గా సమర్పించబడింది",
  "and is published under": "మరియు క్రింద ప్రచురించబడింది",
  "Please copy this for your reference":
    "దయచేసి మీ సూచన కోసం దీన్ని కాపీ చేయండి",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "మీ ప్రశ్న సరిగ్గా సమర్పించబడింది",
};
export const Thai = {
  "User Instructions": "คำแนะนำผู้ใช้",
  Contact: "ติดต่อ",
  "News or HereSays Herald": "ข่าวหรือ HereSays Herald",
  Disclaimer: "ข้อจำกัดความรับผิดชอบ",
  "Region or Province or County or State": "ภูมิภาคหรือจังหวัดหรือเขตหรือรัฐ",
  and: "และ",
  Date: "วันที่",
  Content: "เนื้อหา",
  "TopicName is required": "ชื่อหัวข้อ จำเป็น",
  "Publisher Name is required": "ต้องระบุชื่อผู้เผยแพร่",
  "Enter Your Feedback": "ป้อนความคิดเห็นของคุณ",
  HereSays: "นี่พูด",
  Region: "ภูมิภาค",
  Province: "จังหวัด",
  Heard: "ได้ยิน",
  Shared: "ใช้ร่วมกัน",
  Successfully: "เรียบร้อยแล้ว",
  Explanation: "คำอธิบาย",
  Language: "ภาษา",
  Jurisdiction: "อำนาจศาล",
  Attention: "ความสนใจ",
  "LANGUAGE SELECTION": "การเลือกภาษา",
  ACCESS: "เข้าถึง",
  "Terms of use": "ข้อกำหนดการใช้งาน",
  REFUSE: "ปฏิเสธ",
  ACCEPT: "ยอมรับ",
  READ: "อ่าน",
  PUBLISH: "เผยแพร่",
  CHAT: "แชท",
  "comming soon": "เร็ว ๆ นี้",
  Other: "อื่น",
  Remark: "ข้อสังเกต",
  Suggestion: "คำแนะนำ",
  Complaint: "ร้องเรียน",
  Manual: "คู่มือ",
  SEARCH: "ค้นหา",
  SHARE: "แบ่งปัน",
  DENOUNCE: "ปฏิเสธ",
  "This post has not been reviewed": "โพสต์นี้ยังไม่ได้รับการตรวจสอบ",
  SUBMIT: "ส่ง",
  Topic: "หัวข้อ",
  "Published BY": "เผยเเพร่โดย",
  English: "ภาษาอังกฤษ",
  Country: "ประเทศ",
  City: "เมือง",
  Place: "สถานที่",
  "Subject 1": "เรื่องที่ 1",
  "Subject 2": "เรื่องที่ 2",
  "Subject 3": "เรื่องที่ 3",
  "Reference Id": "รหัสอ้างอิง",
  "Enter Email Address": "ป้อนที่อยู่อีเมล",
  CHOOSE: "เลือก",
  By: "โดย",
  "End": "ตอนจบ",
  "Enter Your Story Content": "ป้อนเนื้อหาเรื่องราวของคุณ",
  "UPLOAD IMAGES": "อัปโหลดภาพ",
  "Click or Drop here": "คลิกหรือวางที่นี่",
  "Remove all images": "ลบภาพทั้งหมด",
  "Your post has been properly submitted": "โพสต์ของคุณถูกส่งอย่างถูกต้อง",
  "and is published under": "และเผยแพร่ภายใต้",
  "Please copy this for your reference": "โปรดคัดลอกข้อมูลนี้เพื่อใช้อ้างอิง",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "คำถามของคุณถูกส่งอย่างถูกต้องแล้ว",
};
export const Tigrinya = {
  "User Instructions": "መምርሒ ተጠቃሚ",
  Contact: "መርከቢ",
  "News or HereSays Herald": "ዜና ወይ Heresays Herald",
  Disclaimer: "ሓላፍነት ምውሳድ",
  "Region or Province or County or State": "ዞባ ወይ ኣውራጃ ወይ ኣውራጃ ወይ ግዝኣት",
  and: "እና",
  Date: "ዕለት",
  Content: "ትሕዝቶ",
  "TopicName is required": "TopicName የድሊ",
  "Publisher Name is required": "ስም ኣሕታሚ የድሊ",
  "Enter Your Feedback": "ርእይቶኹም ኣእትዉ",
  HereSays: "ኣብዚ ይብል",
  Region: "ክልል",
  Province: "ገጸር",
  Heard: "ሰሚዐ",
  Shared: "ሼር ዝገበረ",
  Successfully: "ብዓወት",
  Explanation: "መግለጺ",
  Language: "ቋንቋ",
  Jurisdiction: "ስልጣን ፍርዲ",
  Attention: "ቀልቢ",
  "LANGUAGE SELECTION": "ምምራጽ ቋንቋ",
  ACCESS: "ናይ ምርካብ ዓቅሚ",
  "Terms of use": "ውዕል ኣጠቓቕማ",
  REFUSE: "እበይ",
  ACCEPT: "ተቀበል",
  READ: "ኣንብብ",
  PUBLISH: "PUBLISH ምግባር",
  CHAT: "ፀወታ",
  "comming soon": "ኣብ ቀረባ እዋን ክመጽእ እዩ።",
  Other: "ካልእ",
  Remark: "ርእይቶ",
  Suggestion: "ምኽሪ",
  Complaint: "ቕሬታ",
  Manual: "ናይ ኢድ",
  SEARCH: "ምድላይ",
  SHARE: "ናይ ሓባር",
  DENOUNCE: "DENOUNCE ዝብል ቃል ንረክብ",
  "This post has not been reviewed": "እዚ ጽሑፍ ኣይተገምገመን",
  SUBMIT: "ኣእትው",
  Topic: "ኣርእስቲ",
  "Published BY": "ዝተሓትመ BY",
  English: "ኢንግሊሽ",
  Country: "ሃገር",
  City: "ከተማ",
  Place: "ቦታ",
  "Subject 1": "ርእሰ ነገር 1",
  "Subject 2": "ርእሰ ነገር 2",
  "Subject 3": "ርእሰ ነገር 3",
  "Reference Id": "መወከሲ ቁጽሪ",
  "Enter Email Address": "ኢመይል ኣድራሻ ኣእቱ",
  CHOOSE: "ምረፅ",
  By: "ብ",
  "End": "መወዳእታ",
  "Enter Your Story Content": "ትሕዝቶ ዛንታኻ ኣእቱ",
  "UPLOAD IMAGES": "ምስልታት ስቐል",
  "Click or Drop here": "ኣብዚ ጠውቑ ወይ Drop",
  "Remove all images": "ኩሉ ምስልታት ኣውጽእዎ።",
  "Your post has been properly submitted": "ጽሑፍካ ብግቡእ ቀሪቡ ኣሎ።",
  "and is published under": "ከምኡ’ውን ኣብ ትሕቲ...",
  "Please copy this for your reference": "ነዚ ንመወከሲኹም ቅዳሕዎ።",
  "ID:HERESAYS000": "መለለዪ ቁጽሪ:በዚ000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ሕቶኹም ብግቡእ ቀሪቡ ኣሎ።",
};
export const Tsonga = {
  "User Instructions": "Swiletelo swa Mutirhisi",
  Contact: "Hlanganisa",
  "News or HereSays Herald": "Mahungu kumbe LahaKu vula Herald",
  Disclaimer: "Ku tiyimelela",
  "Region or Province or County or State":
    "Xifundzankulu kumbe Xifundzankulu kumbe Xifundzankulu kumbe Xifundzankulu",
  and: "na",
  Date: "Siku",
  Content: "Vundzeni",
  "TopicName is required": "TopicName ya laveka",
  "Publisher Name is required": "Vito ra Muhuweleri ra laveka",
  "Enter Your Feedback": "Nghenisa Vuyelo bya Wena",
  HereSays: "LahaKu Vulavula",
  Region: "Ndhawu",
  Province: "Xifundzhankulu",
  Heard: "Twile",
  Shared: "Ku averiwa",
  Successfully: "Hi ku humelela",
  Explanation: "Nhlamuselo",
  Language: "Ririmi",
  Jurisdiction: "Vulawuri bya vulawuri",
  Attention: "Rinoko",
  "LANGUAGE SELECTION": "NHLAWULO WA RIRIMI",
  ACCESS: "FIKELELA",
  "Terms of use": "Mimpimanyeto ya ku tirhisa",
  REFUSE: "ALA",
  ACCEPT: "AMUKELA",
  READ: "HLAYA",
  PUBLISH: "PUBLISH",
  CHAT: "BULA",
  "comming soon": "ku ta ku nga ri khale",
  Other: "Xin'wana",
  Remark: "Xibumabumelo",
  Suggestion: "Ndzinganyeto",
  Complaint: "Muvileri",
  Manual: "Manyuwali",
  SEARCH: "SECHA",
  SHARE: "AVELANA",
  DENOUNCE: "XITLHOKOVETSELO",
  "This post has not been reviewed": "Post leyi a yi se kamberiwa",
  SUBMIT: "YISA",
  Topic: "Nhlokomhaka",
  "Published BY": "Ku kandziyisiwe HI",
  English: "Xinghezi",
  Country: "Tiko",
  City: "Doroba",
  Place: "Ndhawu",
  "Subject 1": "Nhlokomhaka 1",
  "Subject 2": "Nhlokomhaka ya 2",
  "Subject 3": "Nhlokomhaka ya 3",
  "Reference Id": "Id ya Xikombo",
  "Enter Email Address": "Nghenisa Adirese ya Imeyili",
  CHOOSE: "HLAWULA",
  By: "Hi",
  "End": "Makumu",
  "Enter Your Story Content": "Nghenisa Vuxokoxoko bya Xitori xa Wena",
  "UPLOAD IMAGES": "UPLOAD SWIFANISO",
  "Click or Drop here": "Click kumbe Drop laha",
  "Remove all images": "Susa swifaniso hinkwaswo",
  "Your post has been properly submitted": "Post ya wena yi rhumeriwile kahle",
  "and is published under": "naswona yi kandziyisiwa ehansi ka",
  "Please copy this for your reference":
    "Hi kombela u kopa leswi leswaku u ta swi kongomisa",
  "ID:HERESAYS000": "ID:SWIVUTISO SWA SWONA000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "XIVUTISO XA WENA XI RHUMELERIWILE HI SWINENE",
};
export const Turkmen = {
  "User Instructions": "Ulanyjy görkezmeleri",
  Contact: "Habarlaşyň",
  "News or HereSays Herald": "Habarlar ýa-da HereSays Herald",
  Disclaimer: "Düşündiriş",
  "Region or Province or County or State":
    "Sebit ýa-da welaýat ýa-da etrap ýa-da ştat",
  and: "we",
  Date: "Sene",
  Content: "Mazmuny",
  "TopicName is required": "TopicName hökmany",
  "Publisher Name is required": "Neşirçiniň ady talap edilýär",
  "Enter Your Feedback": "Pikirleriňizi giriziň",
  HereSays: "Bu ýerde",
  Region: "Sebit",
  Province: "Welaýat",
  Heard: "Eşit",
  Shared: "Paýlaşyldy",
  Successfully: "Üstünlik",
  Explanation: "Düşündiriş",
  Language: "Dil",
  Jurisdiction: "Kazyýet",
  Attention: "Üns beriň",
  "LANGUAGE SELECTION": "DIL SAYLAMASY",
  ACCESS: "ACCESS",
  "Terms of use": "Ulanyş şertleri",
  REFUSE: "TERJIME",
  ACCEPT: "Kabul et",
  READ: "Oka",
  PUBLISH: "Çap et",
  CHAT: "CHAT",
  "comming soon": "ýakynda",
  Other: "Beýlekiler",
  Remark: "Bellik",
  Suggestion: "Teklip",
  Complaint: "Şikaýat",
  Manual: "Gollanma",
  SEARCH: "Gözleg",
  SHARE: "Paýlaş",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "Bu ýazgy gözden geçirilmedi",
  SUBMIT: "SUBMIT",
  Topic: "Mowzuk",
  "Published BY": "Çap edildi",
  English: "Iňlis",
  Country: "Countryurt",
  City: "Şäher",
  Place: "Placeeri",
  "Subject 1": "1-nji mowzuk",
  "Subject 2": "2-nji mowzuk",
  "Subject 3": "3-nji mowzuk",
  "Reference Id": "Salgy ID",
  "Enter Email Address": "E-poçta salgysyny giriziň",
  CHOOSE: "Saýla",
  By: "Byazan",
  "End": "Ahyrzaman",
  "Enter Your Story Content": "Hekaýa mazmunyňyzy giriziň",
  "UPLOAD IMAGES": "Suratlary ýükläň",
  "Click or Drop here": "Şu ýere basyň ýa-da taşlaň",
  "Remove all images": "Imageshli suratlary aýyryň",
  "Your post has been properly submitted": "Siziň ýazgyňyz dogry tabşyryldy",
  "and is published under": "astynda çap edilýär",
  "Please copy this for your reference":
    "Salgylanmak üçin muny göçürmegiňizi haýyş edýäris",
  "ID:HERESAYS000": "Şahsyýetnamasy: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "SORAGY .YZ ÜPJÜN EDILDI",
};
export const Twi_Akan = {
  "User Instructions": "Akwankyerɛ a Wɔde Di Dwuma",
  Contact: "Di nkutaho",
  "News or HereSays Herald": "News anaa Haka Herald ka",
  Disclaimer: "Nsɛm a Wɔka Kyerɛ",
  "Region or Province or County or State":
    "Ɔmantam anaa Ɔmantam anaa Mantam anaa Ɔman",
  and: "ne",
  Date: "Da",
  Content: "Emu nsɛm",
  "TopicName is required": "TopicName na wɔhwehwɛ",
  "Publisher Name is required": "Ɔdawurubɔfo Din na wɔhwehwɛ",
  "Enter Your Feedback": "Hyehyɛ Wo Feedback no mu",
  HereSays: "Ɛha Ka",
  Region: "Mantam",
  Province: "Mansini",
  Heard: "Teeɛ",
  Shared: "Wɔkyekyɛ",
  Successfully: "Ɛyɛɛ yiye",
  Explanation: "Nkyerɛmu",
  Language: "Kasa",
  Jurisdiction: "Tumi a Wɔde Di Dwuma",
  Attention: "Adwene nsisoɔ",
  "LANGUAGE SELECTION": "KASA A WƆBƐpaw",
  ACCESS: "KWAN",
  "Terms of use": "Nsɛm a wɔde di dwuma",
  REFUSE: "SI KWAN",
  ACCEPT: "GYE TO MU",
  READ: "KAN",
  PUBLISH: "NKYERƐKYERƐMU",
  CHAT: "DI NKƆMMƆ",
  "comming soon": "reba nnansa yi ara",
  Other: "Foforɔ",
  Remark: "Hyɛ no nsow",
  Suggestion: "Nsusuiɛ",
  Complaint: "Kwaadu",
  Manual: "Akwankyerɛ",
  SEARCH: "HWEHWƐ",
  SHARE: "KYƐ",
  DENOUNCE: "DENOUNCE NKYEKYƐMU",
  "This post has not been reviewed": "Wɔnhwɛɛ saa post yi mu",
  SUBMIT: "FA KƆ",
  Topic: "Atifi asɛm",
  "Published BY": "Wotintim no BY",
  English: "Borɔfo",
  Country: "Ɔman",
  City: "Kuropɔn",
  Place: "Beaeɛ",
  "Subject 1": "Asɛmti 1.",
  "Subject 2": "Asɛmti 2.",
  "Subject 3": "Asɛmti 3.",
  "Reference Id": "Nhwehwɛmu Id",
  "Enter Email Address": "Hyehyɛ Email Address no mu",
  CHOOSE: "YI",
  By: "Mu",
  "End": "Awiei no",
  "Enter Your Story Content": "Hyehyɛ W’asɛm no mu Nsɛm",
  "UPLOAD IMAGES": "FA MFONINI A WƆDE GU SO",
  "Click or Drop here": "Klik anaa Drop ha",
  "Remove all images": "Yi mfonini ahorow no nyinaa fi hɔ",
  "Your post has been properly submitted": "Wɔde wo post no akɔma yiye",
  "and is published under": "na wotintim wɔ ase",
  "Please copy this for your reference":
    "Yɛsrɛ sɛ kɔpi eyi na ama woatumi ahwɛ",
  "ID:HERESAYS000": "ID:NKYERƐKYERƐMU NKYERƐKYERƐMU000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "WƆDE WO NSƐMMISA NO AMA SƐNEA ƐYƐ YIYE",
};
export const Ukrainian = {
  "User Instructions": "Інструкції користувача",
  Contact: "контакт",
  "News or HereSays Herald": "Новини або HereSays Herald",
  Disclaimer: "Відмова від відповідальності",
  "Region or Province or County or State": "Регіон, провінція, округ чи штат",
  and: "і",
  Date: "Дата",
  Content: "Зміст",
  "TopicName is required": "Потрібно вказати назву теми",
  "Publisher Name is required": "Потрібно вказати назву видавця",
  "Enter Your Feedback": "Введіть свій відгук",
  HereSays: "HereSays",
  Region: "Регіон",
  Province: "Провінція",
  Heard: "Почув",
  Shared: "Спільний доступ",
  Successfully: "Успішно",
  Explanation: "Пояснення",
  Language: "Мова",
  Jurisdiction: "Юрисдикція",
  Attention: "Увага",
  "LANGUAGE SELECTION": "ВИБІР МОВИ",
  ACCESS: "ДОСТУП",
  "Terms of use": "Умови використання",
  REFUSE: "ВІДМОВИТИ",
  ACCEPT: "ПРИЙНЯТИ",
  READ: "ПРОЧИТАЙТЕ",
  PUBLISH: "ПУБЛІКУВАТИ",
  CHAT: "ЧАТ",
  "comming soon": "скоро буде",
  Other: "Інший",
  Remark: "Зауваження",
  Suggestion: "Пропозиція",
  Complaint: "Скарга",
  Manual: "Інструкція",
  SEARCH: "ПОШУК",
  SHARE: "ПОДІЛИТИСЯ",
  DENOUNCE: "ДОНОСИТИ",
  "This post has not been reviewed": "Ця публікація не була перевірена",
  SUBMIT: "ПОДАТИ",
  Topic: "Тема",
  "Published BY": "Опубліковано BY",
  English: "англійська",
  Country: "Країна",
  City: "місто",
  Place: "Місце",
  "Subject 1": "Тема 1",
  "Subject 2": "Тема 2",
  "Subject 3": "Тема 3",
  "Reference Id": "Ідентифікатор посилання",
  "Enter Email Address": "Введіть адресу електронної пошти",
  CHOOSE: "ВИБЕРІТЬ",
  By: "за",
  "End": "Кінець",
  "Enter Your Story Content": "Введіть вміст вашої історії",
  "UPLOAD IMAGES": "ЗАВАНТАЖИТИ ЗОБРАЖЕННЯ",
  "Click or Drop here": "Клацніть або перетягніть сюди",
  "Remove all images": "Видалити всі зображення",
  "Your post has been properly submitted": "Ваш допис надіслано належним чином",
  "and is published under": "і публікується під",
  "Please copy this for your reference": "Скопіюйте це для довідки",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "ВАШ ЗАПИТ ПРАВИЛЬНО ПОДАНО",
};
export const Urdu = {
  "User Instructions": "صارف کی ہدایات",
  Contact: "رابطہ کریں۔",
  "News or HereSays Herald": "نیوز یا HereSays Herald",
  Disclaimer: "ڈس کلیمر",
  "Region or Province or County or State": "علاقہ یا صوبہ یا کاؤنٹی یا ریاست",
  and: "اور",
  Date: "تاریخ",
  Content: "مواد",
  "TopicName is required": "موضوع کا نام درکار ہے۔",
  "Publisher Name is required": "پبلیشر کا نام درکار ہے۔",
  "Enter Your Feedback": "اپنی رائے درج کریں۔",
  HereSays: "یہاں کہتے ہیں۔",
  Region: "علاقہ",
  Province: "صوبہ",
  Heard: "سنا ہے۔",
  Shared: "مشترکہ",
  Successfully: "کامیابی سے",
  Explanation: "وضاحت",
  Language: "زبان",
  Jurisdiction: "دائرہ کار",
  Attention: "توجہ",
  "LANGUAGE SELECTION": "زبان کا انتخاب",
  ACCESS: "رسائی",
  "Terms of use": "استعمال کرنے کی شرائط",
  REFUSE: "انکار",
  ACCEPT: "قبول کریں۔",
  READ: "پڑھیں",
  PUBLISH: "شائع کریں۔",
  CHAT: "چیٹ",
  "comming soon": "جلد آرہا ہے۔",
  Other: "دیگر",
  Remark: "تبصرہ",
  Suggestion: "مشورہ",
  Complaint: "شکایت",
  Manual: "دستی",
  SEARCH: "تلاش کریں۔",
  SHARE: "بانٹیں",
  DENOUNCE: "مذمت کرنا",
  "This post has not been reviewed": "اس پوسٹ کا جائزہ نہیں لیا گیا ہے۔",
  SUBMIT: "جمع کرائیں",
  Topic: "موضوع",
  "Published BY": "کی طرف سے شائع",
  English: "انگریزی",
  Country: "ملک",
  City: "شہر",
  Place: "جگہ",
  "Subject 1": "موضوع 1",
  "Subject 2": "موضوع 2",
  "Subject 3": "موضوع 3",
  "Reference Id": "حوالہ شناخت",
  "Enter Email Address": "ای میل ایڈریس درج کریں۔",
  CHOOSE: "منتخب کریں۔",
  By: "کی طرف سے",
  "End": "ختم شد",
  "Enter Your Story Content": "اپنی کہانی کا مواد درج کریں۔",
  "UPLOAD IMAGES": "امیجز اپ لوڈ کریں۔",
  "Click or Drop here": "یہاں کلک کریں یا ڈراپ کریں۔",
  "Remove all images": "تمام تصاویر کو ہٹا دیں۔",
  "Your post has been properly submitted":
    "آپ کی پوسٹ مناسب طریقے سے جمع کرائی گئی ہے۔",
  "and is published under": "اور کے تحت شائع کیا گیا ہے۔",
  "Please copy this for your reference":
    "براہ کرم اسے اپنے حوالہ کے لیے کاپی کریں۔",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "آپ کا استفسار مناسب طریقے سے جمع کر دیا گیا ہے۔",
};
export const Uyghur = {
  "User Instructions": "ئىشلەتكۈچى كۆرسەتمىسى",
  Contact: "ئالاقىلىشىڭ",
  "News or HereSays Herald": "خەۋەر ياكى HereSays Herald",
  Disclaimer: "ئاگاھلاندۇرۇش",
  "Region or Province or County or State":
    "رايون ياكى ئۆلكە ياكى ناھىيە ياكى شىتات",
  and: "ۋە",
  Date: "چېسلا",
  Content: "مەزمۇن",
  "TopicName is required": "TopicName تەلەپ قىلىنىدۇ",
  "Publisher Name is required": "نەشر قىلغۇچىنىڭ ئىسمى تەلەپ قىلىنىدۇ",
  "Enter Your Feedback": "تەكلىپ-پىكىرلىرىڭىزنى كىرگۈزۈڭ",
  HereSays: "HereSays",
  Region: "رايون",
  Province: "ئۆلكە",
  Heard: "ئاڭلىدى",
  Shared: "ئورتاقلاشتى",
  Successfully: "مۇۋەپپەقىيەتلىك بولدى",
  Explanation: "چۈشەندۈرۈش",
  Language: "تىل",
  Jurisdiction: "باشقۇرۇش تەۋەلىكى",
  Attention: "دىققەت",
  "LANGUAGE SELECTION": "تىل تاللاش",
  ACCESS: "ACCESS",
  "Terms of use": "ئىشلىتىش شەرتلىرى",
  REFUSE: "رەت قىلىش",
  ACCEPT: "ACCEPT",
  READ: "READ",
  PUBLISH: "PUBLISH",
  CHAT: "CHAT",
  "comming soon": "comming soon",
  Other: "باشقىلىرى",
  Remark: "ئەسكەرتىش",
  Suggestion: "تەكلىپ",
  Complaint: "ئەرز",
  Manual: "Manual",
  SEARCH: "SEARCH",
  SHARE: "SHARE",
  DENOUNCE: "DENOUNCE",
  "This post has not been reviewed": "بۇ يازما تەكشۈرۈلمىدى",
  SUBMIT: "SUBMIT",
  Topic: "تېما",
  "Published BY": "BY BY",
  English: "ئىنگىلىزچە",
  Country: "دۆلەت",
  City: "شەھەر",
  Place: "ئورۇن",
  "Subject 1": "تېما 1",
  "Subject 2": "Subject 2",
  "Subject 3": "3-تېما",
  "Reference Id": "Reference Id",
  "Enter Email Address": "ئېلخەت ئادرېسىنى كىرگۈزۈڭ",
  CHOOSE: "CHOOSE",
  By: "By",
  "End": "ئاخىرەت",
  "Enter Your Story Content": "ھېكايە مەزمۇنىنى كىرگۈزۈڭ",
  "UPLOAD IMAGES": "UPLOAD IMAGES",
  "Click or Drop here": "بۇ يەرنى چېكىڭ ياكى تاشلاڭ",
  "Remove all images": "بارلىق رەسىملەرنى ئۆچۈرۈڭ",
  "Your post has been properly submitted": "يازمىڭىز مۇۋاپىق يوللاندى",
  "and is published under": "ۋە ئاستىدا ئېلان قىلىنغان",
  "Please copy this for your reference": "پايدىلىنىشىڭىز ئۈچۈن بۇنى كۆچۈرۈڭ",
  "ID:HERESAYS000": "كىملىك: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "سىزنىڭ سوئالىڭىز مۇۋاپىق يوللاندى",
};
export const Uzbek = {
  "User Instructions": "Foydalanuvchi ko'rsatmalari",
  Contact: "Aloqa",
  "News or HereSays Herald": "Yangiliklar yoki HereSays Herald",
  Disclaimer: "Rad etish",
  "Region or Province or County or State":
    "Mintaqa yoki viloyat yoki tuman yoki shtat",
  and: "va",
  Date: "Sana",
  Content: "Tarkib",
  "TopicName is required": "Mavzu nomi talab qilinadi",
  "Publisher Name is required": "Noshir nomi talab qilinadi",
  "Enter Your Feedback": "Fikr-mulohazangizni kiriting",
  HereSays: "Mana deydi",
  Region: "Mintaqa",
  Province: "Viloyat",
  Heard: "Eshitildi",
  Shared: "Ulashgan",
  Successfully: "Muvaffaqiyatli",
  Explanation: "Tushuntirish",
  Language: "Til",
  Jurisdiction: "Yurisdiksiya",
  Attention: "Diqqat",
  "LANGUAGE SELECTION": "TIL TANLASH",
  ACCESS: "KIRISH",
  "Terms of use": "Foydalanish shartlari",
  REFUSE: "RAD ET",
  ACCEPT: "QABUL QILING",
  READ: "O'QING",
  PUBLISH: "NOSOR QILISh",
  CHAT: "CHAT",
  "comming soon": "tez orada",
  Other: "Boshqa",
  Remark: "Izoh",
  Suggestion: "Taklif",
  Complaint: "Shikoyat",
  Manual: "Qo'llanma",
  SEARCH: "QIDIRMOQ",
  SHARE: "ULOSING",
  DENOUNCE: "TASHKOR QILING",
  "This post has not been reviewed": "Bu post ko‘rib chiqilmagan",
  SUBMIT: "YUBORING",
  Topic: "Mavzu",
  "Published BY": "tomonidan nashr etilgan",
  English: "Ingliz",
  Country: "Mamlakat",
  City: "Shahar",
  Place: "Joy",
  "Subject 1": "Mavzu 1",
  "Subject 2": "Mavzu 2",
  "Subject 3": "Mavzu 3",
  "Reference Id": "Malumot identifikatori",
  "Enter Email Address": "Elektron pochta manzilini kiriting",
  CHOOSE: "TANLANG",
  By: "tomonidan",
  "End": "Yakun",
  "Enter Your Story Content": "Hikoya kontentingizni kiriting",
  "UPLOAD IMAGES": "RASMLARNI YUKLASH",
  "Click or Drop here": "Bu yerga bosing yoki tashlang",
  "Remove all images": "Barcha rasmlarni olib tashlang",
  "Your post has been properly submitted":
    "Sizning postingiz to'g'ri yuborildi",
  "and is published under": "ostida nashr etiladi",
  "Please copy this for your reference":
    "Iltimos, ma'lumot uchun buni nusxa oling",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "SAVOLINGIZ TO'G'RI TAQDIMLANIB ETILGAN",
};
export const Vietnamese = {
  "User Instructions": "Hướng dẫn sử dụng",
  Contact: "Liên hệ",
  "News or HereSays Herald": "Tin tức hoặc HereSays Herald",
  Disclaimer: "từ chối trách nhiệm",
  "Region or Province or County or State":
    "Vùng hoặc Tỉnh hoặc Hạt hoặc Tiểu bang",
  and: "Và",
  Date: "Ngày",
  Content: "Nội dung",
  "TopicName is required": "Tên chủ đề là bắt buộc",
  "Publisher Name is required": "Tên nhà xuất bản là bắt buộc",
  "Enter Your Feedback": "Nhập phản hồi của bạn",
  HereSays: "ĐâySays",
  Region: "Vùng đất",
  Province: "Tỉnh",
  Heard: "Đã nghe",
  Shared: "chia sẻ",
  Successfully: "thành công",
  Explanation: "Giải trình",
  Language: "Ngôn ngữ",
  Jurisdiction: "Quyền hạn",
  Attention: "Chú ý",
  "LANGUAGE SELECTION": "SỰ LỰA CHỌN NGÔN NGỮ",
  ACCESS: "TRUY CẬP",
  "Terms of use": "Điều khoản sử dụng",
  REFUSE: "TỪ CHỐI",
  ACCEPT: "CHẤP NHẬN",
  READ: "ĐỌC",
  PUBLISH: "CÔNG BỐ",
  CHAT: "TRÒ CHUYỆN",
  "comming soon": "đến sớm",
  Other: "Khác",
  Remark: "Nhận xét",
  Suggestion: "Gợi ý",
  Complaint: "Lời phàn nàn",
  Manual: "Thủ công",
  SEARCH: "TÌM KIẾM",
  SHARE: "CHIA SẺ",
  DENOUNCE: "TỐ CÁO",
  "This post has not been reviewed": "Bài đăng này chưa được xem xét",
  SUBMIT: "NỘP",
  Topic: "Đề tài",
  "Published BY": "Xuất bản bởi",
  English: "Tiếng Anh",
  Country: "Quốc gia",
  City: "Thành phố",
  Place: "Địa điểm",
  "Subject 1": "chủ đề 1",
  "Subject 2": "chủ đề 2",
  "Subject 3": "chủ đề 3",
  "Reference Id": "Id tham chiếu",
  "Enter Email Address": "Nhập địa chỉ email",
  CHOOSE: "CHỌN",
  By: "Qua",
  "End": "Kết thúc",
  "Enter Your Story Content": "Nhập nội dung câu chuyện của bạn",
  "UPLOAD IMAGES": "ĐĂNG TẢI HÌNH ẢNH",
  "Click or Drop here": "Nhấp hoặc thả vào đây",
  "Remove all images": "Xóa tất cả hình ảnh",
  "Your post has been properly submitted":
    "Bài viết của bạn đã được gửi đúng cách",
  "and is published under": "và được xuất bản dưới",
  "Please copy this for your reference": "Xin chép lại để các bạn tham khảo",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "CÂU HỎI CỦA BẠN ĐÃ ĐƯỢC GỬI ĐÚNG CÁCH",
};
export const Welsh = {
  "User Instructions": "Cyfarwyddiadau Defnyddiwr",
  Contact: "Cysylltwch",
  "News or HereSays Herald": "Newyddion neu HereSays Herald",
  Disclaimer: "Ymwadiad",
  "Region or Province or County or State":
    "Rhanbarth neu Dalaith neu Sir neu Dalaith",
  and: "a",
  Date: "Dyddiad",
  Content: "Cynnwys",
  "TopicName is required": "Mae angen TopicName",
  "Publisher Name is required": "Mae angen Enw'r Cyhoeddwr",
  "Enter Your Feedback": "Rhowch Eich Adborth",
  HereSays: "HereSays",
  Region: "Rhanbarth",
  Province: "Talaith",
  Heard: "Clywed",
  Shared: "Wedi'i rannu",
  Successfully: "Yn llwyddianus",
  Explanation: "Eglurhad",
  Language: "Iaith",
  Jurisdiction: "Awdurdodaeth",
  Attention: "Sylw",
  "LANGUAGE SELECTION": "DEWIS IAITH",
  ACCESS: "MYNEDIAD",
  "Terms of use": "Telerau defnyddio",
  REFUSE: "GWRTHOD",
  ACCEPT: "DERBYN",
  READ: "DARLLENWCH",
  PUBLISH: "CYHOEDDI",
  CHAT: "SGWRS",
  "comming soon": "yn dod yn fuan",
  Other: "Arall",
  Remark: "Sylw",
  Suggestion: "Awgrym",
  Complaint: "Cwyn",
  Manual: "Llawlyfr",
  SEARCH: "CHWILIO",
  SHARE: "RHANNWCH",
  DENOUNCE: "GADWAD",
  "This post has not been reviewed": "Nid yw'r swydd hon wedi'i hadolygu",
  SUBMIT: "CYFLWYNO",
  Topic: "Testun",
  "Published BY": "Cyhoeddwyd GAN",
  English: "Saesneg",
  Country: "Gwlad",
  City: "Dinas",
  Place: "Lle",
  "Subject 1": "Pwnc 1",
  "Subject 2": "Pwnc 2",
  "Subject 3": "Pwnc 3",
  "Reference Id": "Cyfeirnod id",
  "Enter Email Address": "Rhowch Cyfeiriad E-bost",
  CHOOSE: "DEWIS",
  By: "Gan",
  "End": "Y diwedd",
  "Enter Your Story Content": "Rhowch Gynnwys Eich Stori",
  "UPLOAD IMAGES": "Llwytho i fyny DELWEDDAU",
  "Click or Drop here": "Cliciwch neu Gollwng yma",
  "Remove all images": "Tynnwch yr holl ddelweddau",
  "Your post has been properly submitted":
    "Mae eich post wedi'i gyflwyno'n gywir",
  "and is published under": "ac fe'i cyhoeddir o dan",
  "Please copy this for your reference": "Copïwch hwn er gwybodaeth",
  "ID:HERESAYS000": "ID: YMA 000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "MAE EICH YMHOLIAD WEDI EI GYFLWYNO'N IAWN",
};
export const Xhosa = {
  "User Instructions": "Imiyalelo yomsebenzisi",
  Contact: "Qhagamshelana",
  "News or HereSays Herald": "Iindaba okanye AphaSays Herald",
  Disclaimer: "Ukuzihlangula",
  "Region or Province or County or State":
    "INgingqi okanye iPhondo okanye iNqila okanye iLizwe",
  and: "kwaye",
  Date: "Umhla",
  Content: "Umxholo",
  "TopicName is required": "Igama lesihloko liyafuneka",
  "Publisher Name is required": "Igama lompapashi liyafuneka",
  "Enter Your Feedback": "Ngenisa Ingxelo Yakho",
  HereSays: "Apha utsho",
  Region: "Ummandla",
  Province: "Iphondo",
  Heard: "Iviwe",
  Shared: "Kwabiwe",
  Successfully: "Iphumelele",
  Explanation: "Ingcaciso",
  Language: "Ulwimi",
  Jurisdiction: "Ulawulo",
  Attention: "Ingqalelo",
  "LANGUAGE SELECTION": "UKUKHETHA ULWIMI",
  ACCESS: "UKUFIKELELA",
  "Terms of use": "Imimmiselo yokusebenzisa",
  REFUSE: "YALA",
  ACCEPT: "YAMKELA",
  READ: "FUNDA",
  PUBLISH: "PAPASHA",
  CHAT: "INGXOXA",
  "comming soon": "iyeza kungekudala",
  Other: "Okunye",
  Remark: "Phawula",
  Suggestion: "Ingcebiso",
  Complaint: "Isikhalazo",
  Manual: "Imanyuwali",
  SEARCH: "KHANGELA",
  SHARE: "YABELANA",
  DENOUNCE: "THOLAKALA",
  "This post has not been reviewed": "Esi sithuba asikaphononongwa",
  SUBMIT: "NGENISA",
  Topic: "Umxholo",
  "Published BY": "Ipapashwe NGU",
  English: "IsiNgesi",
  Country: "Ilizwe",
  City: "Isixeko",
  Place: "Indawo",
  "Subject 1": "Isihloko 1",
  "Subject 2": "Isihloko sesi-2",
  "Subject 3": "Isihloko sesi-3",
  "Reference Id": "I-ID yesalathiso",
  "Enter Email Address": "Ngenisa idilesi ye-imeyile",
  CHOOSE: "KHETHA",
  By: "Ngu",
  "End": "Isiphelo",
  "Enter Your Story Content": "Ngenisa uMxholo weBali lakho",
  "UPLOAD IMAGES": "layisha IMIFANEKISO",
  "Click or Drop here": "Cofa okanye uLahla apha",
  "Remove all images": "Susa yonke imifanekiso",
  "Your post has been properly submitted":
    "Iposti yakho ithunyelwe ngokufanelekileyo",
  "and is published under": "kwaye ipapashwa phantsi",
  "Please copy this for your reference":
    "Nceda khuphela oku kwireferensi yakho",
  "ID:HERESAYS000": "ID:HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED":
    "UMBUZO WAKHO UNIKEZELWE NGOKUFANELEKILEYO",
};
export const Yiddish = {
  "User Instructions": "באַניצער אינסטרוקציעס",
  Contact: "קאָנטאַקט",
  "News or HereSays Herald": "נייַעס אָדער HereSays Herald",
  Disclaimer: "אָפּלייקענונג",
  "Region or Province or County or State":
    "געגנט אָדער פּראַווינס אָדער קאָונטי אָדער שטאַט",
  and: "און",
  Date: "טאָג",
  Content: "אינהאַלט",
  "TopicName is required": "TopicName איז פארלאנגט",
  "Publisher Name is required": "אַרויסגעבער נאָמען איז פארלאנגט",
  "Enter Your Feedback": "אַרייַן דיין באַמערקונגען",
  HereSays: "דאָ סייַס",
  Region: "געגנט",
  Province: "פּראָווינץ",
  Heard: "געהערט",
  Shared: "שערד",
  Successfully: "הצלחה",
  Explanation: "דערקלערונג",
  Language: "שפּראַך",
  Jurisdiction: "דזשוריסדיקשאַן",
  Attention: "ופמערקזאַמקייַט",
  "LANGUAGE SELECTION": "שפּראַך סעלעקציע",
  ACCESS: "אַקסעס",
  "Terms of use": "טערמינען פון נוצן",
  REFUSE: "אָפּזאָגן",
  ACCEPT: "אָננעמען",
  READ: "לייענען",
  PUBLISH: "PUBLISH",
  CHAT: "שמועסן",
  "comming soon": "קומענדיק באַלד",
  Other: "אנדערע",
  Remark: "באַמערקונג",
  Suggestion: "פאָרשלאָג",
  Complaint: "קלאָג",
  Manual: "מאַנואַל",
  SEARCH: "זוכן",
  SHARE: "ייַנטיילן",
  DENOUNCE: "דענאָונס",
  "This post has not been reviewed": "דעם פּאָסטן איז נישט ריוויוד",
  SUBMIT: "SUBMIT",
  Topic: "טעמע",
  "Published BY": "פֿאַרעפֿנטלעכט דורך",
  English: "ענגליש",
  Country: "לאַנד",
  City: "שטאָט",
  Place: "אָרט",
  "Subject 1": "טעמע 1",
  "Subject 2": "טעמע 2",
  "Subject 3": "טעמע 3",
  "Reference Id": "רעפערענץ שייַן",
  "Enter Email Address": "אַרייַן בליצפּאָסט אַדרעס",
  CHOOSE: "קלייַבן",
  By: "דורך",
  "End": "דער סוף",
  "Enter Your Story Content": "אַרייַן דיין סטאָרי אינהאַלט",
  "UPLOAD IMAGES": "ופּלאָאַד בילדער",
  "Click or Drop here": "דריקט אָדער פאַלן דאָ",
  "Remove all images": "אַראָפּנעמען אַלע בילדער",
  "Your post has been properly submitted": "דיין פּאָסטן איז רעכט דערלאנגט",
  "and is published under": "און איז ארויס אונטער",
  "Please copy this for your reference":
    "ביטע נאָכמאַכן דעם פֿאַר דיין רעפֿערענץ",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "דיין אָנפֿרעג איז ריכטיק דערלאנגט",
};
export const Yoruba = {
  "User Instructions": "Awọn Itọsọna olumulo",
  Contact: "Olubasọrọ",
  "News or HereSays Herald": "Iroyin tabi HereSays Herald",
  Disclaimer: "AlAIgBA",
  "Region or Province or County or State":
    "Ekun tabi Agbegbe tabi Agbegbe tabi Ipinle",
  and: "ati",
  Date: "Ọjọ",
  Content: "Akoonu",
  "TopicName is required": "Orukọ Akori nilo",
  "Publisher Name is required": "Orúkọ akéde ni a nílò",
  "Enter Your Feedback": "Tẹ esi rẹ sii",
  HereSays: "Nibi Sọ",
  Region: "Agbegbe",
  Province: "Agbegbe",
  Heard: "gbo",
  Shared: "Pipin",
  Successfully: "Ni aṣeyọri",
  Explanation: "Alaye",
  Language: "Ede",
  Jurisdiction: "Aṣẹ",
  Attention: "Ifarabalẹ",
  "LANGUAGE SELECTION": "ASAYAN EDE",
  ACCESS: "Wiwọle",
  "Terms of use": "Awọn ofin lilo",
  REFUSE: "KỌ",
  ACCEPT: "GBA",
  READ: "KA",
  PUBLISH: "tẹjade",
  CHAT: "OBROLAN",
  "comming soon": "nbo laipe",
  Other: "Omiiran",
  Remark: "Akiyesi",
  Suggestion: "Imọran",
  Complaint: "Ẹdun",
  Manual: "Afowoyi",
  SEARCH: "WÁ",
  SHARE: "Pinpin",
  DENOUNCE: "DINOUNCE",
  "This post has not been reviewed": "Ifiweranṣẹ yii ko ti ṣe atunyẹwo",
  SUBMIT: "GBIGBE",
  Topic: "Koko-ọrọ",
  "Published BY": "Atejade BY",
  English: "English",
  Country: "Orilẹ-ede",
  City: "Ilu",
  Place: "Ibi",
  "Subject 1": "Koko-ọrọ 1",
  "Subject 2": "Koko-ọrọ 2",
  "Subject 3": "Koko-ọrọ 3",
  "Reference Id": "Itọkasi ID",
  "Enter Email Address": "Tẹ adirẹsi imeeli sii",
  CHOOSE: "YAN",
  By: "Nipasẹ",
  "End": "Ipari",
  "Enter Your Story Content": "Tẹ Akoonu Itan Rẹ sii",
  "UPLOAD IMAGES": "Awọn aworan ikojọpọ",
  "Click or Drop here": "Tẹ tabi Ju silẹ nibi",
  "Remove all images": "Yọ gbogbo awọn aworan kuro",
  "Your post has been properly submitted": "Ifiweranṣẹ rẹ ti fi silẹ daradara",
  "and is published under": "ati ki o ti wa ni atejade labẹ",
  "Please copy this for your reference": "Jọwọ daakọ eyi fun itọkasi rẹ",
  "ID:HERESAYS000": "ID: HERESAYS000",
  "YOUR QUERY HAS BEEN PROPERLY SUBMITTED": "A TI FI IBEERE RẸ DARA",
};
