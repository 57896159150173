import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {
      withNamespaces,
      NamespacesConsumer,
      Trans,
      useTranslation,
} from "react-i18next";

function AlertDismissible({ modalVisible, setModalVisible }) {
      const { t, i18n } = useTranslation();
      return (
            <div className='warning_view-box'>
                  <Alert show={modalVisible} variant="success" style={{
                        height: "330px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: 0,
                        color: "#ffe570",
                  }}>
                        <Alert.Heading>{t("WARNING")}</Alert.Heading>
                        <p>
                              {t("The group is now filled, however if someone leaves, you can still join and speak")}</p>

                        <div className="d-flex justify-content-end">
                              <a
                                    onClick={() => {
                                          setModalVisible(false)
                                    }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="close-button-style"
                              >
                                    <span></span>
                              </a>
                        </div>
                  </Alert>


            </div>
      );
}

export default AlertDismissible;