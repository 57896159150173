import React, { useEffect, useState } from "react";
import "./home.css";
import bookImg from "../../assets/images/bookimagenew.png";
import Zoom from "react-reveal/Zoom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../components/modal/popup";
import SocialIcons from "../components/modal/socialIcons";
// import { useSpeechRecognition } from "react-speech-kit";
import { ApiUrl, SocketApiUrl, SocketLocal } from "../../config/config";
import ReadAStory from "../../assets/images/read-a-story-icon.png";
import WriteStory from "../../assets/images/write-a-story-icon.png";
import ChatRoom from "../../assets/images/chatroom-icon.png";
import { Loader } from "../components/loader";
import EventEmitter from "reactjs-eventemitter";
import "../home/home.css";
import Header from "../components/header";
import Banner from "../components/banner";
import avatarman from "../../assets/images/avatar-man.png";
import avatarwoman from "../../assets/images/avatar-woman.png";
import GroupIcon from "../../assets/images/bookimagenew.png";
import searchImg from "../../assets/images/icons/search.png";

import {
  simpleAction,
  setFruitTwo,
  setFruitOne,
  setgroupDataRedux
} from "../../redux/actions/simpleActions";
import { useDispatch } from "react-redux";
import {
  withNamespaces,
  NamespacesConsumer,
  Trans,
  useTranslation,
} from "react-i18next";
// import AllCountryData from "../../constants/locales/CountryTranslation.json"
import io from "socket.io-client";
import Chat from "./Chat";
import "./chat.css";
import GroupModal from "../../chatapp/GroupModal";
import SelectAvatar from "../../chatapp/SelectAvatar";
import ReactHtmlParser from "react-html-parser";
import AddGroupForm from "../../chatapp/AddGroupForm";
import GroupFilter from "../../chatapp/GroupFilter";

// const Socket = io.connect('http://localhost:3000');

// const socket = io.connect(SocketApiUrl);
const Home = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [ShowLanguageModal, setShowLanguageModal] = useState(false);
  const [socialUrl, setSocialUrl] = useState(false);
  const [ThanksTypeModalForGraph, setThanksTypeModalForGraph] = useState(null);
  const [disclaimer, setDisclaimer] = useState();
  const [attentionData, setAttentionData] = useState();
  const [AllLaguages, setAllLaguages] = useState(false);
  const [userAgreementData, setUserAgreementData] = useState(null);
  const [emailType, setEmailType] = useState("");
  const [addBannerStoryData, setAddBannerStoryData] = useState();
  const [NewsModal, setNewsModal] = useState(false);
  const [GroupPopup, setGroupPopUp] = useState(false);
  const [GroupName, setGroupName] = useState("");
  const [chatGroups, setChatGroups] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showSociaModal, setShowSocialModal] = useState(false);
  const [latestStory, setLatestStory] = useState();
  const [PublisherTime, setPublisherTime] = useState();
  const [searchModal, setSearchModal] = useState(false);
  const [searchIcon, setSearchIcon] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [fillData, setFillData] = useState([]);
  const [username, setUsername] = useState("");
  const [room, setRoom] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [firstScreen, setFirstScreen] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const localUserData = JSON.parse(localStorage.getItem("user"));
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [modalShowTerm, setModalShowTerm] = useState(false);


  // const socket = io("https://api.heresays.com", {
  //   withCredentials: true,
  //   extraHeaders: {
  //     "Access-Control-Allow-Origin": "https://heresayswebsite.web.app",
  //   },
  // });
  // const newTermsFlag = localStorage.setItem("terms", false);

  const navigate = useNavigate();
  const getAddBannerData = () => {
    axios.get(`${ApiUrl}getBanner`).then((result) => {
      setAddBannerStoryData(
        result.data.data.filter((x) => x.attributes.published_at != null)
      );
    });
  };
  const closeFilterModal = () => {
    setSearchModal(false);
  };

  const setGroupPopUpFalse = () => {
    setGroupPopUp(false);
    // getAllGroups();
    // getAllUsers();
  };


  useEffect(() => {
    getTermsData()
    if (JSON.parse(localStorage.getItem("terms")) != false) {
      setModalShowTerm(true);
    }
  }, []);

  const getTermsData = () => {
    axios.post(`${ApiUrl}getTermsAndCondition`, { language: localStorage.getItem("prefered_language") }).then((result) => {
      // let requiredTermsData = Object.fromEntries(
      //   Object.entries(
      //     JSON.parse(
      //       decodeURIComponent(
      //         result?.data?.data[0]?.attributes?.description
      //       )
      //     )
      //   )?.filter(([key]) => key.includes(currentLanguage))
      // )[currentLanguage];
      setUserAgreementData(result?.data?.data?.description);
    });
  };


  let currentLanguageSetting = localStorage.getItem("prefered_language")
    ? localStorage.getItem("prefered_language")
    : "en";
  const [currentLanguage, setCurrentLanguage] = useState(
    currentLanguageSetting
  );

  useEffect(() => {
    let currentLanguageSetting = localStorage.getItem("prefered_language")
      ? localStorage.getItem("prefered_language")
      : "en";
    if (currentLanguageSetting == "en") {
      localStorage.setItem("prefered_language", "en");
    }
    i18n.changeLanguage(currentLanguageSetting);
    getAllData();
    getAddBannerData();
    localStorage.setItem("scrollPosition", 0);
    dispatch(simpleAction("sandesh rajawat"));
    dispatch(setFruitOne("apple "));
    dispatch(setFruitTwo("mangoo "));
  }, []);

  useEffect(() => {
    let url = `${ApiUrl}getListStory`;
    axios.get(url).then((res) => {
      let attribute = res?.data?.data;
      const filterData = attribute?.filter((item) => {
        return item.attributes.isDisable === 0;
      });
      filterData &&
        localStorage.setItem("wholeStoryData", JSON.stringify(filterData));
    });

  }, []);

  useEffect(() => {
    if (!loadedOnce) {
      getAllGroups();
      getAllUsers();
      // exitGroup()
      setLoadedOnce(true);
    }
  }, [loadedOnce]);

  useEffect(() => {
    getAllGroups();
    // getAllUsers();
    // exitGroup()
    // setLoadedOnce(true);

  }, []);

  // deleteuser 
  // const exitGroup = () => {

  //   let ApiData = {
  //     userId: JSON.parse(localStorage.getItem("user"))?.userId
  //   };
  //   axios
  //     .post(`${ApiUrl}/deleteChatUser`, ApiData)
  //     .then((res) => {
  //       getAllGroups();
  //       // toastr.info(
  //       //   `${localUserData?.userId} ${t("is")} ${t("deleted")}`
  //       // );
  //       localStorage.removeItem('user');
  //       // handleClose()
  //       // window.location.reload()
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       // toastr.info(`user is not exits in Group`);
  //     });
  //   // remove person from group by socket id

  // }

  const closeNewsModal = () => {
    setSearchIcon(false);
  };

  useEffect(() => {
    let url = `${ApiUrl}getListStory`;
    axios.get(url).then((res) => {
      let attribute = res?.data?.data;
      setLatestStory(attribute[attribute.length - 1]);
      const currentDate = new Date();
      const specificDate = new Date(
        attribute[attribute.length - 1]?.attributes?.published_at
      );
      const timeDiff = (currentDate.getTime() - specificDate.getTime()) / 1000;

      var days = timeDiff / (3600 * 24);
      var finalDay = parseInt(days, 10);
      var date = new Date(timeDiff * 1000);
      var hours = timeDiff / 3600;
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var formattedTime =
        hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

      setPublisherTime(
        `${finalDay} d ${parseInt(hours)} hr ${minutes.substr(-2)} min `
      );
    });
  }, []);

  const getAllData = () => {
    axios
      .all([
        axios.get(`${ApiUrl}i18n_locale`),
        axios.post(`${ApiUrl}getTermsAndCondition`),
        axios.get(`${ApiUrl}getDisclaimer`),
        axios.get(`${ApiUrl}getAttention`),
      ])
      .then(
        axios.spread(
          (firstResponse, secondResponse, thirdResponse, attentionResponse) => {
            setAllLaguages(firstResponse.data);
            // setuserAgreementData(secondResponse.data.data);

            setDisclaimer(thirdResponse.data.data);
            setAttentionData(attentionResponse.data.data);
            const dis = ReactHtmlParser(
              thirdResponse?.data?.data[0]?.attributes
            );
          }
        )
      )
      .catch((error) => console.log(error));
  };
  const OnEmailchange = (text) => {
    setEmailType(text.target.value);
  };

  const onLanguageChange = (data) => {
    localStorage.setItem("prefered_language", data);
    setCurrentLanguage(data);
    getAllData();
    setShowLanguageModal(false);
    setThanksTypeModalForGraph(`${t("YOUR LANGUAGE HAS BEEN CHANGED TO")} ${data}`);
  };

  const AddGroup = () => {
    if (GroupName) {
      let apiData = {
        group_name: GroupName,
      };
      axios
        .post(`${ApiUrl}addNewGroup`, apiData)
        .then((result) => {
          getAllGroups();
          setGroupPopUp(false);
        })
        .catch((err) => { });
    }
  };

  const getAllGroups = async () => {
    const res = await axios.get(`${ApiUrl}getAllGroups`)
    setChatGroups(res?.data?.list);
    dispatch(setgroupDataRedux(res?.data?.list))
  };

  const getAllUsers = () => {
    axios
      .get(`${ApiUrl}/getAllUsers`)
      .then((userData) => {
        setAllUsers(userData?.data?.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchStory = (newQueryString) => {
    let url = `${ApiUrl}getAllGroups`;
    url = url + `?${newQueryString}`;
    setSpinner(true);
    axios.get(url).then((res) => {
      setChatGroups(res?.data?.list);
    });
  };

  function areAllParticipantsEmpty(group) {
    return ["participants1", "participants2", "participants3", "participants4", "participants5"].every(participant => {
      return !group[participant] || group[participant] == undefined || group[participant] == null;
    });
  }

  useEffect(() => {
    deleteGroupIfNoUser()
  }, [chatGroups])

  useEffect(() => {
    if (localStorage.getItem("user")) {
      exitGroup()
    }
  }, [])


  const deleteGroupIfNoUser = async () => {
    const newFillData = await chatGroups?.length > 0 && chatGroups?.filter(group => areAllParticipantsEmpty(group)).map(group => group.groupid);

    newFillData?.length > 0 && newFillData?.map((groupid) => {
      let ApiData = {
        groupid: groupid,
      };
      axios
        .post(`${ApiUrl}/deleteGroupParticipants`, ApiData)
        .then(async (res) => {
          // await getAllUsers();
          await getAllGroups();
        })
        .catch((err) => {
          console.log(err);
        });
    })

  }

  const exitGroup = () => {

    let ApiData = {
      userId: JSON.parse(localStorage.getItem("user"))?.userId
    };
    axios
      .post(`${ApiUrl}/deleteChatUser`, ApiData)
      .then((res) => {
        getAllGroups();
        // toastr.info(
        //   `${localUserData?.userId} ${t("is")} ${t("deleted")}`
        // );
        // if (socket) {
        //   socket?.emit('removeUserFromGroup', localUserData?.name, GroupData?.group_name);

        // }
        localStorage.removeItem('user');
        // handleClose()
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        // toastr.info(`user is not exits in Group`);
      });
    // remove person from group by socket id

  }

  if (!props.imagesPreloaded) return <Loader />;

  return (
    <>
      <div className="container1 Container_new mobile_height">
        <Header
        // languageRefresh={props.languageRefresh}
        // setLanguageRefresh={props.setLanguageRefresh}
        />
        <div className="middle mobile_middle homeMiddletop">
          <div className="B1 tabs_view"></div>
          <div className="B2 cards custome_b2 b2cardnew">
            <Link
              to="/storylist2"
              onClick={() => {
                navigate("/storylist2");
              }}
              className="welcome-item animate__animated duration-animation-choice-home animate__rotateInDownLeft home-img-div"
            >
              <img src={ReadAStory} alt="img" />
              <span className="read">{t("READ")}</span>
            </Link>
            <Link
              to="/writestory"
              className="welcome-item animate__animated duration-animation-choice-home animate__fadeInUp home-img-div"
            >
              <img src={WriteStory} alt="img" />
              <span className="publish11">{t("PUBLISH")}</span>
            </Link>
            <Link
              to="#"
              data-toggle="modal"
              onClick={() => {
                setNewsModal(true);
              }}
              data-target="#NewsModal"
              className="welcome-item animate__animated duration-animation-choice-home animate__rotateInDownRight home-img-div"
            >
              <img src={ChatRoom} alt="img" />
              <span className="chat">{t("CHAT")}</span>
            </Link>
          </div>
          <div className="B3 tabs_view_books">
            {/* <SocialIcons
                Translate={Translate}
                setSocialUrl={setSocialUrl}
                showSociaModal={showSociaModal}
                setShowSocialModal={setShowSocialModal}
              /> */}
            <div className="d-block d-md-none d-lg-none d-xl-none">
              <div className="bottom_outer_block">
                <h2 className="title_books">
                  {latestStory?.attributes?.topic_name}
                </h2>
                <div className="book_infored">
                  <div className="bottom_inner_block">
                    <p>{latestStory?.attributes?.topic_details}</p>
                    <img
                      className="storylist_bool_img  icon iconbook shareIcon"
                      onClick={() => {
                        navigate(
                          `/readstorybook/${latestStory?.id}`
                        );
                      }}
                      src={bookImg}
                      alt="img"
                    />
                    <p>
                      {PublisherTime}
                      { }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="continue-btn-group">
          <Zoom when={NewsModal} center>
            <div
              id="NewsModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="NewsModal"
              aria-hidden="true"
              data-backdrop="false"
              className={
                NewsModal
                  ? "modal fade zoom-in zoom-in-right show"
                  : "modal fade zoom-in zoom-in-right"
              }
            >
              <div
                role="document"
                className="modal-dialog modal-dialog-centered sociallogin-modal-dialog chat_modal"
              >
                <div>
                  {" "}
                  <h3
                    style={{
                      textAlign: "center",
                      color: "#cfa741",
                      fontFamily: "sanitarium_bbregular",
                      marginBottom: "0%",
                    }}
                  >
                    {t("CHOOSE")}
                  </h3>
                </div>
                <div>
                  {" "}
                  {!searchModal && !GroupPopup && searchIcon ? (
                    <button
                      onClick={() => {
                        setGroupPopUp(true);
                      }}
                      className="create-group-btn mobile_new_grup_icon"
                    >
                      <img src={GroupIcon} />
                    </button>
                  ) : null}
                </div>

                <div className="modal-content">
                  <a
                    onClick={() => {
                      setNewsModal(false);
                      setShowGroup(false);
                      window.location.reload();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                    className="close-button-style"
                  >
                    <span></span>
                  </a>
                  {/* search filter  */}
                  {searchIcon ? (
                    <div className="help-icon searchIcon emailDiv position-absolute top-0 start-0">
                      <div className="likeCount1">
                        <div
                          className="searchDivMain"
                          onClick={() => {
                            setSearchModal(true);
                          }}
                        >
                          <div
                            className="searchDiv"
                          // disabled={isDisabled ? "true" : "false"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                setSearchModal(true);
                              }}
                              className="welcome-item animate__animated duration-animation-choice-home animate__rotateInDownLeft home-img-div"
                            >
                              <img
                                className="icon iconbook searchIcon"
                                src={searchImg}
                                alt="img"
                              />
                              <span className="sLSearch">{t("SEARCH")}</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {allUsers.length > 0 && chatGroups.length > 0 ?
                    <GroupModal
                      // socket={"Socket"}
                      groupdata={chatGroups}
                      usersData={allUsers}
                      closeNewsModal={closeNewsModal}
                    /> : <div className="modal-body parentbody-list text-center">
                      <div className="storylist-list chat-modal-boxes"></div></div>
                  }
                </div>
                {/* <div>sdf</div> */}
              </div>
            </div>
          </Zoom>
          {/* NewsModal End*/}
          <Zoom when={GroupPopup} center>
            <div
              id="DisclaimerModall"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="NewsModal"
              aria-hidden="true"
              data-backdrop="false"
              className={
                GroupPopup
                  ? "modal fade zoom-in zoom-in-right show modal-dialog-centered"
                  : "modal fade zoom-in zoom-in-right"
              }
            >
              <AddGroupForm
                setGroupPopUpFalse={setGroupPopUpFalse}
                CallgetAllGroups={getAllGroups}
                CallgetAllUsers={getAllUsers}
              // socket={socket}
              />
            </div>
          </Zoom>

          {/* group filter pop up  */}
          <Zoom when={searchModal} center>
            <div
              id="DisclaimerModall"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="ContactModal"
              aria-hidden="true"
              data-backdrop="false"
              className={
                searchModal
                  ? "modal overflow-hidden fade zoom-in zoom-in-left show"
                  : "modal fade zoom-in zoom-in-left"
              }
              style={searchModal ? { display: "block" } : { display: "none" }}
            >
              {/* <div className="modal-dialog modal-dialog-centered contact-modal-dialog">
                        <div className="modal-body border-style-8">
                            <div className="contact-body-in">
                                <div className="contact-checkbox-group"> */}
              <GroupFilter
                searchStory={searchStory}
                closemodal={() => setSearchModal(!searchModal)}
              />
              {/* </div>
                            </div>
                        </div>
                    </div> */}
            </div>
          </Zoom>

          {/* terms modal  */}
          {modalShowTerm && (
            <div
              id="TermsModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LanguageModal"
              aria-hidden="true"
              data-backdrop="static"
              // className="modal fade zoom-in-center show"
              className={
                modalShowTerm
                  ? "modal fade zoom-in zoom-in-right show"
                  : "modal fade zoom-in zoom-in-right"
              }
            >
              <div className="modal-dialog modal-dialog-centered terms-modal-dialog terms-modal">
                <div className="modal-content modal-content-term">
                  <div className="modal-body">
                    <div className="terms-wrapper">
                      <div className="terms-wrap terms-wrap-term">
                        <div className="disclaimer-title-head cmn-title-head text-center">
                          <h2>
                            <span>{t("Terms of use")}</span>
                          </h2>
                        </div>
                        <div className="terms-info terms-info-term">

                          {" "}
                          {!userAgreementData ?
                            <div className="manual-loader-window">
                              <div style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                margin: "auto",
                                // height: "50%",
                              }} className="spinner-border" role="status">/
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div> :
                            ReactHtmlParser(
                              userAgreementData
                            )}
                        </div>
                      </div>
                      <div className="terms-footer acc_ref">
                        <a
                          data-dismiss="modal"
                          onClick={() => {
                            // setModalShowTerm(false);
                          }}
                          className="btn-dontagree refuse"
                          style={{ textDecoration: "none" }}
                        >
                          <span className="btn-d-text">{t("REFUSE")}</span>
                        </a>
                        <a
                          onClick={() => {
                            setModalShowTerm(false); localStorage.setItem("terms", false);
                          }}
                          data-dismiss="modal"
                          className="btn-agree accept"
                          style={{ textDecoration: "none" }}
                        >
                          <span>{t("ACCEPT")}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        <app-footer-panel>
          <div className="bottom-wrapper-initial-mobile">
            <div className="">
              <Banner className="footer_home" />
            </div>
          </div>
        </app-footer-panel>
      </div>
      <Popup
        socialUrl={socialUrl}
        showSociaModal={showSociaModal}
        setShowSocialModal={setShowSocialModal}
      />
    </>
  );
};

export default Home;

// <Chat
// socket={socket}
// username={username}
// room={room}
// />
